var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: {
            title: _vm.$t("VERIFICATION__RESET_PROFILE_TITLE"),
            "has-search-bar": false
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "main-inner" }, [
          _c(
            "div",
            { staticClass: "profile" },
            [
              _c("breadcrumb", { attrs: { paths: _vm.paths } }),
              _vm._v(" "),
              _c("div", { staticClass: "main-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("VERIFICATION__RESET_PROFILE_TITLE")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "profile-main" },
                [
                  _c("dl", { staticClass: "profile-warning" }, [
                    _c("dt", { staticClass: "profile-warning__title" }, [
                      _vm._v(_vm._s(_vm.$t("PROFILE__DESCRIPTION")))
                    ]),
                    _vm._v(" "),
                    _c("dd", [
                      _c("ol", { staticClass: "profile-warning__list" }, [
                        _c("li", { staticClass: "profile-warning__item" }, [
                          _vm._v(_vm._s(_vm.$t("PROFILE__WARNING_1")))
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "profile-warning__item" }, [
                          _vm._v(_vm._s(_vm.$t("PROFILE__WARNING_2")))
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "profile-warning__item" }, [
                          _vm._v(_vm._s(_vm.$t("PROFILE__WARNING_3")))
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.isDataReady
                    ? [
                        _c("div", { staticClass: "profile-data" }, [
                          _c(
                            "dl",
                            { staticClass: "profile-data__row" },
                            [
                              false
                                ? [
                                    _c(
                                      "dt",
                                      { staticClass: "profile-data__title" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "VERIFICATION__RESET_PROFILE_PERSONAL_DATA"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "dd",
                                      { staticClass: "profile-data__item" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "profile-data__item-name"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "VERIFICATION__RESET_PROFILE_PERSONAL_DATA_NAME"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "profile-data__item-value"
                                          },
                                          [_vm._v("*********")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "dd",
                                      { staticClass: "profile-data__item" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "profile-data__item-name"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "VERIFICATION__RESET_PROFILE_PERSONAL_DATA_ID"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "profile-data__item-value"
                                          },
                                          [_vm._v("************")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("dd", {
                                      staticClass:
                                        "profile-data__item profile-data__changeprofile",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "VERIFICATION__RESET_PROFILE_PERSONAL_DATA_DESC"
                                          )
                                        )
                                      }
                                    })
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "profile-formwrap" }, [
                                _c(
                                  "div",
                                  { staticClass: "profile-data__title" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "VERIFICATION__RESET_PROFILE_APPLICATION"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "form",
                                  { staticClass: "profile-form" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "profile-form__row" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "profile-form__inline"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "profile-form__label profile-form__label--t0"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "VERIFICATION__RESET_PROFILE_APPLICATION_SERVICE"
                                                      )
                                                    ) +
                                                    "\n                        "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "profile-form__field"
                                              },
                                              [
                                                _vm.verificationTypes.includes(
                                                  _vm.constants
                                                    .APPLICATION__VERIFICATION_TYPES
                                                    .CANCEL_PHONE
                                                )
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "profile-form__field-ssection"
                                                        },
                                                        [
                                                          _c(
                                                            "custom-checkbox",
                                                            {
                                                              attrs: {
                                                                name:
                                                                  "verification_service",
                                                                label: _vm.$t(
                                                                  "VERIFICATION__RESET_PROFILE_APPLICATION_CANCEL_PHONE"
                                                                )
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.hasCancelPhoneChecked,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.hasCancelPhoneChecked = $$v
                                                                },
                                                                expression:
                                                                  "hasCancelPhoneChecked"
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.verificationTypes.includes(
                                                  _vm.constants
                                                    .APPLICATION__VERIFICATION_TYPES
                                                    .CANCEL_EMAIL
                                                )
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "profile-form__field-ssection"
                                                        },
                                                        [
                                                          _c(
                                                            "custom-checkbox",
                                                            {
                                                              attrs: {
                                                                name:
                                                                  "verification_service",
                                                                label: _vm.$t(
                                                                  "VERIFICATION__RESET_PROFILE_APPLICATION_CANCEL_EMAIL"
                                                                )
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.hasCancelEmailChecked,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.hasCancelEmailChecked = $$v
                                                                },
                                                                expression:
                                                                  "hasCancelEmailChecked"
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.verificationTypes.includes(
                                                  _vm.constants
                                                    .APPLICATION__VERIFICATION_TYPES
                                                    .RESET_PASSWORD
                                                )
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "profile-form__field-ssection"
                                                        },
                                                        [
                                                          _c(
                                                            "custom-checkbox",
                                                            {
                                                              attrs: {
                                                                name:
                                                                  "verification_service",
                                                                label: _vm.$t(
                                                                  "VERIFICATION__RESET_PROFILE_APPLICATION_RESET_PASSWORD"
                                                                )
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.hasResetPasswordChecked,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.hasResetPasswordChecked = $$v
                                                                },
                                                                expression:
                                                                  "hasResetPasswordChecked"
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.hasResetPasswordChecked
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "profile-form__row"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "profile-form__inline"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "profile-form__label profile-form__label--t0"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                            " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY"
                                                            )
                                                          ) +
                                                          "\n                          "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "profile-form__field"
                                                    },
                                                    [
                                                      false
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "profile-form__field-ssection"
                                                            },
                                                            [
                                                              _c(
                                                                "custom-radio",
                                                                {
                                                                  attrs: {
                                                                    name:
                                                                      "notification_type",
                                                                    label: _vm.$t(
                                                                      "VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY_SMS"
                                                                    ),
                                                                    "radio-value":
                                                                      _vm
                                                                        .constants
                                                                        .APPLICATION__VERIFICATION_NOTIFICATION_TYPES
                                                                        .SMS
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.notificationType,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.notificationType = $$v
                                                                    },
                                                                    expression:
                                                                      "notificationType"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      false
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "profile-form__field-ssection"
                                                            },
                                                            [
                                                              _c(
                                                                "custom-radio",
                                                                {
                                                                  attrs: {
                                                                    name:
                                                                      "notification_type",
                                                                    label: _vm.$t(
                                                                      "VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY_EMAIL"
                                                                    ),
                                                                    "radio-value":
                                                                      _vm
                                                                        .constants
                                                                        .APPLICATION__VERIFICATION_NOTIFICATION_TYPES
                                                                        .EMAIL
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.notificationType,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.notificationType = $$v
                                                                    },
                                                                    expression:
                                                                      "notificationType"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "profile-form__field-ssection"
                                                        },
                                                        [
                                                          _vm.isNotifyByEmail
                                                            ? [
                                                                _c(
                                                                  "custom-input",
                                                                  {
                                                                    attrs: {
                                                                      name:
                                                                        "notification_info",
                                                                      type:
                                                                        "email",
                                                                      "custom-type":
                                                                        _vm
                                                                          .constants
                                                                          .GENERAL__INPUT_TYPES
                                                                          .EMAIL,
                                                                      placeholder: _vm.$t(
                                                                        "GENERAL__INPUT_PLACEHOLDER_HALFWIDTH"
                                                                      ),
                                                                      "error-msg": _vm.$t(
                                                                        "GENERAL__INPUT_ERROR_EMAIL_FORMAT"
                                                                      )
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.notificationInfo,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.notificationInfo = $$v
                                                                      },
                                                                      expression:
                                                                        "notificationInfo"
                                                                    }
                                                                  }
                                                                )
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.isNotifyBySms
                                                            ? [
                                                                _c(
                                                                  "phone-input",
                                                                  {
                                                                    attrs: {
                                                                      name:
                                                                        "notification_info",
                                                                      placeholder: _vm.$t(
                                                                        "GENERAL__INPUT_PLACEHOLDER_HALFWIDTH_INT"
                                                                      ),
                                                                      "error-msg": _vm.$t(
                                                                        "GENERAL__INPUT_ERROR_PHONE_FORMAT"
                                                                      )
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.notificationInfo,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.notificationInfo = $$v
                                                                      },
                                                                      expression:
                                                                        "notificationInfo"
                                                                    }
                                                                  }
                                                                )
                                                              ]
                                                            : _vm._e()
                                                        ],
                                                        2
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "profile-form__row" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "profile-form__inline"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "profile-form__label"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "VERIFICATION__RESET_PROFILE_APPLICATION_UPLOAD"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "profile-form__field"
                                              },
                                              [
                                                _c("uploader", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "GENERAL__BUTTON_UPLOAD_ID"
                                                    ),
                                                    "show-notice": false,
                                                    "is-required": true,
                                                    "is-img-only": true,
                                                    "max-file-cnt":
                                                      _vm.constants
                                                        .GENERAL__MAX_FILE_CNT
                                                        .APPLICATION,
                                                    "error-msg": _vm.$t(
                                                      "GENERAL__INPUT_ERROR_FILE_NOT_SELECTED"
                                                    ),
                                                    name: "files"
                                                  },
                                                  on: {
                                                    uploading:
                                                      _vm.updateUploadingFiles,
                                                    uploaded:
                                                      _vm.addUploadedFileIds,
                                                    removed:
                                                      _vm.removeUploadedFileIds
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("ol", {
                                                  staticClass:
                                                    "onlineservice-form__uploader-info",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$t(
                                                        "CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD_NOTICE_1"
                                                      )
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "profile-form__divider"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "profile-form__row" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "profile-form__inline"
                                          },
                                          [
                                            _c("div", {
                                              staticClass: "profile-form__label"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "profile-form__field"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "profile-form__field-ck"
                                                  },
                                                  [
                                                    _c("custom-checkbox", {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "CANT_LOGIN__ACCOUNT_RECOVERY_CHECKBOX"
                                                        ),
                                                        name: "tos_checkbox"
                                                      },
                                                      model: {
                                                        value: _vm.isTosChecked,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.isTosChecked = $$v
                                                        },
                                                        expression:
                                                          "isTosChecked"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "profile-form__next"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "rountbutton"
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "rountbutton__widget",
                                                            attrs: {
                                                              disabled: !_vm.isButtonEnabled
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.submitApplication.apply(
                                                                  null,
                                                                  arguments
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "GENERAL__BUTTON_SUBMIT_FORM"
                                                                  )
                                                                ) +
                                                                "\n                                "
                                                            ),
                                                            _vm.isSubmitting
                                                              ? _c(
                                                                  "vue-countdown",
                                                                  {
                                                                    attrs: {
                                                                      time: 10000
                                                                    },
                                                                    on: {
                                                                      end:
                                                                        _vm.handleCountdownEnd
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "default",
                                                                          fn: function(
                                                                            props
                                                                          ) {
                                                                            return [
                                                                              _vm._v(
                                                                                "(" +
                                                                                  _vm._s(
                                                                                    props.totalSeconds
                                                                                  ) +
                                                                                  ")"
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      1521751193
                                                                    )
                                                                  }
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.isTosChecked &&
                                                        !_vm.isButtonEnabled &&
                                                        !_vm.isSubmitting
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "report-form__error"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "TICKET__SUBMIT_NOTICE"
                                                                      )
                                                                    ) +
                                                                    "\n                              "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  2
                                )
                              ])
                            ],
                            2
                          )
                        ])
                      ]
                    : [
                        _c("div", { staticClass: "loading" }, [
                          _c("img", {
                            staticClass: "icon-loading",
                            attrs: {
                              src:
                                _vm.CDN + "/assets/pc/img/common/loading.gif",
                              alt: ""
                            }
                          })
                        ])
                      ]
                ],
                2
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }