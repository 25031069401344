import { render, staticRenderFns } from "./ApplicationList.vue?vue&type=template&id=b450b1d2&scoped=true&"
import script from "./ApplicationList.vue?vue&type=script&lang=js&"
export * from "./ApplicationList.vue?vue&type=script&lang=js&"
import style0 from "./ApplicationList.vue?vue&type=style&index=0&id=b450b1d2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b450b1d2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/support.garena.all/frontend/main/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b450b1d2')) {
      api.createRecord('b450b1d2', component.options)
    } else {
      api.reload('b450b1d2', component.options)
    }
    module.hot.accept("./ApplicationList.vue?vue&type=template&id=b450b1d2&scoped=true&", function () {
      api.rerender('b450b1d2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pc/pages/ApplicationList.vue"
export default component.exports