<template>
  <div class="notification-wrap">
    <div class="notification-dimmer">
      <div class="notification notification--sm">
        <button class="rountbutton__widget"
                @click.prevent="closePopup">{{ $t('M_HOME__SERVICE_RECORD_POPUP_BUTTON') }}</button>
        <div class="notification-inner">
          <div class="notification__title">
            <div class="notification__title-txt">{{ $t('M_HOME__SERVICE_RECORD_POPUP_TITLE') }}</div>
          </div>
          <div class="notification__main">
            <div class="notification__content">
              <div class="notification__content-txt">
                <router-link :to="{ name: 'MobileReportList' }">
                  <button>{{ $t('M_HOME__SERVICE_RECORD_POPUP_OPTION_REPORT') }}</button>
                </router-link>
                <router-link :to="{ name: 'MobileApplicationList' }">
                  <button>{{ $t('M_HOME__SERVICE_RECORD_POPUP_OPTION_SERVICE') }}</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PopupChooseRecord',
  computed: {
    ...mapGetters([
      'isFbUser',
    ]),
  },
  methods: {
    closePopup() {
      this.$emit('close');
    },
  },
  created() {
    if (this.isFbUser) {
      this.$router.push({ name: 'MobileReportList' });
    }
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .notification--no_button{
    padding: 24px 10px;
  }
  .notification__main {
    margin: 0;
  }
  .notification__content-txt button {
    vertical-align: middle;
    font-size: 14px;
    color: #5F5F5F;
    border: solid 1px $general-input-border-color;
    border-radius: 150px;
    width: 100%;
    margin: 5px 2px;
    text-align: center;
    background-color: #fff;
    padding: 10px;
  }
}
</style>
