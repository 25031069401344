<template>
  <div class="onlineservicedet-reply">
    <div class="onlineservicedet-panel">
      <div class="onlineservicedet-record">
        <div class="onlineservicedet-record__date">
          {{ utils.formatTimestampToString(createdAt, $t('GENERAL__DATETIME_FORMAT_2')) }}
        </div>
      </div>
      <div class="onlineservicedet-record__dialogue">
        <div :class="[isStaff ? 'onlineservicedet-record__avatar-cs' : 'onlineservicedet-record__avatar']">
          <div v-if="!isStaff" class="onlineservicedet-record__name">
            {{ displayName }}
          </div>
          <img :src="displayAvatar" class="onlineservicedet-record__avatarimg" alt="" />
          <div v-if="isStaff" class="onlineservicedet-record__name">
            {{ displayName }}
          </div>
        </div>
        <div class="onlineservicedet-record__box">
          <div :class="[isStaff ? 'onlineservicedet-record__arrow-cs' : 'onlineservicedet-record__arrow']"></div>
          <div class="onlineservicedet-record__main">
            <div class="onlineservicedet-record__content">
              <div class="onlineservicedet-record__content-inner">
                <div class="onlineservicedet-record__content-title">
                  {{ $t('REPORT__REPLY_TITLE_USER') }}
                </div>
                <div class="onlineservicedet-record__content-txt">
                  <template v-if="isStaff">
                    <!-- only staff replies allows html -->
                    <span v-html="content"></span>
                  </template>
                  <template v-else>
                    <span>{{ content }}</span>
                  </template>
                </div>
                <!-- attachment -->
                <template v-if="isStaff && attachments.length > 0">
                  <div class="onlineservicedet-record__content-panel">
                    <div class="onlineservicedet-record__content-title">
                      {{ $t('REPORT__REPLY_ATTACHMENT') }}
                    </div>
                  </div>
                  <div class="attachment">
                    <div v-for="(attachment, idx) in attachments" :key="idx" class="attachment-txt">
                      {{ attachment.filename }}
                      <a :href="attachment.url" target="_blank">[{{ $t('REPORT__REPLY_DOWNLOAD') }}]</a>
                    </div>
                  </div>
                </template>
                <template v-else-if="!isStaff && numOfAttachments > 0">
                  <div class="onlineservicedet-record__content-panel">
                    <div class="onlineservicedet-record__content-title">
                      {{ $t('REPORT__REPLY_ATTACHMENT') }}
                    </div>
                  </div>
                  <div class="attachment">
                    <div class="attachment-txt">{{ $t('REPORT__REPLY_ATTACHMENT_NUM') }}{{ numOfAttachments }}</div>
                    <div class="attachment-txt-notice">
                      {{ $t('REPORT__REPLY_ATTACHMENT_NOTICE') }}
                    </div>
                  </div>
                </template>
                <!-- attachment /-->
                <!-- rate -->
                <template v-if="isStaff && !hideRated">
                  <div class="onlineservicedet-record__content-panel">
                    <div class="onlineservicedet-record__content-title">
                      {{ $t('REPORT__REPLY_RATE_TITLE') }}
                    </div>
                  </div>
                  <template v-if="hasRated">
                    <div class="helpful">
                      <label class="helpful-radio helpful-radio__disabled">
                        <div class="helpful-txt">
                          {{ $t('REPORT__REPLY_RATE_YES') }}
                        </div>
                        <input v-if="isHelpful" type="radio" :name="'helpful-' + replyId" class="helpful-input" checked="checked" />
                        <div class="helpful-icon helpful-icon--helpful"></div>
                      </label>
                      <label class="helpful-radio helpful-radio__disabled">
                        <div class="helpful-txt">
                          {{ $t('REPORT__REPLY_RATE_NO') }}
                        </div>
                        <input v-if="!isHelpful" type="radio" :name="'helpful-' + replyId" class="helpful-input" checked="checked" />
                        <div class="helpful-icon helpful-icon--unhelpful"></div>
                      </label>
                    </div>
                  </template>
                  <template v-else>
                    <div class="helpful">
                      <label class="helpful-radio" :class="{ 'helpful-radio__disabled': hasSentRate }">
                        <div class="helpful-txt">
                          {{ $t('REPORT__REPLY_RATE_YES') }}
                        </div>
                        <input
                          class="helpful-input"
                          type="radio"
                          :name="'helpful-' + replyId"
                          :disabled="hasSentRate"
                          :value="constants.TICKET__TICKET_REPLY_RATE.IS_HELPFUL"
                          v-model="hasRatedAndIsHelpful"
                        />
                        <div class="helpful-icon helpful-icon--helpful"></div>
                      </label>
                      <label class="helpful-radio" :class="{ 'helpful-radio__disabled': hasSentRate }">
                        <div class="helpful-txt">
                          {{ $t('REPORT__REPLY_RATE_NO') }}
                        </div>
                        <input
                          class="helpful-input"
                          type="radio"
                          :name="'helpful-' + replyId"
                          :disabled="hasSentRate"
                          :value="constants.TICKET__TICKET_REPLY_RATE.IS_NOT_HELPFUL"
                          v-model="hasRatedAndIsHelpful"
                        />
                        <div class="helpful-icon helpful-icon--unhelpful"></div>
                      </label>
                    </div>
                  </template>
                </template>
                <!-- rate /-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import constants from '@/lib/constants';
import ticket from '@/api/prod/ticket';
import utils from '@/lib/utils';
import { ACTION_TRIGGER_POPUP } from '@/store/action-types';

export default {
  name: 'Reply',
  props: {
    csNumber: Number,
    replyId: Number,
    type: Number,
    createdAt: Number,
    creatorType: Number,
    content: String,
    attachments: Array,
    numOfAttachments: Number,
    staffName: String,
    hasRated: Boolean,
    isHelpful: Boolean,
    hideRated: Boolean,
  },
  data() {
    return {
      constants,
      utils,
      hasSentRate: false,
      hasRatedAndIsHelpful: null,
    };
  },
  computed: {
    isStaff() {
      return this.creatorType === constants.TICKET__TICKET_REPLY__CREATOR_TYPES.STAFF;
    },
    displayName() {
      if (this.isStaff) {
        return this.staffName;
      }
      return this.userInfo.name || '';
    },
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    displayAvatar() {
      if (this.isStaff) {
        return 'https://support.cdn.garenanow.com/support-center/common/garena-logo-s.jpg';
      }
      return this.userInfo.avatar;
    },
  },
  methods: {
    rateTicketReply(isHelpful) {
      const payload = {
        is_helpful: isHelpful,
      };
      ticket.rateTicketReply(this.csNumber, this.replyId, payload).then((resp) => {
        const { data, error } = resp;
        if (error || !data) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_EXCEPTION'),
          });
        } else {
          this.hasSentRate = true;
          this.triggerPopup({
            title: this.$t('POPUP__ALERT', {
              csNumber: data.csNumber,
            }),
            content: this.$t('POPUP__ALERT_TICKET_REPLY_RATE_SUCCEED_CONTENT', { csNumber: data.csNumber }),
          });
        }
      });
    },
    ...mapActions({
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
  },
  watch: {
    hasRatedAndIsHelpful(newValue) {
      this.rateTicketReply(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .onlineservicedet-record__content-txt {
    overflow-wrap: break-word;
  }
  .onlineservicedet-record__content-txt ::v-deep strong,
  .onlineservicedet-record__content-txt ::v-deep strong em {
    font-weight: bold;
  }
  .onlineservicedet-record__content-txt ::v-deep em {
    font-style: italic;
  }
  .attachment .attachment-txt {
    margin: 10px 0;
  }
  .onlineservicedet-record__content-txt ::v-deep a,
  .attachment .attachment-txt a {
    color: $general-red;
  }
  .onlineservicedet-record__content-txt ::v-deep a:hover,
  .attachment .attachment-txt a:hover {
    text-decoration: underline;
  }
  .attachment-txt-notice {
    font-size: 0.8rem;
    color: $general-light-text-color;
  }
  .helpful-radio {
    cursor: pointer;
  }
  .helpful-radio__disabled {
    cursor: not-allowed;
  }
}
</style>
