/* eslint-disable no-useless-escape */

const moment = require('moment-timezone');

const gopLoginEndPoint = '/oauth/login';
const gopLogoutEndPoint = '/oauth/logout';

const fakeWindow = {
  atob: function atob() {},
  open: function open() {},
  location: {},
  localStorage: {
    setItem: function setItem() {},
    getItem: function getItem() {},
    removeItem: function removeItem() {},
  },
};

const $window = typeof window !== 'undefined' ? window : fakeWindow;

function getCookie(name) {
  const str = RegExp(`${name}[^;]+`).exec(document.cookie);
  return decodeURIComponent(str ? str.toString().replace(/^[^=]+./, '') : '');
}

function setCookie(name, value, expireSeconds) {
  const expires = new Date();
  expires.setTime(expires.getTime() + expireSeconds * 1000);
  document.cookie = `${name}=${escape(value)}${expireSeconds == null ? '' : `;expires=${expires.toGMTString()}`};path=/`;
}

function delCookie(name) {
  const expires = new Date();
  expires.setTime(expires.getTime() - 1);
  const cookieValue = getCookie(name);
  if (cookieValue != null) {
    document.cookie = `${name}=${cookieValue};expires=${expires.toGMTString()}`;
  }
}

function getCSRFToken() {
  return getCookie('csrftoken');
}

function buildLoginUrl(platformId = 1) {
  let locale = 'en-US';
  if (process.env.REGION === 'tw') {
    locale = 'zh-TW';
  }
  return `${process.env.GOP_HOST}${gopLoginEndPoint}?response_type=token&locale=${locale}&redirect_uri=${encodeURIComponent(
    `${$window.location.protocol}//${$window.location.hostname}/`,
  )}&client_id=${process.env.GOP_APP_ID}&platform=${platformId}`;
}

function buildLogoutUrl(accessToken) {
  return `${process.env.GOP_HOST}${gopLogoutEndPoint}?access_token=${accessToken}`;
}

function formatTimestampToString(timestamp, format = 'YYYY-MM-DD HH:mm:ss', isMillisecond = false) {
  const ts = isMillisecond ? timestamp : timestamp * 1000;
  return moment(ts).format(format);
}

function convertDatetimeStringFormat(fromDatetimeStr, fromFormat, toFormat) {
  const momentObj = moment(fromDatetimeStr, fromFormat);
  return momentObj.isValid() ? momentObj.format(toFormat) : null;
}

function isValidGarenaAccount(email) {
  const re = /^[a-zA-Z0-9]([a-zA-Z0-9\-_.]){5,14}$/;
  return re.test(email);
}

function isValidEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function nl2br(content) {
  return content.replace(/\n/g, '<br/>');
}

function objBoolean2Int(obj) {
  const ret = {};
  Object.keys(obj).forEach((key) => {
    ret[key] = typeof obj[key] === 'boolean' ? +obj[key] : obj[key];
  });
  return ret;
}

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

function isEmptyArray(arr) {
  return arr.length === 0;
}

export default {
  getCookie,
  setCookie,
  delCookie,
  getCSRFToken,
  buildLoginUrl,
  buildLogoutUrl,
  formatTimestampToString,
  convertDatetimeStringFormat,
  isValidGarenaAccount,
  isValidEmail,
  nl2br,
  objBoolean2Int,
  isEmptyObject,
  isEmptyArray,
};
