var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "phone_input-wrap" }, [
    _c(
      "div",
      { staticClass: "input-wrap" },
      [
        _c("vue-tel-input", {
          ref: "input",
          class: { "vue-tel-input--error": !_vm.isValid },
          attrs: {
            placeholder: _vm.placeholder,
            name: _vm.name,
            enabledCountryCode: true,
            validCharactersOnly: true,
            preferredCountries: ["TW", "HK", "MO"],
            disabledFetchingCountry: true,
            mode: "international"
          },
          on: { input: _vm.onInputChange, close: _vm.onDropdownClose },
          model: {
            value: _vm.phone,
            callback: function($$v) {
              _vm.phone = $$v
            },
            expression: "phone"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "report-form__note" }, [
      !_vm.isValid
        ? _c("div", { staticClass: "report-form__error" }, [
            _vm._v(_vm._s(_vm.errorMsg))
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }