/* eslint-disable */

import api from '@/api';

function getGames() {
  return api.call(api.GET, `/games`);
}

// function getGames() {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: {
//         mobile: [
//           {
//             id: 14,
//             code: 'fam',
//             name: '\u92fc\u4e4b\u934a\u91d1\u8853\u5e2b M',
//             display_image_url: 'https://support.cdn.garenanow.com/support-center/tw/games/fam/game-fam.jpg',
//           },
//           {
//             id: 13,
//             code: 'bcm',
//             name: '\u9ed1\u8272\u4e94\u8449\u8349 M \u9b54\u6cd5\u5e1d\u4e4b\u9053',
//             display_image_url: 'https://support.cdn.garenanow.com/support-center/tw/games/bcm/game-bcm.jpg',
//           },
//           {
//             id: 12,
//             code: 'ud',
//             name: '\u9ece\u660e\u89ba\u9192',
//             display_image_url: 'https://support.cdn.garenanow.com/support-center/tw/games/ud/game-ud.jpg',
//           },
//           {
//             id: 10,
//             code: 'mbm',
//             name: '\u5929\u6daf\u660e\u6708\u5200 M',
//             display_image_url: 'https://support.cdn.garenanow.com/support-center/tw/games/mbm/game-mbm.jpg',
//           },
//           {
//             id: 8,
//             code: 'codm',
//             name: '\u6c7a\u52dd\u6642\u523b:Mobile',
//             display_image_url: 'https://support.cdn.garenanow.com/support-center/tw/games/codm/game-codm.jpg',
//           },
//           {
//             id: 7,
//             code: 'spd',
//             name: '\u6975\u901f\u9818\u57df',
//             display_image_url: 'https://support.cdn.garenanow.com/support-center/tw/games/spd/game-spd.jpg',
//           },
//           {
//             id: 6,
//             code: 'ff',
//             name: 'Free Fire',
//             display_image_url: 'https://support.cdn.garenanow.com/support-center/tw/games/ff/game-ff.jpg',
//           },
//           {
//             id: 2,
//             code: 'aov',
//             name: '\u50b3\u8aaa\u5c0d\u6c7a',
//             display_image_url: 'https://support.cdn.garenanow.com/support-center/tw/games/aov/game-aov.jpg',
//           },
//         ],
//         pc: [
//           {
//             id: 4,
//             code: 'poe',
//             name: '\u6d41\u4ea1\u9eef\u9053',
//             display_image_url: 'https://support.cdn.garenanow.com/support-center/tw/games/poe/game-poe.jpg',
//           },
//         ],
//       },
//     });
//   });
// }

export default {
  getGames,
};
