<template>
  <div id="mobile-topbar">
    <div class="mobile-topbar-header">
      <router-link class="mobile-topbar-logo" :to="{ name: 'MobileHome' }" @click.native="switchProfileMenu(true)"></router-link>
      <div class="mobile-topbar-menu"></div>
      <div class="mobile-topbar-title"></div>
      <div class="mobile-topbar-user-menu" @click.prevent="switchProfileMenu()">
        <template v-if="hasLoggedIn">
          <div class="mobile-topbar-user-avatar">
            <img
              class="mobile-topbar-user-avatar-img"
              alt=""
              :src="`${CDN}/assets/share/img/icon-circle-platform-${userInfo.platform}.png`"
            />
          </div>
          <div class="mobile-topbar-user-name">
            {{ userInfo.name }}
          </div>
        </template>
        <template v-else>
          <div class="mobile-topbar-user-avatar">
            <img class="mobile-topbar-user-avatar-img" alt="" :src="`${CDN}/assets/share/img/icon-notloggedin@2x.jpg`" />
          </div>
          <div class="mobile-topbar-user-name">
            <a @click="switchLoginPopup(true)">{{ $t('GENERAL__LOGIN') }}</a>
          </div>
        </template>
      </div>
    </div>

    <transition name="mobile-topbar-profile-dropdown">
      <template v-if="isProfileMenuOpen">
        <div class="mobile-topbar-profile">
          <section class="profile-dropdown">
            <ul class="profile__category">
              <li v-if="isTwVersion">
                <router-link class="profile__category-link" :to="{ name: 'MobileProfile' }" @click.native="switchProfileMenu()">
                  <div class="profile__category-icon profile__category-icon--user"></div>
                  <h2 class="profile__category-txt">{{ $t('HOME__USER_ACCOUNT_INFO') }}</h2>
                  <div class="cproblem__category-blank"></div>
                  <div class="cproblem__category-arrow">
                    <div class="cproblem__category-arrow--icon"></div>
                  </div>
                </router-link>
              </li>
              <li v-if="isGarenaUser">
                <a href="https://account.garena.com/" target="_blank" class="profile__category-link">
                  <div class="profile__category-icon profile__category-icon--account2"></div>
                  <h2 class="profile__category-txt">{{ $t('HOME__USER_ACCOUNT_CENTER') }}</h2>
                  <div class="cproblem__category-blank"></div>
                  <div class="cproblem__category-arrow">
                    <div class="cproblem__category-arrow--icon"></div>
                  </div>
                </a>
              </li>
              <li>
                <a @click.prevent="toLogout()" class="profile__category-link">
                  <div class="profile__category-icon profile__category-icon--logout">
                    <img :src="`${CDN}/assets/mobile/img/icon/icon-profile-logout.svg`" alt="" width="18px" />
                  </div>
                  <h2 class="profile__category-txt">{{ $t('GENERAL__LOGOUT') }}</h2>
                  <div class="cproblem__category-blank"></div>
                  <div class="cproblem__category-arrow">
                    <div class="cproblem__category-arrow--icon"></div>
                  </div>
                </a>
              </li>
            </ul>
          </section>
          <div class="profile__category-dimmer"></div>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { ACTION_LOGOUT, ACTION_SWITCH_LOGIN_POPUP } from '@/store/action-types';

export default {
  // @TODO: new topbar
  name: 'Topbar',
  data() {
    return {
      isProfileMenuOpen: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    ...mapGetters(['hasLoggedIn', 'isGarenaUser']),
  },
  methods: {
    switchProfileMenu(forceClose = false) {
      if (forceClose) {
        this.isProfileMenuOpen = false;
      } else if (this.hasLoggedIn) {
        this.isProfileMenuOpen = !this.isProfileMenuOpen;
      }
    },
    toLogout() {
      this.switchProfileMenu();
      this.logout().then(() => {
        if (this.$route.name !== 'MobileHome') {
          this.$router.push({ name: 'MobileHome' });
        }
      });
    },
    ...mapActions({
      logout: ACTION_LOGOUT,
      switchLoginPopup: ACTION_SWITCH_LOGIN_POPUP,
    }),
  },
};
</script>

<style lang="scss" scoped>
#mobile-topbar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 10;
  transform: translateZ(0);
  box-shadow: 0 0 5px rgba(20%, 20%, 40%, 0.5);
}
#mobile-topbar .mobile-topbar-header {
  padding: 0.6rem 1rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: relative;
  z-index: 9;
  font-size: 13.334vw;
  background: #fff;
}
#mobile-topbar .mobile-topbar {
  &-logo {
    width: 6.4rem;
    height: 1.7rem;
    background-image: assetsUrl('assets/share/img/garena-logo.png');
    background-size: 100% 100%;
    flex: 0 0 auto;
  }
  &-user {
    &-menu {
      margin-top: 3px;
      float: right;
      cursor: pointer;
      border-radius: 30px;
      border: 1px solid rgba(237, 239, 247, 0.2);
    }
    &-avatar {
      margin: 4px;
      float: left;
      border-radius: 50%;
      border: 1px solid rgba(237, 239, 247, 0.2);
      overflow: hidden;
    }
    &-avatar-img {
      display: block;
      width: 24px;
      height: 24px;
      border: 0.5px solid #e0e4e9;
      border-radius: 50%;
    }
    &-name {
      float: left;
      margin: 8px 10px 8px 2px;
      font-size: 12px;
    }
  }
}
#mobile-topbar .mobile-topbar-profile {
  height: 100vh;
}
.mobile-topbar-profile-dropdown-enter-active {
  transition: all 0.3s ease;
}
.mobile-topbar-profile-dropdown-leave-active {
  transition: all 0.3s ease;
}
.mobile-topbar-profile-dropdown-enter,
.mobile-topbar-profile-dropdown-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
