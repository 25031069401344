<template>
  <div>
    <div class="textarea">
      <label>
        <textarea
          class="textarea__widget"
          ref="input"
          :class="{ 'textarea__widget--error': !isValid }"
          :name="name"
          :placeholder="placeholder"
          :disabled="isDisabled"
          :required="isRequired"
          :value="val"
          :maxlength="isLimitLength && maxLength"
          @input="$emit('input', $event.target.value)"
        ></textarea>
      </label>
    </div>
    <div class="report-form__note">
      <div :class="{ invisible: isValid }" class="report-form__error">{{ errorMsg }}</div>
      <div v-show="isLimitLength" class="report-form__length">{{ curLength }} / {{ maxLength }}</div>
    </div>
  </div>
</template>

<script>
import constants from '@/lib/constants';

export default {
  name: 'CustomTextarea',
  props: {
    name: String,
    placeholder: String,
    isDisabled: Boolean,
    isRequired: Boolean,
    errorMsg: String,
    value: String,
    isLimitLength: { type: Boolean, default: false },
    isValidIfNull: { type: Boolean, default: true },
    maxLength: { type: Number, default: constants.TICKET__TEXTAREA_MAXLENGTH },
  },
  computed: {
    val() {
      return this.value;
    },
    isValid() {
      return this.validate(this.val);
    },
    curLength() {
      if (this.val) {
        return this.val.length;
      }
      return 0;
    },
  },
  methods: {
    validate(value) {
      if (this.isValidIfNull && value === null) {
        return true;
      }
      let hasValueIfRequired = true;
      if (this.isRequired) {
        hasValueIfRequired = value && value.length > 0;
      }
      return hasValueIfRequired;
    },
  },
};
</script>

<style lang="scss" scoped>
.report-form {
  &__note {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__length {
    color: $general-input-border-color;
    font-size: 0.8rem;
  }
}
</style>
