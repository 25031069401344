<template>
  <div class="main-wrap">
    <!-- ticket not found -->
    <template v-if="errorMsg === 'error_no_matched_data'">
      <section class="form-section">
        <div class="reportlist__detail-main">
          <div class="reportlist__detail-title">
            <div class="main-inner__not-found">
              <div class="main-inner__not-found-title">
                {{ $t('REPORT__DETAIL_NOT_FOUND') }}
              </div>
              <div class="main-inner__not-found-desc" v-html="$t('REPORT__DETAIL_NOT_FOUND_DESC')"></div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <!-- ticket not found /-->

    <!-- ticket info -->
    <template v-else-if="isDataReady">
      <section v-if="ticket" class="form-section">
        <div class="reportlist__detail-main">
          <div class="reportlist__detail-title">
            <div class="onlineservice-title">
              <div class="reportlist__detail-top">
                <div class="reportlist__main-top--title">{{ $t('REPORT__DETAIL_TITLE') }}</div>
              </div>
              <div class="reportlist__detail-top">
                <div class="reportlist__main-top--remind">{{ $t('REPORT__DETAIL_CS_NUMBER') }} #{{ ticket.csNumber }}</div>
                <div class="reportlist__main-top--status">
                  <span>
                    {{ $t('REPORT__STATUS_'.concat(constants.TICKET__TICKET_STATUS_MAPPING[ticket.ticketStatus].TICKET_STATUS)) }}
                  </span>
                </div>
              </div>
              <div class="reportlist__detail-category">
                <div class="reportlist__main-top--title2">
                  <dl v-if="!isLegacy" class="reportlist__main-top--title2-item">
                    <dt>{{ $t('REPORT__DETAIL_GAME') }}</dt>
                    <dd>{{ ticket.gameName }}</dd>
                  </dl>
                  <dl class="reportlist__main-top--title2-item">
                    <dt>{{ $t('REPORT__DETAIL_BASE_CATEGORY') }}</dt>
                    <dd>{{ ticket.baseCategoryName }}</dd>
                  </dl>
                  <dl class="reportlist__main-top--title2-item">
                    <dt>{{ $t('REPORT__DETAIL_TOPIC_CATEGORY') }}</dt>
                    <dd>{{ ticket.ticketTopicCategoryName }}</dd>
                  </dl>
                  <dl class="reportlist__main-top--title2-item">
                    <dt>{{ $t('REPORT__DETAIL_TOPIC') }}</dt>
                    <dd>{{ ticket.ticketTopicName }}</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="reportlist__detail">
          <a
            class="onlineservicedet-accordion"
            :class="{ 'onlineservicedet-accordion__hide': isShowingAllReplies }"
            @click="showAllReplies"
          >
            {{ $t('M_REPORT__REPLY_VIEW_MORE') }}
          </a>
          <reply
            v-for="reply in ticket.replies"
            :key="reply.id"
            :cs-number="ticket.csNumber"
            :reply-id="reply.id"
            :type="reply.type"
            :creator-type="reply.creatorType"
            :created-at="reply.createdAt"
            :content="reply.content"
            :attachments="reply.attachments"
            :numOfAttachments="reply.numOfAttachments"
            :staff-name="reply.staffName"
            :is-helpful="reply.isHelpful"
            :has-rated="reply.isHelpful !== null"
            :hide-rated="isLegacy"
            v-show="shownTicketReplyIds.includes(reply.id) || isShowingAllReplies"
          />
          <template v-if="showFeedback">
            <feedback :cs-number="ticket.csNumber" :feedback="ticket.feedback" :can-provide-feedback="ticket.canProvideFeedback" />
          </template>
        </div>

        <template v-if="canContinueReport">
          <continue
            :cs-number="ticket.csNumber"
            :is-email-required="ticket.needNotificationEmail"
            :user-email="ticket.notificationEmailAddress"
            @submit="getTicketByCsNumber(csNumber, isLegacy)"
          />
        </template>
      </section>
    </template>
    <!-- ticket info /-->

    <!-- loading -->
    <template v-else>
      <div class="loading">
        <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
      </div>
    </template>
    <!-- loading /-->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Reply from '@/components/mobile/layouts/report/Reply';
import Feedback from '@/components/mobile/layouts/report/Feedback';
import Continue from '@/components/mobile/layouts/report/Continue';
import ticket from '@/api/prod/ticket';
import constants from '@/lib/constants';
import utils from '@/lib/utils';
import { ACTION_GET_TICKET_TOPIC_CATEGORIES, ACTION_GET_TICKET_TOPICS, ACTION_TRIGGER_POPUP } from '@/store/action-types';

export default {
  name: 'MobileReport',
  components: {
    Reply,
    Continue,
    Feedback,
  },
  data() {
    return {
      constants,
      utils,
      errorMsg: null,
      csNumber: this.$route.params.csNumber,
      ticket: null,
      shownTicketReplyIds: [],
      isShowingAllReplies: false,
    };
  },
  computed: {
    isDataReady() {
      return this.userInfo && (this.errorMsg || this.ticket);
    },
    showFeedback() {
      return this.ticket.canProvideFeedback || (this.ticket.feedback && true);
    },
    canContinueReport() {
      return (
        constants.TICKET__TICKET_STATUS_MAPPING[this.ticket.ticketStatus].FILTER_STATUS !== constants.TICKET__TICKET_STATUS_FILTER.CLOSED &&
        !this.isLegacy
      );
    },
    isLegacy() {
      return Number(this.$route.query.source) === constants.TICKET__TICKET_STATUS_FOR_USER.LEGACY;
    },
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      games: (state) => state.game.games,
      baseCategories: (state) => state.category.baseCategories,
    }),
  },
  methods: {
    getTicketByCsNumber(csNumber, isLegacy) {
      ticket.getTicketByCsNumber(csNumber, isLegacy).then((resp) => {
        const { data, error } = resp;
        if (error || !data) {
          if (error === 'error_no_matched_data') {
            this.errorMsg = error;
          } else {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_TICKET_LOAD_FAILED'),
            });
            this.$router.push({ name: 'MobileReportList' });
          }
        } else {
          this.ticket = data;
          this.setRepliesDisplay(this.ticket.replies);
        }
      });
    },
    showAllReplies() {
      this.isShowingAllReplies = true;
    },
    setRepliesDisplay(replies) {
      let staffReplyCnt = 0;
      const reversedReplies = replies.slice().reverse();
      reversedReplies.every((r) => {
        if ([constants.TICKET__TICKET_REPLY_TYPES.SYSTEM, constants.TICKET__TICKET_REPLY_TYPES.STAFF].includes(r.type)) {
          staffReplyCnt += 1;
          if (staffReplyCnt >= 2) {
            return false;
          }
          this.shownTicketReplyIds.push(r.id);
          return true;
        }
        this.shownTicketReplyIds.push(r.id);
        return true;
      });
      if (this.shownTicketReplyIds.length === this.ticket.replies.length) {
        this.showAllReplies();
      }
    },
    /* eslint-disable no-param-reassign */
    ...mapActions({
      getTicketTopicCategories: ACTION_GET_TICKET_TOPIC_CATEGORIES,
      getTicketTopics: ACTION_GET_TICKET_TOPICS,
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
  },
  mounted() {
    this.getTicketByCsNumber(this.csNumber, !!this.isLegacy);
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .reportlist__detail {
    background-color: #f8f9fa;
    margin-bottom: 20px;
    padding: 0 18px 40px 18px;
    .onlineservicedet-accordion__hide {
      padding: 0;
      visibility: hidden;
    }
  }
  .loading {
    text-align: center;
    img {
      width: 15%;
    }
  }
  .main-inner__not-found {
    padding-top: 25px;
    margin: 0 12px;
    &-title {
      font-size: 20px;
      font-stretch: normal;
      font-weight: bold;
    }
    &-desc {
      padding-top: 25px;
      ::v-deep a {
        color: $general-red;
      }
      ::v-deep ol {
        margin-top: 15px;
      }
      ::v-deep li {
        margin-left: 18px;
        list-style: outside decimal;
        line-height: 30px;
      }
    }
  }
}
</style>
