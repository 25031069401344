<template>
  <div class="radio-wrap">
    <label class="radio">
      <input class="radio__widget" type="radio"
             :name="name"
             :disabled="isDisabled"
             :value="radioValue"
             :checked="val"
             @change="$emit('input', parseInt($event.target.value))" />
      <div class="radio__indicator"></div>
      <div class="radio__label">
        {{ label }}
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'CustomRadio',
  props: {
    label: String,
    name: String,
    radioValue: Number,
    isDisabled: Boolean,
    value: Number,
  },
  computed: {
    val() {
      return this.value === this.radioValue;
    },
  },
};
</script>
