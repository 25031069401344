var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "main-wrap newslist__container",
      on: { scroll: _vm.onScroll }
    },
    [
      _c(
        "section",
        { staticClass: "game-section" },
        [
          _vm.newsList && _vm.newsList.length > 0
            ? [
                _c("div", { staticClass: "cantlogin-list__main" }, [
                  _c("div", { staticClass: "reportlist__main-top" }, [
                    _c("div", { staticClass: "reportlist__main-top--title" }, [
                      _vm._v(_vm._s(_vm.$t("NEWS__TITLE")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", {}, [
                    _c(
                      "ul",
                      { staticClass: "cproblem__main-list" },
                      [
                        _vm._l(_vm.newsList, function(news) {
                          return [
                            _c(
                              "li",
                              { key: news.id },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "cproblem__main-item",
                                    attrs: {
                                      to: {
                                        name: "MobileNews",
                                        params: { id: news.id }
                                      }
                                    }
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "cproblem__main-item--icon"
                                    }),
                                    _vm._v(" "),
                                    _c("p", [_vm._v(_vm._s(news.title))])
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("hr", { key: news.id + "hr" })
                          ]
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm.isLoadingMore
                  ? _c("div", { staticClass: "news__nomore" }, [
                      _c("img", {
                        staticClass: "icon-loading",
                        attrs: {
                          src: _vm.CDN + "/assets/pc/img/common/loading.gif",
                          alt: ""
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.canLoadMore
                  ? _c("div", { staticClass: "news__nomore" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("M_NEWS__NO_MORE_NEWS")) +
                          "\n      "
                      )
                    ])
                  : _vm._e()
              ]
            : [
                _c("div", { staticClass: "news__nomore" }, [
                  _c("img", {
                    staticClass: "icon-loading",
                    attrs: {
                      src: _vm.CDN + "/assets/pc/img/common/loading.gif",
                      alt: ""
                    }
                  })
                ])
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }