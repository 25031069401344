<template>
  <div v-if="hasPopup" class="notification-wrap">
    <div class="notification-dimmer"></div>
    <div class="notification">
      <div class="notification-inner">
        <div class="notification__title">
          <div class="notification__title-icon"></div>
          <div class="notification__title-txt">
            {{ popupContent.title }}
          </div>
        </div>
        <div class="notification__main">
          <div class="notification__content">
            <div class="notification__content-txt" v-html="popupContent.content"></div>
          </div>
        </div>
        <div class="rountbutton" @click.prevent="onClickButton">
          <button class="rountbutton__widget">
            {{ $t('GENERAL__BUTTON_NOTIFICATION') }}
          </button>
        </div>
        <a @click.prevent="closePopup" class="notification__close"></a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ACTION_CLOSE_POPUP } from '@/store/action-types';

export default {
  name: 'Popup',
  computed: {
    ...mapState({
      hasPopup: (state) => state.common.hasPopup,
      popupContent: (state) => state.common.popupContent,
    }),
  },
  methods: {
    onClickButton() {
      if (this.popupContent.action) {
        this.popupContent.action();
      } else {
        this.closePopup();
      }
    },
    ...mapActions({
      closePopup: ACTION_CLOSE_POPUP,
    }),
  },
};
</script>
