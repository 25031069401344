<template>
  <div v-if="!utils.isEmptyObject(options)" class="select-wrap" @click="switchSelectOptions">
    <div v-if="val" class="select" :class="{ 'input--error': !isValid }" ref="selectedOption">
      {{ options[val].name }}
    </div>
    <div v-else-if="placeholder" class="select" :class="{ 'select-placeholder': placeholder, 'input--error': !isValid }">
      {{ placeholder }}
    </div>
    <div v-else class="select" :class="{ 'input--error': !isValid }">
      {{ options[0] }}
    </div>
    <div class="select__arrow"></div>
    <!-- Dropdown -->
    <template v-if="isOpen">
      <div class="select-options">
        <div v-for="option in options" :key="option.id" class="select__option" @click="changeSelectedValue(option.id)">
          {{ option.name }}
        </div>
      </div>
    </template>
    <!-- Dropdown /-->
  </div>
</template>

<script>
import utils from '@/lib/utils';

export default {
  name: 'CustomSelect',
  props: {
    placeholder: String,
    value: { type: null, default: 0 },
    options: Object,
    isRequired: Boolean,
    isValidIfNull: { type: Boolean, default: true },
  },
  data() {
    return {
      utils,
      isOpen: false,
    };
  },
  computed: {
    val() {
      return this.value;
    },
    isValid() {
      return this.validate(this.val);
    },
  },
  methods: {
    validate(value) {
      if (this.isValidIfNull && value === null) {
        return true;
      }
      let hasValueIfRequired = true;
      if (this.isRequired) {
        hasValueIfRequired = value;
      }
      return hasValueIfRequired;
    },
    switchSelectOptions() {
      this.isOpen = !this.isOpen;
    },
    changeSelectedValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>
