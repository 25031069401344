var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.utils.isEmptyObject(_vm.options)
    ? _c(
        "div",
        { staticClass: "select-wrap", on: { click: _vm.switchSelectOptions } },
        [
          _vm.val
            ? _c(
                "div",
                {
                  ref: "selectedOption",
                  staticClass: "select",
                  class: { "input--error": !_vm.isValid }
                },
                [_vm._v("\n    " + _vm._s(_vm.options[_vm.val].name) + "\n  ")]
              )
            : _vm.placeholder
            ? _c(
                "div",
                {
                  staticClass: "select",
                  class: {
                    "select-placeholder": _vm.placeholder,
                    "input--error": !_vm.isValid
                  }
                },
                [_vm._v("\n    " + _vm._s(_vm.placeholder) + "\n  ")]
              )
            : _c(
                "div",
                {
                  staticClass: "select",
                  class: { "input--error": !_vm.isValid }
                },
                [_vm._v("\n    " + _vm._s(_vm.options[0]) + "\n  ")]
              ),
          _vm._v(" "),
          _c("div", { staticClass: "select__arrow" }),
          _vm._v(" "),
          _vm.isOpen
            ? [
                _c(
                  "div",
                  { staticClass: "select-options" },
                  _vm._l(_vm.options, function(option) {
                    return _c(
                      "div",
                      {
                        key: option.id,
                        staticClass: "select__option",
                        on: {
                          click: function($event) {
                            return _vm.changeSelectedValue(option.id)
                          }
                        }
                      },
                      [_vm._v("\n        " + _vm._s(option.name) + "\n      ")]
                    )
                  }),
                  0
                )
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }