/* eslint-disable */
import api from '@/api';

function getGarenaUserRealInfo() {
  return api.call(api.GET, `/user/real-info`);
}

function getPlatformUserInfo() {
  return api.call(api.GET, `/platform-user-info`);
}

function updatePlatformUserInfo(payload) {
  return api.call(api.POST, `/platform-user-info/me`, payload);
}

// function getPlatformUserInfo() {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: JSON.parse(JSON.stringify({
//         data: null,
//         error: '',
//       })),
//     });
//   });
// }

export default {
  getGarenaUserRealInfo,
  getPlatformUserInfo,
  updatePlatformUserInfo,
};
