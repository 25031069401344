/* eslint-disable */

import api from '@/api';

function getNotification(pos, categoryId = null) {
  const params = {
    type: pos,
    gameId: categoryId,
  };
  return api.call(api.GET, '/notification', params);
}

// function getNotification(pos, categoryId) {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: JSON.parse(JSON.stringify({
//         // data: null,
//         data: {
//           // img: 'https://image.garena.tw/images/spd/Pet/%E6%9C%88%E5%85%94.jpg',
//           img: '',
//           content: '<p>這是 ' + pos + '/' + categoryId + ' 的通知</p><p style="font-weight: bold;">親愛的召喚師，你好：</p><p>2018/03/20(二)凌晨00:00~2018/04/03(二)早上08:00，因應國際海纜進行維護，港澳地區的線路可能會受到輕微影響，造成召喚師的不便還請多多見諒，詳情請參考官網公告說明。</p><p>2018/03/20(二)凌晨00:00~2018/04/03(二)早上08:00，因應國際海纜進行維護，港澳地區的線路可能會受到輕微影響，造成召喚師的不便還請多多見諒，詳情請參考官網公告說明。</p>',
//         },
//         error: '',
//       })),
//     });
//   });
// }

export default {
  getNotification,
};
