<template>
  <div class="main-wrap">
    <div class="main-bg"></div>
    <div class="main">
      <navigation :title="$t('GENERAL__TITLE')" :has-search-bar="true" :is-blank="true" />
      <div class="main-inner main-inner--nobg">
        <template v-if="isTwVersion">
          <!-- Application -->
          <div class="home">
            <div class="home-main">
              <router-link :to="{ name: 'MobileCantLogin' }" class="home-entry">
                <div class="home-entry__item-content">
                  <div class="home-entry__item-icon">
                    <img :src="`${CDN}/assets/mobile/img/home/icon-cantlogin@2.svg`" alt="" height="40" />
                  </div>
                  <div class="home-entry__item-name">{{ $t('M_HOME__CANT_LOGIN_TITLE') }}</div>
                </div>
              </router-link>
            </div>
            <!-- Application /-->
            <!-- Verification -->
            <div class="home-main">
              <router-link :to="{ name: 'MobileVerification' }" class="home-entry">
                <div class="home-entry__item-content">
                  <div class="home-entry__item-icon">
                    <img :src="`${CDN}/assets/mobile/img/home/icon-verification@2.svg`" alt="" height="40" />
                  </div>
                  <div class="home-entry__item-name">{{ $t('M_HOME__VERIFICATION_TITLE') }}</div>
                </div>
              </router-link>
            </div>
          </div>
          <!-- Verification /-->
        </template>
        <div class="home">
          <!-- Ticket -->
          <template v-if="isJpVersion">
            <div class="home-main">
              <router-link :to="{ name: 'MobileBaseCategoryList', params: { gameCode: 'ff' } }" class="home-entry">
                <div class="home-entry__item-content">
                  <div class="home-entry__item-icon">
                    <img :src="`${CDN}/assets/mobile/img/home/icon-games@2.svg`" alt="" height="40" />
                  </div>
                  <div class="home-entry__item-name">{{ $t('M_HOME__REPORT__TITLE') }}</div>
                </div>
              </router-link>
            </div>
          </template>
          <template v-else>
            <div class="home-main">
              <router-link :to="{ name: 'MobileGameList' }" class="home-entry">
                <div class="home-entry__item-content">
                  <div class="home-entry__item-icon">
                    <img :src="`${CDN}/assets/mobile/img/home/icon-games@2.svg`" alt="" height="40" />
                  </div>
                  <div class="home-entry__item-name">{{ $t('M_HOME__REPORT__TITLE') }}</div>
                </div>
              </router-link>
            </div>
          </template>
          <!-- Ticket /-->
          <!-- Reports -->
          <template v-if="isTwVersion">
            <div class="home-main">
              <a href="#" class="home-entry" @click.prevent="popupChooseRecord(true)">
                <div class="home-entry__item-content">
                  <div class="home-entry__item-icon">
                    <img :src="`${CDN}/assets/mobile/img/home/icon-list@2.svg`" alt="" height="40" />
                  </div>
                  <div class="home-entry__item-name">{{ $t('M_HOME__SERVICE_RECORD_TITLE') }}</div>
                </div>
              </a>
            </div>
          </template>
          <template v-else>
            <div class="home-main">
              <router-link :to="{ name: 'MobileReportList' }" class="home-entry">
                <div class="home-entry__item-content">
                  <div class="home-entry__item-icon">
                    <img :src="`${CDN}/assets/mobile/img/home/icon-list@2.svg`" alt="" height="40" />
                  </div>
                  <div class="home-entry__item-name">{{ $t('M_HOME__SERVICE_RECORD_TITLE') }}</div>
                </div>
              </router-link>
            </div>
          </template>
          <!-- Reports /-->
        </div>
        <template v-if="isTwVersion">
          <div class="home">
            <div class="home-main">
              <router-link :to="{ name: 'MobileNewsList' }" class="home-entry">
                <div class="home-entry__item-content">
                  <div class="home-entry__item-icon">
                    <img :src="`${CDN}/assets/mobile/img/home/icon-news@2.svg`" alt="" height="40" />
                  </div>
                  <div class="home-entry__item-name">{{ $t('M_HOME__NEWS_TITLE') }}</div>
                </div>
              </router-link>
            </div>
            <div class="home-main home-main-none"></div>
          </div>
        </template>
      </div>
    </div>
    <notification :pos="'home'"></notification>
    <popup-choose-record v-if="isShowingPopupChooseRecord" @close="popupChooseRecord(false)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Navigation from '@/components/mobile/layouts/Navigation';
import Notification from '@/components/mobile/layouts/Notification';
import PopupChooseRecord from '@/components/mobile/layouts/home/PopupChooseRecord';

export default {
  name: 'Home',
  components: {
    Navigation,
    Notification,
    PopupChooseRecord,
  },
  data() {
    return {
      isShowingPopupChooseRecord: false,
    };
  },
  computed: {
    ...mapGetters(['isFbUser']),
  },
  methods: {
    popupChooseRecord(isShown) {
      this.isShowingPopupChooseRecord = isShown;
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile .home-main-none {
  border: none;
  background: none;
}
</style>
