<template>
  <div class="chat__body" ref="messageList">
    <div class="chat__body-row" v-for="(m, i) in messages" :key="i" :class="m.chatRole">
      <div class="chat__body-system" v-if="m.chatRole === constants.AI_FAQ__CHAT_ROLE.SYSTEM">{{ m.message }}</div>

      <template v-else>
        <div class="chat__body-img">
          <img :src="m.chatRole === constants.AI_FAQ__CHAT_ROLE.USER ? userInfo.avatar : constants.AI_FAQ__CHAT_IMG[gameCodeOnUrl]" />
        </div>

        <div class="chat__body-wrapper">
          <div
            v-if="m.chatRole !== constants.AI_FAQ__CHAT_ROLE.LOADING"
            class="chat__body-message"
            v-html="generateMessage(m.message)"
            readonly
            :class="m.chatRole"
          ></div>

          <div v-else class="chat__body-message">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>

          <div class="chat__body-ts">
            <span>
              {{ generateTime(m) }}
            </span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import constants from '@/lib/constants';

export default {
  name: 'ChatMessages',
  props: {
    messages: Array,
  },
  data() {
    return {
      format,
      constants,
      gameCodeOnUrl: this.$route.params.gameCode,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods: {
    generateTime(message) {
      if (message.chatRole === constants.AI_FAQ__CHAT_ROLE.LOADING || !message.ts) return '';
      return this.format(message.ts, 'kk:mm', { locale: window.locale });
    },
    generateMessage(input) {
      if (!input) return '';

      const arr = input.split('').map((i) => (i === '\n' ? '<br/>' : i));

      // eslint-disable-next-line no-plusplus
      for (let i = arr.length - 1; i > 0; i--) {
        if (arr[i] === '<br/>') {
          // 去掉後面一直換行
          arr.pop();
        } else {
          break;
        }
      }
      return arr.join('');
    },
  },
};
</script>

<style lang="scss" scoped>
$ai-general: $general-red;
$ai-message: rgba(234, 234, 234, 0.5);
$ai-message-text: #3d3d3d;

.chat {
  &__body {
    width: 100%;
    flex: 1;
    overflow-y: auto;
    align-items: flex-start;
    padding: 0 10px;

    &-row {
      position: relative;
      @include flexCenter(row);
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 10px;

      .chat__body-ts {
        font-size: 11px;
        color: rgb(123, 123, 123);
        margin-top: 2px;
        margin-left: 15px;
        @include flexCenter(row);
        justify-content: flex-start;
      }

      &.user {
        flex-direction: row-reverse;

        .chat__body-message {
          margin-right: 10px;
        }

        .chat__body-ts {
          margin-right: 15px;
          justify-content: flex-end;
        }
      }
    }

    &-img {
      $imgSize: 48;
      width: $imgSize + px;
      height: $imgSize + px;
      border-radius: 24px;
      overflow: hidden;

      > img {
        width: 48px;
        height: 48px;
      }
    }

    &-system {
      color: rgb(123, 123, 123);
      text-align: center;
      padding: 5px 20px;
      margin: 0 auto;
      font-size: 13px;
    }

    &-wrapper {
      max-width: calc(100% - 115px);
    }

    &-message {
      border-radius: 4px;
      background-color: $ai-message;
      color: $ai-message-text;
      font-size: 16px;
      resize: none;
      margin-left: 10px;
      margin-top: 2px;
      padding: 10px 15px;
      position: relative;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      border-radius: 10px;
      word-break: break-all;
    }
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 16px;
  margin: 0 5px;
}
.lds-ellipsis div {
  position: absolute;
  top: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $ai-general;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  margin-left: -14px;
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
