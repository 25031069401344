<template>
  <div class="main-wrap">
    <div class="main-bg"></div>
    <div class="main">
      <navigation :title="$t('GENERAL__TITLE')" :has-search-bar="true" />
      <section class="game-section">
        <div class="cproblem__main-content">
          <div class="faqdetail__content">
            <div class="ip-solution__txt">
              {{ $t('POPUP__ERROR_404') }}
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/mobile/layouts/Navigation';

export default {
  name: 'PageNotFound',
  components: {
    Navigation,
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .main {
    height: 100%;
  }
  .game-section {
    display: flex;
    flex-flow: column;
    padding-top: 40px;
  }
  .ip-solution__txt {
    margin: 12px 12px 20px 12px;
  }
}
</style>
