var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notification-wrap" }, [
    _c("div", { staticClass: "notification-dimmer" }, [
      _c("div", { staticClass: "notification notification--sm" }, [
        _c(
          "button",
          {
            staticClass: "rountbutton__widget-left",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.closePopup.apply(null, arguments)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("GENERAL__BUTTON_CANCEL")))]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "rountbutton__widget-right",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.confirmAction.apply(null, arguments)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("GENERAL__BUTTON_CONFIRM")))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "notification-inner" }, [
          _c("div", { staticClass: "notification__title" }, [
            _c("div", { staticClass: "notification__title-icon" }),
            _vm._v(" "),
            _c("div", { staticClass: "notification__title-txt" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "notification__main" }, [
            _c("div", { staticClass: "notification__content" }, [
              _c("div", { staticClass: "notification__content-txt" }, [
                _c("p", { domProps: { innerHTML: _vm._s(_vm.content) } })
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }