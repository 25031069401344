var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", { attrs: { title: _vm.$t("REPORT__DETAIL_TITLE") } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "main-inner main-inner--nobg" },
          [
            _vm.errorMsg === "error_no_matched_data"
              ? [
                  _c(
                    "div",
                    { staticClass: "onlineservicedet" },
                    [
                      _c("breadcrumb", { attrs: { paths: _vm.paths } }),
                      _vm._v(" "),
                      _c("div", { staticClass: "main-inner__not-found" }, [
                        _c(
                          "div",
                          { staticClass: "main-inner__not-found-title" },
                          [_vm._v(_vm._s(_vm.$t("REPORT__DETAIL_NOT_FOUND")))]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "main-inner__not-found-desc",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("REPORT__DETAIL_NOT_FOUND_DESC")
                            )
                          }
                        })
                      ])
                    ],
                    1
                  )
                ]
              : _vm.isDataReady
              ? [
                  _c(
                    "div",
                    { staticClass: "onlineservicedet" },
                    [
                      _c("breadcrumb", { attrs: { paths: _vm.paths } }),
                      _vm._v(" "),
                      _c("div", { staticClass: "main-title" }, [
                        _vm._v(
                          _vm._s(_vm.$t("REPORT__DETAIL_CS_NUMBER")) +
                            " #" +
                            _vm._s(_vm.ticket.csNumber)
                        )
                      ]),
                      _vm._v(" "),
                      _vm.ticket
                        ? _c("div", { staticClass: "onlineservicedet-main" }, [
                            _c(
                              "div",
                              { staticClass: "onlineservicedet-info" },
                              [
                                !_vm.isLegacy
                                  ? _c(
                                      "dl",
                                      {
                                        staticClass:
                                          "onlineservicedet-info__item"
                                      },
                                      [
                                        _c(
                                          "dt",
                                          {
                                            staticClass:
                                              "onlineservicedet-info__item-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t("REPORT__DETAIL_GAME")
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("dd", [
                                          _vm._v(_vm._s(_vm.ticket.gameName))
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "dl",
                                  {
                                    staticClass: "onlineservicedet-info__item"
                                  },
                                  [
                                    _c(
                                      "dt",
                                      {
                                        staticClass:
                                          "onlineservicedet-info__item-title"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "REPORT__DETAIL_BASE_CATEGORY"
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("dd", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.ticket.baseCategoryName) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "dl",
                                  {
                                    staticClass: "onlineservicedet-info__item"
                                  },
                                  [
                                    _c(
                                      "dt",
                                      {
                                        staticClass:
                                          "onlineservicedet-info__item-title"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "REPORT__DETAIL_TOPIC_CATEGORY"
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("dd", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.ticket.ticketTopicCategoryName
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "dl",
                                  {
                                    staticClass: "onlineservicedet-info__item"
                                  },
                                  [
                                    _c(
                                      "dt",
                                      {
                                        staticClass:
                                          "onlineservicedet-info__item-title"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t("REPORT__DETAIL_TOPIC")
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("dd", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.ticket.ticketTopicName) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "onlineservicedet-recordwrap" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "onlineservicedet-record" },
                                  [
                                    _vm._l(_vm.ticket.replies, function(reply) {
                                      return _c("reply", {
                                        key: reply.id,
                                        attrs: {
                                          "cs-number": _vm.ticket.csNumber,
                                          "reply-id": reply.id,
                                          type: reply.type,
                                          "creator-type": reply.creatorType,
                                          "created-at": reply.createdAt,
                                          content: reply.content,
                                          attachments: reply.attachments,
                                          numOfAttachments:
                                            reply.numOfAttachments,
                                          "staff-name": reply.staffName,
                                          "is-helpful": reply.isHelpful,
                                          "has-rated": reply.isHelpful !== null,
                                          "hide-rated": _vm.isLegacy
                                        }
                                      })
                                    }),
                                    _vm._v(" "),
                                    _vm.showFeedback
                                      ? [
                                          _c("feedback", {
                                            attrs: {
                                              "cs-number": _vm.ticket.csNumber,
                                              feedback: _vm.ticket.feedback,
                                              "can-provide-feedback":
                                                _vm.ticket.canProvideFeedback
                                            }
                                          })
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.canContinueReport
                    ? [
                        _c("continue", {
                          attrs: {
                            "cs-number": _vm.ticket.csNumber,
                            "is-email-required":
                              _vm.ticket.needNotificationEmail,
                            "user-email": _vm.ticket.notificationEmailAddress
                          },
                          on: {
                            submit: function($event) {
                              return _vm.getTicketByCsNumber(
                                _vm.csNumber,
                                _vm.isLegacy
                              )
                            }
                          }
                        })
                      ]
                    : _vm._e()
                ]
              : [
                  _c(
                    "div",
                    { staticClass: "onlineservicedet" },
                    [
                      _c("breadcrumb", { attrs: { paths: _vm.paths } }),
                      _vm._v(" "),
                      _c("div", { staticClass: "loading" }, [
                        _c("img", {
                          staticClass: "icon-loading",
                          attrs: {
                            src: _vm.CDN + "/assets/pc/img/common/loading.gif",
                            alt: ""
                          }
                        })
                      ])
                    ],
                    1
                  )
                ]
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }