<template>
  <div class="main-wrap reportlist__container" :class="{ 'bg-white': isTicketListEmpty }" @scroll="onScroll">
    <section class="game-section">
      <template v-if="tickets && !utils.isEmptyArray(tickets)">
        <div class="reportlist__main" v-for="ticket in tickets" :key="ticket.csNumber">
          <router-link
            :to="{
              name: 'MobileReport',
              params: { csNumber: ticket.csNumber, isLegacy },
              query: isLegacy ? { source: constants.TICKET__TICKET_STATUS_FOR_USER.LEGACY } : undefined,
            }"
            class="searchlist__cards"
          >
            <div class="reportlist__main-top">
              <div class="reportlist__main-top--title">
                <div class="reportlist__main-title--icon"></div>
                {{ ticket.csNumber }}
              </div>
              <div v-if="ticket.hasUnreadReply" class="reportlist__main-top--status">
                {{ $t('REPORT__STATUS_'.concat(constants.TICKET__TICKET_STATUS_MAPPING[ticket.ticketStatus].TICKET_STATUS)) }}
                <span class="reportlist__main-top--remind--view reportlist__main-top--remind--view--unread">
                  {{ $t('REPORT__VIEW_UNREAD') }}
                </span>
              </div>
              <div v-else class="reportlist__main-top--status">
                {{ $t('REPORT__STATUS_'.concat(constants.TICKET__TICKET_STATUS_MAPPING[ticket.ticketStatus].TICKET_STATUS)) }}
                <span class="reportlist__main-top--remind--view">
                  {{ $t('REPORT__VIEW_READ') }}
                </span>
              </div>
            </div>
            <div class="reportlist__content">
              <div class="reportlist__detail-category">
                <div class="reportlist__main-top--title2">
                  <dl v-if="!isLegacy" class="reportlist__main-top--title2-item">
                    <dt>{{ $t('REPORT__DETAIL_GAME') }}</dt>
                    <dd>{{ ticket.gameName }}</dd>
                  </dl>
                  <dl class="reportlist__main-top--title2-item">
                    <dt>{{ $t('REPORT__DETAIL_BASE_CATEGORY') }}</dt>
                    <dd>{{ ticket.baseCategoryName }}</dd>
                  </dl>
                  <dl class="reportlist__main-top--title2-item">
                    <dt>{{ $t('REPORT__DETAIL_TOPIC_CATEGORY') }}</dt>
                    <dd>{{ ticket.ticketTopicCategoryName }}</dd>
                  </dl>
                  <dl class="reportlist__main-top--title2-item">
                    <dt>{{ $t('REPORT__DETAIL_TOPIC') }}</dt>
                    <dd>{{ ticket.ticketTopicName }}</dd>
                  </dl>
                </div>
              </div>
              <div class="reportlist__question-3">
                {{ utils.formatTimestampToString(ticket.createdAt, $t('GENERAL__DATETIME_FORMAT')) }}
              </div>
            </div>
          </router-link>
        </div>
        <div v-if="isLoadingMore" class="reportlist__nomore">
          <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
        </div>
        <div v-if="!canLoadMore" class="reportlist__nomore">
          {{ $t('M_REPORT__NO_MORE_RECORD') }}
        </div>
      </template>
      <template v-else-if="isTicketListEmpty">
        <div class="reportlist-main">
          <div class="faqdetail-title">
            <div class="faqdetail__question">{{ $t('REPORT__TITLE') }}</div>
          </div>
          <div class="reportlist-inner">
            <div class="reportlist__inner-icon reportlist__inner-icon--2"></div>
            <div class="reportlist__inner-txt">{{ $t('REPORT__NO_RECORD') }}</div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="reportlist__nomore">
          <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
        </div>
      </template>
      <div class="legacy-btn" v-if="this.isTwVersion || this.isSmpVersion" @click="toggleLegacyStatus">
        {{ isLegacy ? $t('M_REPORT__BACK_TO_RECENT') : $t('M_REPORT__BACK_TO_LEGACY') }}
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ticket from '@/api/prod/ticket';
import constants from '@/lib/constants';
import utils from '@/lib/utils';
import { ACTION_SET_MOBILE_LEGACY } from '@/store/action-types';

export default {
  name: 'ReportList',
  data() {
    return {
      constants,
      utils,
      tickets: null,
      currentPage: 1,
      canLoadMore: true,
      isLoadingMore: false,
    };
  },
  computed: {
    isTicketListEmpty() {
      return this.tickets && utils.isEmptyArray(this.tickets);
    },
    queryOffset() {
      return (this.currentPage - 1) * constants.GENERAL__RECORDS_PER_PAGE;
    },
    queryParams() {
      return { status: this.isLegacy ? constants.TICKET__TICKET_STATUS_FOR_USER.LEGACY : null, offset: this.queryOffset };
    },
    ticketStatusNameMapping() {
      const ret = {};
      Object.keys(constants.TICKET__TICKET_STATUS_FOR_USER).forEach((ticketStatusName) => {
        ret[constants.TICKET__TICKET_STATUS_FOR_USER[ticketStatusName]] = {
          status: constants.TICKET__TICKET_STATUS_FOR_USER[ticketStatusName],
          name: ticketStatusName,
        };
      });
      return ret;
    },
    ...mapState({
      isLegacy: (state) => state.category.inMobileLegacy,
    }),
  },
  methods: {
    ...mapActions({
      toggleLegacy: ACTION_SET_MOBILE_LEGACY,
    }),
    onScroll({ target }) {
      if (!target) return;
      const { scrollTop, clientHeight, scrollHeight } = target;
      if (scrollTop + clientHeight >= scrollHeight && this.canLoadMore && !this.isLoadingMore) {
        this.currentPage += 1;
      }
    },
    toggleLegacyStatus() {
      this.toggleLegacy();
      this.currentPage = 1;
    },
  },
  watch: {
    queryParams: {
      immediate: true,
      handler(newValue) {
        this.isLoadingMore = true;
        ticket.getTickets(newValue.status, newValue.offset).then((resp) => {
          const { data, error } = resp;
          if (error || !data) {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_EXCEPTION'),
            });
          } else {
            this.tickets = data.tickets.slice(0, constants.GENERAL__RECORDS_PER_PAGE);
            if (data.pagination.curOffset + constants.GENERAL__RECORDS_PER_PAGE >= data.pagination.totalCnt) {
              this.canLoadMore = false;
            }
            this.isLoadingMore = false;
          }
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .bg-white {
    background-color: white;
  }
  .reportlist__container {
    overflow: scroll;
    position: fixed;
    top: 60px;
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
  }
  .game-section {
    padding: 0;
    margin-bottom: 20px;
  }
  .reportlist__main-top--remind--view--unread {
    color: $general-white;
    background-color: $general-red;
  }
  .reportlist__main-top--notice {
    color: $general-red;
  }
  .reportlist__nomore {
    text-align: center;
    color: $general-light-text-color;
    img {
      width: 100px;
      height: 100px;
    }
  }

  .legacy-btn {
    position: relative;
    display: flex;
    justify-content: center;
    color: $general-light-text-color;
    text-decoration: underline;
  }
}
</style>
