<template>
  <div class="main-wrap">
    <section class="form-section">
      <div class="reportlist-main">
        <div class="faqdetail-title">
          <div class="faqdetail__question">{{ $t('PROFILE__TITLE') }}</div>
          <div class="profile-warning__title">
            {{ $t('PROFILE__DESCRIPTION') }}
          </div>
          <div class="reportlist-result-subhead">
            <ol class="profile-warning__list">
              <li class="profile-warning__item">
                {{ $t('PROFILE__WARNING_1') }}
              </li>
              <li class="profile-warning__item">
                {{ $t('PROFILE__WARNING_2') }}
              </li>
              <li class="profile-warning__item">
                {{ $t('PROFILE__WARNING_3') }}
              </li>
            </ol>
          </div>
        </div>
        <dl class="profile-data__row">
          <dd class="profile-data__item">
            <template v-if="isGarenaUser">
              <div class="profile-data__item-name">
                {{ $t('GENERAL__PLATFORM_GARENA') }}
                {{ $t('PROFILE__ACCOUNT') }}
              </div>
              <div class="profile-data__item-value">
                {{ garenaUserRealInfo.username }}
              </div>
            </template>
            <template v-else-if="isFbUser">
              <div class="profile-data__item-name">
                {{ $t('GENERAL__PLATFORM_FACEBOOK') }}
                {{ $t('PROFILE__ACCOUNT') }}
              </div>
              <div class="profile-data__item-value">{{ userInfo.name }}</div>
            </template>
          </dd>
          <dd class="profile-data__item">
            <div v-if="isGarenaUser" class="profile-data__item-name">{{ $t('GENERAL__PLATFORM_GARENA') }} UID</div>
            <div v-else-if="isFbUser" class="profile-data__item-name">{{ $t('GENERAL__PLATFORM_FACEBOOK') }} UID</div>
            <div class="profile-data__item-value">{{ userInfo.uid }}</div>
          </dd>
        </dl>
        <template v-if="isGarenaUser && garenaUserRealInfo.username">
          <dl class="profile-data__row">
            <dd class="profile-data__item">
              <div class="profile-data__item-name">
                {{ $t('PROFILE__REAL_NAME') }}
              </div>
              <div v-if="garenaUserRealInfo.realname" class="profile-data__item-value">
                {{ garenaUserRealInfo.realname }}
              </div>
              <div v-else class="profile-data__item-value-gray">
                {{ $t('PROFILE__EMPTY') }}
              </div>
            </dd>
            <dd class="profile-data__item">
              <div class="profile-data__item-name">
                {{ $t('PROFILE__ID_CARD_NO') }}
              </div>
              <div v-if="garenaUserRealInfo.idcard" class="profile-data__item-value">
                {{ garenaUserRealInfo.idcard }}
              </div>
              <div v-else class="profile-data__item-value-gray">
                {{ $t('PROFILE__EMPTY') }}
              </div>
            </dd>
            <dd class="profile-data__item">
              <div class="profile-data__item-name">
                {{ $t('PROFILE__PHONE') }}
              </div>
              <div v-if="garenaUserRealInfo.formattedPhoneNumber" class="profile-data__item-value">
                {{ garenaUserRealInfo.formattedPhoneNumber }}
              </div>
              <div v-else class="profile-data__item-value-gray">
                {{ $t('PROFILE__EMPTY') }}
              </div>
            </dd>
            <dd class="profile-data__item">
              <div class="profile-data__item-name">
                {{ $t('PROFILE__EMAIL_ADDRESS') }}
              </div>
              <div v-if="garenaUserRealInfo.email" class="profile-data__item-value">
                {{ garenaUserRealInfo.email }}
              </div>
              <div v-else class="profile-data__item-value-gray">
                {{ $t('PROFILE__EMPTY') }}
              </div>
            </dd>
            <div v-if="isGarenaUser" class="profile-data__item">
              <div class="reportlist-result-subhead" v-html="$t('PROFILE__TO_ACCOUNT_CENTER')"></div>
            </div>
          </dl>
        </template>
        <template v-if="isFbUser">
          <template v-if="profile && !isEditing">
            <dl class="profile-data__row">
              <dd class="profile-data__item">
                <div class="profile-data__item-name">
                  {{ $t('PROFILE__REAL_NAME') }}
                </div>
                <div class="profile-data__item-value">
                  {{ profile.realname }}
                </div>
              </dd>
              <dd class="profile-data__item">
                <div class="profile-data__item-name">
                  {{ $t('PROFILE__ID_CARD_NO') }}
                </div>
                <div class="profile-data__item-value">{{ profile.idcard }}</div>
              </dd>
              <dd class="profile-data__item">
                <div class="profile-data__item-name">
                  {{ $t('PROFILE__BIRTHDATE') }}
                </div>
                <div v-if="profile.formattedBirthdate" class="profile-data__item-value">
                  {{ profile.formattedBirthdate }}
                </div>
                <div v-else-if="hasProfile" class="profile-data__item-value-gray">
                  {{ $t('PROFILE__INVALID_BIRTHDATE') }}
                </div>
              </dd>
              <dd class="profile-data__item">
                <div class="profile-data__item-name">
                  {{ $t('PROFILE__PHONE') }}
                </div>
                <div class="profile-data__item-value">
                  {{ profile.formattedPhoneNumber }}
                </div>
              </dd>
              <dd class="profile-data__item">
                <div class="profile-data__item-name">
                  {{ $t('PROFILE__EMAIL_ADDRESS') }}
                </div>
                <div class="profile-data__item-value">{{ profile.email }}</div>
              </dd>
              <dd class="profile-data__item">
                <div class="profile-data__item-name">
                  <a v-if="!hasProfile" @click.prevent="switchEditing()">{{ $t('PROFILE__ADD_PERSONAL_INFO') }}</a>
                </div>
              </dd>
            </dl>
          </template>
          <template v-else>
            <form class="report-form">
              <div class="report-form__content">
                <div class="report-form__label">
                  {{ $t('PROFILE__REAL_NAME') }}
                </div>
                <div class="report-form__field">
                  <custom-input
                    name="realname"
                    :is-required="true"
                    :error-msg="$t('GENERAL__INPUT_ERROR_REQUIRED')"
                    v-model="profile.realname"
                  />
                </div>
                <div class="report-form__label">
                  {{ $t('PROFILE__ID_CARD_NO') }}
                </div>
                <div class="report-form__field">
                  <custom-input
                    name="idcard"
                    :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH')"
                    :is-required="true"
                    :error-msg="$t('GENERAL__INPUT_ERROR_REQUIRED')"
                    v-model="profile.idcard"
                  />
                </div>
                <div class="report-form__label">
                  {{ $t('PROFILE__BIRTHDATE') }}
                </div>
                <div class="report-form__field">
                  <custom-datetime-picker
                    v-model="profile.birthdate"
                    type="date"
                    :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_UNDER_AGE')"
                    :format="$t('GENERAL__DATE_FORMAT')"
                  >
                    <div slot="calendar-icon" class="profile-form__inputdateicon"></div>
                  </custom-datetime-picker>
                </div>
                <div class="report-form__label">{{ $t('PROFILE__PHONE') }}</div>
                <div class="report-form__field">
                  <phone-input
                    name="phone_number"
                    :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH_INT')"
                    :error-msg="$t('GENERAL__INPUT_ERROR_PHONE_FORMAT')"
                    :default-number="profile.formattedPhoneNumber"
                    v-model="phoneNumberObj"
                  />
                </div>
                <div class="report-form__label">
                  {{ $t('PROFILE__EMAIL_ADDRESS') }}
                </div>
                <div class="report-form__field">
                  <custom-input
                    name="email"
                    :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH')"
                    :is-required="true"
                    :custom-type="constants.GENERAL__INPUT_TYPES.EMAIL"
                    :error-msg="$t('GENERAL__INPUT_ERROR_EMAIL_FORMAT')"
                    v-model="profile.email"
                  />
                </div>
                <div class="report-form__uploader-info">
                  <ol>
                    <li class="report-form__uploader-infoitem">
                      {{ $t('PROFILE__NOTICE_2') }}
                    </li>
                  </ol>
                </div>
              </div>
            </form>
            <button class="reportlist__return" :disabled="!isButtonEnabled" @click.prevent="submitProfile">
              {{ $t('GENERAL__BUTTON_SUBMIT_FORM') }}
              <vue-countdown v-if="isSubmitting" :time="10000" @end="handleCountdownEnd">
                <template slot-scope="props">({{ props.totalSeconds }})</template>
              </vue-countdown>
            </button>
          </template>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import VueCountdown from '@chenfengyuan/vue-countdown';
import CustomDatetimePicker from '@/components/share/layouts/partial/CustomDatetimePicker';
import CustomInput from '@/components/share/layouts/partial/CustomInput';
import PhoneInput from '@/components/share/layouts/partial/PhoneInput';
import profile from '@/api/prod/profile';
import constants from '@/lib/constants';
import utils from '@/lib/utils';
import { ACTION_TRIGGER_POPUP } from '@/store/action-types';

export default {
  name: 'Profile',
  components: {
    VueCountdown,
    CustomDatetimePicker,
    CustomInput,
    PhoneInput,
  },
  data() {
    return {
      constants,
      utils,
      paths: [
        {
          id: 1,
          name: 'Profile',
          params: {},
          title: this.$t('PROFILE__TITLE'),
        },
      ],
      isSubmitting: false,
      isEditing: false,
      phoneNumberObj: null,
      hasProfile: true,
      garenaUserRealInfo: {
        username: null,
        realname: null,
        idcard: null,
        phoneNumber: null,
        phoneNumberCountryCode: null,
        formattedPhoneNumber: null,
        email: null,
      },
      profile: {
        realname: null,
        idcard: null,
        birthdate: null,
        formattedBirthdate: null,
        phoneNumber: null,
        phoneNumberCountryCode: null,
        formattedPhoneNumber: null,
        email: null,
      },
    };
  },
  computed: {
    formValues() {
      return {
        realname: this.profile.realname,
        idcard: this.profile.idcard,
        birthdate: this.profile.birthdate && this.utils.formatTimestampToString(this.profile.birthdate, 'YYYYMMDD'),
        phoneNumber: this.profile.phoneNumber,
        phoneNumberCountryCode: this.profile.phoneNumberCountryCode,
        email: this.profile.email,
      };
    },
    requiredFields() {
      return Object.keys(this.formValues);
    },
    isButtonEnabled() {
      return (
        this.requiredFields.every((field) => this.formValues[field] && this.formValues[field].length > 0) &&
        utils.isValidEmail(this.formValues.email) &&
        !this.isSubmitting
      );
    },
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      unreadTicket: (state) => state.user.unreadTicket,
      unreadApplication: (state) => state.user.unreadApplication,
    }),
    ...mapGetters(['hasLoggedIn', 'isFbUser', 'isGarenaUser']),
  },
  methods: {
    redirectToAccountCenter() {
      window.location.href = constants.GENERAL__ACCOUNT_CENTER_URL;
    },
    switchEditing() {
      this.isEditing = !this.isEditing;
    },
    handleCountdownEnd() {
      this.isSubmitting = false;
    },
    submitProfile() {
      this.isSubmitting = true;
      const payload = this.formValues;
      profile
        .updatePlatformUserInfo(payload)
        .then((resp) => {
          try {
            if (resp.error) {
              throw new Error();
            }
            const { data } = resp;
            if (data) {
              this.triggerPopup({
                title: this.$t('POPUP__ALERT'),
                content: this.$t('POPUP__ALERT_PROFILE_UPDATE_SUCCEED'),
              });
              this.profile = this.formValues;
              const dialCode = this.profile.phoneNumberCountryCode && `+${this.profile.phoneNumberCountryCode} `;
              this.profile.formattedBirthdate = this.utils.convertDatetimeStringFormat(this.profile.birthdate, 'YYYYMMDD', 'YYYY-MM-DD');
              this.profile.formattedPhoneNumber = this.profile.phoneNumber && `${dialCode}${this.profile.phoneNumber}`;
              this.isEditing = false;
            } else {
              this.triggerPopup({
                title: this.$t('POPUP__ERROR'),
                content: this.$t('POPUP__ERROR_PROFILE_UPDATE_FAILED'),
              });
            }
          } catch {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_PROFILE_NO_PLATFORM_USER_INFO_REQUIRED'),
            });
          }
          this.hasProfile = true;
          this.isSubmitting = false;
        })
        .catch(() => {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_PROFILE_UPDATE_FAILED'),
          });
        });
    },
    ...mapActions({
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
  },
  mounted() {
    if (this.isGarenaUser) {
      let isRealInfoCompleted = false;
      profile.getGarenaUserRealInfo().then((resp) => {
        try {
          if (resp.error) {
            throw new Error();
          }
          const { data } = resp;
          if (data) {
            this.garenaUserRealInfo = data;
            const dialCode = this.garenaUserRealInfo.phoneNumberCountryCode && `+${this.garenaUserRealInfo.phoneNumberCountryCode} `;
            this.garenaUserRealInfo.formattedPhoneNumber =
              this.garenaUserRealInfo.phoneNumber && `${dialCode}${this.garenaUserRealInfo.phoneNumber}`;
          }
          isRealInfoCompleted = Object.values(data).every((v) => v.length > 0);
          if (!isRealInfoCompleted) {
            this.triggerPopup({
              title: this.$t('POPUP__ALERT'),
              content: this.$t('POPUP__ALERT_PROFILE_REDIRECT_TO_ACCOUNT_CENTER'),
            });
          }
        } catch {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_EXCEPTION'),
          });
        }
      });
    } else {
      profile.getPlatformUserInfo().then((resp) => {
        try {
          if (resp.error) {
            throw new Error();
          }
          const { data } = resp;
          if (data) {
            this.profile = data;
            const dialCode = this.profile.phoneNumberCountryCode && `+${this.profile.phoneNumberCountryCode} `;
            this.profile.formattedBirthdate = this.utils.convertDatetimeStringFormat(this.profile.birthdate, 'YYYYMMDD', 'YYYY-MM-DD');
            this.profile.formattedPhoneNumber = this.profile.phoneNumber && `${dialCode}${this.profile.phoneNumber}`;
            this.phoneNumberObj = {
              countryCode: this.profile.phoneNumberCountryCode,
              number: this.profile.phoneNumber,
            };
          } else {
            this.hasProfile = false;
          }
        } catch {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_PROFILE_NO_PLATFORM_USER_INFO_REQUIRED'),
          });
        }
      });
    }
  },
  watch: {
    phoneNumberObj(newValue) {
      if (newValue) {
        this.profile.phoneNumber = newValue.number;
        this.profile.phoneNumberCountryCode = newValue.countryCode;
      } else {
        this.profile.phoneNumber = null;
        this.profile.phoneNumberCountryCode = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrap ::v-deep ::placeholder {
  color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.input-wrap ::v-deep .mx-datepicker {
  width: 100%;
}
.input-wrap ::v-deep .mx-input-wrapper {
  height: 42px;
}
.input-wrap ::v-deep .mx-input {
  height: 100%;
  border: 1px solid $general-input-border-color;
  border-radius: 3px;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.input-wrap ::v-deep .mx-calendar-content .cell.actived {
  background-color: $general-red;
  color: #fff;
}
.input-wrap ::v-deep .mx-panel-date td.today {
  color: $general-red;
}
.profile-data__item-name {
  ::v-deep a {
    color: $general-red;
    cursor: pointer;
  }
}
.report-form__uploader-info {
  ol {
    margin-top: 20px;
  }
}
</style>
