var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notification-wrap" }, [
    _c("div", { staticClass: "notification-dimmer" }, [
      _c("div", { staticClass: "notification notification--sm" }, [
        _c(
          "button",
          {
            staticClass: "rountbutton__widget",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.closePopup.apply(null, arguments)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("M_HOME__SERVICE_RECORD_POPUP_BUTTON")))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "notification-inner" }, [
          _c("div", { staticClass: "notification__title" }, [
            _c("div", { staticClass: "notification__title-txt" }, [
              _vm._v(_vm._s(_vm.$t("M_HOME__SERVICE_RECORD_POPUP_TITLE")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "notification__main" }, [
            _c("div", { staticClass: "notification__content" }, [
              _c(
                "div",
                { staticClass: "notification__content-txt" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "MobileReportList" } } },
                    [
                      _c("button", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("M_HOME__SERVICE_RECORD_POPUP_OPTION_REPORT")
                          )
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "MobileApplicationList" } } },
                    [
                      _c("button", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "M_HOME__SERVICE_RECORD_POPUP_OPTION_SERVICE"
                            )
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }