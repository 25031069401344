<template>
  <div class="main-wrap">
    <template v-if="isDataReady">
      <section class="form-section">
        <div class="reportlist-main">
          <div class="faqdetail-title">
            <div class="faqdetail__question">{{ $t('VERIFICATION__RESET_PROFILE_TITLE') }}</div>
            <div class="reportlist-result-subhead">
              <dl class="profile-warning">
                <dt class="profile-warning__title">{{ $t('PROFILE__DESCRIPTION') }}</dt>
                <dd>
                  <ol class="profile-warning__list">
                    <li class="profile-warning__item">{{ $t('PROFILE__WARNING_1') }}</li>
                    <li class="profile-warning__item">{{ $t('PROFILE__WARNING_2') }}</li>
                    <li class="profile-warning__item">{{ $t('PROFILE__WARNING_3') }}</li>
                  </ol>
                </dd>
              </dl>
            </div>
          </div>
        </div>

        <form class="report-form">
          <div class="report-form__content">
            <div class="report-form__label">{{ $t('VERIFICATION__RESET_PROFILE_APPLICATION_SERVICE') }}</div>
          </div>
          <ol class="report-form__ol">
            <li class="report-form__row">
              <template v-if="verificationTypes.includes(constants.APPLICATION__VERIFICATION_TYPES.CANCEL_PHONE)">
                <custom-checkbox
                  :name="'verification_service_phone'"
                  :label="$t('VERIFICATION__RESET_PROFILE_APPLICATION_CANCEL_PHONE')"
                  v-model="hasCancelPhoneChecked"
                />
              </template>
              <template v-if="verificationTypes.includes(constants.APPLICATION__VERIFICATION_TYPES.CANCEL_EMAIL)">
                <custom-checkbox
                  :name="'verification_service_email'"
                  :label="$t('VERIFICATION__RESET_PROFILE_APPLICATION_CANCEL_EMAIL')"
                  v-model="hasCancelEmailChecked"
                />
              </template>
              <template v-if="verificationTypes.includes(constants.APPLICATION__VERIFICATION_TYPES.RESET_PASSWORD)">
                <custom-checkbox
                  :name="'verification_service_pwd'"
                  :label="$t('VERIFICATION__RESET_PROFILE_APPLICATION_RESET_PASSWORD')"
                  v-model="hasResetPasswordChecked"
                />
              </template>
            </li>
          </ol>

          <!-- 以何種方式通知密碼 -->
          <template v-if="hasResetPasswordChecked">
            <div class="report-form__content">
              <div class="report-form__label">{{ $t('VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY') }}</div>
            </div>
            <ol class="report-form__ol">
              <li v-if="false" class="report-form__row">
                <custom-radio
                  name="notification_type"
                  :label="$t('VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY_SMS')"
                  :radio-value="constants.APPLICATION__VERIFICATION_NOTIFICATION_TYPES.SMS"
                  v-model="notificationType"
                />
                <template v-if="isNotifyBySms">
                  <phone-input
                    name="notification_info"
                    :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH_INT')"
                    :error-msg="$t('GENERAL__INPUT_ERROR_PHONE_FORMAT')"
                    v-model="notificationInfo"
                  />
                </template>
              </li>
              <li class="report-form__row">
                <custom-radio
                  v-if="false"
                  name="notification_type"
                  :label="$t('VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY_EMAIL')"
                  :radio-value="constants.APPLICATION__VERIFICATION_NOTIFICATION_TYPES.EMAIL"
                  v-model="notificationType"
                />
                <template v-if="isNotifyByEmail">
                  <custom-input
                    name="notification_info"
                    :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH')"
                    :custom-type="constants.GENERAL__INPUT_TYPES.EMAIL"
                    :error-msg="$t('GENERAL__INPUT_ERROR_EMAIL_FORMAT')"
                    v-model="notificationInfo"
                  />
                </template>
              </li>
            </ol>
          </template>
          <!-- 以何種方式通知密碼 /-->

          <div class="report-form__content report-form__content-files">
            <uploader
              @uploading="updateUploadingFiles"
              @uploaded="addUploadedFileIds"
              @removed="removeUploadedFileIds"
              :label="$t('GENERAL__BUTTON_UPLOAD_ID')"
              :show-notice="false"
              :is-required="true"
              :is-img-only="true"
              :max-file-cnt="constants.GENERAL__MAX_FILE_CNT.APPLICATION"
              :error-msg="$t('GENERAL__INPUT_ERROR_FILE_NOT_SELECTED')"
              name="files"
            />
            <ol class="report-form__uploader-info" v-html="$t('CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD_NOTICE_1')"></ol>
          </div>
        </form>
        <div class="bg-grey"></div>
        <div class="report-form__content report-form__content-tos">
          <custom-checkbox :name="'ticket_tos_checkbox'" :label="$t('TICKET__TOS_CHECKBOX')" v-model="isTosChecked" />
        </div>
      </section>
      <button class="reportlist__return" :disabled="!isButtonEnabled" @click.prevent="submitApplication">
        {{ $t('GENERAL__BUTTON_SUBMIT_FORM') }}
        <vue-countdown v-if="isSubmitting" :time="10000" @end="handleCountdownEnd">
          <template slot-scope="props">({{ props.totalSeconds }})</template>
        </vue-countdown>
      </button>
    </template>
    <template v-else>
      <div class="loading">
        <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import VueCountdown from '@chenfengyuan/vue-countdown';
import CustomCheckbox from '@/components/mobile/layouts/partial/CustomCheckbox';
import CustomInput from '@/components/share/layouts/partial/CustomInput';
import CustomRadio from '@/components/mobile/layouts/partial/CustomRadio';
import PhoneInput from '@/components/share/layouts/partial/PhoneInput';
import Uploader from '@/components/share/layouts/partial/Uploader';
import application from '@/api/prod/application';
import constants from '@/lib/constants';
import utils from '@/lib/utils';
import { ACTION_TRIGGER_POPUP } from '@/store/action-types';
import {
  API_RETURN_CODE_APPLICATION_IN_PROGRESS,
  API_RETURN_CODE_APPLIED_TOO_MANY_TIMES,
  API_RETURN_CODE_PROFILE_INCOMPLETE,
  API_RETURN_CODES_ERROR_REQUIRE_GARENA_ACCOUNT,
} from '@/lib/return-codes';

export default {
  name: 'AccountRecovery',
  components: {
    VueCountdown,
    CustomCheckbox,
    CustomInput,
    CustomRadio,
    PhoneInput,
    Uploader,
  },
  data() {
    return {
      constants,
      isDataReady: false,
      verificationTypes: [],
      hasCancelPhoneChecked: false,
      hasCancelEmailChecked: false,
      hasResetPasswordChecked: false,
      notificationType: constants.APPLICATION__VERIFICATION_NOTIFICATION_TYPES.EMAIL,
      notificationInfo: null,
      emailAddress: null,
      phoneNumberObj: null,
      uploadingFiles: {},
      uploadedFiles: [],
      isTosChecked: false,
      isSubmitting: false,
      submittingCountdown: 0,
    };
  },
  computed: {
    fieldsValue() {
      const ret = {
        types: this.verificationServiceTypes,
        files: this.uploadedFiles,
      };
      if (this.notificationType !== null) {
        ret.notificationType = this.notificationType;
      }
      if (this.notificationInfo !== null) {
        ret.notificationInfo = this.notificationInfo;
      }
      return ret;
    },
    verificationServiceTypes() {
      return [
        this.hasCancelPhoneChecked ? constants.APPLICATION__VERIFICATION_TYPES.CANCEL_PHONE : '',
        this.hasCancelEmailChecked ? constants.APPLICATION__VERIFICATION_TYPES.CANCEL_EMAIL : '',
        this.hasResetPasswordChecked ? constants.APPLICATION__VERIFICATION_TYPES.RESET_PASSWORD : '',
      ].filter((e) => e !== '');
    },
    isNotifyByEmail() {
      return this.notificationType === constants.APPLICATION__VERIFICATION_NOTIFICATION_TYPES.EMAIL;
    },
    isNotifyBySms() {
      return this.notificationType === constants.APPLICATION__VERIFICATION_NOTIFICATION_TYPES.SMS;
    },
    isNotificationInfoFilledIfResetPassword() {
      return (
        (this.hasResetPasswordChecked &&
          this.isNotifyByEmail &&
          utils.isValidEmail(this.notificationInfo) &&
          this.notificationInfo.length !== 0) ||
        (this.hasResetPasswordChecked && this.isNotifyBySms && this.notificationInfo && this.notificationInfo.length !== 0)
      );
    },
    isUploading() {
      return !utils.isEmptyObject(this.uploadingFiles) && Object.values(this.uploadingFiles).some((file) => !file.uploadCompleted);
    },
    isFormFilled() {
      return (
        this.verificationServiceTypes.length !== 0 &&
        this.uploadedFiles.length !== 0 &&
        (this.isNotificationInfoFilledIfResetPassword || !this.hasResetPasswordChecked)
      );
    },
    isButtonEnabled() {
      return this.isTosChecked && this.isFormFilled && !this.isUploading && !this.isSubmitting;
    },
  },
  methods: {
    updateUploadingFiles(files) {
      this.uploadingFiles = files;
    },
    addUploadedFileIds(name, fileId) {
      this.uploadedFiles.push(fileId);
    },
    removeUploadedFileIds(name, fileId) {
      const idx = this.uploadedFiles.indexOf(fileId);
      this.uploadedFiles.splice(idx, 1);
    },
    handleCountdownEnd() {
      this.isSubmitting = false;
    },
    submitApplication() {
      this.isSubmitting = true;
      const payload = this.fieldsValue;
      application.createApplication('verification', payload).then((resp) => {
        const { error } = resp;
        if (error) {
          if (error === API_RETURN_CODE_APPLIED_TOO_MANY_TIMES) {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_APPLICATION_APPLIED_TOO_MANY_TIMES'),
            });
          } else if (error === API_RETURN_CODE_PROFILE_INCOMPLETE) {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_APPLICATION_PROFILE_INCOMPLETE'),
            });
          } else if (error === API_RETURN_CODE_APPLICATION_IN_PROGRESS) {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_APPLICATION_APPLICATION_IN_PROGRESS'),
            });
          } else if (error === API_RETURN_CODES_ERROR_REQUIRE_GARENA_ACCOUNT) {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_APPLICATION_FB_ACCOUNT_CANT_VERIFICATION'),
            });
          } else {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_APPLICATION_CREATE_FAILED'),
            });
          }
        } else {
          this.$router.push({
            name: 'MobileResetProfileCompletion',
            params: { status: constants.APPLICATION__VERIFICATION_RESULTS.SUCCESS },
          });
        }
      });
    },
    ...mapActions({
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
  },
  mounted() {
    application.getVerificationAvailability().then((resp) => {
      const { data, error } = resp;
      if (error) {
        if (error === API_RETURN_CODE_APPLIED_TOO_MANY_TIMES) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_APPLICATION_APPLIED_TOO_MANY_TIMES'),
          });
        } else if (error === API_RETURN_CODE_PROFILE_INCOMPLETE) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_APPLICATION_PROFILE_INCOMPLETE'),
          });
        } else if (error === API_RETURN_CODE_APPLICATION_IN_PROGRESS) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_APPLICATION_APPLICATION_IN_PROGRESS'),
          });
        } else if (error === API_RETURN_CODES_ERROR_REQUIRE_GARENA_ACCOUNT) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_APPLICATION_FB_ACCOUNT_CANT_VERIFICATION'),
          });
        } else {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_EXCEPTION'),
          });
        }
        this.$router.push({
          name: 'MobileHome',
        });
      } else {
        this.isDataReady = true;
        this.verificationTypes = data.verificationTypes;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .profile-warning__item {
    list-style-type: decimal;
    margin: 0 0 0 20px;
  }
  .report-form__content-files {
    margin-top: 22px;
  }
  .report-form__content-tos {
    margin: 16px auto 30px;
  }
  .report-form__row .custom_input-wrap,
  .report-form__row .phone_input-wrap {
    margin-top: 5px;
  }
  .report-form__row .md-radio-inline {
    margin: 8px 10px 0;
  }
  .loading {
    margin-top: 100px;
    text-align: center;
    img {
      width: 15%;
    }
  }
}
</style>
