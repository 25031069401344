export default {
  APPLICATION__ACCOUNT_RECOVERY_STATUS_CANCELLED: '신청취소',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_COMPLETED: '신청완료',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_DUPLICATED: '중복신청',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_NG_ALREADY_VERIFIED: '심사 실패 - 이메일 또는 핸드폰은 이미 인증되었음',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_NG_BLURRED_ID: '심사 실패 - 사진이 선명하지 않음',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_NG_CONTACT_SUPPORT: '심사 실패 - 신청 실패. 고객 센터에 연락해 주세요.',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_NG_ID_NOT_FOUND: '심사 실패 - 찾을 수 없는 계정 정보',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_NG_INCOMPLETE_ID: '심사 실패 - 증명 서류가 누락되었음',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_NG_INCOMPLETE_PLAYER_INFO: '심사 실패 - 게임 정보가 누락되었음',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_NG_INCOMPLETE_USER_INFO: '심사 실패 - 회원 정보가 누락되었음',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_NG_INVALID_ID: '심사 실패 - 무효한 증명 서류',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_NG_MISMATCHED_ID: '심사 실패 - 잘못된 증명 서류',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_NG_MISMATCHED_PLAYER_INFO: '심사 실패 - 잘못된 게임 정보',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_NG_MISMATCHED_SERVICE_REGION: '심사 실패 - 서비스 지역이 아님',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_NG_NO_COLOR_ID: '심사 실패 - 컬러사진이 아님',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_NG_OVER_LIMIT: '심사 실패 - 신청 횟수가 너무 많음',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_OK_PROCESSING: '심사 성공 - 처리 중',
  APPLICATION__ACCOUNT_RECOVERY_STATUS_PENDING: '심사 대기 중',
  APPLICATION__NO_RECORD: '온라인 서비스 신청 기록이 없습니다.',
  APPLICATION__RECORD_ITEM: '신청항목',
  APPLICATION__RECORD_NOTES: '備註',
  APPLICATION__RECORD_STATUS: '상태',
  APPLICATION__RECORD_TIME: '제출 시간',
  APPLICATION__TAB_CANT_LOGIN: '로그인할 수 없습니다.',
  APPLICATION__TAB_OTHERS: '회원 서비스 신청',
  APPLICATION__TAB_VERIFICATION: '인증 취소',
  APPLICATION__TITLE: '온라인 서비스 신청 기록',
  APPLICATION__VERIFICATION_STATUS_CANCELLED: '신청취소',
  APPLICATION__VERIFICATION_STATUS_COMPLETED: '신청완료',
  APPLICATION__VERIFICATION_STATUS_DUPLICATED: '중복신청',
  APPLICATION__VERIFICATION_STATUS_NG_ALREADY_VERIFIED: '심사 실패 - 이메일 또는 핸드폰은 이미 인증되었음',
  APPLICATION__VERIFICATION_STATUS_NG_BLURRED_ID: '심사 실패 - 사진이 선명하지 않음',
  APPLICATION__VERIFICATION_STATUS_NG_CONTACT_SUPPORT: '심사 실패 - 신청 실패. 고객 센터에 연락해 주세요.',
  APPLICATION__VERIFICATION_STATUS_NG_INCOMPLETE_ID: '심사 실패 - 증명 서류가 누락되었음',
  APPLICATION__VERIFICATION_STATUS_NG_INVALID_ID: '심사 실패 - 무효한 증명 서류',
  APPLICATION__VERIFICATION_STATUS_NG_MISMATCHED_ID: '심사 실패 - 잘못된 증명 서류',
  APPLICATION__VERIFICATION_STATUS_NG_MISMATCHED_SERVICE_REGION: '심사 실패 - 서비스 지역이 아님',
  APPLICATION__VERIFICATION_STATUS_NG_NO_COLOR_ID: '심사 실패 - 컬러사진이 아님',
  APPLICATION__VERIFICATION_STATUS_NG_OVER_LIMIT: '심사 실패 - 신청 횟수가 너무 많음',
  APPLICATION__VERIFICATION_STATUS_OK_PROCESSING: '심사 성공 - 처리 중',
  APPLICATION__VERIFICATION_STATUS_PENDING: '심사 대기 중',
  ARTICLE__FEEDBACK_NOTE: '感謝你告訴我們',
  ARTICLE__FEEDBACK_OPTION_NO: '否',
  ARTICLE__FEEDBACK_OPTION_YES: '是',
  ARTICLE__FEEDBACK_PLACEHOLDER: '要如何讓這篇文章變得更實用？（選填）\\n此非問題回報管道，請勿提供帳號與個人敏感資訊。',
  ARTICLE__FEEDBACK_SUBMIT: '送出',
  ARTICLE__FEEDBACK_TITLE: '本篇說明是否有幫助？',
  ARTICLE__ID: 'FAQ번호：',
  ARTICLE__RELATED_ARTICLE: '관련 기사',
  ARTICLE__SHOW_MORE: '더 많은 FAQ 보기',
  ARTICLE__TAGS: 'FAQ태그:',
  ARTICLE__TICKET_CHOOSE_GAME: '게임을 선택하세요',
  ARTICLE__TICKET_DESC: '의견 보내기 기능은 24시간 사용할 수 있습니다.',
  ARTICLE__TICKET_SUBTITLE: '문제가 해결되지 않습니까?',
  ARTICLE__TICKET_TITLE: '의견 보내기',
  ARTICLE__TITLE: '{currentBaseCategoryName}자주 하는 질문：',
  ARTICLE__TO_TOP: '제일 위로 이동',
  CANT_LOGIN__ACCOUNT_RECOVERY_ACCOUNT: 'Garena 계정:',
  CANT_LOGIN__ACCOUNT_RECOVERY_CHECKBOX:
    '나는 <a href="https://terms.support.garena.com/tw" target="_blank" class="onlineservice-form__link">규약 & 정책 </a> 및 리포트 시스템 주의사항을 자세히 읽고 준수하겠습니다.',
  CANT_LOGIN__ACCOUNT_RECOVERY_CONFIRM: '저는 비밀번호 재설정 서비스를 신청 시, 이메일 및 휴대폰 인증이 취소된다는 것을 알고 있습니다.',
  CANT_LOGIN__ACCOUNT_RECOVERY_CONTACT_EMAIL: '이메일 주소:',
  CANT_LOGIN__ACCOUNT_RECOVERY_CONTACT_EMAIL_TIP: '審核結果將透過電子郵件通知，請務必填寫可用信箱。',
  CANT_LOGIN__ACCOUNT_RECOVERY_COUNTRY_CODE: '국가 코드',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_ACCOUNT_CREATED_TIME: 'Garena 계정 생성 날짜:',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_ACCOUNT_CREATED_TIME_PLACEHOLDER: 'Garena 계정 생성 날짜:',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_LAST_LOGIN_TIME: '마지막 접속 시간:',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_LAST_LOGIN_TIME_PLACEHOLDER: '예: 2013년',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_MAIN_GAME: '주로 하는 게임:',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP: '충전 기록 유무:',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_AMOUNT: '충전 금액:',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_AMOUNT_PLACEHOLDER: '0',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_AMOUNT_UNIT: '원',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_CHANNEL: '충전 방식:',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_LAST_TOPUP_TIME: '마지막 충전 시간:',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_LAST_TOPUP_TIME_PLACEHOLDER: '예: 2013년',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_NO: '충전한 적 없음',
  CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_YES: '충전한 적 있음',
  CANT_LOGIN__ACCOUNT_RECOVERY_NOTICE: '주의사항:',
  CANT_LOGIN__ACCOUNT_RECOVERY_NOTICE_NOTICE_1:
    '이 서비스는 컬러 [사진] 또는 [스캔 파일]만 접수하므로, 반드시 선명한 증명 사진을 제공해 주세요. 만약 증명 사진을 잘 볼 수 없거나, 증명 사진이 회원 정보와 일치하지 않는 경우, 신청을 접수할 수 없습니다.',
  CANT_LOGIN__ACCOUNT_RECOVERY_NOTICE_NOTICE_2: '신청 후 Garena는 바로 계정 정보 및 이용 상태를 확인할 예정이니 심사 결과를 기다려 주세요.',
  CANT_LOGIN__ACCOUNT_RECOVERY_PHONE: '전화번호:',
  CANT_LOGIN__ACCOUNT_RECOVERY_PROFILE_STATUS: '개인 정보 작성 상태:',
  CANT_LOGIN__ACCOUNT_RECOVERY_PROFILE_WITHOUT_FULL_DATA: '작성 미완료. 다시 확인해 주세요.',
  CANT_LOGIN__ACCOUNT_RECOVERY_PROFILE_WITH_FULL_DATA: '작성 완료',
  CANT_LOGIN__ACCOUNT_RECOVERY_REGION: '지역을 선택하세요.',
  CANT_LOGIN__ACCOUNT_RECOVERY_REGISTER_EMAIL: '등록된 이메일:',
  CANT_LOGIN__ACCOUNT_RECOVERY_REMINDER:
    '알림: 아래 빈칸는 모두 작성해야 합니다. 만약 회원 정보가 잘못 등록되어 있다면 <a href="/" class="onlineservice-form__link">온라인 리포트 </a>로 문의 주시기 바랍니다.',
  CANT_LOGIN__ACCOUNT_RECOVERY_SAME_EMAIL: '등록 이메일과 동일합니다.',
  CANT_LOGIN__ACCOUNT_RECOVERY_SAME_EMAIL_TIP: '먼저 등록 이메일을 입력하세요.',
  CANT_LOGIN__ACCOUNT_RECOVERY_TITLE: '온라인 서비스 신청',
  CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD: '파일 업로드:',
  CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD_NOTICE_1:
    '<li>本服務需提供彩色證件正本圖片。</li><li>中華民國（台澎金馬地區）之會員請提供【身分證正面】；未滿18歲之用戶，若未持有身分證，需提供健保卡及戶口名簿。</li><li>其他地區之會員請提供個人身分證件。</li>',
  CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD_NOTICE_2:
    '<li>本服務需提供彩色證件正本圖片。</li><li>中華民國（台澎金馬地區）之會員請提供【雙證件】， 年滿18歲之用戶限提供身分證正面及第二證件；未滿18歲之用戶，若未持有身分證，需提供健保卡及戶口名簿。</li><li>其他地區之會員請提供個人身分證件。</li>',
  CANT_LOGIN__ITEM_ACCBLOCK_CONTENT:
    '만약 Garena 계정을 로그인할 수 없다면, Facebook 또는 다른 Garena 계정으로 로그인해서 고객 센터에 연락하세요.',
  CANT_LOGIN__ITEM_ACCBLOCK_LINKTEXT: '사용 정지 리포트',
  CANT_LOGIN__ITEM_ACCBLOCK_TITLE: '계정이 사용 정지됨',
  CANT_LOGIN__ITEM_GAMEBLOCK_CONTENT:
    '만약 게임이 정지되어 접속할 수 없는 경우, 해당 계정으로 로그인해서 고객 센터 홈페이지에서 [해당 게임] →[정지 신고] →[온라인 의견 보내기]를 선택하여 고객 센터에 연락하세요.',
  CANT_LOGIN__ITEM_GAMEBLOCK_LINKTEXT: '리포트 작성',
  CANT_LOGIN__ITEM_GAMEBLOCK_TITLE: '게임 사용 정지',
  CANT_LOGIN__ITEM_RESETPWD_CONTENT:
    '只要驗證信箱或手機仍可使用，就可以透過<a href="https://account.garena.com/recovery" class="cantlogin__card-link">「忘記密碼」</a>功能自行重置密碼囉！<br />建議您先嘗試利用忘記密碼功能，並檢查是否有收到驗證信件或簡訊。<br />若您的會員資料有未詳盡填寫或填寫不實，請透過<a href="/" class="onlineservice-form__link">「線上回報單」</a>與我們諮詢。<br />※若您一直無法收到驗證碼，請點選下方按鈕申請重置密碼，讓我們協助您。',
  CANT_LOGIN__ITEM_RESETPWD_LINKTEXT: 'Garena 계정 및 비밀번호 재설정 신청',
  CANT_LOGIN__ITEM_RESETPWD_TITLE: '비밀번호 재설정',
  GENERAL__BUTTON_CANCEL: '취소',
  GENERAL__BUTTON_CONFIRM: '확인',
  GENERAL__BUTTON_NEXT_STEP: '다음',
  GENERAL__BUTTON_NOTIFICATION: '알겠습니다',
  GENERAL__BUTTON_PREVIOUS_STEP: '이전',
  GENERAL__BUTTON_SUBMIT_FORM: '제출',
  GENERAL__BUTTON_SUBMIT_REPLY: '답장 보내기',
  GENERAL__BUTTON_SUBMIT_REPORT__FEEDBACK: '의견 보내기',
  GENERAL__BUTTON_SUBMIT_TICKET: '제출',
  GENERAL__BUTTON_UPLOAD_FILE: '파일 업로드:',
  GENERAL__BUTTON_UPLOAD_ID: '증명 서류 업로드:',
  GENERAL__CALENDAR_DAY_FRI: '금',
  GENERAL__CALENDAR_DAY_MON: '월',
  GENERAL__CALENDAR_DAY_SAT: '토',
  GENERAL__CALENDAR_DAY_SUN: '일',
  GENERAL__CALENDAR_DAY_THU: '목',
  GENERAL__CALENDAR_DAY_TUE: '화',
  GENERAL__CALENDAR_DAY_WED: '수',
  GENERAL__CALENDAR_MONTH_APR: '4월',
  GENERAL__CALENDAR_MONTH_AUG: '8월',
  GENERAL__CALENDAR_MONTH_DEC: '12월',
  GENERAL__CALENDAR_MONTH_FEB: '2월',
  GENERAL__CALENDAR_MONTH_JAN: '1월',
  GENERAL__CALENDAR_MONTH_JUL: '7월',
  GENERAL__CALENDAR_MONTH_JUN: '6월',
  GENERAL__CALENDAR_MONTH_MAR: '3월',
  GENERAL__CALENDAR_MONTH_MAY: '5월',
  GENERAL__CALENDAR_MONTH_NOV: '11월',
  GENERAL__CALENDAR_MONTH_OCT: '10월',
  GENERAL__CALENDAR_MONTH_SEP: '9월',
  GENERAL__DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  GENERAL__DATETIME_FORMAT_2: 'YYYY 년 MM 월 DD 일 HH:mm:ss',
  GENERAL__DATE_FORMAT: 'YYYY-MM-DD',
  GENERAL__INPUT_ERROR_ACCOUNT_FORMAT: '계정 정보가 맞지 않습니다. 입력하신 계정 정보가 맞는지 확인 부탁드립니다.',
  GENERAL__INPUT_ERROR_APPLICATION_PLAYER_INFO_INCOMPLETE: '게임 정보가 누락됩니다. 다시 한 번 확인 바랍니다.',
  GENERAL__INPUT_ERROR_EMAIL_FORMAT: '이메일 주소가 맞지 않습니다. 입력하신 이메일 주소가 맞는지 확인 부탁드립니다.',
  GENERAL__INPUT_ERROR_FILE_NOT_SELECTED: '파일 업로드되지 않았습니다.',
  GENERAL__INPUT_ERROR_PHONE_FORMAT: '전화번호가 맞지 않습니다. 입력하신 전화번호가 맞는지 확인 부탁드립니다.',
  GENERAL__INPUT_ERROR_REQUIRED: '필수 항목입니다.',
  GENERAL__INPUT_FILE_UPLOAD_INFO:
    'JPG, JPEG, PNG, LOG, AVI, WMV, MOV, MP4, RM 파일로 업로드하세요. 파일은 20MB를 초과하면 안되며 한번에 최대 4개의 파일을 업로드할 수 있습니다.',
  GENERAL__INPUT_FILE_UPLOAD_TIP: '한 번에 최대 {maxFileCnt}개 파일을 업로드할 수 있습니다.',
  GENERAL__INPUT_PLACEHOLDER_HALFWIDTH: '반각 문자를 사용하세요.',
  GENERAL__INPUT_PLACEHOLDER_HALFWIDTH_INT: '반각 숫자를 사용하세요.',
  GENERAL__INPUT_PLACEHOLDER_PHONE_TIME: '플레이어님을 방해하지 않도록 입력할 것을 권장합니다.',
  GENERAL__INPUT_PLACEHOLDER_UNDER_AGE: '미성년자 충전 시 부모의 동의를 받으셔야 합니다.',
  GENERAL__LOGIN: '로그인',
  GENERAL__LOGOUT: '로그아웃',
  GENERAL__NOT_LOGGED_IN: '로그인하지 않음',
  GENERAL__PLACEHOLDER_DEFAULT_SELECT: '선택하세요',
  GENERAL__PLACEHOLDER_DEFAULT_SELECT_DATE: '날짜를 선택하세요',
  GENERAL__PLACEHOLDER_DEFAULT_SELECT_GAME: '게임을 선택하세요',
  GENERAL__PLATFORM_FACEBOOK: 'Facebook',
  GENERAL__PLATFORM_GARENA: 'Garena',
  GENERAL__SERVICE_PHONE: '고객 센터 전화: (02)7735-2000',
  GENERAL__SERVICE_TIME: '근무시간: 월요일부터 금요일까지 9:00 ~ 13:00, 14:00 ~ 18:00(휴일 제외)',
  GENERAL__SITE_TITLE: 'Garena 고객지원',
  GENERAL__TITLE: '고객 센터',
  HOME__CANT_LOGIN_DESC: '비밀번호 재설정, 기타 문제',
  HOME__CANT_LOGIN_TITLE: '계정을 로그인할 수 없습니다.',
  HOME__FAQ: 'FAQ',
  HOME__MOBILE_GAME_QUESTION: '모바일 게임 문제',
  HOME__NEWS_LEARN_MORE: '더 알아보기',
  HOME__NEWS_TITLE: '최신 소식',
  HOME__PC_GAME_QUESTION: '온라인 게임 문제',
  HOME__USER_ACCOUNT_CENTER: '회원 센터',
  HOME__USER_ACCOUNT_INFO: '회원 정보',
  HOME__USER_APPLICATION_RECORD: '온라인 서비스 신청 기록',
  HOME__USER_TICKET_RECORD: '나의 리포트 기록',
  HOME__USER_UNREAD: '읽지 않음',
  HOME__VERIFICATION_DESC: '이메일 인증, 휴대폰 인증, 인증 취소',
  HOME__VERIFICATION_TITLE: '계정 안전 인증',
  LOGIN__CHOOSE_PLATFORM: '로그인 방식을 선택하세요:',
  M_CANT_LOGIN__ACCOUNT_RECOVERY_REMINDER:
    '溫馨提醒：以下資料均為必填欄位，若您的會員資料登記不正確，請透過<a href="/m/games" class="onlineservice-form__link">線上回報單</a>與我們諮詢。',
  M_CANT_LOGIN__ITEM_ACCBLOCK_CONTENT:
    '만약 Garena 계정을 로그인할 수 없다면, Facebook 또는 다른 Garena 계정으로 로그인해서 고객 센터에 연락하세요.',
  M_CANT_LOGIN__ITEM_GAMEBLOCK_CONTENT:
    '만약 게임이 정지되어 접속할 수 없는 경우, 해당 계정으로 로그인해서 고객 센터 홈페이지에서 [해당 게임] →[정지 신고] →[온라인 의견 보내기]를 선택하여 고객 센터에 연락하세요.',
  M_CANT_LOGIN__ITEM_RESETPWD_CONTENT:
    '只要驗證信箱或手機仍可使用，就可以透過<a href="https://account.garena.com/recovery" class="cantlogin__card-link">「忘記密碼」</a>功能自行重置密碼囉！<br />建議您先嘗試利用忘記密碼功能，並檢查是否有收到驗證信件或簡訊。<br />若您的會員資料有未詳盡填寫或填寫不實，請透過<a href="/m/games" class="onlineservice-form__link">「線上回報單」</a>與我們諮詢。<br />※若您一直無法收到驗證碼，請點選下方按鈕申請重置密碼，讓我們協助您。',
  M_HOME__CANT_LOGIN_TITLE: '계정을 로그인할 수 없습니다.',
  M_HOME__NEWS_TITLE: '뉴스 및 공지',
  M_HOME__REPORT__TITLE: '온라인 리포트 보내기',
  M_HOME__SERVICE_RECORD_POPUP_BUTTON: '돌아가기',
  M_HOME__SERVICE_RECORD_POPUP_OPTION_REPORT: '신청 기록',
  M_HOME__SERVICE_RECORD_POPUP_OPTION_SERVICE: '온라인 서비스 신청 기록',
  M_HOME__SERVICE_RECORD_POPUP_TITLE: '신청 기록 선택',
  M_HOME__SERVICE_RECORD_TITLE: '신청 기록',
  M_HOME__VERIFICATION_TITLE: '계정 안전 인증',
  M_NEWS__NO_MORE_NEWS: '더 이상 뉴스 및 공지가 없음.',
  M_REPORT__NO_MORE_RECORD: '더 이상 기록이 없음',
  M_REPORT__REPLY_VIEW_MORE: '더 많은 기록 보기',
  M_VERIFICATION__ITEM_2STEPS_CONTENT:
    "인증할 핸드폰 번호를 입력하고 아래쪽에 있는 '인증 코드 발송'을 클릭합니다. 발송된 인증 코드를 입력해서 인증이 완료됩니다. 시스템은 자동으로 두 단계 인증을 활성화합니다. 보안을 강화하기 위해 인증 상태를 다시 확인하기를 권장합니다. < br/ > ※ 하나의 전화 번호는 3개의 Garena 계정만 등록할 수 있습니다. 인증 전에 전화번호를 확인하세요.",
  M_VERIFICATION__ITEM_ACCOUNT_CONTENT:
    '※ 각 이메일은 Garena 계정 하나만 동록할 수 있습니다. 이메일 인증을 완료하지 않으면 비밀번호 재설정할 수 없습니다! <br/> 아직 메일을 받지 못했습니까? 1.스팸메일함을 확인하기. 2. Yahoo 메일함이 지연될 수 있으니 다른 이메일 주소로 인증할 것을 권장합니다. 3. <a href="/m/games" class="verification__card-link">\'리포트\'를 통해 고객 센터에 연락하세요.',
  M_VERIFICATION__ITEM_RESET_CONTENT:
    '인증된 전화번호 및 메일 주소를 사용할 수 없는 경우, 아래 버튼을 클릭하세요. <br /><span class="verification__card-span1"> 해당 서비스 적용 대상: 실제 회원 정보로 등록된 Garena 계정입니다. </span><br />만약 회원 정보가 누락되거나 잘못 정보로 등록하면<a href="/m/games" class="verification__card-link">\'리포트\'</a>를 통해 고객 센터에 연락하세요.',
  M_VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_BUTTON: '홈페이지로 이동',
  M_VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_SUCCESS_TEXT:
    '신청 상태는 <a href="/m/applications" class="profileresult-tip__link">「온라인 서비스 신청 기록」「/a」에서 확인해 주세요.',
  NEWS__PUBLISH_AT: '공지날짜:',
  NEWS__TITLE: '뉴스 및 공지',
  NOTIFICATION__TITLE: '메시지 알림',
  POPUP__ALERT: '알림',
  POPUP__ALERT_APPLICATION_CREATE_SUCCEED_CONTENT:
    'Garena 계정 및 비밀번호 재설정 신청이 완료되었습니다. 메일 또는 문자 메시지를 기다려 주세요.',
  POPUP__ALERT_ARTICLE_FEEDBACK_SUCCEED_CONTENT: '感謝您的回饋。',
  POPUP__ALERT_PROFILE_REDIRECT_TO_ACCOUNT_CENTER: '회원 정보는 회원 센터에서 관리할 수 있습니다.',
  POPUP__ALERT_PROFILE_UPDATE_SUCCEED: '개인 정보가 성공적으로 업데이트되었습니다.',
  POPUP__ALERT_TICKET_CREATE_SUCCEED_CONTENT: '신청 성공. 신청 기록을 확인하세요.(신청번호: {csNumber})',
  POPUP__ALERT_TICKET_CREATE_SUCCEED_TITLE: '신청 성공 #{csNumber}',
  POPUP__ALERT_TICKET_REPLY_CREATE_SUCCEED_CONTENT: '새로운 답변 하나 추가되었으니, 답장 기록을 확인하세요.(번호{csNumber})',
  POPUP__ALERT_TICKET_REPLY_CREATE_SUCCEED_TITLE: '답장 성공 #{csNumber}',
  POPUP__ALERT_TICKET_REPLY_RATE_SUCCEED_CONTENT: '답장 감사합니다.',
  POPUP__CANT_LOGIN: '',
  POPUP__CONFIRM_LEAVE: '페이지 나가시겠습니까? < br>페이지 나가면 작성된 정보가 삭제됩니다.',
  POPUP__ERROR: '오류',
  POPUP__ERROR_400: '오류',
  POPUP__ERROR_404: '파일을 찾을 수 없습니다. 파일이 삭제되거나 이름이 변경되어 사용할 수 없습니다.',
  POPUP__ERROR_APPLICATION_APPLICATION_IN_PROGRESS:
    '해당 신청은 심사 중이오니 잠시 기다려 주세요. 온라인 서비스 신청 기록에서 진행 상태를 확인하실 수 있습니다.',
  POPUP__ERROR_APPLICATION_APPLIED_TOO_MANY_TIMES:
    '죄송합니다. 신청하신 횟수가 제한 횟수를 넘으셨으니, 고객 센터에 연락해 주시기 바랍니다.',
  POPUP__ERROR_APPLICATION_CREATE_FAILED: '정보 재설정 신청에 오류가 발생했으니 다시 시도해 주세요.',
  POPUP__ERROR_APPLICATION_FB_ACCOUNT_CANT_VERIFICATION: 'Facebook 계정은 계정 인증 서비스에 해당되지 않습니다.',
  POPUP__ERROR_APPLICATION_LOAD_FAILED: '온라인 서비스 신청 기록을 로딩 시 오류가 발생했습니다. 잠시 후 다시 시도해 주시기 바랍니다.',
  POPUP__ERROR_APPLICATION_PROFILE_INCOMPLETE: '계정이 회원 정보를 입력하지 않았으니, 회원 정보에서 신분증 번호 입력해 주세요.',
  POPUP__ERROR_EXCEPTION: '오류가 발생했습니다. 잠시 후 다시 시도해 주시기 바랍니다.',
  POPUP__ERROR_FILE_SIZE_EXCEEDED: '파일 크기가 제한을 초과하었습니다. 다른 파일을 선택해 주세요.',
  POPUP__ERROR_INPUT_TOO_LONG: '리포트의 글자수가 제한을 초과하였으니 확인 후 다시 보내 주세요.',
  POPUP__ERROR_INVALID_FILE_TYPE: '지원되지 않는 파일 형식입니다. 다른 파일을 선택해 주세요.',
  POPUP__ERROR_PROFILE_NO_PLATFORM_USER_INFO_REQUIRED: '해당 계정은 사용자 정보를 입력할 필요가 없습니다.',
  POPUP__ERROR_PROFILE_UPDATE_FAILED: '개인 정보 업데이트가 실패했습니다. 다시 시도해 주세요.',
  POPUP__ERROR_REQUIRE_LOGIN: '로그인 실패. 다시 로그인 하세요.',
  POPUP__ERROR_TICKET_CREATE_FAILED: '리포트 생성 시 오류가 발생하였습니다. 잠시 후 다시 시도해 주세요.',
  POPUP__ERROR_TICKET_LINK_NOT_FOUND: '해당 링크가 무효됩니다.',
  POPUP__ERROR_TICKET_LOAD_FAILED: '리포트 로딩 시 오류가 발생하였습니다. 잠시 후 다시 시도해 주세요.',
  POPUP__ERROR_TICKET_REPLY_CREATE_FAILED: '답장 시 오류가 발생하였습니다. 잠시 후 다시 시도해 주세요.',
  POPUP__ERROR_TICKET_THROTTLING:
    '죄송합니다. 현재 리포트를 작성하실 수 없습니다. 잠시 후 다시 시도해 주세요. 이유는 다음과 같습니다. <br/>1. 서버가 바쁩니다. <br/>2. 몇 분 전에 이미 리포트를 작성 완료하였습니다. <br/>3. 신청할 플레이어 수량이 너무 많습니다.</br>',
  PROFILE__ACCOUNT: '계정',
  PROFILE__ADD_PERSONAL_INFO: '개인 정보 입력',
  PROFILE__BIRTHDATE: '생년월일:',
  PROFILE__DESCRIPTION: '실제 정보를 입력해 주시기 바랍니다. 당신은 해당 계정의 유일한 합법 사용자입니다.',
  PROFILE__EMAIL_ADDRESS: '이메일:',
  PROFILE__EMPTY: '(입력되지 않음)',
  PROFILE__GENDER: '성별:',
  PROFILE__GENDER_FEMALE: '여성',
  PROFILE__GENDER_MALE: '남성',
  PROFILE__ID_CARD_NO: '신분증 번호:',
  PROFILE__INFO: '계정 정보',
  PROFILE__INVALID_BIRTHDATE: '(잘못된 생일 정보)',
  PROFILE__NORMAL: '일반 회원',
  PROFILE__NOTICE_1: '도움말:안전을 위해 일부 정보가 차단되어 있습니다.',
  PROFILE__NOTICE_2: '도움말:회원 정보 입력된 후, 수정이 불가능하므로 반드시 실제 정보를 입력해 주시기 바랍니다.',
  PROFILE__PERSONAL_INFO: '회원 정보',
  PROFILE__PHONE: '연락처:',
  PROFILE__PHONE_BACKUP: '기타 연락처:',
  PROFILE__PHONE_PERMANENT: '연락처',
  PROFILE__PHONE_TIME: '연락 가능한 시간:',
  PROFILE__REAL_NAME: '이름:',
  PROFILE__STATUS: '회원 상태',
  PROFILE__TITLE: '회원 정보',
  PROFILE__TO_ACCOUNT_CENTER: "<a href='https://account.garena.com' target='_blank'>회원 센터</a>로 이동",
  PROFILE__UNKNOWN: 'UNKNOWN',
  PROFILE__WARNING_1: '회원 서비스 규약에 따라, 계정 생성 시 실제 개인 정보를 제공할 의무가 있습니다.',
  PROFILE__WARNING_2: '만약 부실 정보를 입력하거나 업데이트되지 않는 경우, Garena는 해당 계정을 사용 중지할 권리가 있습니다.',
  PROFILE__WARNING_3:
    'Garena는 불시에 회원들의 정보를 확인합니다. 만약 회원 서비스 규약의 제5항 제3조에 부합하는 사실이 있으면 Garena는 해당 계정을 사용 중지할 권리가 있습니다.',
  REPORT__COLUMN_BASE_CATEGORY: '유형',
  REPORT__COLUMN_CREATED_DATE: '제출날짜',
  REPORT__COLUMN_CS_NUMBER: '서비스 번호',
  REPORT__COLUMN_GAME: '게임',
  REPORT__COLUMN_STATUS: '상태',
  REPORT__COLUMN_TOPIC: '항목',
  REPORT__COLUMN_TOPIC_CATEGORY: '유형',
  REPORT__COLUMN_VIEW: '답장 보기',
  REPORT__CONTINUE_REPLY_CHANGE_EMAIL: '이메일',
  REPORT__CONTINUE_REPLY_PLACEHOLDER: '고객 센터는 근무 시간에 신청 순서에 따라 처리합니다. 잠시 기다려 주세요. 감사합니다.',
  REPORT__CONTINUE_REPLY_SEND_EMAIL: '해당 리포트의 알림 켜기',
  REPORT__CONTINUE_REPLY_TITLE: '계속 문의하기',
  REPORT__CONTINUE_REPLY_UPLOAD_FILE: '파일 업로드',
  REPORT__DETAIL_BASE_CATEGORY: '유형:',
  REPORT__DETAIL_CS_NUMBER: '서비스 번호:',
  REPORT__DETAIL_GAME: '게임:',
  REPORT__DETAIL_NOT_FOUND: '해당 리포트 기록을 찾을 수 없습니다.',
  REPORT__DETAIL_NOT_FOUND_DESC:
    '可能的原因如下：<ol><li>您當前登入的並非填寫指定回報單的帳號，請至客服中心首頁登出帳號後重新登入對應帳號，並點選【我的回報紀錄】查看。</li><li>若您先前是透過遊戲中選擇【客服中心】填寫回報單，目前僅能透過同一管道查看回覆，還請從遊戲中開啟客服中心確認回報紀錄。</li></ol>',
  REPORT__DETAIL_TITLE: '리포트 기록 상세 정보',
  REPORT__DETAIL_TOPIC: '항목:',
  REPORT__DETAIL_TOPIC_CATEGORY: '유형:',
  REPORT__FEEDBACK_BAD: '불만족',
  REPORT__FEEDBACK_DESC: '이번 서비스의 만족도를 평가해 주세요',
  REPORT__FEEDBACK_GOOD: '만족',
  REPORT__FEEDBACK_PLACEHOLDER: '더 좋은 서비스를 제공할 수 있도록 의견을 남겨 주시기 바랍니다.',
  REPORT__FEEDBACK_RECORD_DESC: '이번 서비스의 만족도를 평가해 주세요:',
  REPORT__FEEDBACK_RECORD_TITLE: '서비스 평가를 제출했습니다.',
  REPORT__FEEDBACK_TITLE: '서비스 평가',
  REPORT__LINK_CONFIRM_MSG: '플레이어님의 리포트과 계정을 연동하시겠습니까?',
  REPORT__LINK_CONFIRM_NO_BUTTON: '홈페이지로 이동',
  REPORT__LINK_CONFIRM_YES_BUTTON: '연동 확인',
  REPORT__LINK_CONTENT: '현재 로그인 계정:',
  REPORT__LINK_TITLE: '리포트 연동',
  REPORT__NO_RECORD: '신청 기록이 없습니다.',
  REPORT__NO_RECORD_DESC: '여기 클릭해서 고객 센터로 이동 <a href="/" class="reportlist-empty__link"> 「홈페이지로 이동」 </a>',
  REPORT__REPLY_ATTACHMENT: '첨부 파일',
  REPORT__REPLY_ATTACHMENT_NOTICE: '정보 안전을 위해 업로드한 첨부 파일이 숨겨져 있습니다.',
  REPORT__REPLY_ATTACHMENT_NUM: '업로드된 파일 수:',
  REPORT__REPLY_DOWNLOAD: '확인',
  REPORT__REPLY_RATE_NO: '아니오, 도움이 안 됩니다.',
  REPORT__REPLY_RATE_TITLE: '해당 방식은 플레이어님에게 도움이 되셨습니까?',
  REPORT__REPLY_RATE_YES: '네, 문제는 해결됐습니다.',
  REPORT__REPLY_TITLE_STAFF: '답장',
  REPORT__REPLY_TITLE_USER: '상황 설명',
  REPORT__STATUS_DUPLICATED: '중복 작성',
  REPORT__STATUS_FILTER_ALL: '모두 신청',
  REPORT__STATUS_FILTER_CLOSED: '처리 완료',
  REPORT__STATUS_FILTER_PENDING: '처리 대기 중',
  REPORT__STATUS_FILTER_PROCESSING: '처리 중',
  REPORT__STATUS_PENDING: '처리되지 않음',
  REPORT__STATUS_PENDING_USER_REPLY: '플레이어님의 답변을 기다리고 있습니다.',
  REPORT__STATUS_PROCESSING: '처리 중',
  REPORT__STATUS_REPLIED: '답변됨',
  REPORT__STATUS_SOLVED: '해결됨',
  REPORT__STATUS_STAFF_REPLIED: '답변했습니다.',
  REPORT__STATUS_SUBMITTED: '제출 중',
  REPORT__STATUS_SYSTEM_CLOSED: '시스템 해결 완료',
  REPORT__STATUS_USER_REPLIED: '플레이어님이 답장했습니다.',
  REPORT__TITLE: '리포트 기록',
  REPORT__VIEW_READ: '보기',
  REPORT__VIEW_UNREAD: '읽지 않은 메시지',
  SEARCH__BAR_ALL: '모든 서비스',
  SEARCH__BAR_PLACEHOLDER: '검색어를 입력해서 검색하기',
  SEARCH__BAR_TITLE_MOBILE: '모바일 게임',
  SEARCH__BAR_TITLE_OTHERS: '기타',
  SEARCH__BAR_TITLE_PC: '온라인 게임',
  SEARCH__BREADCRUMB: '검색 결과',
  SEARCH__NO_RESULT: '找不到相關常見問題',
  SEARCH__READ_MORE: '더 많이 보기',
  SEARCH__RESULT_TITLE: '현재 검색어:',
  TERMS__PP: '프라이버시 정책',
  TERMS__PP_LINK: 'https://contentgarena-a.akamaihd.net/legal/pp/pp_en.html',
  TERMS__TOS: '서비스 정책',
  TERMS__TOS_LINK: 'https://contentgarena-a.akamaihd.net/legal/tos/tos_en.html',
  TICKET__EMAIL: '이메일',
  TICKET__EMAIL_FIELD_CHECKBOX: '고객 센터가 답변 시 이메일로 알려 주세요.',
  TICKET__EMAIL_FIELD_PLACEHOLDER: '유효한 이메일 주소를 입력해 주세요.',
  TICKET__FIELD_BASE_CATEGORY: '유형',
  TICKET__FIELD_GAME: '게임',
  TICKET__FIELD_TICKET_TOPIC: '항목',
  TICKET__FIELD_TICKET_TOPIC_CATEGORY: '유형',
  TICKET__NOTICE: '주의사항',
  TICKET__NOTICE_1:
    '리포트 작성 후, 고객 센터는 제출 순서에 따라 처리합니다. 리포트가 처리될 때까지 기다려 주시기 바랍니다. 처리 상태 및 답변 내용은 <a href="/reports" class="report-warning__link" target="_blank">「리포트 기록」</a >에서 확인해 주시기 바랍니다',
  TICKET__NOTICE_2:
    '빠르게 플레이어님을 도와 드릴 수 있도록 리포트를 자세히 작성하시고 중복 제출하지 마시기 바랍니다. 리포트 내용에 따라 처리 시간이 달라지므로 잠시 기다려 주세요.',
  TICKET__NOTICE_3:
    '고객 센터가 답변한지 한 달이 지나도 플레이어님의 답장을 받지 못한 경우, 시스템은 자동으로 리포트를 종결 처리합니다. 종결 처리된 리포트는 다시 답변할 수 없습니다. 문의가 있으시면 다시 작성해 주시기 바랍니다. 양해해 주셔서 감사합니다!',
  TICKET__NOTICE_4:
    '본 리포트 시스템을 사용 시, 작성 내용에 공격, 음란 또는 본사 및 본사 사원에게 희롱, 비방, 비난 등 언론 공격이 있는 경우, 다음과 같이 처리하겠습니다. 1번 위반 시: 경고, 2번 위반 시: 회원 계정 3일간 사용정지, 3번 위반 시: 회원계정 1주 사용정지, 4번 위반 시: 회원계정 영구 사용정지',
  TICKET__QUESTION_1: '문의 유형은 뭐예요?',
  TICKET__QUESTION_2: '자세한 문의 항목이 뭐예요?',
  TICKET__REMINDER: '도움말: 정확한 유형을 선택하시면 담당 직원이 더 빨리 문의를 해결해 드릴 수 있습니다.',
  TICKET__SUBMIT_NOTICE: '입력하지 않거나 양식이 틀린 부분이 있습니다.',
  TICKET__TITLE: '리포트 작성',
  TICKET__TOS_CHECKBOX:
    '나는 <a href="https://terms.support.garena.com/tw" target="_blank" class="report-form__link">규약 & 정책 </a> 및 리포트 시스템 주의사항을 자세히 읽고 준수하겠습니다.',
  TICKET__TOS_NOTICE: "'규약 & 정책'을 자세히 읽고 체크하세요.",
  VERIFICATION__ITEM_2STEPS_CONTENT:
    "인증할 핸드폰 번호를 입력하고 아래쪽에 있는 '인증 코드 발송'을 클릭합니다. 발송된 인증 코드를 입력해서 인증이 완료됩니다. 시스템은 자동으로 두 단계 인증을 활성화합니다. 보안을 강화하기 위해 인증 상태를 다시 확인하기를 권장합니다. < br/ > ※ 하나의 전화 번호는 3개의 Garena 계정만 등록할 수 있습니다. 인증 전에 전화번호를 확인하세요.",
  VERIFICATION__ITEM_2STEPS_LINKTEXT: '핸드폰 인증',
  VERIFICATION__ITEM_2STEPS_TITLE: '2스텝으로 핸드폰을 인증',
  VERIFICATION__ITEM_ACCOUNT_CONTENT:
    '※ 각 이메일은 Garena 계정 하나만 동록할 수 있습니다. 이메일 인증을 완료하지 않으면 비밀번호 재설정할 수 없습니다! <br/> 아직 메일을 받지 못했습니까? 1.스팸메일함을 확인하기. 2. Yahoo 메일함이 지연될 수 있으니 다른 이메일 주소로 인증할 것을 권장합니다. 3. <a href="/m/games" class="verification__card-link">\'리포트\'</a>를 통해 고객 센터에 연락하세요.',
  VERIFICATION__ITEM_ACCOUNT_LINKTEXT: '이메일 인증',
  VERIFICATION__ITEM_ACCOUNT_TITLE: '이메일 인증',
  VERIFICATION__ITEM_RESET_CONTENT:
    '인증된 전화번호 및 메일 주소를 사용할 수 없는 경우, 아래 버튼을 클릭하세요. <br /><span class="verification__card-span1"> 해당 서비스 적용 대상: 실제 회원 정보로 등록된 Garena 계정입니다. </span><br />만약 회원 정보가 누락되거나 잘못 정보로 등록하면<a href="/m/games" class="verification__card-link">\'리포트\'</a>를 통해 고객 센터에 연락하세요.',
  VERIFICATION__ITEM_RESET_TITLE: '인증 취소',
  VERIFICATION__RESET_PROFILE_APPLICATION: '서비스 신청',
  VERIFICATION__RESET_PROFILE_APPLICATION_CANCEL_EMAIL: '이메일 인증 취소',
  VERIFICATION__RESET_PROFILE_APPLICATION_CANCEL_PHONE: '핸드폰 인증 취소',
  VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_FAILURE_TEXT:
    '서버가 바쁘거나 서비스를 신청한 플레이어님이 너무 많으니 잠시 후 다시 시도하세요.',
  VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_FAILURE_TITLE:
    '죄송합니다. 현재 신청을 제출할 수 없습니다. 잠시 후 다시 시도해 주세요.',
  VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_NOTICE: '도움말',
  VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_NOTICE_1:
    '상태:심사성공 - 처리 중은 증명 서류의 심사가 완료되어 처리 중이라는 뜻입니다.',
  VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_NOTICE_2:
    '[인증취소] 및 [비밀번호 재설정] 서비스는 약 1-3일이 필요합니다. 잠시 기다려 주세요.',
  VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_SUCCESS_TEXT:
    '신청 상태는 <a href="/m/applications" class="profileresult-tip__link">「온라인 서비스 신청 기록」</a>에서 확인해 주세요.',
  VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_SUCCESS_TITLE: '신청을 성공적으로 제출하였습니다!',
  VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY: '새 비밀번호 접수방식을 선택하세요:',
  VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY_EMAIL: '이메일',
  VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY_SMS: '문자 메시지',
  VERIFICATION__RESET_PROFILE_APPLICATION_RESET_PASSWORD: '비밀번호 재설정',
  VERIFICATION__RESET_PROFILE_APPLICATION_SERVICE: '신청하실 서비스를 선택하세요:',
  VERIFICATION__RESET_PROFILE_APPLICATION_UPLOAD: '파일 업로드:',
  VERIFICATION__RESET_PROFILE_PERSONAL_DATA: '회원 정보',
  VERIFICATION__RESET_PROFILE_PERSONAL_DATA_DESC:
    '안전을 위해 일부 정보가 차단되어 있습니다. 회원 정보를 수정할 필요가 있는 경우, <a class="profile-data__link" href="">여기</a>를 통해 도움을 받으세요.',
  VERIFICATION__RESET_PROFILE_PERSONAL_DATA_ID: '신분증 번호',
  VERIFICATION__RESET_PROFILE_PERSONAL_DATA_NAME: '이름',
  VERIFICATION__RESET_PROFILE_TITLE: '정보 재설정 신청',
};
