<template>
  <div class="main-hd">
    <h1 class="main-hd__title" :class="{ 'main-hd__title-common': !isGamePage }">
      {{ title }}
    </h1>
    <template v-if="hasSearchBar">
      <form class="main-hd__search" @submit.prevent>
        <div class="main-hd__search-icon" :class="{ 'main-hd__search-icon--active': searchString }" @click="submit()"></div>
        <div class="main-hd__search-input">
          <input
            type="text"
            class="main-hd__search-widget"
            :placeholder="$t('SEARCH__BAR_PLACEHOLDER')"
            v-model.trim="searchString"
            @keyup.enter="submit()"
          />
        </div>
        <!-- 開關搜尋常見問題分類過濾器 -->
        <div @click="switchSearchMenu" class="main-hd__search-filter">
          <span id="selected-search-cate">{{ currentSearchCategory }}</span>
          <div class="main-hd__search-arrow"></div>
        </div>
        <!-- 開關搜尋常見問題分類過濾器 /-->
        <div class="main-hd__category" :style="{ display: isSearchMenuVisible ? 'block' : 'none' }">
          <div class="main-hd__category-arrow"></div>
          <template v-if="games">
            <div class="main-hd__category-main">
              <div v-show="games.pc" class="main-hd__category-list">
                <div class="main-hd__category-title">{{ $t('SEARCH__BAR_TITLE_PC') }}</div>
                <div class="search_bar-sublist" data-type="game">
                  <a
                    v-for="game in games.pc"
                    :key="game.id"
                    @click.prevent="changeSelectedCategory(constants.SEARCH__SEARCH_TYPES.PC, game.code)"
                    :class="{ 'main-hd__category-item--active': game.code === currentSearchCode }"
                    class="main-hd__category-item"
                    href="#"
                  >
                    <div class="main-hd__category-name">
                      {{ game.name }}
                    </div>
                  </a>
                </div>
              </div>
              <div v-show="games.mobile" class="main-hd__category-list">
                <div class="main-hd__category-title">
                  {{ $t('SEARCH__BAR_TITLE_MOBILE') }}
                </div>
                <div class="search_bar-sublist">
                  <a
                    v-for="game in games.mobile"
                    :key="game.id"
                    @click.prevent="changeSelectedCategory(constants.SEARCH__SEARCH_TYPES.MOBILE, game.code)"
                    :class="{ 'main-hd__category-item--active': game.code === currentSearchCode }"
                    class="main-hd__category-item"
                    href="#"
                  >
                    <div class="main-hd__category-name">
                      {{ game.name }}
                    </div>
                  </a>
                </div>
              </div>
              <div class="main-hd__category-list">
                <div class="main-hd__category-title">
                  {{ $t('SEARCH__BAR_TITLE_OTHERS') }}
                </div>
                <div class="search_bar-sublist">
                  <a
                    @click.prevent="changeSelectedCategory('', '')"
                    :class="{ 'main-hd__category-item--active': currentSearchCode === '' }"
                    class="main-hd__category-item"
                    href="#"
                  >
                    <div class="main-hd__category-name">
                      {{ $t('SEARCH__BAR_ALL') }}
                    </div>
                  </a>
                  <a
                    v-for="category in baseCategories"
                    :key="category.id"
                    @click.prevent="changeSelectedCategory(constants.SEARCH__SEARCH_TYPES.OTHERS, category.code)"
                    :class="{ 'main-hd__category-item--active': category.code === currentSearchCode }"
                    class="main-hd__category-item"
                    href="#"
                  >
                    <div class="main-hd__category-name">
                      {{ category.name }}
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </template>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { ACTION_GET_GAMES, ACTION_GET_BASE_CATEGORIES } from '@/store/action-types';
import constants from '@/lib/constants';

export default {
  name: 'navigation',
  props: {
    title: String,
    hasSearchBar: Boolean,
    searchType: String,
    searchCode: String,
    isGamePage: Boolean,
  },
  data() {
    return {
      constants,
      isSearchMenuVisible: false,
      searchString: '',
      currentSearchType: '',
      currentSearchCode: '',
    };
  },
  computed: {
    currentSearchCategory() {
      const ret = this.$t('SEARCH__BAR_ALL');
      return this.gamesCodeNameMapping[this.currentSearchCode] || this.baseCategoriesCodeNameMapping[this.currentSearchCode] || ret;
    },
    ...mapState({
      games: (state) => state.game.games,
      baseCategories: (state) => state.category.baseCategories,
    }),
    ...mapGetters(['gamesCodeNameMapping', 'baseCategoriesCodeNameMapping']),
  },
  methods: {
    switchSearchMenu() {
      this.isSearchMenuVisible = !this.isSearchMenuVisible;
    },
    changeSelectedCategory(type, code) {
      this.currentSearchType = type;
      this.currentSearchCode = code;
      this.switchSearchMenu();
      if (this.searchString) {
        this.submit(true);
      }
    },
    initSearchCategory() {
      this.currentSearchType = this.searchType || '';
      this.currentSearchCode = this.searchCode || '';
    },
    submit(force = false) {
      if (this.searchString) {
        if (!force && this.$route.query.q === this.searchString) {
          return;
        }
        const queryString = { q: this.searchString };
        if (this.currentSearchType) {
          queryString.type = this.currentSearchType;
        }
        if (this.currentSearchCode) {
          queryString.code = this.currentSearchCode;
        }
        this.$router.push({
          name: 'Search',
          query: queryString,
        });
      }
    },
    ...mapActions({
      getGames: ACTION_GET_GAMES,
      getBaseCategories: ACTION_GET_BASE_CATEGORIES,
    }),
  },
  mounted() {
    this.initSearchCategory();
    this.searchString = this.$route.query.q;
  },
};
</script>
