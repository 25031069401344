<template>
  <div class="home-user">
    <div class="home-user__info">
      <div class="home-user__avatar-wrap">
        <div class="home-user__avatar">
          <img v-if="hasLoggedIn && userInfo.avatar" :src="userInfo.avatar" alt="" class="home-user__avatar-img" />
          <img v-else :src="`${CDN}/assets/share/img/icon-notloggedin@2x.jpg`" alt="" class="home-user__avatar-img" />
        </div>
        <template v-if="hasLoggedIn">
          <div class="home-user__channel">
            <img :src="`${CDN}/assets/share/img/icon-circle-platform-${userInfo.platform}.png`" alt="" class="home-user__channel-img" />
          </div>
        </template>
      </div>

      <template v-if="hasLoggedIn">
        <div class="home-user__data">
          <div class="home-user__account">
            <div class="home-user__name">
              {{ userInfo.name }}
            </div>
            <a @click="logout()" class="home-user__logflow">
              {{ $t('GENERAL__LOGOUT') }}
            </a>
          </div>
          <div class="home-user__id">UID: {{ userInfo.uid }}</div>
        </div>
      </template>
      <template v-else>
        <div class="home-user__data">
          <div class="home-user__account">
            <div class="home-user__name">
              {{ $t('GENERAL__NOT_LOGGED_IN') }}
            </div>
            <a @click="switchLoginPopup(true)" class="home-user__logflow">
              {{ $t('GENERAL__LOGIN') }}
            </a>
          </div>
        </div>
      </template>
    </div>
    <ul class="home-user__entry">
      <template v-if="isTwVersion">
        <li class="home-user__entry-item">
          <router-link :to="{ name: 'Profile' }" class="home-user__entry-link">
            <div class="home-user__entry-icon home-user__entry-icon--user"></div>
            <div class="home-user__entry-name">
              {{ $t('HOME__USER_ACCOUNT_INFO') }}
            </div>
          </router-link>
        </li>
        <template v-if="isGarenaUser">
          <li class="home-user__entry-item">
            <a :href="constants.GENERAL__ACCOUNT_CENTER_URL" target="_blank" class="home-user__entry-link">
              <div class="home-user__entry-icon home-user__entry-icon--account2"></div>
              <div class="home-user__entry-name">
                {{ $t('HOME__USER_ACCOUNT_CENTER') }}
              </div>
            </a>
          </li>
        </template>
      </template>
      <li class="home-user__entry-item">
        <router-link :to="{ name: 'ReportList' }" class="home-user__entry-link">
          <div class="home-user__entry-icon home-user__entry-icon--report2"></div>
          <div class="home-user__entry-name">
            {{ $t('HOME__USER_TICKET_RECORD') }}
          </div>
          <div class="home-user__entry-unread">
            <template v-if="hasUnreadTicket">
              <div class="home-user__entry-unreadtxt">{{ $t('HOME__USER_UNREAD') }}</div>
              <div class="home-user__entry-unreadcount">{{ unreadStats.ticket }}</div>
            </template>
          </div>
        </router-link>
      </li>
      <template v-if="isGarenaUser && isTwVersion">
        <li class="home-user__entry-item">
          <router-link :to="{ name: 'ApplicationList' }" class="home-user__entry-link">
            <div class="home-user__entry-icon home-user__entry-icon--record"></div>
            <div class="home-user__entry-name">
              {{ $t('HOME__USER_APPLICATION_RECORD') }}
            </div>
            <div class="home-user__entry-unread">
              <template v-if="hasUnreadApplication">
                <div class="home-user__entry-unreadtxt">{{ $t('HOME__USER_UNREAD') }}</div>
                <div class="home-user__entry-unreadcount">{{ unreadStats.application }}</div>
              </template>
            </div>
          </router-link>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { ACTION_LOGOUT, ACTION_GET_USER_INFO, ACTION_GET_UNREAD_STATS, ACTION_SWITCH_LOGIN_POPUP } from '@/store/action-types';
import constants from '@/lib/constants';
import utils from '@/lib/utils';

export default {
  name: 'User',
  data() {
    return {
      constants,
      loginUrl: utils.buildLoginUrl(),
    };
  },
  computed: {
    hasUnreadTicket() {
      return this.unreadStats && this.unreadStats.ticket;
    },
    hasUnreadApplication() {
      return this.unreadStats && this.unreadStats.application;
    },
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      unreadStats: (state) => state.user.unreadStats,
    }),
    ...mapGetters(['hasLoggedIn', 'isGarenaUser']),
  },
  methods: {
    ...mapActions({
      logout: ACTION_LOGOUT,
      switchLoginPopup: ACTION_SWITCH_LOGIN_POPUP,
      getUserInfo: ACTION_GET_USER_INFO,
      getUnreadStats: ACTION_GET_UNREAD_STATS,
    }),
  },
  mounted() {
    this.getUnreadStats();
  },
};
</script>

<style scoped>
.home-user__logflow {
  cursor: pointer;
}
</style>
