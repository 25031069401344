var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom_input-wrap" }, [
    _c(
      "div",
      { staticClass: "input-wrap" },
      [
        _c(
          "date-picker",
          {
            class: { "input--error": !_vm.isValid },
            attrs: {
              "value-type": _vm.valueType,
              type: _vm.type,
              format: _vm.format,
              "time-picker-options": _vm.timePickerOptions,
              lang: _vm.lang,
              placeholder: _vm.placeholder
                ? _vm.placeholder
                : _vm.$t("GENERAL__PLACEHOLDER_DEFAULT_SELECT_DATE"),
              "disabled-date": _vm.notAfterToday
            },
            model: {
              value: _vm.val,
              callback: function($$v) {
                _vm.val = $$v
              },
              expression: "val"
            }
          },
          [
            _c("div", {
              staticClass: "profile-form__inputdateicon",
              attrs: { slot: "calendar-icon" },
              slot: "calendar-icon"
            })
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    !_vm.isValid
      ? _c("div", { staticClass: "report-form__error" }, [
          _vm._v(_vm._s(_vm.errorMsg))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }