<template>
  <div id="topbar">
    <header class="topbar-header">
      <router-link :to="{ name: 'Home' }" class="topbar-logo"></router-link>
      <div class="topbar-panel">
        <nav class="topbar-nav"></nav>
        <div class="topbar-login">
          <template v-if="hasLoggedIn">
            <div class="topbar-login-info">
              <div class="topbar-login-info-account">
                <div class="topbar-login-info-platform-icon" :style="{ backgroundImage: platformIconImage }"></div>
                <div class="topbar-login-info-account-name">{{ userInfo.name }}</div>
              </div>
              <a @click="toLogout()" class="topbar-logout-btn">{{ $t('GENERAL__LOGOUT') }}</a>
            </div>
          </template>
          <template v-else>
            <a @click="switchLoginPopup(true)" class="topbar-login-btn">{{ $t('GENERAL__LOGIN') }}</a>
          </template>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { ACTION_LOGOUT, ACTION_SWITCH_LOGIN_POPUP } from '@/store/action-types';

export default {
  // @TODO: new topbar
  name: 'Topbar',
  computed: {
    platformIconImage() {
      return `url(${process.env.CDN}/assets/share/img/icon-circle-platform-${this.userInfo.platform}.png`;
    },
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    ...mapGetters(['hasLoggedIn']),
  },
  methods: {
    toLogout() {
      this.logout().then(() => {
        if (this.$route.name !== 'Home') {
          this.$router.push(
            { name: 'Home' },
            () => {},
            () => {},
          );
        }
      });
    },
    ...mapActions({
      logout: ACTION_LOGOUT,
      switchLoginPopup: ACTION_SWITCH_LOGIN_POPUP,
    }),
  },
};
</script>

<style lang="scss">
#topbar {
  background: #fff;
  font-size: 14px;
  line-height: 1.4;
  position: relative;
  z-index: 10;
  color: #202325;
  margin: 0;
  padding: 0;
  border: none;
  text-decoration: none;
}
#topbar .topbar-header {
  width: 1000px;
  align-items: center;
  position: relative;
  margin: auto;
  padding: 14px 0;
  display: flex;
}
#topbar .topbar-logo {
  flex: 0 0 auto;
  background-image: assetsUrl('assets/share/img/garena-logo.png');
  background-size: 100% 100%;
  width: 117px;
  height: 31px;
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  margin: 0 35px 0 0;
}
#topbar .topbar-panel {
  justify-content: flex-end;
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
}
#topbar .topbar-nav {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}
#topbar .topbar-login {
  overflow: hidden;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
}
#topbar .topbar-login-info {
  padding: 0 0 0 20px;
  margin: 0 0 0 20px;
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
}
#topbar .topbar-login-info-account {
  display: flex;
  flex: 0 1 auto;
  margin: 0 15px 0 0;
  align-items: center;
  min-width: 0;
}
#topbar .topbar-login-info-platform-icon {
  margin: 0 8px 0 0;
  flex: 0 0 auto;
  background-size: contain;
  width: 20px;
  height: 20px;
  border: 0.5px solid $general-input-border-color;
  border-radius: 50%;
}
#topbar .topbar-login-info-account-name {
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#topbar .topbar-login-btn {
  cursor: pointer;
  color: #d43831;
  transition: color 0.3s;
  padding: 0 0 0 20px;
  margin: 0 0 0 20px;
  position: relative;
  flex: 1 1 auto;
}
#topbar .topbar-logout-btn {
  align-items: center;
  display: flex;
  cursor: pointer;
  flex: 0 0 auto;
  color: rgba(32, 35, 37, 0.5);
}
</style>
