var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom_input-wrap" }, [
    _c(
      "div",
      { staticClass: "input-wrap" },
      [
        _c("label", [
          _c("input", {
            ref: "input",
            staticClass: "input",
            class: { "input--error": !_vm.isValid },
            attrs: {
              name: _vm.name,
              type: _vm.type,
              placeholder: _vm.placeholder,
              disabled: _vm.isDisabled,
              required: _vm.isRequired,
              maxlength: _vm.maxLength
            },
            domProps: { value: _vm.val },
            on: { input: _vm.onInputChange }
          })
        ]),
        _vm._v(" "),
        _vm.children
          ? [_c("div", { domProps: { innerHTML: _vm._s(_vm.children) } })]
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "report-form__note" }, [
      _c("div", { staticClass: "report-form__error" }, [
        _vm._v(_vm._s(_vm.noteMsg))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "report-form__note" }, [
      _c(
        "div",
        {
          staticClass: "report-form__error",
          class: { invisible: _vm.isValid }
        },
        [_vm._v(_vm._s(_vm.errorMsg))]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLimitLength,
              expression: "isLimitLength"
            }
          ],
          staticClass: "report-form__length"
        },
        [_vm._v(_vm._s(_vm.curLength) + " / " + _vm._s(_vm.maxLength))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }