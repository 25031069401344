var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-user" }, [
    _c(
      "div",
      { staticClass: "home-user__info" },
      [
        _c(
          "div",
          { staticClass: "home-user__avatar-wrap" },
          [
            _c("div", { staticClass: "home-user__avatar" }, [
              _vm.hasLoggedIn && _vm.userInfo.avatar
                ? _c("img", {
                    staticClass: "home-user__avatar-img",
                    attrs: { src: _vm.userInfo.avatar, alt: "" }
                  })
                : _c("img", {
                    staticClass: "home-user__avatar-img",
                    attrs: {
                      src:
                        _vm.CDN + "/assets/share/img/icon-notloggedin@2x.jpg",
                      alt: ""
                    }
                  })
            ]),
            _vm._v(" "),
            _vm.hasLoggedIn
              ? [
                  _c("div", { staticClass: "home-user__channel" }, [
                    _c("img", {
                      staticClass: "home-user__channel-img",
                      attrs: {
                        src:
                          _vm.CDN +
                          "/assets/share/img/icon-circle-platform-" +
                          _vm.userInfo.platform +
                          ".png",
                        alt: ""
                      }
                    })
                  ])
                ]
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _vm.hasLoggedIn
          ? [
              _c("div", { staticClass: "home-user__data" }, [
                _c("div", { staticClass: "home-user__account" }, [
                  _c("div", { staticClass: "home-user__name" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.userInfo.name) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "home-user__logflow",
                      on: {
                        click: function($event) {
                          return _vm.logout()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("GENERAL__LOGOUT")) +
                          "\n          "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "home-user__id" }, [
                  _vm._v("UID: " + _vm._s(_vm.userInfo.uid))
                ])
              ])
            ]
          : [
              _c("div", { staticClass: "home-user__data" }, [
                _c("div", { staticClass: "home-user__account" }, [
                  _c("div", { staticClass: "home-user__name" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("GENERAL__NOT_LOGGED_IN")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "home-user__logflow",
                      on: {
                        click: function($event) {
                          return _vm.switchLoginPopup(true)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("GENERAL__LOGIN")) +
                          "\n          "
                      )
                    ]
                  )
                ])
              ])
            ]
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "home-user__entry" },
      [
        _vm.isTwVersion
          ? [
              _c(
                "li",
                { staticClass: "home-user__entry-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "home-user__entry-link",
                      attrs: { to: { name: "Profile" } }
                    },
                    [
                      _c("div", {
                        staticClass:
                          "home-user__entry-icon home-user__entry-icon--user"
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "home-user__entry-name" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("HOME__USER_ACCOUNT_INFO")) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.isGarenaUser
                ? [
                    _c("li", { staticClass: "home-user__entry-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "home-user__entry-link",
                          attrs: {
                            href: _vm.constants.GENERAL__ACCOUNT_CENTER_URL,
                            target: "_blank"
                          }
                        },
                        [
                          _c("div", {
                            staticClass:
                              "home-user__entry-icon home-user__entry-icon--account2"
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "home-user__entry-name" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("HOME__USER_ACCOUNT_CENTER")) +
                                "\n            "
                            )
                          ])
                        ]
                      )
                    ])
                  ]
                : _vm._e()
            ]
          : _vm._e(),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "home-user__entry-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "home-user__entry-link",
                attrs: { to: { name: "ReportList" } }
              },
              [
                _c("div", {
                  staticClass:
                    "home-user__entry-icon home-user__entry-icon--report2"
                }),
                _vm._v(" "),
                _c("div", { staticClass: "home-user__entry-name" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("HOME__USER_TICKET_RECORD")) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "home-user__entry-unread" },
                  [
                    _vm.hasUnreadTicket
                      ? [
                          _c(
                            "div",
                            { staticClass: "home-user__entry-unreadtxt" },
                            [_vm._v(_vm._s(_vm.$t("HOME__USER_UNREAD")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "home-user__entry-unreadcount" },
                            [_vm._v(_vm._s(_vm.unreadStats.ticket))]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.isGarenaUser && _vm.isTwVersion
          ? [
              _c(
                "li",
                { staticClass: "home-user__entry-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "home-user__entry-link",
                      attrs: { to: { name: "ApplicationList" } }
                    },
                    [
                      _c("div", {
                        staticClass:
                          "home-user__entry-icon home-user__entry-icon--record"
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "home-user__entry-name" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("HOME__USER_APPLICATION_RECORD")) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "home-user__entry-unread" },
                        [
                          _vm.hasUnreadApplication
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "home-user__entry-unreadtxt" },
                                  [_vm._v(_vm._s(_vm.$t("HOME__USER_UNREAD")))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "home-user__entry-unreadcount"
                                  },
                                  [_vm._v(_vm._s(_vm.unreadStats.application))]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  )
                ],
                1
              )
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }