/* eslint-disable no-shadow */

import {
  MUTATION_SET_ACCOUNT_RECOVERY_PLAYER_INFO_FIELDS,
  MUTATION_SET_ERROR,
} from '@/store/mutation-types';

import {
  ACTION_GET_ACCOUNT_RECOVERY_PLAYER_INFO_FIELDS,
} from '@/store/action-types';

import application from '@/api/prod/application';

// initial state
const state = {
  accountRecoveryPlayerInfoFields: {},
};

const actions = {
  async [ACTION_GET_ACCOUNT_RECOVERY_PLAYER_INFO_FIELDS]({ commit }) {
    try {
      const { data } = await application.getPlayInfo();
      commit(MUTATION_SET_ACCOUNT_RECOVERY_PLAYER_INFO_FIELDS, data);
    } catch (err) {
      commit(MUTATION_SET_ERROR, { msg: err });
    }
  },
};

const mutations = {
  [MUTATION_SET_ACCOUNT_RECOVERY_PLAYER_INFO_FIELDS](state, data) {
    state.accountRecoveryPlayerInfoFields = data;
  },
};

export default {
  state,
  actions,
  mutations,
};
