var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: { title: _vm.$t("NEWS__TITLE"), "has-search-bar": false }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "main-inner main-inner--nobg" }, [
          _c(
            "div",
            { staticClass: "faqdetail" },
            [
              _c("breadcrumb", {
                attrs: { paths: _vm.paths, "has-border": true }
              }),
              _vm._v(" "),
              _vm.news
                ? [
                    _c("div", { staticClass: "faqdetail-main" }, [
                      _c("div", { staticClass: "faqdetail__title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.news.title) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "faqdetail__content" }, [
                        _c("div", {
                          staticClass: "ip-solution__txt",
                          domProps: { innerHTML: _vm._s(_vm.news.content) }
                        })
                      ])
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }