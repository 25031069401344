var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isEnded
    ? _c("div", [
        _vm._v("\n  " + _vm._s(_vm.$t("AI_FAQ__COUNTDOWN_1")) + "\n  "),
        _vm.days > 0
          ? _c("span", [_vm._v(_vm._s(_vm.days) + " 天")])
          : _vm._e(),
        _vm._v(" "),
        _vm.hours > 0
          ? _c("span", [_vm._v(_vm._s(_vm.hours) + " 小時")])
          : _vm._e(),
        _vm._v(" "),
        _vm.minutes > 0
          ? _c("span", [_vm._v(_vm._s(_vm.minutes) + " 分")])
          : _vm._e(),
        _vm._v(" "),
        _vm.seconds > 0
          ? _c("span", [_vm._v(_vm._s(_vm.seconds) + " 秒")])
          : _vm._e(),
        _vm._v("\n  " + _vm._s(_vm.$t("AI_FAQ__COUNTDOWN_2")) + "\n")
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }