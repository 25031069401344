/* eslint-disable */

import api from '@/api';

function getNews(offset = null) {
  const params = {
    offset: offset,
  };
  return api.call(api.GET, '/news', params);
}

function getNewsById(newsId) {
  return api.call(api.GET, `/news/${newsId}`);
}

// function getNews() {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: JSON.parse(JSON.stringify({
//         data: [
//           {
//             id: 1,
//             title: '這是第一篇新聞',
//           },
//           {
//             id: 2,
//             title: '這是第二篇新聞',
//           },
//           {
//             id: 3,
//             title: '可不可以不要再有韓市長的新聞',
//           },
//           {
//             id: 4,
//             title: '芒果亂報很好看',
//           },
//         ],
//         error: '',
//       })),
//     });
//   });
// }

// function getNewsById(newId) {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: JSON.parse(JSON.stringify({
//         data: {
//           id: 1,
//           title: '0219 亡靈來襲 亡靈派對組合包 早鳥優惠',
//           content: '<p>亡靈來襲 亡靈派對組合包 早鳥優惠</p>\n' +
//             '\n' +
//             '<p><br />\n' +
//             '【亡靈歸來】【死亡之舞】</p>\n' +
//             '\n' +
//             '<p>上架時間：02/19 00:00 &ndash; 02/21 23:59<br />\n' +
//             '原價 1600 鑽，早鳥優惠 1499 鑽<br />\n' +
//             '※2/22 起開放單獨購買<br />\n' +
//             '※ 穿戴防彈衣與頭盔仍可顯示外觀<br />\n' +
//             '<img alt="" src="https://freefiremobile-a.akamaihd.net//common/OB13/TW/Splashbanner/0219skullcoupleea_tw.png" /></p>\n' +
//             '\n' +
//             '<p>&nbsp;</p>\n' +
//             '\n' +
//             '<p>骷髏面具幸運箱</p>\n' +
//             '\n' +
//             '<p><br />\n' +
//             '開啟後可隨機獲得一項永久或天期的骷髏面具：<br />\n' +
//             '【爽朗的骷髏】<br />\n' +
//             '【花語亡靈】<br />\n' +
//             '【駭骨美人】</p>\n' +
//             '\n' +
//             '<p>上架時間：02/19 00:00 -</p>\n' +
//             '\n' +
//             '<p>骷髏面具幸運箱，每箱 30 鑽<br />\n' +
//             '※ 同步更新贈禮商城<br />\n' +
//             '※ 穿戴頭盔仍可顯示外觀<br />\n' +
//             '※&nbsp;此為機會中獎商品，消費者購買或參與活動不代表即可獲得特定商品請妥善評估再行參加或購買。<br />\n' +
//             '<img alt="" src="https://freefiremobile-a.akamaihd.net//common/OB13/TW/Splashbanner/0219skullmask_tw.png" /></p>\n' +
//             '\n' +
//             '<p>帕洛瑪角色碎片幸運箱</p>\n' +
//             '\n' +
//             '<p>上架時間：02/19 00:00 -</p>\n' +
//             '\n' +
//             '<p>帕洛瑪角色碎片幸運箱，每箱 40 鑽<br />\n' +
//             '※&nbsp;此為機會中獎商品，消費者購買或參與活動不代表即可獲得特定商品請妥善評估再行參加或購買。</p>\n' +
//             '\n' +
//             '<p>&nbsp;</p>\n' +
//             '\n' +
//             '<p><span style="color: rgb(0, 0, 0); font-family: Arial; white-space: pre-wrap;">周卡會員專屬獎</span><br />\n' +
//             '<br />\n' +
//             '活動期間內，購買小資周卡即可額外獲得【金幣轉蛋券】【掃描器*3】獎勵<br />\n' +
//             '活動時間：2/20 04:00 - 2/27 03:59</p>\n' +
//             '\n' +
//             '<p>&nbsp;</p>\n' +
//             '\n' +
//             '<p>開春喜慶幸運箱<br />\n' +
//             '<br />\n' +
//             '上架時間：2/02 00:00 -<br />\n' +
//             '每箱 30 鑽<br />\n' +
//             '※同步更新贈禮商城<br />\n' +
//             '※&nbsp;此為機會中獎商品，消費者購買或參與活動不代表即可獲得特定商品請妥善評估再行參加或購買。</p>\n' +
//             '\n' +
//             '<p><img alt="" src="https://image.garena.tw/ff/Events%20News/201902/0214/0219dynastic_tw.png" /></p>\n',
//         },
//         error: '',
//       })),
//     });
//   });
// }

export default {
  getNews,
  getNewsById,
};
