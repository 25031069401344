<template>
  <div v-if="hasLoginPopup" class="notification-wrap">
    <div @click.prevent="switchLoginPopup(false)" class="notification-dimmer"></div>
    <div class="notification__login">
      <div class="notification-inner">
        <div class="notification__title">
          <div class="notification__title-txt">
            {{ $t('GENERAL__LOGIN') }}
          </div>
        </div>
        <div class="notification__platforms">
          <div v-for="platformId in platformIds" :key="platformId" class="notification__content-icon">
            <img @click="toLogin(platformId)" :src="`${CDN}/assets/share/img/icon-circle-platform-${platformId}.png`" alt="" />
            <div v-if="platformId === constants.GENERAL__GOP_PLATFORM.GARENA" @click.prevent="switchLoginPopup(false)">
              <router-link :to="{ name: 'MobileCantLogin' }">
                {{ $t('POPUP__CANT_LOGIN') }}
              </router-link>
            </div>
          </div>
        </div>
        <a @click.prevent="switchLoginPopup(false)" class="notification__close"></a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ACTION_SWITCH_LOGIN_POPUP } from '@/store/action-types';
import constants from '@/lib/constants';
import utils from '@/lib/utils';

export default {
  name: 'LoginPopup',
  data() {
    return {
      constants,
    };
  },
  computed: {
    ...mapState({
      hasLoginPopup: (state) => state.common.hasLoginPopup,
    }),
  },
  methods: {
    loginUrl(platformId) {
      return utils.buildLoginUrl(platformId);
    },
    toLogin(platformId) {
      const queryString = Object.keys(this.$route.query)
        .map((key) => `${key}=${this.$route.query[key]}`)
        .join('&');
      if (!utils.getCookie('gopredirect')) {
        utils.setCookie('gopredirect', `${this.$route.path}?${queryString}`, 60);
      }
      window.location.href = this.loginUrl(platformId);
    },
    ...mapActions({
      switchLoginPopup: ACTION_SWITCH_LOGIN_POPUP,
    }),
  },
};
</script>

<style lang="scss" scoped>
.login-popup {
  width: 500px;
}
.notification {
  &__login {
    background: #ffffff;
    border-radius: 6px;
    padding: 24px 10px;
    position: absolute;
    width: 360px;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  &__platforms {
    display: flex;
  }
  &__content-icon {
    text-align: center;
    flex: 1;
  }
  &__content-icon img {
    border: $general-input-border-color 0.5px solid;
    border-radius: 40px;
    cursor: pointer;
    width: 60px;
  }
  &__content-icon a {
    font-size: 12px;
    color: $general-red;
    &:hover {
      text-decoration-line: revert;
    }
  }
}
</style>
