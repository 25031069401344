<template>
  <div class="main-wrap">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('REPORT__DETAIL_TITLE')" />
      <div class="main-inner main-inner--nobg">
        <template v-if="isDataReady">
          <div class="onlineservicedet">
            <breadcrumb :paths="paths" />
            <div class="onlineservicedet-main">
              <div class="onlineservicedet-linkwrap">
                <div class="report-linking">
                  {{ $t('REPORT__LINK_CONTENT') }}
                </div>
                <div class="report-user">
                  <div class="report-user__info">
                    <div class="report-user__avatar-wrap">
                      <div class="report-user__avatar">
                        <img :src="userInfo.avatar" alt="" class="report-user__avatar-img" />
                      </div>
                      <div class="report-user__channel">
                        <img
                          :src="`${CDN}/assets/share/img/icon-circle-platform-${userInfo.platform}.png`"
                          alt=""
                          class="report-user__channel-img"
                        />
                      </div>
                    </div>
                    <div class="report-user__data">
                      <div class="report-user__account">
                        <div class="report-user__name">
                          {{ userInfo.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div class="report-linking">
                  {{ $t('REPORT__LINK_CONFIRM_MSG') }}
                </div>
                <div class="report-form__next">
                  <div class="rountbutton">
                    <router-link :to="{ name: 'Home' }">
                      <button class="rountbutton__widget">{{ $t('REPORT__LINK_CONFIRM_NO_BUTTON') }}</button>
                    </router-link>
                  </div>
                  <div class="rountbutton">
                    <button class="rountbutton__widget" @click.prevent="linkTicketLink">{{ $t('REPORT__LINK_CONFIRM_YES_BUTTON') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <!-- loading -->
        <template v-else>
          <div class="onlineservicedet">
            <breadcrumb :paths="paths" />
            <div class="loading">
              <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
            </div>
          </div>
        </template>
        <!-- loading /-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';
import Navigation from '@/components/pc/layouts/Navigation';
import ticket from '@/api/prod/ticket';
import constants from '@/lib/constants';
import utils from '@/lib/utils';
import { ACTION_TRIGGER_POPUP } from '@/store/action-types';

export default {
  name: 'ReportLink',
  components: {
    Navigation,
    Breadcrumb,
  },
  data() {
    return {
      constants,
      utils,
      errorMsg: null,
      token: this.$route.query.token,
      ticketLinkInfo: null,
      paths: [
        {
          id: 1,
          name: 'ReportList',
          title: this.$t('REPORT__TITLE'),
        },
        {
          id: 2,
          name: '',
          title: this.$t('REPORT__LINK_TITLE'),
        },
      ],
    };
  },
  computed: {
    isDataReady() {
      return this.userInfo && (this.errorMsg || this.ticketLinkInfo);
    },
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    ...mapGetters(['hasLoggedIn']),
  },
  methods: {
    ...mapActions({
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
    getTicketLinkInfo(token) {
      if (!token) {
        this.$router.push({ name: 'Home' });
      }
      ticket.getTicketLinkInfo(token).then((resp) => {
        const { data, error } = resp;
        if (error || !data) {
          if (error === 'error_require_login') {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_REQUIRE_LOGIN'),
            });
          } else {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_TICKET_LINK_NOT_FOUND'),
            });
          }
          this.$router.push({ name: 'Home' });
        } else if (data.hasLinkedToCurUser) {
          this.$router.push({ name: 'Report', params: { csNumber: data.csNumber } });
        } else {
          this.ticketLinkInfo = data;
        }
      });
    },
    linkTicketLink() {
      const payload = {
        token: this.token,
      };
      ticket.linkTicketLink(payload).then((resp) => {
        const { data, error } = resp;
        if (error) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_EXCEPTION'),
          });
        } else {
          this.$router.push({ name: 'Report', params: { csNumber: data.csNumber } });
        }
      });
    },
  },
  mounted() {
    this.getTicketLinkInfo(this.token);
  },
};
</script>

<style lang="scss" scoped>
.loading {
  text-align: center;
}
.onlineservicedet-main {
  text-align: center;
}
.onlineservicedet-linkwrap {
  padding-bottom: 50px;
}
.report-linking {
  font-size: 20px;
  padding: 8px 68px 16px;
  font-stretch: normal;
  font-weight: bold;
}
.report-form__next {
  .rountbutton {
    display: inline-block;
  }
}
.report-user {
  height: 66px;
  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__avatar-wrap {
    color: #ffffff;
    position: relative;
    margin: 0 18px 0 0;
  }
  &__avatar {
    border: 3px solid currentColor;
    border-radius: 50%;
    overflow: hidden;
    &-img {
      display: block;
      width: 60px;
      height: 60px;
    }
  }
  &__channel {
    position: absolute;
    border: 3px solid currentColor;
    border-radius: 4px;
    overflow: hidden;
    right: 0;
    bottom: 0;
    background: currentColor;
    &-img {
      display: block;
      width: 20px;
      height: 20px;
    }
  }
  &__data {
    margin: 0 13px 0 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  &__account {
    display: flex;
    justify-content: space-between;
  }
  &__name {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
