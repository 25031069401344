var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "containerRef",
      class: ["marquee", { "align-left": _vm.alignLeft }]
    },
    [
      _c("p", {
        ref: "textRef",
        staticClass: "text-marquee",
        domProps: { innerHTML: _vm._s(_vm.text) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }