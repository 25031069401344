<template>
  <div class="main-wrap main-wrap--hassearch">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('GENERAL__TITLE')" :has-search-bar="true" />
      <div class="main-inner">
        <div class="faqdetail-main">
          {{ $t('POPUP__ERROR_404') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/pc/layouts/Navigation';

export default {
  name: 'PageNotFound',
  components: {
    Navigation,
  },
};
</script>

<style lang="scss" scoped>
.pc {
  .faqdetail-main {
    height: 180px;
  }
}
</style>
