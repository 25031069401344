var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: { title: _vm.$t("PROFILE__TITLE"), "has-search-bar": false }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "main-inner" }, [
          _c(
            "div",
            { staticClass: "profile" },
            [
              _c("breadcrumb", {
                attrs: { paths: _vm.paths, "has-border": false }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "main-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("PROFILE__TITLE")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "profile-main" },
                [
                  _c("dl", { staticClass: "profile-warning" }, [
                    _c("dt", { staticClass: "profile-warning__title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("PROFILE__DESCRIPTION")) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("dd", [
                      _c("ol", { staticClass: "profile-warning__list" }, [
                        _c("li", { staticClass: "profile-warning__item" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("PROFILE__WARNING_1")) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "profile-warning__item" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("PROFILE__WARNING_2")) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "profile-warning__item" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("PROFILE__WARNING_3")) +
                              "\n                "
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.userInfo
                    ? [
                        _c(
                          "div",
                          { staticClass: "profile-data" },
                          [
                            _c("dl", { staticClass: "profile-data__row" }, [
                              _c("dt", { staticClass: "profile-data__title" }, [
                                _vm._v(_vm._s(_vm.$t("PROFILE__INFO")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "dd",
                                { staticClass: "profile-data__item" },
                                [
                                  _vm.isGarenaUser
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "profile-data__item-name"
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "GENERAL__PLATFORM_GARENA"
                                                  )
                                                ) +
                                                "\n                      " +
                                                _vm._s(
                                                  _vm.$t("PROFILE__ACCOUNT")
                                                ) +
                                                "\n                    "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "profile-data__item-value"
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.garenaUserRealInfo
                                                    .username
                                                ) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm.isFbUser
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "profile-data__item-name"
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "GENERAL__PLATFORM_FACEBOOK"
                                                  )
                                                ) +
                                                "\n                      " +
                                                _vm._s(
                                                  _vm.$t("PROFILE__ACCOUNT")
                                                ) +
                                                "\n                    "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "profile-data__item-value"
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(_vm.userInfo.name) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("dd", { staticClass: "profile-data__item" }, [
                                _vm.isGarenaUser
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "profile-data__item-name"
                                      },
                                      [_vm._v("Garena UID")]
                                    )
                                  : _vm.isFbUser
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "profile-data__item-name"
                                      },
                                      [_vm._v("Facebook UID")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "profile-data__item-value" },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.userInfo.uid) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.isGarenaUser && _vm.garenaUserRealInfo.username
                              ? [
                                  _c(
                                    "dl",
                                    { staticClass: "profile-data__row" },
                                    [
                                      _c(
                                        "dt",
                                        { staticClass: "profile-data__title" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t("PROFILE__PERSONAL_INFO")
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "dd",
                                        { staticClass: "profile-data__item" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "profile-data__item-name"
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t("PROFILE__REAL_NAME")
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.garenaUserRealInfo.realname
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "profile-data__item-value"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.garenaUserRealInfo
                                                          .realname
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "profile-data__item-value-gray"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t("PROFILE__EMPTY")
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "dd",
                                        { staticClass: "profile-data__item" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "profile-data__item-name"
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "PROFILE__ID_CARD_NO"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.garenaUserRealInfo.idcard
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "profile-data__item-value"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.garenaUserRealInfo
                                                          .idcard
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "profile-data__item-value-gray"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t("PROFILE__EMPTY")
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "dd",
                                        { staticClass: "profile-data__item" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "profile-data__item-name"
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t("PROFILE__PHONE")
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.garenaUserRealInfo
                                            .formattedPhoneNumber
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "profile-data__item-value"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.garenaUserRealInfo
                                                          .formattedPhoneNumber
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "profile-data__item-value-gray"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t("PROFILE__EMPTY")
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "dd",
                                        { staticClass: "profile-data__item" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "profile-data__item-name"
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "PROFILE__EMAIL_ADDRESS"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.garenaUserRealInfo.email
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "profile-data__item-value"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.garenaUserRealInfo
                                                          .email
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "profile-data__item-value-gray"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t("PROFILE__EMPTY")
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.isGarenaUser
                                        ? _c(
                                            "dd",
                                            {
                                              staticClass: "profile-data__item"
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "profile-data__item-name",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$t(
                                                      "PROFILE__TO_ACCOUNT_CENTER"
                                                    )
                                                  )
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              : _vm.isFbUser
                              ? [
                                  _c(
                                    "dl",
                                    { staticClass: "profile-data__row" },
                                    [
                                      _c(
                                        "dt",
                                        { staticClass: "profile-data__title" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t("PROFILE__PERSONAL_INFO")
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      !_vm.isEditing
                                        ? [
                                            _c(
                                              "dd",
                                              {
                                                staticClass:
                                                  "profile-data__item"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "profile-data__item-name"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PROFILE__REAL_NAME"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "profile-data__item-value"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.profile.realname
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "dd",
                                              {
                                                staticClass:
                                                  "profile-data__item"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "profile-data__item-name"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PROFILE__ID_CARD_NO"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "profile-data__item-value"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.profile.idcard
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "dd",
                                              {
                                                staticClass:
                                                  "profile-data__item"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "profile-data__item-name"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PROFILE__BIRTHDATE"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.profile.formattedBirthdate
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "profile-data__item-value"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.profile
                                                                .formattedBirthdate
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm.hasProfile
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "profile-data__item-value-gray"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "PROFILE__INVALID_BIRTHDATE"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "dd",
                                              {
                                                staticClass:
                                                  "profile-data__item"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "profile-data__item-name"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PROFILE__PHONE"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "profile-data__item-value"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.profile
                                                            .formattedPhoneNumber
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "dd",
                                              {
                                                staticClass:
                                                  "profile-data__item"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "profile-data__item-name"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "PROFILE__EMAIL_ADDRESS"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "profile-data__item-value"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.profile.email
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "dd",
                                              {
                                                staticClass:
                                                  "profile-data__item"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "profile-data__item-name"
                                                  },
                                                  [
                                                    !_vm.hasProfile
                                                      ? _c(
                                                          "a",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.switchEditing()
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PROFILE__ADD_PERSONAL_INFO"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        : [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "profile-formwrap"
                                              },
                                              [
                                                _c(
                                                  "form",
                                                  {
                                                    staticClass: "profile-form"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "profile-form__row"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "profile-form__inline"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "profile-form__label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "PROFILE__REAL_NAME"
                                                                      )
                                                                    ) +
                                                                    "\n                            "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "profile-form__field"
                                                              },
                                                              [
                                                                _c(
                                                                  "custom-input",
                                                                  {
                                                                    attrs: {
                                                                      name:
                                                                        "realname",
                                                                      "is-required": true,
                                                                      "error-msg": _vm.$t(
                                                                        "GENERAL__INPUT_ERROR_REQUIRED"
                                                                      )
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .profile
                                                                          .realname,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.profile,
                                                                          "realname",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "profile.realname"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "profile-form__row"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "profile-form__inline"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "profile-form__label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "PROFILE__ID_CARD_NO"
                                                                      )
                                                                    ) +
                                                                    "\n                            "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "profile-form__field"
                                                              },
                                                              [
                                                                _c(
                                                                  "custom-input",
                                                                  {
                                                                    attrs: {
                                                                      name:
                                                                        "idcard",
                                                                      placeholder: _vm.$t(
                                                                        "GENERAL__INPUT_PLACEHOLDER_HALFWIDTH"
                                                                      ),
                                                                      "is-required": true,
                                                                      "error-msg": _vm.$t(
                                                                        "GENERAL__INPUT_ERROR_REQUIRED"
                                                                      )
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .profile
                                                                          .idcard,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.profile,
                                                                          "idcard",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "profile.idcard"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "profile-form__row"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "profile-form__inline"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "profile-form__label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "PROFILE__BIRTHDATE"
                                                                      )
                                                                    ) +
                                                                    "\n                            "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "profile-form__field"
                                                              },
                                                              [
                                                                _c(
                                                                  "custom-datetime-picker",
                                                                  {
                                                                    attrs: {
                                                                      type:
                                                                        "date",
                                                                      placeholder: _vm.$t(
                                                                        "GENERAL__INPUT_PLACEHOLDER_UNDER_AGE"
                                                                      ),
                                                                      format: _vm.$t(
                                                                        "GENERAL__DATE_FORMAT"
                                                                      )
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .profile
                                                                          .birthdate,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.profile,
                                                                          "birthdate",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "profile.birthdate"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("div", {
                                                                      staticClass:
                                                                        "profile-form__inputdateicon",
                                                                      attrs: {
                                                                        slot:
                                                                          "calendar-icon"
                                                                      },
                                                                      slot:
                                                                        "calendar-icon"
                                                                    })
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "profile-form__row"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "profile-form__inline"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "profile-form__label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "PROFILE__PHONE"
                                                                      )
                                                                    ) +
                                                                    "\n                            "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "profile-form__field"
                                                              },
                                                              [
                                                                _c(
                                                                  "phone-input",
                                                                  {
                                                                    attrs: {
                                                                      name:
                                                                        "phone_number",
                                                                      placeholder: _vm.$t(
                                                                        "GENERAL__INPUT_PLACEHOLDER_HALFWIDTH_INT"
                                                                      ),
                                                                      "error-msg": _vm.$t(
                                                                        "GENERAL__INPUT_ERROR_PHONE_FORMAT"
                                                                      ),
                                                                      "default-number":
                                                                        _vm
                                                                          .profile
                                                                          .formattedPhoneNumber
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.phoneNumberObj,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.phoneNumberObj = $$v
                                                                      },
                                                                      expression:
                                                                        "phoneNumberObj"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "profile-form__row"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "profile-form__inline"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "profile-form__label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "PROFILE__EMAIL_ADDRESS"
                                                                      )
                                                                    ) +
                                                                    "\n                            "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "profile-form__field"
                                                              },
                                                              [
                                                                _c(
                                                                  "custom-input",
                                                                  {
                                                                    attrs: {
                                                                      name:
                                                                        "email",
                                                                      placeholder: _vm.$t(
                                                                        "GENERAL__INPUT_PLACEHOLDER_HALFWIDTH"
                                                                      ),
                                                                      "is-required": true,
                                                                      "custom-type":
                                                                        _vm
                                                                          .constants
                                                                          .GENERAL__INPUT_TYPES
                                                                          .EMAIL,
                                                                      "error-msg": _vm.$t(
                                                                        "GENERAL__INPUT_ERROR_EMAIL_FORMAT"
                                                                      )
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .profile
                                                                          .email,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.profile,
                                                                          "email",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "profile.email"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", {
                                                      staticClass:
                                                        "profile-form__divider"
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "profile-form__row"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "profile-form__inline"
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "profile-form__label"
                                                            }),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "profile-form__field"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "profile-form__next"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "rountbutton"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            staticClass:
                                                                              "rountbutton__widget",
                                                                            attrs: {
                                                                              disabled: !_vm.isButtonEnabled
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.submitProfile.apply(
                                                                                  null,
                                                                                  arguments
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                    " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "GENERAL__BUTTON_SUBMIT_FORM"
                                                                                  )
                                                                                ) +
                                                                                "\n                                    "
                                                                            ),
                                                                            _vm.isSubmitting
                                                                              ? _c(
                                                                                  "vue-countdown",
                                                                                  {
                                                                                    attrs: {
                                                                                      time: 10000
                                                                                    },
                                                                                    on: {
                                                                                      end:
                                                                                        _vm.handleCountdownEnd
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "default",
                                                                                          fn: function(
                                                                                            props
                                                                                          ) {
                                                                                            return [
                                                                                              _vm._v(
                                                                                                "(" +
                                                                                                  _vm._s(
                                                                                                    props.totalSeconds
                                                                                                  ) +
                                                                                                  ")"
                                                                                              )
                                                                                            ]
                                                                                          }
                                                                                        }
                                                                                      ],
                                                                                      null,
                                                                                      false,
                                                                                      1521751193
                                                                                    )
                                                                                  }
                                                                                )
                                                                              : _vm._e()
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                    ],
                                    2
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }