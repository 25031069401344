/* eslint-disable */
import api from '@/api';

function uploadFile(file, anonymous = false, onUploadProgress = null, fileType = null) {
  const config = onUploadProgress ? { onUploadProgress } : {};
  let formData = new FormData();
  formData.append('file', file);
  let endpoint = '/file';
  if (anonymous) {
    endpoint += '/anonymous';
  }
  if (fileType) {
    endpoint += `?type=${fileType}`;
  }
  return api.call(api.POST, endpoint, formData, config);
}

export default {
  uploadFile,
};
