<template>
  <div class="main-wrap">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('NEWS__TITLE')" :has-search-bar="false" />
      <div class="main-inner main-inner--nobg">
        <div class="faqdetail">
          <breadcrumb :paths="paths" :has-border="true" />
          <template v-if="news">
            <div class="faqdetail-main">
              <div class="faqdetail__title">
                {{ news.title }}
              </div>
              <div class="faqdetail__content">
                <div class="ip-solution__txt" v-html="news.content"></div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import news from '@/api/prod/news';
import Navigation from '@/components/pc/layouts/Navigation';
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';

export default {
  name: 'News',
  components: {
    Navigation,
    Breadcrumb,
  },
  data() {
    return {
      news: null,
    };
  },
  computed: {
    paths() {
      return [
        {
          id: 1,
          name: 'NewsList',
          params: {},
          title: this.$t('NEWS__TITLE'),
        },
        {
          id: 2,
          name: 'News',
          params: {},
          title: this.news ? this.news.title : '',
        },
      ];
    },
  },
  mounted() {
    news.getNewsById(this.$route.params.id).then((resp) => {
      const { data, error } = resp;
      if (error || !data) {
        if (error === 'error_no_matched_data') {
          this.$router.push(
            { name: 'NotFound' },
            () => {},
            () => {},
          );
        } else {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_EXCEPTION'),
          });
        }
      } else {
        this.news = data;
      }
    });
  },
};
</script>
