<template>
  <div id="app-mobile" class="mobile">
    <topbar />
    <router-view />
    <garena-footer />
    <AIFaq v-if="showAIFaqBtn" />
    <login-popup />
    <popup />
  </div>
</template>

<script>
import Topbar from '@/components/mobile/main/Topbar';
import GarenaFooter from '@/components/mobile/main/Footer';
import Popup from '@/components/mobile/main/Popup';
import LoginPopup from '@/components/mobile/main/LoginPopup';
import AIFaq from '@/components/mobile/components/ai/Main';

export default {
  name: 'MobileMaster',
  components: {
    Topbar,
    GarenaFooter,
    Popup,
    LoginPopup,
    AIFaq,
  },
  computed: {
    gameCodeOnUrl() {
      return this.$route.params.gameCode;
    },
    baseCategoryNameOnUrl() {
      return this.$route.params.baseCategory;
    },
    isTWRegion() {
      return process.env.REGION === 'tw';
    },
    showAIFaqBtn() {
      return (this.gameCodeOnUrl === 'aov' || this.gameCodeOnUrl === 'ff') && this.baseCategoryNameOnUrl && this.isTWRegion;
    },
  },
  mounted() {
    // eslint-disable-next-line global-require
    require('../../styles/style-mobile.scss');
  },
};
</script>

<style src="../../styles/reset.scss"></style>
<style>
body.white-background {
  background-color: #fff;
}
body.gray-background {
  background-color: #f8f9fa;
}
</style>
