// Shared
export const API_RETURN_CODE_FLAG_OK = 'ok';
export const API_RETURN_CODE_NO_MATCHED_DATA = 'error_no_matched_data';
export const API_RETURN_CODE_UNAUTHORIZED_OPERATION = 'error_unauthorized_operation';
export const API_RETURN_CODE_INVALID_DATA = 'error_invalid_data';
export const API_RETURN_CODE_FILE_NOT_AVAILABLE = 'error_file_not_available';

// Ticket
export const API_RETURN_CODE_INVALID_CATEGORY = 'error_invalid_category';
export const API_RETURN_CODE_INVALID_NOTIFICATION_EMAIL_ADDRESS = 'error_invalid_notification_email_address';
export const API_RETURN_CODE_FILE_INVALID_FORMAT = 'error_invalid_file_format';
export const API_RETURN_CODE_TOO_MANY_FILES = 'error_too_many_files';
export const API_RETURN_CODE_OPERATION_ALREADY_IN_PROGRESS = 'error_operation_already_in_progress';
export const API_RETURN_CODE_INVALID_CS_NUMBER = 'error_invalid_cs_number';
export const API_RETURN_CODE_TICKET_ALREADY_RATED = 'error_ticket_already_rated';
export const API_RETURN_CODE_TICKET_INVALID_OPERATION = 'error_ticket_invalid_operation';

// Application
export const API_RETURN_CODE_APPLIED_TOO_MANY_TIMES = 'error_applied_too_many_times';
export const API_RETURN_CODE_PROFILE_INCOMPLETE = 'error_profile_incomplete';
export const API_RETURN_CODE_APPLICATION_IN_PROGRESS = 'error_application_in_progress';
export const API_RETURN_CODES_ERROR_REQUIRE_GARENA_ACCOUNT = 'error_garena_account';
