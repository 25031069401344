/* eslint-disable no-shadow */

import {
  MUTATION_SWITCH_LOGIN_POPUP,
  MUTATION_TRIGGER_POPUP,
  MUTATION_CLOSE_POPUP,
  MUTATION_SWITCH_AI_CHATTING,
  MUTATION_TRIGGER_AI_CHATTING,
} from '@/store/mutation-types';

import {
  ACTION_SWITCH_LOGIN_POPUP,
  ACTION_TRIGGER_POPUP,
  ACTION_CLOSE_POPUP,
  ACTION_SWITCH_AI_CHATTING,
  ACTION_TRIGGER_AI_CHATTING,
} from '@/store/action-types';

// initial state
const state = {
  hasLoginPopup: false,
  hasPopup: false,
  popupContent: null,
  isAIChatting: false,
  AIChattingTriggered: false,
};

const actions = {
  [ACTION_SWITCH_LOGIN_POPUP]({ commit }, status) {
    commit(MUTATION_SWITCH_LOGIN_POPUP, status);
  },
  [ACTION_TRIGGER_POPUP]({ commit }, content) {
    commit(MUTATION_TRIGGER_POPUP, content);
  },
  [ACTION_CLOSE_POPUP]({ commit }) {
    commit(MUTATION_CLOSE_POPUP);
  },
  [ACTION_SWITCH_AI_CHATTING]({ commit }, status) {
    commit(MUTATION_SWITCH_AI_CHATTING, status);
  },
  [ACTION_TRIGGER_AI_CHATTING]({ commit }, status) {
    commit(MUTATION_TRIGGER_AI_CHATTING, status);
  },
};

const mutations = {
  [MUTATION_SWITCH_LOGIN_POPUP](state, status) {
    state.hasLoginPopup = status;
  },
  [MUTATION_TRIGGER_POPUP](state, content) {
    state.hasPopup = true;
    state.popupContent = content;
  },
  [MUTATION_CLOSE_POPUP](state) {
    state.hasPopup = false;
    state.popupContent = null;
  },
  [MUTATION_SWITCH_AI_CHATTING](state, status) {
    state.isAIChatting = status;
  },
  [MUTATION_TRIGGER_AI_CHATTING](state, status) {
    state.AIChattingTriggered = status;
  },
};

export default {
  state,
  actions,
  mutations,
};
