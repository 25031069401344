/* eslint-disable */

import api from '@/api';

function getBaseCategories() {
  return api.call(api.GET, '/categories/base');
}

function getTicketTopicCategories(gameId) {
  return api.call(api.GET, `/categories/game/${gameId}/ticket-topic-categories`);
}

function getTicketTopicsByTicketTopicCategoryId(ticketTopicCategoryId) {
  return api.call(api.GET, `/ticket-topic-category/${ticketTopicCategoryId}/topics`);
}

// function getBaseCategories() {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,

//       data: [
//         {
//           id: 1,
//           code: 'game',
//           name: '遊戲相關',
//         },
//         {
//           id: 2,
//           code: 'credit',
//           name: '儲值轉點',
//         },
//         {
//           id: 3,
//           code: 'account',
//           name: '帳號管理',
//         },
//         {
//           id: 4,
//           code: 'violation',
//           name: '違規檢舉',
//         },
//         {
//           id: 5,
//           code: 'appeal',
//           name: '停權申訴',
//         },
//       ],
//       error: '',
//     });
//   });
// }

// function getTicketTopicCategories(gameId) {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: JSON.parse(JSON.stringify({
//         "data": [
//           {
//             "id": 1,
//             "ticket_topic_categories": [
//               {
//                 "id": 1,
//                 "name": "遊戲內容問題"
//               },
//               {
//                 "id": 2,
//                 "name": "參加活動有問題"
//               },
//               {
//                 "id": 3,
//                 "name": "回報BUG"
//               },
//               {
//                 "id": 4,
//                 "name": "賽事問題"
//               }
//             ]
//           },
//           {
//             "id": 2,
//             "ticket_topic_categories": [
//               {
//                 "id": 5,
//                 "name": "沒拿到點數"
//               },
//               {
//                 "id": 6,
//                 "name": "儲值相關問題"
//               }
//             ]
//           },
//           {
//             "id": 3,
//             "ticket_topic_categories": [
//               {
//                 "id": 7,
//                 "name": "信箱認證問題"
//               },
//               {
//                 "id": 8,
//                 "name": "手機認證問題"
//               },
//               {
//                 "id": 9,
//                 "name": "更改帳號資料"
//               },
//               {
//                 "id": 10,
//                 "name": "其它服務申請"
//               },
//               {
//                 "id": 11,
//                 "name": "Garena 電腦版"
//               },
//               {
//                 "id": 12,
//                 "name": "Garena手機版"
//               },
//               {
//                 "id": 13,
//                 "name": "Garena Live"
//               },
//               {
//                 "id": 14,
//                 "name": "LAN Games"
//               },
//               {
//                 "id": 15,
//                 "name": "客服中心"
//               }
//             ]
//           },
//           {
//             "id": 4,
//             "ticket_topic_categories": [
//               {
//                 "id": 16,
//                 "name": "違規檢舉"
//               }
//             ]
//           },
//           {
//             "id": 5,
//             "ticket_topic_categories": [
//               {
//                 "id": 17,
//                 "name": "停權疑問"
//               }
//             ]
//           }
//         ],
//         error: '',
//       })),
//     });
//   });
// }

// function getTicketTopicsByTicketTopicCategoryId(ticketTopicCategoryId) {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: JSON.parse(JSON.stringify({
//         "data": {
//           "game_id": 1,
//           "category_id": 1,
//           "ticket_topic_category": {
//             "id": 1,
//             "name": "遊戲內容問題"
//           },
//           "ticket_topics": [
//             {
//               "id": 1,
//               "name": "遊戲建議",
//               "description": "這裡可以輸入一些注意事項給玩家查看",
//               "fields": [
//                 {
//                   "type": "input",
//                   "name": "character_name",
//                   "display_name": "角色暱稱",
//                   "is_required": 1,
//                   "attributes": {
//
//                   }
//                 },
//                 {
//                   "type": "textarea",
//                   "name": "content",
//                   "display_name": "狀況敘述",
//                   "is_required": 1,
//                   "attributes": {
//
//                   }
//                 },
//                 {
//                   "type": "file",
//                   "name": "file",
//                   "display_name": "上傳檔案",
//                   "is_required": 0,
//                   "attributes": {
//
//                   }
//                 }
//               ]
//             },
//             {
//               "id": 2,
//               "name": "遊戲建議2",
//               "description": "這裡可以輸入一些注意是給玩家查看??",
//               "fields": [
//                 {
//                   "type": "input",
//                   "name": "garena_account",
//                   "display_name": "Garena 帳戶：",
//                   "is_required": 1,
//                   "attributes": {
//                     "placeholder": "Garena用戶名/郵箱/電話",
//                   }
//                 },
//                 {
//                   "type": "textarea",
//                   "name": "content2",
//                   "display_name": "狀況敘述2",
//                   "is_required": 0,
//                   "attributes": {
//                     "placeholder": "請詳細敘述你遇到的問題，或附上相關截圖。",
//                     "invalidation_hint": "填寫狀況敘述",
//                   }
//                 },
//                 {
//                   "type": "file",
//                   "name": "file_img",
//                   "display_name": "上傳圖片",
//                   "is_required": 1,
//                   "attributes": {
//
//                   }
//                 },
//                 {
//                   "type": "file",
//                   "name": "file_doc",
//                   "display_name": "上傳文件",
//                   "is_required": 1,
//                   "attributes": {
//
//                   }
//                 }
//               ]
//             },
//           ]
//         },
//         error: 'error_require_login',
//       })),
//     });
//   });
// }

export default {
  getBaseCategories,
  getTicketTopicCategories,
  getTicketTopicsByTicketTopicCategoryId,
};
