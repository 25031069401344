<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ACTION_GET_GAMES, ACTION_GET_BASE_CATEGORIES } from '@/store/action-types';
import { loadLocale } from './i18n';

export default {
  methods: {
    ...mapActions({
      getGames: ACTION_GET_GAMES,
      getBaseCategories: ACTION_GET_BASE_CATEGORIES,
    }),
  },
  async created() {
    await loadLocale(process.env.LOCALE);
    await this.getGames();
    await this.getBaseCategories();
  },
};
</script>

<style>
#app {
  font: 16px/1.375 arial, 'Microsoft JhengHei', \5fae\8edf\6b63\9ed1\9ad4, PMingLiU, \65b0\7d30\660e\9ad4;
}
</style>
