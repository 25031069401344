<template>
  <div class="ai-float-button" :class="{ 'chat-mode': isChatting }">
    <div class="clickable content" :class="{ hide: isChatting }" @click="handleChatOpen">
      <div class="text">
        <span v-for="(n, i) in constants.AI_FAQ__ASSISTANT_NAME[gameCodeOnUrl].split('')" :key="i" :class="`char${i + 1}`">{{ n }}</span>
      </div>
      <img class="ai-img" :src="constants.AI_FAQ__CHAT_IMG[gameCodeOnUrl]" />
    </div>
    <div class="content" :style="{ opacity: isChatting ? 1 : 0, pointerEvents: isChatting ? 'all' : 'none' }">
      <div class="chat">
        <div class="chat__top">
          <div class="chat__top-title" v-html="config.title"></div>
          <div class="chat__top-minimize clickable" @click="onMinimizeClick"></div>
          <div class="chat__top-close clickable" @click="onCloseClick"></div>
        </div>
        <ItemMarquee v-if="isChatting" class="chat__marquee" :text="config.marquee" />
        <ChatMessages ref="messageList" :messages="messages" />

        <div v-if="currentReplyIndex === null" class="chat__quick-replay">
          <div
            v-for="(r, index) in config.quickReply"
            :key="`reply-title-${index}`"
            class="chat__quick-replay-content clickable"
            @click="onQuickReply(r, index)"
          >
            {{ r.title }}
          </div>
        </div>

        <template v-else>
          <div class="chat__quick-replay--sort">
            <a @click="currentReplyIndex = null">&#x2715;</a>
            <p>{{ config.quickReply[currentReplyIndex].title }}</p>
          </div>
          <div class="chat__quick-replay">
            <div
              v-for="r in config.quickReply[currentReplyIndex].children"
              :key="r.title"
              class="chat__quick-replay-content clickable"
              @click="onQuickReply(r)"
            >
              {{ r.title }}
            </div>
          </div>
        </template>

        <ChatInput
          ref="chatInput"
          :value="input"
          :isMessageLoading="isMessageLoading || showLoading"
          :inputLocked="isMaintaining || tokenOverLimited || showLoading"
          @input="input = $event"
          @onFocus="onFocus"
          @onBlur="onBlur"
          @onSend="onInputSend(input)"
        />
      </div>

      <Popup
        v-if="showClose"
        :popupContent="$t('AI_FAQ__POPUP__CLOSE_NOTE')"
        :rate="rate"
        :endDate="endDate"
        @onEnd="onEnd"
        @onLinkClose="onLinkClose"
        @onRateHelpful="onRateHelpful"
        @onRateUnhelpful="onRateUnhelpful"
        @onPopupCancel="onPopupCancel"
        @onPopupConfirm="onPopupConfirm"
      />
    </div>

    <!-- loading -->
    <div class="ai-loading" v-if="showLoading">
      <img class="ai-loading-icon" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
    </div>
    <!-- loading /-->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { ACTION_SWITCH_AI_CHATTING, ACTION_TRIGGER_AI_CHATTING, ACTION_SWITCH_LOGIN_POPUP } from '@/store/action-types';
import { getChatConfig, postCreateRoom, postChat, postGrading } from '@/api/prod/ai-faq';
import constants from '@/lib/constants';
import PageTitleNotification from '@/utils/page-notification';
import ItemMarquee from './ItemMarquee';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import Popup from './Popup';

export default {
  name: 'Main',
  components: {
    ItemMarquee,
    ChatMessages,
    ChatInput,
    Popup,
  },
  data() {
    return {
      constants,
      isChatting: false,
      isMessageLoading: false,
      isMaintaining: false,

      showLoading: false,
      showClose: false,

      messages: [],
      input: '',

      pushSystemMessageTO: null,
      openClosePopupTO: null,

      endDate: null,
      rate: null,

      // API
      config: {},
      chatRoomUuid: null,
      currentTokens: 0,

      // quick reply
      currentReplyIndex: null,

      // window
      windowBlur: false,
    };
  },
  computed: {
    gameCodeOnUrl() {
      return this.$route.params.gameCode;
    },
    beforeSystemInformPeriod() {
      return this.config.beforeSystemInformPeriod * 60 * 1000;
    },
    beforeGradingPeriod() {
      return this.config.beforeGradingPeriod * 60 * 1000;
    },
    closePeriod() {
      return this.config.closePeriod * 60 * 1000;
    },
    tokenOverLimited() {
      return this.currentTokens > 0 && this.config.tokenLimit > 0 && this.currentTokens >= this.config.tokenLimit;
    },
    ...mapState({
      AIChattingTriggered: (state) => state.common.AIChattingTriggered,
      userInfo: (state) => state.user.userInfo,
    }),
    ...mapGetters(['hasLoggedIn']),
  },
  methods: {
    handleChatOpen() {
      if (this.hasLoggedIn) {
        this.isChatting = true;
        this.gaTrack('click', 'pc', 'float', this.userInfo.uid);

        if (this.messages.length > 0) return;
        this.showLoading = true;

        getChatConfig(this.gameCodeOnUrl).then((res) => {
          if (res.error) {
            this.config = {
              greetings: '',
              title: '',
              marquee: '',

              beforeSystemInformPeriod: 1,
              beforeGradingPeriod: 1,
              closePeriod: 1,
              tokenLimit: 1,
              scoreRange: 0,
              quickReply: [],
            };
            this.addSystemMessage(`${this.$t(res.error)} ${res.code ? `[${res.code}]` : ''}`);
            this.isMaintaining = true;

            this.showLoading = false;
          } else {
            this.config = res;

            this.addSystemMessage(this.$t('AI_FAQ__SYSTEM__ENTER_NOTE'));
            this.addAIMessage(res.greetings);

            postCreateRoom(this.gameCodeOnUrl)
              .then((roomRes) => {
                if (roomRes.error) {
                  this.addSystemMessage(`${this.$t(roomRes.error)} ${roomRes.code ? `[${roomRes.code}]` : ''}`);
                  this.isMaintaining = true;
                } else {
                  this.chatRoomUuid = roomRes.chatRoomUuid;
                  this.currentTokens = roomRes.currentTokens;

                  if (!roomRes.chatRoomUuid) {
                    this.isMaintaining = true;
                    this.addSystemMessage(this.$t('AI_FAQ__SYSTEM__MAINTAIN'));
                  }
                }
              })
              .finally(() => {
                this.showLoading = false;
              });
          }
        });
      } else {
        this.switchLoginPopup(true);
      }
    },
    addUserMessage(message) {
      if (!message) return;

      this.messages.push({ chatRole: constants.AI_FAQ__CHAT_ROLE.USER, message, ts: new Date() });
      this.input = '';

      setTimeout(() => {
        this.scrollToLastMessage();
      }, 100);
    },
    addAIMessage(message, needResponse = false) {
      if (needResponse) {
        this.isMessageLoading = true;
        this.messages.push({ chatRole: constants.AI_FAQ__CHAT_ROLE.LOADING, message: '' });

        postChat({ eventKey: this.gameCodeOnUrl, chatRoomUuid: this.chatRoomUuid, message }).then((res) => {
          if (res.error) {
            this.messages.pop();
            this.addSystemMessage(`${this.$t(res.error)} ${res.code ? `[${res.code}]` : ''}`);
            this.isMaintaining = true;
            this.isMessageLoading = false;
          } else {
            this.messages.pop();
            this.messages.push({
              chatRole: constants.AI_FAQ__CHAT_ROLE.AI,
              message: res.message || this.$t('AI_FAQ__NO_RESPONSE'),
              ts: new Date(),
            });
            this.isMessageLoading = false;
            this.currentTokens = res.currentTokens;
            this.gaTrack('chat', 'pc', 'receive_response', this.userInfo.uid);
          }
        });
      } else {
        this.messages.push({ chatRole: constants.AI_FAQ__CHAT_ROLE.AI, message, ts: new Date() });
      }
    },
    addSystemMessage(systemMessage) {
      this.messages.push({ chatRole: constants.AI_FAQ__CHAT_ROLE.SYSTEM, message: systemMessage });
    },
    onInputSend(input) {
      this.addUserMessage(input);
      this.addAIMessage(input, true);
      this.gaTrack('chat', 'pc', 'send_message', this.userInfo.uid);
    },

    scrollToLastMessage() {
      const lastChildElement = this.$refs.messageList.$refs.messageList.lastElementChild;
      if (lastChildElement)
        lastChildElement.scrollIntoView({
          behavior: 'smooth',
        });
    },
    onQuickReply(reply, index = null) {
      if (this.isMessageLoading) return;
      this.resetSystemAndPopupCountDown();

      if (reply.content) {
        this.addUserMessage(reply.title);
        this.addAIMessage(reply.content);
        this.gaTrack('chat', 'pc', `quick_reply.${reply.title}`, this.userInfo.uid)
      } else {
        this.currentReplyIndex = index;
      }
    },
    startSystemAndPopupCountDown() {
      // onBlur & !showCLose
      this.pushSystemMessageTO = setTimeout(() => {
        this.addSystemMessage(this.$t('AI_FAQ__CLOSE_COUNTDOWN', { mins: this.config.beforeGradingPeriod }));

        this.openClosePopupTO = setTimeout(() => {
          if (!this.isChatting) {
            this.isChatting = true;
          }
          if (this.windowBlur) {
            PageTitleNotification.On(this.$t('AI_FAQ__PAGE_NOTIFICATION'));
          }

          this.openCloseModal();
        }, this.beforeGradingPeriod);
      }, this.beforeSystemInformPeriod);
    },
    resetSystemAndPopupCountDown() {
      // quickReply & Minimize
      this.clearSystemAndPopupCountDown();

      this.startSystemAndPopupCountDown();
    },
    clearSystemAndPopupCountDown() {
      // onFocus & clickClose
      clearTimeout(this.pushSystemMessageTO);
      this.pushSystemMessageTO = null;

      clearTimeout(this.openClosePopupTO);
      this.openClosePopupTO = null;
    },
    startCloseCountDown() {
      // showClose
      this.endDate = new Date(new Date().getTime() + this.closePeriod);
    },
    resetCloseCountDown() {
      // onRate
      this.startCloseCountDown();
    },

    onMinimizeClick() {
      this.resetSystemAndPopupCountDown();
      this.isChatting = false;
    },
    onCloseClick() {
      this.openCloseModal();
    },
    onPopupConfirm() {
      if (this.rate && this.chatRoomUuid) {
        postGrading({ eventKey: this.gameCodeOnUrl, chatRoomUuid: this.chatRoomUuid, score: this.rate === 'helpful' ? 1 : 0 });
      }
      this.closeChat();
    },
    onPopupCancel() {
      this.showClose = false;
      this.$refs.chatInput.$refs.chatInput.focus();
    },
    onFocus() {
      if (this.isChatting) {
        this.clearSystemAndPopupCountDown();
        PageTitleNotification.Off();
      }
    },
    onBlur() {
      if (!this.showClose && this.config.beforeSystemInformPeriod) {
        this.startSystemAndPopupCountDown();
      }
    },
    onEnd() {
      this.closeChat();
    },
    closeChat() {
      this.showClose = false;
      this.isChatting = false;
      this.isMaintaining = false;

      this.config = {};
      this.chatRoomUuid = null;
      this.currentTokens = 0;
      this.input = '';
      this.messages = [];
      this.currentReplyIndex = null;

      this.clearSystemAndPopupCountDown();
      this.switchAIChatting(false);

      PageTitleNotification.Off();
    },
    onRateHelpful() {
      this.rate = 'helpful';
      this.resetCloseCountDown();
    },
    onRateUnhelpful() {
      this.rate = 'unhelpful';
      this.resetCloseCountDown();
    },
    onLinkClose() {
      this.onPopupConfirm();

      window.open(
        `https://tw.support.garena.com/ticket/${this.gameCodeOnUrl}?bc=game`,
        '_blank', // <- This is what makes it open in a new window.
      );
    },
    openCloseModal() {
      this.startCloseCountDown();
      this.showClose = true;
    },
    onWindowFocus() {
      PageTitleNotification.Off();
      this.windowBlur = false;
    },
    onWindowBlur() {
      this.windowBlur = true;
    },
    onUnload(e) {
      // Check if any of the input fields are filled
      if (this.messages.length > 0) {
        // Cancel the event and show alert that
        // the unsaved changes would be lost
        e.preventDefault();
        e.returnValue = '';
      }
    },
    ...mapActions({
      switchLoginPopup: ACTION_SWITCH_LOGIN_POPUP,
      switchAIChatting: ACTION_SWITCH_AI_CHATTING,
      triggerAIChatting: ACTION_TRIGGER_AI_CHATTING,
    }),
  },
  watch: {
    isChatting(showing) {
      if (showing) {
        this.switchAIChatting(true);
        setTimeout(() => {
          this.$refs.chatInput.$refs.chatInput.focus();
        }, 500);
      }
    },
    showClose(show) {
      if (show) {
        this.clearSystemAndPopupCountDown();
        this.startCloseCountDown();
      } else {
        this.rate = null;
      }
    },
    tokenOverLimited(over) {
      if (over) {
        this.addSystemMessage(this.$t('AI_FAQ__SYSTEM__LACK_OF_TOKEN'));
      }
    },
    AIChattingTriggered(triggered) {
      if (triggered) {
        if (!this.isChatting) this.handleChatOpen();
        this.triggerAIChatting(false);
      }
    },
  },
  mounted() {
    window.addEventListener('focus', this.onWindowFocus);

    window.addEventListener('blur', this.onWindowBlur);

    window.addEventListener('beforeunload', this.onUnload);
  },
  beforeDestroy() {
    this.closeChat();

    window.removeEventListener('focus', this.onWindowFocus);

    window.removeEventListener('blur', this.onWindowBlur);

    window.removeEventListener('beforeunload', this.onUnload);
  },
};
</script>

<style lang="scss" scoped>
$ai-general: $general-red;
$ai-chat: #ffffff;

$ai-reply: #fff5f5;
$ai-reply-text: $general-red;

$size: 80;
$border-radius: 10px;

.content {
  @include flexCenter(column);
  width: 100%;
  height: 100%;
}
.hide {
  display: none;
}

.ai-float-button {
  position: fixed;
  right: 60px;
  bottom: 120px;
  width: $size + px;
  height: $size + px;
  background-color: $ai-general;
  border-radius: $size + px;
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;

  .text {
    user-select: none;
    position: absolute;
    left: -5px;
    top: -32px;
    width: $size + px;
    height: $size + px;
    border-radius: $size + px;
    transform: rotate(-54deg);
    span {
      font-size: 13px;
      height: 80px;
      position: absolute;
      width: 0px;
      left: 0;
      top: 0;
      transform-origin: bottom center;
      color: $ai-general;
    }

    @include rotatedText(6, 65deg);
  }

  .ai-img {
    width: $size - 10 + px;
    height: $size - 10 + px;
    background-color: #ffffff;
    border-radius: $size + px;
  }

  .clickable:hover {
    cursor: pointer;
  }

  &.chat-mode {
    width: 500px;
    height: 90%;
    right: 15px;
    bottom: 0;
    border-radius: 10px;
    box-shadow: -2px 1px 20px rgba(0, 0, 0, 0.3);
  }
}

.icon {
  font-family: 'icon';
  font-size: 36px;
  margin-top: -10px;
  color: white;
}

.icon::before {
  content: '\E90E';
}

span {
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.chat {
  background-color: $ai-chat;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  @include flexCenter(column);

  &__marquee {
    height: 50px;
    width: 100%;
  }

  &__top {
    @include flexCenter(row);
    width: 100%;
    height: 50px;
    color: white;
    background-color: $ai-general;
    padding: 0 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &-title {
      flex: 1;
      font-weight: bold;
    }

    &-minimize {
      font-family: 'icon';
      font-size: 40px;
      font-weight: bold;
      transform: rotate(90deg);

      &::before {
        content: '\E915';
      }
    }

    &-close {
      font-family: 'icon';
      font-size: 30px;

      &::before {
        content: '\E902';
      }
    }
  }

  &__quick-replay {
    @include flexCenter(row);

    width: 100%;
    justify-content: flex-start;
    overflow-x: auto;

    &--sort {
      @include flexCenter(row);
      justify-content: flex-start;
      width: 100%;
      color: $general-red;
      > a {
        margin: 0px 3px 0 10px;
      }
    }

    &-content {
      flex-shrink: 0;
      margin: 8px 0 8px 10px;
      padding: 5px 10px;
      color: $general-red;
      background-color: $ai-reply;
      border-radius: 5px;
      position: relative;

      &:last-child {
        margin-right: 10px;
      }

      &::before {
        content: '';
        position: absolute;
        left: -3.5px;
        bottom: -1px;
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-right: 5px solid $ai-reply;
        border-bottom: 3px solid transparent;
        transform: rotate(-25deg);
      }
    }
  }
}

.ai-loading {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  @include flexCenter(row);

  &-icon {
    width: 100px;
    height: 100px;
  }
}
</style>
