<template>
  <footer class="ga_footer">
    <div class="fullc">
      <div class="top-footer">
        <div class="footer-rules">
          <a :href="$t('TERMS__TOS_LINK')" target="_blank">{{ $t('TERMS__TOS') }}</a>
        </div>
        <div class="pipe">|</div>
        <div class="footer-rules">
          <a :href="$t('TERMS__PP_LINK')" target="_blank">{{ $t('TERMS__PP') }}</a>
        </div>
      </div>
      <p>Copyright © Garena Online. Trademarks belong to their respective owners. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss" scoped>
.ga_footer {
  font-family: 'PingFang TC', serif;
  font-size: 0.8rem;
  width: 100%;
  margin: auto;
  body.scroll-wrap & {
    display: none;
  }
}

body.gray-background {
  .fullc {
    border-top: 1px solid #eee;
  }
}

.fullc {
  text-align: center;
  margin: 0 10px 12px;
  padding: 20px 10px 10px;
  p {
    font-size: 9px;
    color: $general-light-text-color;
    opacity: 0.5;
    padding-top: 6px;
  }
}

.top-footer {
  display: flex;
  color: $general-light-text-color;
  a {
    white-space: nowrap;
    text-decoration: none;
    color: $general-dark-text-color;
    font-weight: 300;
    margin: 0 6px;
  }
}
.footer-rules {
  flex: 1;
}
</style>
