<template>
  <div class="main-wrap">
    <div class="main-bg"></div>
    <div class="main">
      <navigation :title="$t('GENERAL__TITLE')" :is-blank="true" />
      <div class="login">
        <div class="login-main">
          <div class="login-title">
            {{ $t('LOGIN__CHOOSE_PLATFORM') }}
          </div>
          <div class="login-inner">
            <div v-for="platformId in platformIds" :key="platformId" class="login-icon">
              <img @click="toLogin(platformId)" :src="`${CDN}/assets/share/img/icon-circle-platform-${platformId}.png`" alt="" />
              <div v-if="platformId === constants.GENERAL__GOP_PLATFORM.GARENA" @click.prevent="switchLoginPopup(false)">
                <router-link :to="{ name: 'MobileCantLogin' }">
                  {{ $t('POPUP__CANT_LOGIN') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Navigation from '@/components/mobile/layouts/Navigation';
import constants from '@/lib/constants';
import utils from '@/lib/utils';

export default {
  name: 'Login',
  components: {
    Navigation,
  },
  data() {
    return {
      constants,
    };
  },
  computed: {
    ...mapGetters(['hasLoggedIn']),
  },
  methods: {
    loginUrl(platformId) {
      return utils.buildLoginUrl(platformId);
    },
    toLogin(platformId) {
      window.location.href = this.loginUrl(platformId);
    },
  },
  mounted() {
    if (this.hasLoggedIn) {
      this.$router.push(
        { name: 'MobileHome' },
        () => {},
        () => {},
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.login {
  margin-top: 6rem;
  &-main {
    padding: 0 18px 50px;
    text-align: center;
  }
  &-title {
    font-size: 20px;
    padding: 8px 68px 16px;
    font-stretch: normal;
    font-weight: bold;
  }
  &-inner {
    display: flex;
    margin: 10px auto 0;
  }
  &-icon {
    text-align: center;
    flex: 1;
  }
  &-icon img {
    border: $general-input-border-color 0.5px solid;
    border-radius: 40px;
    cursor: pointer;
    width: 60px;
  }
  &-icon a {
    font-size: 12px;
    color: $general-red;
    &:hover {
      text-decoration-line: revert;
    }
  }
}
</style>
