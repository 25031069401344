<template>
  <div class="main-wrap">
    <section class="game-section">
      <template v-if="games && games.pc">
        <div class="game-section__title">
          <div class="game-section__title-icon game-section__title-icon-pcgames"></div>
          <h3 class="game-section__title-txt">
            {{ $t('HOME__PC_GAME_QUESTION') }}
          </h3>
        </div>
        <div class="game-cards">
          <router-link
            v-for="game in games.pc"
            :key="game.id"
            class="game-cards__item"
            :to="{ name: 'MobileBaseCategoryList', params: { gameCode: game.code } }"
          >
            <img :src="game.displayImageUrl" alt="" class="game-cards__img" />
          </router-link>
        </div>
      </template>
      <template v-if="games && games.mobile">
        <div class="game-section__title">
          <div class="game-section__title-icon game-section__title-icon-mgames"></div>
          <h3 class="game-section__title-txt">
            {{ $t('HOME__MOBILE_GAME_QUESTION') }}
          </h3>
        </div>
        <div class="game-cards">
          <router-link
            v-for="game in games.mobile"
            :key="game.id"
            class="game-cards__item"
            :to="{ name: 'MobileBaseCategoryList', params: { gameCode: game.code } }"
          >
            <img :src="game.displayImageUrl" alt="" class="game-cards__img" />
          </router-link>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ACTION_GET_GAMES } from '@/store/action-types';

export default {
  name: 'GameList',
  computed: {
    ...mapState({
      games: (state) => state.game.games,
    }),
  },
  methods: {
    ...mapActions({
      getGames: ACTION_GET_GAMES,
    }),
  },
  mounted() {
    if (!this.games) {
      this.getGames();
    }
  },
};
</script>
