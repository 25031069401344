var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "onlineservicedet-reply" }, [
    _c("div", { staticClass: "onlineservicedet-panel" }, [
      _c("div", { staticClass: "onlineservicedet-record" }, [
        _c("div", { staticClass: "onlineservicedet-record__date" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.utils.formatTimestampToString(
                  _vm.createdAt,
                  _vm.$t("GENERAL__DATETIME_FORMAT_2")
                )
              ) +
              "\n      "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "onlineservicedet-record__dialogue" }, [
        _c(
          "div",
          {
            class: [
              _vm.isStaff
                ? "onlineservicedet-record__avatar-cs"
                : "onlineservicedet-record__avatar"
            ]
          },
          [
            !_vm.isStaff
              ? _c("div", { staticClass: "onlineservicedet-record__name" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.displayName) + "\n        "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("img", {
              staticClass: "onlineservicedet-record__avatarimg",
              attrs: { src: _vm.displayAvatar, alt: "" }
            }),
            _vm._v(" "),
            _vm.isStaff
              ? _c("div", { staticClass: "onlineservicedet-record__name" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.displayName) + "\n        "
                  )
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "onlineservicedet-record__box" }, [
          _c("div", {
            class: [
              _vm.isStaff
                ? "onlineservicedet-record__arrow-cs"
                : "onlineservicedet-record__arrow"
            ]
          }),
          _vm._v(" "),
          _c("div", { staticClass: "onlineservicedet-record__main" }, [
            _c("div", { staticClass: "onlineservicedet-record__content" }, [
              _c(
                "div",
                { staticClass: "onlineservicedet-record__content-inner" },
                [
                  _c(
                    "div",
                    { staticClass: "onlineservicedet-record__content-title" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("REPORT__REPLY_TITLE_USER")) +
                          "\n              "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "onlineservicedet-record__content-txt" },
                    [
                      _vm.isStaff
                        ? [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(_vm.content) }
                            })
                          ]
                        : [_c("span", [_vm._v(_vm._s(_vm.content))])]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.isStaff && _vm.attachments.length > 0
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "onlineservicedet-record__content-panel"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "onlineservicedet-record__content-title"
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$t("REPORT__REPLY_ATTACHMENT")) +
                                    "\n                  "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "attachment" },
                          _vm._l(_vm.attachments, function(attachment, idx) {
                            return _c(
                              "div",
                              { key: idx, staticClass: "attachment-txt" },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(attachment.filename) +
                                    "\n                    "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: attachment.url,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "[" +
                                        _vm._s(
                                          _vm.$t("REPORT__REPLY_DOWNLOAD")
                                        ) +
                                        "]"
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    : !_vm.isStaff && _vm.numOfAttachments > 0
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "onlineservicedet-record__content-panel"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "onlineservicedet-record__content-title"
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$t("REPORT__REPLY_ATTACHMENT")) +
                                    "\n                  "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "attachment" }, [
                          _c("div", { staticClass: "attachment-txt" }, [
                            _vm._v(
                              _vm._s(_vm.$t("REPORT__REPLY_ATTACHMENT_NUM")) +
                                _vm._s(_vm.numOfAttachments)
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "attachment-txt-notice" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$t("REPORT__REPLY_ATTACHMENT_NOTICE")
                                ) +
                                "\n                  "
                            )
                          ])
                        ])
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isStaff && !_vm.hideRated
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "onlineservicedet-record__content-panel"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "onlineservicedet-record__content-title"
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$t("REPORT__REPLY_RATE_TITLE")) +
                                    "\n                  "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.hasRated
                          ? [
                              _c("div", { staticClass: "helpful" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "helpful-radio helpful-radio__disabled"
                                  },
                                  [
                                    _c("div", { staticClass: "helpful-txt" }, [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.$t("REPORT__REPLY_RATE_YES")
                                          ) +
                                          "\n                      "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _vm.isHelpful
                                      ? _c("input", {
                                          staticClass: "helpful-input",
                                          attrs: {
                                            type: "radio",
                                            name: "helpful-" + _vm.replyId,
                                            checked: "checked"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass:
                                        "helpful-icon helpful-icon--helpful"
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "helpful-radio helpful-radio__disabled"
                                  },
                                  [
                                    _c("div", { staticClass: "helpful-txt" }, [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.$t("REPORT__REPLY_RATE_NO")
                                          ) +
                                          "\n                      "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    !_vm.isHelpful
                                      ? _c("input", {
                                          staticClass: "helpful-input",
                                          attrs: {
                                            type: "radio",
                                            name: "helpful-" + _vm.replyId,
                                            checked: "checked"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass:
                                        "helpful-icon helpful-icon--unhelpful"
                                    })
                                  ]
                                )
                              ])
                            ]
                          : [
                              _c("div", { staticClass: "helpful" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "helpful-radio",
                                    class: {
                                      "helpful-radio__disabled": _vm.hasSentRate
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "helpful-txt" }, [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.$t("REPORT__REPLY_RATE_YES")
                                          ) +
                                          "\n                      "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.hasRatedAndIsHelpful,
                                          expression: "hasRatedAndIsHelpful"
                                        }
                                      ],
                                      staticClass: "helpful-input",
                                      attrs: {
                                        type: "radio",
                                        name: "helpful-" + _vm.replyId,
                                        disabled: _vm.hasSentRate
                                      },
                                      domProps: {
                                        value:
                                          _vm.constants
                                            .TICKET__TICKET_REPLY_RATE
                                            .IS_HELPFUL,
                                        checked: _vm._q(
                                          _vm.hasRatedAndIsHelpful,
                                          _vm.constants
                                            .TICKET__TICKET_REPLY_RATE
                                            .IS_HELPFUL
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.hasRatedAndIsHelpful =
                                            _vm.constants.TICKET__TICKET_REPLY_RATE.IS_HELPFUL
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass:
                                        "helpful-icon helpful-icon--helpful"
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "helpful-radio",
                                    class: {
                                      "helpful-radio__disabled": _vm.hasSentRate
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "helpful-txt" }, [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.$t("REPORT__REPLY_RATE_NO")
                                          ) +
                                          "\n                      "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.hasRatedAndIsHelpful,
                                          expression: "hasRatedAndIsHelpful"
                                        }
                                      ],
                                      staticClass: "helpful-input",
                                      attrs: {
                                        type: "radio",
                                        name: "helpful-" + _vm.replyId,
                                        disabled: _vm.hasSentRate
                                      },
                                      domProps: {
                                        value:
                                          _vm.constants
                                            .TICKET__TICKET_REPLY_RATE
                                            .IS_NOT_HELPFUL,
                                        checked: _vm._q(
                                          _vm.hasRatedAndIsHelpful,
                                          _vm.constants
                                            .TICKET__TICKET_REPLY_RATE
                                            .IS_NOT_HELPFUL
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.hasRatedAndIsHelpful =
                                            _vm.constants.TICKET__TICKET_REPLY_RATE.IS_NOT_HELPFUL
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass:
                                        "helpful-icon helpful-icon--unhelpful"
                                    })
                                  ]
                                )
                              ])
                            ]
                      ]
                    : _vm._e()
                ],
                2
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }