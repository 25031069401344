/* eslint-disable */

import api from '@/api';

function getArticlesByGameAndCategory(gameId, categoryId) {
  return api.call(api.GET, `/game/${gameId}/category/${categoryId}/articles`);
}

function getArticleByAgcId(gameId, categoryId, agcId) {
  return api.call(api.GET, `/game/${gameId}/category/${categoryId}/article/${agcId}`);
}

function getArticle(articleId) {
  return api.call(api.GET, `/article/${articleId}`);
}

// function getArticlesByGameAndCategory(gameId, categoryId) {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: [
//         {
//           agcId: 1,
//           title: '這是第一篇文章/' + gameId + '/' + categoryId,
//         },
//         {
//           agcId: 2,
//           title: '這是第二篇文章/' + gameId + '/' + categoryId,
//         },
//       ],
//       error: 'error_require_login',
//     });
//   });
// }
//
// function getArticleByAgcId(gameId, categoryId, agcId) {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: JSON.parse(JSON.stringify({
//         data: {
//           id: 1,
//           title: '這是第一篇文章/' + gameId + '/' + categoryId + '/' + agcId,
//           content: '我好想放假ＲＲＲＲＲＲＲＲ<br>嗚嗚',
//           tags: ['ㄎㄎ', '放假', '日本'],
//           related_articles: [
//             {
//               id: 5,
//               title: '相關文章啦',
//             },
//             {
//               id: 6,
//               title: '相關文章第二篇啦',
//             },
//           ],
//         },
//         error: '',
//       })),
//     });
//   });
// }
//
// function getArticle(id) {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: JSON.parse(JSON.stringify({
//         data: {
//           id: id,
//           title: '這是第一篇相關文章' + id,
//           content: '我好想放假2222<br>嗚嗚',
//           tags: ['兔子', 'YUKI', '可愛動物'],
//           related_articles: [
//             {
//               id: 7,
//               title: '相關文章7',
//             },
//             {
//               id: 8,
//               title: '我覺得填假資料也是滿累的',
//             },
//           ],
//         },
//         error: '',
//       })),
//     });
//   });
// }

export default {
  getArticlesByGameAndCategory,
  getArticleByAgcId,
  getArticle,
};
