<template>
  <div class="main-wrap">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('CANT_LOGIN__ACCOUNT_RECOVERY_TITLE')" :has-search-bar="false" />
      <div class="main-inner">
        <div class="onlineservice">
          <breadcrumb :paths="paths" />
          <div class="main-title">{{ $t('CANT_LOGIN__ITEM_RESETPWD_LINKTEXT') }}</div>
          <div class="onlineservice-main">
            <div class="onlineservice-form">
              <div class="onlineservice-form__tip" v-html="$t('CANT_LOGIN__ACCOUNT_RECOVERY_REMINDER')"></div>
              <div class="onlineservice-form__row">
                <div class="onlineservice-form__inline">
                  <div class="onlineservice-form__label">
                    {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_ACCOUNT') }}
                  </div>
                  <div class="onlineservice-form__field">
                    <custom-input
                      name="accountName"
                      :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH')"
                      :custom-type="constants.GENERAL__INPUT_TYPES.GARENA_ACCOUNT"
                      :error-msg="$t('GENERAL__INPUT_ERROR_ACCOUNT_FORMAT')"
                      :need-validation="true"
                      :validation.sync="fieldsValidation.accountName"
                      v-model.trim="fieldsValue.accountName"
                    />
                  </div>
                </div>
              </div>
              <div class="onlineservice-form__row">
                <div class="onlineservice-form__inline">
                  <div class="onlineservice-form__label">
                    {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_REGISTER_EMAIL') }}
                  </div>
                  <div class="onlineservice-form__field">
                    <custom-input
                      name="registrationEmail"
                      type="email"
                      :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH')"
                      :custom-type="constants.GENERAL__INPUT_TYPES.EMAIL"
                      :error-msg="$t('GENERAL__INPUT_ERROR_EMAIL_FORMAT')"
                      :need-validation="true"
                      :validation.sync="fieldsValidation.registrationEmail"
                      v-model="fieldsValue.registrationEmail"
                    />
                  </div>
                </div>
              </div>
              <div class="onlineservice-form__row">
                <div class="onlineservice-form__inline">
                  <div class="onlineservice-form__label">
                    {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_PHONE') }}
                  </div>
                  <div class="onlineservice-form__field">
                    <div class="onlineservice-form__field-ssection">
                      <phone-input
                        name="notification_info"
                        :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH_INT')"
                        :error-msg="$t('GENERAL__INPUT_ERROR_PHONE_FORMAT')"
                        v-model="contactNumberObj"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="onlineservice-form__row">
                <div class="onlineservice-form__inline">
                  <div class="onlineservice-form__label onlineservice-form__label--t0">
                    {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_CONTACT_EMAIL') }}
                  </div>
                  <div class="onlineservice-form__field">
                    <div class="onlineservice-form__field-ssection">
                      <custom-checkbox
                        name="contactEmail_checkbox"
                        :label="$t('CANT_LOGIN__ACCOUNT_RECOVERY_SAME_EMAIL')"
                        :is-disabled="!canSwitchContactEmailSame"
                        v-model="isContactEmailSame"
                      />
                      <div v-if="!canSwitchContactEmailSame" class="report-form__error">
                        {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_SAME_EMAIL_TIP') }}
                      </div>
                    </div>
                    <custom-input
                      name="contactEmail"
                      type="email"
                      :custom-type="constants.GENERAL__INPUT_TYPES.EMAIL"
                      :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH')"
                      :error-msg="$t('GENERAL__INPUT_ERROR_EMAIL_FORMAT')"
                      :note-msg="$t('CANT_LOGIN__ACCOUNT_RECOVERY_CONTACT_EMAIL_TIP')"
                      :is-disabled="isContactEmailSame"
                      :need-validation="true"
                      :validation.sync="fieldsValidation.contactEmail"
                      v-model="fieldsValue.contactEmail"
                    />
                  </div>
                </div>
              </div>
              <div class="onlineservice-form__row">
                <div class="onlineservice-form__inline">
                  <div class="onlineservice-form__label onlineservice-form__label--t0">
                    {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_PROFILE_STATUS') }}
                  </div>
                  <div class="onlineservice-form__field">
                    <div class="onlineservice-form__field-ssection">
                      <custom-radio
                        name="isUserInfoComplete"
                        :label="$t('CANT_LOGIN__ACCOUNT_RECOVERY_PROFILE_WITH_FULL_DATA')"
                        :radio-value="constants.APPLICATION__ACCOUNT_RECOVERY_USER_INFO_STATUS.COMPLETE"
                        v-model="fieldsValue.isUserInfoComplete"
                      />
                    </div>
                    <div class="onlineservice-form__field-ssection">
                      <custom-radio
                        name="isUserInfoComplete"
                        :label="$t('CANT_LOGIN__ACCOUNT_RECOVERY_PROFILE_WITHOUT_FULL_DATA')"
                        :radio-value="constants.APPLICATION__ACCOUNT_RECOVERY_USER_INFO_STATUS.NOT_COMPLETE"
                        v-model="fieldsValue.isUserInfoComplete"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- 未填寫完整資料，或不確定是否有填寫 -->
              <template v-if="!fieldsValue.isUserInfoComplete && fieldsValue.isUserInfoComplete !== null">
                <div class="onlineservice-form__row">
                  <div class="onlineservice-form__inline">
                    <div class="onlineservice-form__label onlineservice-form__label--t0">
                      {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP') }}
                    </div>
                    <div class="onlineservice-form__field">
                      <div class="onlineservice-form__field-ssection">
                        <custom-radio
                          name="topup_status"
                          :label="$t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_NO')"
                          :radio-value="constants.APPLICATION__ACCOUNT_RECOVERY_USER_TOPUP_STATUS.NO"
                          v-model="fieldsValue.hasTopUpHistory"
                        />
                      </div>
                      <div class="onlineservice-form__field-ssection">
                        <custom-radio
                          name="topup_status"
                          :label="$t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_YES')"
                          :radio-value="constants.APPLICATION__ACCOUNT_RECOVERY_USER_TOPUP_STATUS.YES"
                          v-model="fieldsValue.hasTopUpHistory"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 有儲值紀錄 -->
                <template v-if="fieldsValue.hasTopUpHistory">
                  <div class="onlineservice-form__row">
                    <div class="onlineservice-form__inline">
                      <div class="onlineservice-form__label">
                        {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_LAST_TOPUP_TIME') }}
                      </div>
                      <div class="onlineservice-form__field">
                        <custom-datetime-picker
                          v-model="fieldsValue.lastTopUpTime"
                          type="datetime"
                          :is-required="true"
                          :is-valid-if-null="false"
                          :format="$t('GENERAL__DATETIME_FORMAT')"
                          :not-after="new Date()"
                        >
                          <div slot="calendar-icon" class="profile-form__inputdateicon"></div>
                        </custom-datetime-picker>
                      </div>
                    </div>
                  </div>
                  <div class="onlineservice-form__row">
                    <div class="onlineservice-form__inline">
                      <div class="onlineservice-form__label">
                        {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_AMOUNT') }}
                      </div>
                      <div class="onlineservice-form__field">
                        <div class="onlineservice-form__field-price">
                          <custom-input
                            name="lastTopUpAmount"
                            type="number"
                            :placeholder="$t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_AMOUNT_PLACEHOLDER')"
                            :is-required="true"
                            :is-valid-if-null="false"
                            v-model="fieldsValue.lastTopUpAmount"
                            :children="
                              '<div class=\'onlineservice-form__field-priceunit\'>' +
                              $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_AMOUNT_UNIT') +
                              '</div>'
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="onlineservice-form__row">
                    <div class="onlineservice-form__inline">
                      <div class="onlineservice-form__label">
                        {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_CHANNEL') }}
                      </div>
                      <div class="onlineservice-form__field">
                        <custom-input
                          name="lastTopUpChannel"
                          :is-required="true"
                          :is-valid-if-null="false"
                          v-model="fieldsValue.lastTopUpChannel"
                        />
                      </div>
                    </div>
                  </div>
                </template>
                <!-- 有儲值紀錄/ -->

                <div class="onlineservice-form__row">
                  <div class="onlineservice-form__inline">
                    <div class="onlineservice-form__label">
                      {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_ACCOUNT_CREATED_TIME') }}
                    </div>
                    <div class="onlineservice-form__field">
                      <custom-input
                        name="accountCreateYear"
                        :placeholder="$t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_ACCOUNT_CREATED_TIME_PLACEHOLDER')"
                        :is-required="true"
                        :is-valid-if-null="false"
                        v-model="fieldsValue.accountCreateYear"
                      />
                    </div>
                  </div>
                </div>
                <div class="onlineservice-form__row">
                  <div class="onlineservice-form__inline">
                    <div class="onlineservice-form__label">
                      {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_LAST_LOGIN_TIME') }}
                    </div>
                    <div class="onlineservice-form__field">
                      <custom-datetime-picker
                        v-model="fieldsValue.lastOnlineTime"
                        type="datetime"
                        :is-required="true"
                        :is-valid-if-null="false"
                        :format="$t('GENERAL__DATETIME_FORMAT')"
                        :not-after="new Date()"
                      >
                        <div slot="calendar-icon" class="profile-form__inputdateicon"></div>
                      </custom-datetime-picker>
                    </div>
                  </div>
                </div>
                <div class="onlineservice-form__row">
                  <div class="onlineservice-form__inline">
                    <div class="onlineservice-form__label">
                      {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_MAIN_GAME') }}
                    </div>
                    <div class="onlineservice-form__field">
                      <custom-select
                        :hasPlaceholder="true"
                        :placeholder="$t('GENERAL__PLACEHOLDER_DEFAULT_SELECT_GAME')"
                        :options="gamesIdMapping"
                        :is-required="true"
                        :is-valid-if-null="false"
                        v-model="mainGameId"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="!isPlayerInfoFilled" class="report-form__error">
                  {{ $t('GENERAL__INPUT_ERROR_APPLICATION_PLAYER_INFO_INCOMPLETE') }}
                </div>

                <!-- 主要進行的遊戲更多資訊 -->
                <template v-if="playerInfo && playerInfo.length > 0">
                  <div class="onlineservice-gameinfo">
                    <div class="onlineservice-gameinfo__row" v-for="playerInfoField in playerInfo" :key="playerInfoField.order">
                      <div class="onlineservice-gameinfo__inline">
                        <div class="onlineservice-gameinfo__label">{{ playerInfoField.displayName }}</div>
                        <div class="onlineservice-gameinfo__field">
                          <custom-input
                            :name="'playinfo_' + playerInfoField.order"
                            :placeholder="playerInfoField.placeholder"
                            :is-required="true"
                            :is-valid-if-null="false"
                            v-model="fieldsValue.playerInfo[playerInfoField.key]"
                            @change="$forceUpdate()"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <!-- 主要進行的遊戲更多資訊 /-->
              </template>
              <!-- 未填寫完整資料，或不確定是否有填寫 /-->

              <div class="onlineservice-form__row" v-if="fieldsValue.isUserInfoComplete !== null">
                <div class="onlineservice-form__inline">
                  <div class="onlineservice-form__label">{{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD') }}</div>
                  <div class="onlineservice-form__field">
                    <uploader
                      @uploading="updateUploadingFiles"
                      @uploaded="addUploadedFileIds"
                      @removed="removeUploadedFileIds"
                      :label="$t('GENERAL__BUTTON_UPLOAD_ID')"
                      :is-anonymous="true"
                      :show-notice="false"
                      :is-required="true"
                      :is-img-only="true"
                      :max-file-cnt="constants.GENERAL__MAX_FILE_CNT.APPLICATION"
                      :error-msg="$t('GENERAL__INPUT_ERROR_FILE_NOT_SELECTED')"
                      name="files"
                    />
                    <ol
                      v-if="fieldsValue.isUserInfoComplete"
                      class="onlineservice-form__uploader-info"
                      v-html="$t('CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD_NOTICE_1')"
                    ></ol>
                    <ol v-else class="onlineservice-form__uploader-info" v-html="$t('CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD_NOTICE_2')"></ol>
                  </div>
                </div>
              </div>
              <div class="onlineservice-form__divider"></div>
              <div class="onlineservice-form__row">
                <div class="onlineservice-form__inline">
                  <div class="onlineservice-form__label"></div>
                  <div class="onlineservice-form__field">
                    <div class="onlineservice-form__field-notewrap">
                      <div>{{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_NOTICE') }}</div>
                      <ol class="onlineservice-form__field-note">
                        <li class="onlineservice-form__field-noteitem">
                          {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_NOTICE_NOTICE_1') }}
                        </li>
                        <li class="onlineservice-form__field-noteitem">
                          {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_NOTICE_NOTICE_2') }}
                        </li>
                      </ol>
                    </div>
                    <div class="onlineservice-form__field-cks">
                      <custom-checkbox
                        name="confirm_checkbox"
                        :label="$t('CANT_LOGIN__ACCOUNT_RECOVERY_CONFIRM')"
                        v-model="isConfirmChecked"
                      />
                    </div>
                    <div class="onlineservice-form__field-cks">
                      <custom-checkbox name="tos_checkbox" :label="$t('CANT_LOGIN__ACCOUNT_RECOVERY_CHECKBOX')" v-model="isTosChecked" />
                    </div>
                    <div class="onlineservice-form__next">
                      <div class="rountbutton">
                        <button class="rountbutton__widget" :disabled="!isButtonEnabled" @click.prevent="submitAccountRecoveryApplication">
                          {{ $t('GENERAL__BUTTON_SUBMIT_FORM') }}
                          <vue-countdown v-if="isSubmitting" :time="10000" @end="handleCountdownEnd">
                            <template slot-scope="props">({{ props.totalSeconds }})</template>
                          </vue-countdown>
                        </button>
                        <div v-if="isTosChecked && !isButtonEnabled && !isSubmitting" class="report-form__error">
                          {{ $t('TICKET__SUBMIT_NOTICE') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import VueCountdown from '@chenfengyuan/vue-countdown';
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';
import Navigation from '@/components/pc/layouts/Navigation';
import CustomCheckbox from '@/components/pc/layouts/partial/CustomCheckbox';
import CustomDatetimePicker from '@/components/share/layouts/partial/CustomDatetimePicker';
import CustomInput from '@/components/share/layouts/partial/CustomInput';
import CustomRadio from '@/components/pc/layouts/partial/CustomRadio';
import CustomSelect from '@/components/share/layouts/partial/CustomSelect';
import Uploader from '@/components/share/layouts/partial/Uploader';
import PhoneInput from '@/components/share/layouts/partial/PhoneInput';
import application from '@/api/prod/application';
import constants from '@/lib/constants';
import utils from '@/lib/utils';
import { ACTION_TRIGGER_POPUP, ACTION_GET_ACCOUNT_RECOVERY_PLAYER_INFO_FIELDS } from '@/store/action-types';
import { API_RETURN_CODE_APPLIED_TOO_MANY_TIMES } from '@/lib/return-codes';

export default {
  name: 'AccountRecovery',
  components: {
    VueCountdown,
    Navigation,
    Breadcrumb,
    CustomCheckbox,
    CustomDatetimePicker,
    CustomInput,
    CustomRadio,
    CustomSelect,
    Uploader,
    PhoneInput,
  },
  data() {
    return {
      constants,
      paths: [
        {
          id: 1,
          name: 'CantLogin',
          title: this.$t('HOME__CANT_LOGIN_TITLE'),
        },
        {
          id: 2,
          name: '',
          title: this.$t('CANT_LOGIN__ITEM_RESETPWD_LINKTEXT'),
        },
      ],
      contactNumberObj: null,
      uploadingFiles: {},
      fieldsValue: {
        accountName: null,
        registrationEmail: null,
        contactNumber: null,
        contactNumberCountryCode: null,
        contactEmail: null,
        isUserInfoComplete: null,
        files: [],
        hasTopUpHistory: null,
        lastTopUpTime: null,
        lastTopUpAmount: null,
        lastTopUpChannel: null,
        accountCreateYear: null,
        lastOnlineTime: null,
        mainGameId: null,
        playerInfo: {},
      },
      fieldsValidation: {
        accountName: false,
        registrationEmail: false,
        contactEmail: false,
      },
      mainGameId: null,
      requiredFields: [
        'accountName',
        'registrationEmail',
        'contactNumber',
        'contactNumberCountryCode',
        'contactEmail',
        'isUserInfoComplete',
        'files',
      ],
      playerInfo: [],
      isContactEmailSame: false,
      isConfirmChecked: false,
      isTosChecked: false,
      isSubmitting: false,
    };
  },
  computed: {
    canSwitchContactEmailSame() {
      return this.fieldsValue.registrationEmail && utils.isValidEmail(this.fieldsValue.registrationEmail);
    },
    isButtonEnabled() {
      return (
        this.isConfirmChecked &&
        this.isTosChecked &&
        this.isFormFilled &&
        this.isPlayerInfoFilled &&
        !this.isUploading &&
        !this.isSubmitting
      );
    },
    isUploading() {
      return !utils.isEmptyObject(this.uploadingFiles) && Object.values(this.uploadingFiles).some((file) => !file.uploadCompleted);
    },
    isFormFilled() {
      // check all required fields are filled
      return (
        this.requiredFields.every(
          (field) =>
            (this.fieldsValue[field] && this.fieldsValue[field].length > 0) ||
            typeof this.fieldsValue[field] === 'number' ||
            typeof this.fieldsValue[field] === 'boolean',
        ) && Object.values(this.fieldsValidation).every((field) => field)
      );
    },
    isPlayerInfoFilled() {
      return Object.values(this.fieldsValue.playerInfo).every((value) => value && value.length > 0);
    },
    ...mapState({
      games: (state) => state.game.games,
      allPlayerInfoFields: (state) => state.application.accountRecoveryPlayerInfoFields,
    }),
    ...mapGetters(['gamesIdMapping']),
  },
  methods: {
    updateUploadingFiles(files) {
      this.uploadingFiles = files;
    },
    addUploadedFileIds(name, fileId) {
      if (!this.fieldsValue[name]) {
        this.fieldsValue[name] = [];
      }
      this.fieldsValue[name].push(fileId);
    },
    removeUploadedFileIds(name, fileId) {
      const idx = this.fieldsValue[name].indexOf(fileId);
      this.fieldsValue[name].splice(idx, 1);
    },
    handleCountdownEnd() {
      this.isSubmitting = false;
    },
    submitAccountRecoveryApplication() {
      this.isSubmitting = true;
      const payload = utils.objBoolean2Int(this.fieldsValue);
      try {
        application.createApplication('account-recovery', payload).then((data) => {
          if (data.error) {
            if (data.error === API_RETURN_CODE_APPLIED_TOO_MANY_TIMES) {
              this.triggerPopup({
                title: this.$t('POPUP__ERROR'),
                content: this.$t('POPUP__ERROR_APPLICATION_APPLIED_TOO_MANY_TIMES'),
              });
            } else {
              this.triggerPopup({
                title: this.$t('POPUP__ERROR'),
                content: this.$t('POPUP__ERROR_APPLICATION_CREATE_FAILED'),
              });
            }
          } else {
            this.triggerPopup({
              title: this.$t('POPUP__ALERT'),
              content: this.$t('POPUP__ALERT_APPLICATION_CREATE_SUCCEED_CONTENT'),
            });
            this.$router.push({ name: 'Home' });
            window.scrollTo(0, 0);
          }
        });
      } catch {
        this.triggerPopup({
          title: this.$t('POPUP__ERROR'),
          content: this.$t('POPUP__ERROR_EXCEPTION'),
        });
      }
    },
    ...mapActions({
      triggerPopup: ACTION_TRIGGER_POPUP,
      getAccountRecoveryPlayerInfoFields: ACTION_GET_ACCOUNT_RECOVERY_PLAYER_INFO_FIELDS,
    }),
  },
  watch: {
    contactNumberObj(newValue) {
      if (newValue) {
        this.fieldsValue.contactNumberCountryCode = newValue.countryCode;
        this.fieldsValue.contactNumber = newValue.number;
      } else {
        this.fieldsValue.contactNumberCountryCode = null;
        this.fieldsValue.contactNumber = null;
      }
    },
    isContactEmailSame(newValue) {
      this.fieldsValue.contactEmail = newValue ? this.fieldsValue.registrationEmail : null;
    },
    'fieldsValue.registrationEmail': {
      handler(newValue) {
        if (this.isContactEmailSame) {
          this.fieldsValue.contactEmail = newValue;
        }
      },
    },
    'fieldsValue.isUserInfoComplete': {
      handler(newValue) {
        const addiRequiredFields = ['hasTopUpHistory', 'accountCreateYear', 'lastOnlineTime', 'mainGameId'];
        this.fieldsValue.isUserInfoComplete = newValue;
        if (newValue) {
          addiRequiredFields.forEach((value) => {
            const idx = this.requiredFields.indexOf(value);
            if (idx !== -1) {
              this.requiredFields.splice(idx, 1);
            }
          });
        } else {
          this.requiredFields = this.requiredFields.concat(addiRequiredFields);
        }
        this.fieldsValue.hasTopUpHistory = 0;
        if (this.mainGameId) {
          this.fieldsValue.mainGameId = this.mainGameId;
        }
      },
    },
    'fieldsValue.hasTopUpHistory': {
      handler(newValue) {
        const addiRequiredFields = ['lastTopUpTime', 'lastTopUpAmount', 'lastTopUpChannel'];
        this.fieldsValue.hasTopUpHistory = newValue;
        if (newValue) {
          this.requiredFields = this.requiredFields.concat(addiRequiredFields);
        } else {
          addiRequiredFields.forEach((value) => {
            const idx = this.requiredFields.indexOf(value);
            if (idx !== -1) {
              this.requiredFields.splice(idx, 1);
            }
          });
        }
      },
    },
    mainGameId(newValue) {
      this.playerInfo = this.allPlayerInfoFields && this.allPlayerInfoFields[newValue];
      this.fieldsValue.mainGameId = newValue;
      this.fieldsValue.playerInfo = this.playerInfo.reduce((acc, val) => {
        acc[val.key] = null;
        return acc;
      }, {});
    },
  },
  async mounted() {
    await this.getAccountRecoveryPlayerInfoFields();
  },
};
</script>

<style lang="scss" scoped>
.onlineservice-form__field-ssection {
  .checkbox-wrap {
    display: inline-flex;
  }
  .report-form__error {
    display: inline-flex;
  }
}
</style>
