import Vue from 'vue';
import Router from 'vue-router';
import { isMobile } from 'mobile-device-detect';
import PcMaster from '@/components/pc/Master';
import MobileMaster from '@/components/mobile/Master';
import Home from '@/components/pc/pages/Home';
import Search from '@/components/pc/pages/Search';
import CantLogin from '@/components/pc/pages/CantLogin';
import Verification from '@/components/pc/pages/Verification';
import ArticleList from '@/components/pc/pages/ArticleList';
import Article from '@/components/pc/pages/Article';
import ArticleGeneral from '@/components/pc/pages/ArticleGeneral';
import NewsList from '@/components/pc/pages/NewsList';
import News from '@/components/pc/pages/News';
import Ticket from '@/components/pc/pages/Ticket';
import Profile from '@/components/pc/pages/Profile';
import Report from '@/components/pc/pages/Report';
import ReportLink from '@/components/pc/pages/ReportLink';
import ReportList from '@/components/pc/pages/ReportList';
import ResetProfile from '@/components/pc/pages/ResetProfile';
import ResetProfileCompletion from '@/components/pc/pages/ResetProfileCompletion';
import AccountRecovery from '@/components/pc/pages/AccountRecovery';
import ApplicationList from '@/components/pc/pages/ApplicationList';
import Login from '@/components/pc/pages/Login';
import PageNotFound from '@/components/pc/pages/PageNotFound';
import MobileHome from '@/components/mobile/pages/Home';
import MobileSearch from '@/components/mobile/pages/Search';
import MobileCantLogin from '@/components/mobile/pages/CantLogin';
import MobileVerification from '@/components/mobile/pages/Verification';
import MobileArticle from '@/components/mobile/pages/Article';
import MobileArticleGeneral from '@/components/mobile/pages/ArticleGeneral';
import MobileArticleList from '@/components/mobile/pages/ArticleList';
import MobileNewsList from '@/components/mobile/pages/NewsList';
import MobileNews from '@/components/mobile/pages/News';
import MobileGameList from '@/components/mobile/pages/GameList';
import MobileBaseCategoryList from '@/components/mobile/pages/BaseCategoryList';
import MobileTicket from '@/components/mobile/pages/Ticket';
import MobileProfile from '@/components/mobile/pages/Profile';
import MobileReport from '@/components/mobile/pages/Report';
import MobileReportLink from '@/components/mobile/pages/ReportLink';
import MobileReportList from '@/components/mobile/pages/ReportList';
import MobileResetProfile from '@/components/mobile/pages/ResetProfile';
import MobileResetProfileCompletion from '@/components/mobile/pages/ResetProfileCompletion';
import MobileAccountRecovery from '@/components/mobile/pages/AccountRecovery';
import MobileApplicationList from '@/components/mobile/pages/ApplicationList';
import MobileLogin from '@/components/mobile/pages/Login';
import MobilePageNotFound from '@/components/mobile/pages/PageNotFound';
import store from '@/store';
import utils from '@/lib/utils';
import { ACTION_LOGIN, ACTION_GET_USER_INFO, ACTION_SWITCH_LOGIN_POPUP } from '@/store/action-types';

Vue.use(Router);

const routes = [
  {
    path: '/m',
    component: MobileMaster,
    children: [
      {
        path: '/',
        name: 'MobileHome',
        component: MobileHome,
        meta: {
          grayBackground: true,
        },
      },
      {
        path: 'search',
        name: 'MobileSearch',
        component: MobileSearch,
        meta: {
          grayBackground: true,
        },
      },
      {
        path: 'cant-login',
        name: 'MobileCantLogin',
        component: MobileCantLogin,
        meta: {
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'cant-login/account-recovery',
        name: 'MobileAccountRecovery',
        component: MobileAccountRecovery,
        meta: {
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'verification',
        name: 'MobileVerification',
        component: MobileVerification,
        meta: {
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'verification/reset-profile',
        name: 'MobileResetProfile',
        component: MobileResetProfile,
        meta: {
          requireLogin: true,
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'verification/reset-profile/completion',
        name: 'MobileResetProfileCompletion',
        component: MobileResetProfileCompletion,
        meta: {
          requireLogin: true,
          checkReferer: 'MobileResetProfile',
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'applications',
        name: 'MobileApplicationList',
        component: MobileApplicationList,
        meta: {
          requireLogin: true,
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'games',
        name: 'MobileGameList',
        component: MobileGameList,
      },
      {
        path: 'article/:id(\\d+)',
        name: 'MobileArticleGeneral',
        component: MobileArticleGeneral,
      },
      {
        path: 'article/:gameCode',
        name: 'MobileBaseCategoryList',
        component: MobileBaseCategoryList,
      },
      {
        path: 'article/:gameCode/:baseCategory',
        name: 'MobileArticleList',
        component: MobileArticleList,
      },
      {
        path: 'article/:gameCode/:baseCategory/:agcId(\\d+)',
        name: 'MobileArticle',
        component: MobileArticle,
      },
      {
        path: 'news',
        name: 'MobileNewsList',
        component: MobileNewsList,
        meta: {
          bodyClass: 'scroll-wrap',
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'news/:id(\\d+)',
        name: 'MobileNews',
        component: MobileNews,
        meta: {
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'reports',
        name: 'MobileReportList',
        component: MobileReportList,
        meta: {
          grayBackground: true,
          requireLogin: true,
          bodyClass: 'scroll-wrap',
        },
      },
      {
        path: 'report/link',
        name: 'MobileReportLink',
        component: MobileReportLink,
        meta: {
          requireLogin: true,
        },
      },
      {
        path: 'report/:csNumber',
        name: 'MobileReport',
        component: MobileReport,
        meta: {
          requireLogin: true,
        },
      },
      {
        path: 'ticket/:gameCode',
        name: 'MobileTicket',
        component: MobileTicket,
        meta: {
          requireLogin: true,
        },
      },
      {
        path: 'profile',
        name: 'MobileProfile',
        component: MobileProfile,
        meta: {
          requireLogin: true,
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'login',
        name: 'MobileLogin',
        component: MobileLogin,
      },
      {
        path: 'not-found',
        name: 'MobileNotFound',
        component: MobilePageNotFound,
      },
      {
        path: '*',
        name: 'MobilePageNotFound',
        component: MobilePageNotFound,
      },
    ],
  },
  {
    path: '/',
    component: PcMaster,
    meta: {
      detectMobile: true,
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: 'search',
        name: 'Search',
        component: Search,
        meta: {
          redirectMobile: {
            name: 'MobileSearch',
          },
        },
      },
      {
        path: 'cant-login',
        name: 'CantLogin',
        component: CantLogin,
        meta: {
          redirectMobile: {
            name: 'MobileCantLogin',
          },
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'cant-login/account-recovery',
        name: 'AccountRecovery',
        component: AccountRecovery,
        meta: {
          redirectMobile: {
            name: 'MobileAccountRecovery',
          },
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'verification',
        name: 'Verification',
        component: Verification,
        meta: {
          redirectMobile: {
            name: 'MobileVerification',
          },
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'verification/reset-profile',
        name: 'ResetProfile',
        component: ResetProfile,
        meta: {
          requireLogin: true,
          redirectMobile: {
            name: 'MobileResetProfile',
          },
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'verification/reset-profile/completion',
        name: 'ResetProfileCompletion',
        component: ResetProfileCompletion,
        meta: {
          requireLogin: true,
          checkReferer: 'ResetProfile',
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'applications',
        name: 'ApplicationList',
        component: ApplicationList,
        meta: {
          requireLogin: true,
          redirectMobile: {
            name: 'MobileApplicationList',
          },
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'article/:id(\\d+)',
        name: 'ArticleGeneral',
        component: ArticleGeneral,
        meta: {
          redirectMobile: {
            name: 'MobileArticleGeneral',
          },
        },
      },
      {
        path: 'article/:gameCode/:baseCategory',
        name: 'ArticleList',
        component: ArticleList,
        meta: {
          redirectMobile: {
            name: 'MobileArticleList',
          },
        },
      },
      {
        path: 'article/:gameCode/:baseCategory/:agcId(\\d+)',
        name: 'Article',
        component: Article,
        meta: {
          redirectMobile: {
            name: 'MobileArticle',
          },
        },
      },
      {
        path: 'news',
        name: 'NewsList',
        component: NewsList,
        meta: {
          redirectMobile: {
            name: 'MobileNewsList',
          },
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'news/:id(\\d+)',
        name: 'News',
        component: News,
        meta: {
          redirectMobile: {
            name: 'MobileNews',
            displayedRegions: ['tw'],
          },
        },
      },
      {
        path: 'reports',
        name: 'ReportList',
        component: ReportList,
        meta: {
          requireLogin: true,
          redirectMobile: {
            name: 'MobileReportList',
          },
        },
      },
      {
        path: 'report/link',
        name: 'ReportLink',
        component: ReportLink,
        meta: {
          requireLogin: true,
          redirectMobile: {
            name: 'MobileReportLink',
          },
        },
      },
      {
        path: 'report/:csNumber',
        name: 'Report',
        component: Report,
        meta: {
          requireLogin: true,
          redirectMobile: {
            name: 'MobileReport',
          },
        },
      },
      {
        path: 'ticket/:gameCode',
        name: 'Ticket',
        component: Ticket,
        meta: {
          requireLogin: true,
          redirectMobile: {
            name: 'MobileTicket',
          },
        },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
        meta: {
          requireLogin: true,
          redirectMobile: {
            name: 'MobileProfile',
          },
          displayedRegions: ['tw'],
        },
      },
      {
        path: 'not-found',
        name: 'NotFound',
        component: PageNotFound,
        meta: {
          redirectMobile: {
            name: 'MobileNotFound',
          },
        },
      },
      {
        path: 'login',
        name: 'Login',
        component: Login,
        meta: {
          redirectMobile: {
            name: 'MobileLogin',
          },
        },
      },
      {
        path: '*',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: {
          redirectMobile: {
            name: 'MobilePageNotFound',
          },
        },
      },
    ],
  },
];

const router = new Router({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  const accessToken = to.query.access_token;
  const { query } = to;

  // redirect to page-not-found if this route is only for specific region(s)
  if (to.meta.displayedRegions && !to.meta.displayedRegions.includes(process.env.REGION)) {
    next({ name: 'NotFound' });
    return;
  }

  // SMP only!! redirect the following pages to external link:
  // 1. PC ArticleList
  // 2. PC Ticket
  // 3. Mobile BaseCategoryList
  // 4. Mobile ArticleList
  // 5. Mobile Ticket
  if (['sg', 'my', 'ph'].includes(process.env.REGION)) {
    if (['ArticleList', 'Ticket', 'MobileBaseCategoryList', 'MobileArticleList', 'MobileTicket'].includes(to.name)) {
      if (to.params.gameCode === 'ff') {
        window.location.href = 'https://ffsupport.garena.com/';
        return;
      }
      if (to.params.gameCode === 'ud') {
        window.location.href = 'https://undawnsupportsmp.garena.com/';
        return;
      }
      if (to.params.gameCode === 'codm') {
        window.location.href = 'https://codmsmpsupport.zendesk.com/';
        return;
      }
    }
  }

  // redirect to mobile page if user uses mobile device
  if (to.matched.some((route) => route.meta.detectMobile)) {
    if (isMobile && !accessToken) {
      if (to.meta.redirectMobile) {
        next({
          ...to.meta.redirectMobile,
          params: to.params,
          query: to.query,
        });
        return;
      }
      next('/m');
      return;
    }
  }

  if (to.meta.checkReferer && to.meta.checkReferer !== from.name) {
    next({ name: 'ResetProfile' });
    return;
  }

  // if access_token in query string...
  // 1. call api to login
  // 2. remove access_token from url
  // 3. redirect to previous page, which is set in cookie, or the page to auto-login
  if (accessToken) {
    await store.dispatch(ACTION_LOGIN, accessToken);
    delete query.access_token;
    await router.replace(
      { query },
      () => {},
      () => {},
    );
    const redirectUrl = utils.getCookie('gopredirect');
    if (redirectUrl) {
      utils.delCookie('gopredirect');
      await router.replace(
        { path: redirectUrl },
        () => {},
        () => {},
      );
    } else if (to.name !== 'Home') {
      await router.replace(
        {
          path: to.path,
          query,
        },
        () => {},
        () => {},
      );
    }
    next();
    return;
  }

  // if already logged in, do not call api
  if (store.getters.hasLoggedIn) {
    next();
    return;
  }

  // check whether user has logged in
  if (!store.getters.hasLoggedIn) {
    await store.dispatch(ACTION_GET_USER_INFO);
  }
  if (to.meta.requireLogin && !store.getters.hasLoggedIn) {
    const queryString = Object.keys(to.query)
      .map((key) => (['bc', 'ttc', 'token'].includes(key) ? `${key}=${to.query[key]}` : ''))
      .filter((el) => el)
      .join('&');
    utils.setCookie('gopredirect', `${to.path}?${queryString}`, 60);
    if (!from.name) {
      await router.replace(
        { name: 'Login' },
        () => {},
        () => {},
      );
    } else {
      await store.dispatch(ACTION_SWITCH_LOGIN_POPUP, { status: true });
    }
    return;
  }

  next();
});

router.afterEach((to) => {
  document.body.classList.add(isMobile ? 'mobile' : 'pc');
  if (to.meta.grayBackground) {
    document.body.className = 'gray-background';
  } else {
    document.body.className = 'white-background';
  }
  if (to.meta.bodyClass) {
    document.body.classList.add(to.meta.bodyClass);
  }
});

export default router;
