var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "mobile-topbar" } },
    [
      _c(
        "div",
        { staticClass: "mobile-topbar-header" },
        [
          _c("router-link", {
            staticClass: "mobile-topbar-logo",
            attrs: { to: { name: "MobileHome" } },
            nativeOn: {
              click: function($event) {
                return _vm.switchProfileMenu(true)
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "mobile-topbar-menu" }),
          _vm._v(" "),
          _c("div", { staticClass: "mobile-topbar-title" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mobile-topbar-user-menu",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.switchProfileMenu()
                }
              }
            },
            [
              _vm.hasLoggedIn
                ? [
                    _c("div", { staticClass: "mobile-topbar-user-avatar" }, [
                      _c("img", {
                        staticClass: "mobile-topbar-user-avatar-img",
                        attrs: {
                          alt: "",
                          src:
                            _vm.CDN +
                            "/assets/share/img/icon-circle-platform-" +
                            _vm.userInfo.platform +
                            ".png"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mobile-topbar-user-name" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.userInfo.name) +
                          "\n        "
                      )
                    ])
                  ]
                : [
                    _c("div", { staticClass: "mobile-topbar-user-avatar" }, [
                      _c("img", {
                        staticClass: "mobile-topbar-user-avatar-img",
                        attrs: {
                          alt: "",
                          src:
                            _vm.CDN +
                            "/assets/share/img/icon-notloggedin@2x.jpg"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mobile-topbar-user-name" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.switchLoginPopup(true)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("GENERAL__LOGIN")))]
                      )
                    ])
                  ]
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "mobile-topbar-profile-dropdown" } },
        [
          _vm.isProfileMenuOpen
            ? [
                _c("div", { staticClass: "mobile-topbar-profile" }, [
                  _c("section", { staticClass: "profile-dropdown" }, [
                    _c("ul", { staticClass: "profile__category" }, [
                      _vm.isTwVersion
                        ? _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "profile__category-link",
                                  attrs: { to: { name: "MobileProfile" } },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.switchProfileMenu()
                                    }
                                  }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "profile__category-icon profile__category-icon--user"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "h2",
                                    { staticClass: "profile__category-txt" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("HOME__USER_ACCOUNT_INFO")
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "cproblem__category-blank"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "cproblem__category-arrow" },
                                    [
                                      _c("div", {
                                        staticClass:
                                          "cproblem__category-arrow--icon"
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isGarenaUser
                        ? _c("li", [
                            _c(
                              "a",
                              {
                                staticClass: "profile__category-link",
                                attrs: {
                                  href: "https://account.garena.com/",
                                  target: "_blank"
                                }
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "profile__category-icon profile__category-icon--account2"
                                }),
                                _vm._v(" "),
                                _c(
                                  "h2",
                                  { staticClass: "profile__category-txt" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("HOME__USER_ACCOUNT_CENTER")
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "cproblem__category-blank"
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "cproblem__category-arrow" },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "cproblem__category-arrow--icon"
                                    })
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "profile__category-link",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.toLogout()
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "profile__category-icon profile__category-icon--logout"
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      _vm.CDN +
                                      "/assets/mobile/img/icon/icon-profile-logout.svg",
                                    alt: "",
                                    width: "18px"
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("h2", { staticClass: "profile__category-txt" }, [
                              _vm._v(_vm._s(_vm.$t("GENERAL__LOGOUT")))
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "cproblem__category-blank"
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "cproblem__category-arrow" },
                              [
                                _c("div", {
                                  staticClass: "cproblem__category-arrow--icon"
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "profile__category-dimmer" })
                ])
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }