var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-hd" },
    [
      _c(
        "h1",
        {
          staticClass: "main-hd__title",
          class: { "main-hd__title-common": !_vm.isGamePage }
        },
        [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.hasSearchBar
        ? [
            _c(
              "form",
              {
                staticClass: "main-hd__search",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c("div", {
                  staticClass: "main-hd__search-icon",
                  class: { "main-hd__search-icon--active": _vm.searchString },
                  on: {
                    click: function($event) {
                      return _vm.submit()
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "main-hd__search-input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.searchString,
                        expression: "searchString",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "main-hd__search-widget",
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("SEARCH__BAR_PLACEHOLDER")
                    },
                    domProps: { value: _vm.searchString },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.submit()
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchString = $event.target.value.trim()
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "main-hd__search-filter",
                    on: { click: _vm.switchSearchMenu }
                  },
                  [
                    _c("span", { attrs: { id: "selected-search-cate" } }, [
                      _vm._v(_vm._s(_vm.currentSearchCategory))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "main-hd__search-arrow" })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "main-hd__category",
                    style: {
                      display: _vm.isSearchMenuVisible ? "block" : "none"
                    }
                  },
                  [
                    _c("div", { staticClass: "main-hd__category-arrow" }),
                    _vm._v(" "),
                    _vm.games
                      ? [
                          _c("div", { staticClass: "main-hd__category-main" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.games.pc,
                                    expression: "games.pc"
                                  }
                                ],
                                staticClass: "main-hd__category-list"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "main-hd__category-title" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("SEARCH__BAR_TITLE_PC"))
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "search_bar-sublist",
                                    attrs: { "data-type": "game" }
                                  },
                                  _vm._l(_vm.games.pc, function(game) {
                                    return _c(
                                      "a",
                                      {
                                        key: game.id,
                                        staticClass: "main-hd__category-item",
                                        class: {
                                          "main-hd__category-item--active":
                                            game.code === _vm.currentSearchCode
                                        },
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.changeSelectedCategory(
                                              _vm.constants.SEARCH__SEARCH_TYPES
                                                .PC,
                                              game.code
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "main-hd__category-name"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(game.name) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.games.mobile,
                                    expression: "games.mobile"
                                  }
                                ],
                                staticClass: "main-hd__category-list"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "main-hd__category-title" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t("SEARCH__BAR_TITLE_MOBILE")
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "search_bar-sublist" },
                                  _vm._l(_vm.games.mobile, function(game) {
                                    return _c(
                                      "a",
                                      {
                                        key: game.id,
                                        staticClass: "main-hd__category-item",
                                        class: {
                                          "main-hd__category-item--active":
                                            game.code === _vm.currentSearchCode
                                        },
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.changeSelectedCategory(
                                              _vm.constants.SEARCH__SEARCH_TYPES
                                                .MOBILE,
                                              game.code
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "main-hd__category-name"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(game.name) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "main-hd__category-list" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "main-hd__category-title" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t("SEARCH__BAR_TITLE_OTHERS")
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "search_bar-sublist" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "main-hd__category-item",
                                        class: {
                                          "main-hd__category-item--active":
                                            _vm.currentSearchCode === ""
                                        },
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.changeSelectedCategory(
                                              "",
                                              ""
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "main-hd__category-name"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t("SEARCH__BAR_ALL")
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.baseCategories, function(
                                      category
                                    ) {
                                      return _c(
                                        "a",
                                        {
                                          key: category.id,
                                          staticClass: "main-hd__category-item",
                                          class: {
                                            "main-hd__category-item--active":
                                              category.code ===
                                              _vm.currentSearchCode
                                          },
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.changeSelectedCategory(
                                                _vm.constants
                                                  .SEARCH__SEARCH_TYPES.OTHERS,
                                                category.code
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "main-hd__category-name"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(category.name) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ]
                            )
                          ])
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }