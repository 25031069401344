<template>
  <div class="breadcrumb" :class="{ 'breadcrumb--smpd' : smpd, 'breadcrumb--bd' : hasBorder }">
    <router-link :to="{ name: 'Home' }" class="breadcrumb__item breadcrumb__root">
      <div class="breadcrumb__root-icon"></div>
      <div class="breadcrumb__root-text">{{ $t('GENERAL__TITLE') }}</div>
    </router-link>

    <template v-for="path in paths">
      <div class="breadcrumb__separator" :key="path.id * (-1)"></div>
      <template v-if="path.name.length > 0">
        <router-link :to="{ name: path.name, params: path.params }" class="breadcrumb__item" :key="path.id">
          {{ path.title }}
        </router-link>
      </template>
      <template v-if="path.name.length === 0">
        <a @click.prevent="" class="breadcrumb__item" :key="path.id">
          {{ path.title }}
        </a>
      </template>
    </template>

  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: ['paths', 'smpd', 'hasBorder'],
};
</script>
