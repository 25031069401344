<template>
  <div class="main-wrap main-wrap--hassearch">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('GENERAL__TITLE')" :has-search-bar="true" />
      <div class="main-inner main-inner--nobg">
        <div class="faqdetail">
          <breadcrumb :paths="paths" :has-border="true" />
          <template v-if="article">
            <div class="faqdetail-main">
              <div class="faqdetail__title">
                {{ article.title }}
              </div>
              <div class="faqdetail__content">
                <div class="ip-solution__txt" v-html="article.content"></div>
              </div>
              <!-- Article Tags -->
              <template v-if="!utils.isEmptyArray(article.tags)">
                <div class="faqdetail__aside">
                  <div class="faqdetail__aside-item">
                    {{ $t('ARTICLE__TAGS') }}
                    <a v-for="(tag, idx) in article.tags" :key="idx" @click.prevent="" class="faqdetail__aside-tag">
                      {{ tag }}
                    </a>
                  </div>
                </div>
              </template>
              <!-- Article Tags /-->
              <!-- Related Articles -->
              <template v-if="!utils.isEmptyArray(article.relatedArticles)">
                <div class="faqdetail-related">
                  <div class="faqdetail-related__title">
                    {{ $t('ARTICLE__RELATED_ARTICLE') }}
                  </div>
                  <ul class="faqdetail-related__list">
                    <li
                      v-for="related_article in article.relatedArticles"
                      :key="related_article.id"
                      @click.prevent=""
                      class="faqdetail-related__item"
                    >
                      <router-link :to="{ name: 'ArticleGeneral', params: { id: relatedArticle.id } }" class="faqdetail-related__link">
                        <div class="faqdetail-related__link-title">
                          {{ related_article.title }}
                        </div>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </template>
              <!-- Related Articles /-->
            </div>
          </template>
          <template v-else>
            <div class="loading">
              <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
            </div>
          </template>
        </div>
        <div class="cproblem-entry">
          <a class="cproblem-entry__item rectangle-button" @click.prevent="openGameList">
            <div class="cproblem-entry__item-icon cproblem-entry__item-icon--createreport"></div>
            <div class="cproblem-entry__item-content">
              <div class="cproblem-entry__item-desc">
                {{ $t('ARTICLE__TICKET_SUBTITLE') }}
              </div>
              <div class="cproblem-entry__item-name">
                {{ $t('ARTICLE__TICKET_TITLE') }}
              </div>
              <div class="cproblem-entry__item-desc">
                {{ $t('ARTICLE__TICKET_DESC') }}
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <!--  Popup for choosing games to enter ticket  -->
    <div v-if="isShowingGames && games" class="notification-wrap">
      <div class="notification-dimmer"></div>
      <div class="notification">
        <div class="notification-inner">
          <div class="notification__title">
            <div class="notification__title-icon"></div>
            <div class="notification__title-txt">
              {{ $t('ARTICLE__TICKET_CHOOSE_GAME') }}
            </div>
          </div>
          <div class="notification__main">
            <div class="notification__content">
              <div class="notification__content-txt">
                <div class="home-games">
                  <div class="home-games__inner">
                    <div class="home-section__title">
                      <div class="home-section__title-icon home-section__title-icon--pcgames"></div>
                      <h3 class="home-section__title-txt">
                        {{ $t('HOME__PC_GAME_QUESTION') }}
                      </h3>
                    </div>
                    <ul class="home-games article_general__game">
                      <li class="home-games-game" v-for="game in games.pc" :key="game.id">
                        <router-link :to="{ name: 'Ticket', params: { gameCode: game.code } }" class="cproblem__link">
                          {{ game.name }}
                        </router-link>
                      </li>
                    </ul>
                    <div class="home-section__title">
                      <div class="home-section__title-icon home-section__title-icon--mgames"></div>
                      <h3 class="home-section__title-txt">
                        {{ $t('HOME__MOBILE_GAME_QUESTION') }}
                      </h3>
                    </div>
                    <ul class="home-games article_general__game">
                      <li class="home-games-game" v-for="game in games.mobile" :key="game.id">
                        <router-link :to="{ name: 'Ticket', params: { gameCode: game.code } }" class="cproblem__link">
                          {{ game.name }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a @click.prevent="closeGameList" class="notification__close"></a>
        </div>
      </div>
    </div>
    <!--  Popup for choosing games to enter ticket  /-->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Navigation from '@/components/pc/layouts/Navigation';
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';
import article from '@/api/prod/article';
import utils from '@/lib/utils';

export default {
  name: 'ArticleGeneral',
  components: {
    Breadcrumb,
    Navigation,
  },
  data() {
    return {
      utils,
      article: null,
      isShowingGames: false,
    };
  },
  computed: {
    currentArticleId() {
      return this.$route.params.id;
    },
    paths() {
      return [
        {
          id: 1,
          name: '',
          params: {},
          title: this.$t('HOME__FAQ'),
        },
      ];
    },
    ...mapState({
      games: (state) => state.game.games,
      isAIChatting: (state) => state.common.isAIChatting,
    }),
  },
  methods: {
    openGameList() {
      this.isShowingGames = true;
    },
    closeGameList() {
      this.isShowingGames = false;
    },
  },
  watch: {
    currentArticleId: {
      immediate: true,
      handler(newValue) {
        article.getArticle(newValue).then((resp) => {
          const { data, error } = resp;
          if (error || !data) {
            if (error === 'error_no_matched_data') {
              this.$router.push(
                { name: 'NotFound' },
                () => {},
                () => {},
              );
            } else {
              this.triggerPopup({
                title: this.$t('POPUP__ERROR'),
                content: this.$t('POPUP__ERROR_EXCEPTION'),
              });
            }
          } else {
            this.article = data;
          }
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.pc {
  .loading {
    text-align: center;
  }
  .cproblem-entry__item {
    cursor: pointer;
    .cproblem-entry__item-name {
      margin-top: 8px;
    }
  }
  .home-games {
    display: flex;
    flex-wrap: wrap;
    .home-games__inner {
      width: 100%;
    }
    .home-games-game {
      flex: 0 0 33%;
    }
    .cproblem__link {
      color: $general-dark-text-color;
    }
  }
  .article_general__game {
    margin-bottom: 24px;
  }
  .notification__content-txt {
    font-size: 16px;
  }
}
</style>
