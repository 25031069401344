/* eslint-disable no-shadow */

import { MUTATION_SET_USER_INFO, MUTATION_SET_ERROR, MUTATION_SET_UNREAD_STATS } from '@/store/mutation-types';
import { ACTION_LOGIN, ACTION_LOGOUT, ACTION_GET_USER_INFO, ACTION_GET_UNREAD_STATS } from '@/store/action-types';
import constants from '@/lib/constants';
import user from '@/api/prod/user';
import utils from '@/lib/utils';

// initial state
const state = {
  userInfo: null,
  unreadStats: null,
  globalErrorMsg: undefined,
};

const getters = {
  hasLoggedIn: (state) => Boolean(state.userInfo),
  isFbUser: (state) => state.userInfo && state.userInfo.platform === constants.GENERAL__GOP_PLATFORM.FACEBOOK,
  isGarenaUser: (state) => state.userInfo && state.userInfo.platform === constants.GENERAL__GOP_PLATFORM.GARENA,
};

const actions = {
  async [ACTION_LOGIN]({ commit }, accessToken) {
    try {
      const resp = await user.login(accessToken);
      const { data } = resp;
      commit(MUTATION_SET_USER_INFO, data);
      localStorage.setItem('userToken', accessToken);
    } catch (err) {
      commit(MUTATION_SET_ERROR, { msg: err });
    }
  },
  // 1. load a dummy image to do GOP logout
  // 2. call BE api to clear session
  // 3. reset states
  async [ACTION_LOGOUT]({ commit }) {
    utils.delCookie('sessionid');
    let accessToken = localStorage.getItem('userToken');
    if (accessToken) {
      // use a dummy image to load gop logout page
      // so users won't notice page redirection
      // https://auth.garena.com/oauth/logout?access_token={TOKEN}
      const image = new Image(0, 0);
      image.src = utils.buildLogoutUrl(accessToken);
    }
    try {
      const resp = await user.logout(accessToken);
      const { error } = resp;
      if (error) {
        accessToken = null;
      }
      commit(MUTATION_SET_USER_INFO, null);
      commit(MUTATION_SET_UNREAD_STATS, null);
      localStorage.removeItem('userToken');
    } catch (err) {
      commit(MUTATION_SET_ERROR, { msg: err });
    }
  },
  async [ACTION_GET_USER_INFO]({ commit }) {
    try {
      const resp = await user.getUserInfo();
      const { data } = resp;
      commit(MUTATION_SET_USER_INFO, data);
    } catch (err) {
      commit(MUTATION_SET_ERROR, { msg: err });
    }
  },
  async [ACTION_GET_UNREAD_STATS]({ commit }) {
    try {
      const resp = await user.getUnreadStats();
      const { data } = resp;
      commit(MUTATION_SET_UNREAD_STATS, data);
    } catch (err) {
      commit(MUTATION_SET_ERROR, { msg: err });
    }
  },
};

const mutations = {
  [MUTATION_SET_USER_INFO](state, data) {
    state.userInfo = data;
  },
  [MUTATION_SET_UNREAD_STATS](state, data) {
    state.unreadStats = data ? { ticket: data.ticket, application: data.application } : null;
  },
  [MUTATION_SET_ERROR](state, { msg }) {
    state.globalErrorMsg = msg;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
