<template>
  <div class="main-wrap">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('VERIFICATION__RESET_PROFILE_TITLE')" :has-search-bar="false" />
      <div class="main-inner">
        <div class="profile">
          <breadcrumb :paths="paths" />
          <div class="main-title">
            {{ $t('VERIFICATION__RESET_PROFILE_TITLE') }}
          </div>
          <div class="profile-main">
            <dl class="profile-warning">
              <dt class="profile-warning__title">{{ $t('PROFILE__DESCRIPTION') }}</dt>
              <dd>
                <ol class="profile-warning__list">
                  <li class="profile-warning__item">{{ $t('PROFILE__WARNING_1') }}</li>
                  <li class="profile-warning__item">{{ $t('PROFILE__WARNING_2') }}</li>
                  <li class="profile-warning__item">{{ $t('PROFILE__WARNING_3') }}</li>
                </ol>
              </dd>
            </dl>
            <template v-if="isDataReady">
              <div class="profile-data">
                <dl class="profile-data__row">
                  <!-- @TODO -->
                  <template v-if="false">
                    <dt class="profile-data__title">{{ $t('VERIFICATION__RESET_PROFILE_PERSONAL_DATA') }}</dt>
                    <dd class="profile-data__item">
                      <div class="profile-data__item-name">{{ $t('VERIFICATION__RESET_PROFILE_PERSONAL_DATA_NAME') }}</div>
                      <div class="profile-data__item-value">*********</div>
                    </dd>
                    <dd class="profile-data__item">
                      <div class="profile-data__item-name">{{ $t('VERIFICATION__RESET_PROFILE_PERSONAL_DATA_ID') }}</div>
                      <div class="profile-data__item-value">************</div>
                    </dd>
                    <dd
                      class="profile-data__item profile-data__changeprofile"
                      v-html="$t('VERIFICATION__RESET_PROFILE_PERSONAL_DATA_DESC')"
                    ></dd>
                  </template>
                  <div class="profile-formwrap">
                    <div class="profile-data__title">{{ $t('VERIFICATION__RESET_PROFILE_APPLICATION') }}</div>
                    <form class="profile-form">
                      <div class="profile-form__row">
                        <div class="profile-form__inline">
                          <div class="profile-form__label profile-form__label--t0">
                            {{ $t('VERIFICATION__RESET_PROFILE_APPLICATION_SERVICE') }}
                          </div>
                          <div class="profile-form__field">
                            <template v-if="verificationTypes.includes(constants.APPLICATION__VERIFICATION_TYPES.CANCEL_PHONE)">
                              <div class="profile-form__field-ssection">
                                <custom-checkbox
                                  :name="'verification_service'"
                                  :label="$t('VERIFICATION__RESET_PROFILE_APPLICATION_CANCEL_PHONE')"
                                  v-model="hasCancelPhoneChecked"
                                />
                              </div>
                            </template>
                            <template v-if="verificationTypes.includes(constants.APPLICATION__VERIFICATION_TYPES.CANCEL_EMAIL)">
                              <div class="profile-form__field-ssection">
                                <custom-checkbox
                                  :name="'verification_service'"
                                  :label="$t('VERIFICATION__RESET_PROFILE_APPLICATION_CANCEL_EMAIL')"
                                  v-model="hasCancelEmailChecked"
                                />
                              </div>
                            </template>
                            <template v-if="verificationTypes.includes(constants.APPLICATION__VERIFICATION_TYPES.RESET_PASSWORD)">
                              <div class="profile-form__field-ssection">
                                <custom-checkbox
                                  :name="'verification_service'"
                                  :label="$t('VERIFICATION__RESET_PROFILE_APPLICATION_RESET_PASSWORD')"
                                  v-model="hasResetPasswordChecked"
                                />
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>

                      <!-- 以何種方式通知密碼 -->
                      <template v-if="hasResetPasswordChecked">
                        <div class="profile-form__row">
                          <div class="profile-form__inline">
                            <div class="profile-form__label profile-form__label--t0">
                              {{ $t('VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY') }}
                            </div>
                            <div class="profile-form__field">
                              <div v-if="false" class="profile-form__field-ssection">
                                <custom-radio
                                  name="notification_type"
                                  :label="$t('VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY_SMS')"
                                  :radio-value="constants.APPLICATION__VERIFICATION_NOTIFICATION_TYPES.SMS"
                                  v-model="notificationType"
                                />
                              </div>
                              <div v-if="false" class="profile-form__field-ssection">
                                <custom-radio
                                  name="notification_type"
                                  :label="$t('VERIFICATION__RESET_PROFILE_APPLICATION_NOTIFY_EMAIL')"
                                  :radio-value="constants.APPLICATION__VERIFICATION_NOTIFICATION_TYPES.EMAIL"
                                  v-model="notificationType"
                                />
                              </div>
                              <div class="profile-form__field-ssection">
                                <template v-if="isNotifyByEmail">
                                  <custom-input
                                    name="notification_info"
                                    type="email"
                                    :custom-type="constants.GENERAL__INPUT_TYPES.EMAIL"
                                    :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH')"
                                    :error-msg="$t('GENERAL__INPUT_ERROR_EMAIL_FORMAT')"
                                    v-model="notificationInfo"
                                  />
                                </template>
                                <template v-if="isNotifyBySms">
                                  <phone-input
                                    name="notification_info"
                                    :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH_INT')"
                                    :error-msg="$t('GENERAL__INPUT_ERROR_PHONE_FORMAT')"
                                    v-model="notificationInfo"
                                  />
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <!-- 以何種方式通知密碼 /-->

                      <div class="profile-form__row">
                        <div class="profile-form__inline">
                          <div class="profile-form__label">{{ $t('VERIFICATION__RESET_PROFILE_APPLICATION_UPLOAD') }}</div>
                          <div class="profile-form__field">
                            <uploader
                              @uploading="updateUploadingFiles"
                              @uploaded="addUploadedFileIds"
                              @removed="removeUploadedFileIds"
                              :label="$t('GENERAL__BUTTON_UPLOAD_ID')"
                              :show-notice="false"
                              :is-required="true"
                              :is-img-only="true"
                              :max-file-cnt="constants.GENERAL__MAX_FILE_CNT.APPLICATION"
                              :error-msg="$t('GENERAL__INPUT_ERROR_FILE_NOT_SELECTED')"
                              name="files"
                            />
                            <ol class="onlineservice-form__uploader-info" v-html="$t('CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD_NOTICE_1')"></ol>
                          </div>
                        </div>
                      </div>
                      <div class="profile-form__divider"></div>
                      <div class="profile-form__row">
                        <div class="profile-form__inline">
                          <div class="profile-form__label"></div>
                          <div class="profile-form__field">
                            <div class="profile-form__field-ck">
                              <custom-checkbox
                                :label="$t('CANT_LOGIN__ACCOUNT_RECOVERY_CHECKBOX')"
                                :name="'tos_checkbox'"
                                v-model="isTosChecked"
                              />
                            </div>
                            <div class="profile-form__next">
                              <div class="rountbutton">
                                <button class="rountbutton__widget" :disabled="!isButtonEnabled" @click.prevent="submitApplication">
                                  {{ $t('GENERAL__BUTTON_SUBMIT_FORM') }}
                                  <vue-countdown v-if="isSubmitting" :time="10000" @end="handleCountdownEnd">
                                    <template slot-scope="props">({{ props.totalSeconds }})</template>
                                  </vue-countdown>
                                </button>
                                <div v-if="isTosChecked && !isButtonEnabled && !isSubmitting" class="report-form__error">
                                  {{ $t('TICKET__SUBMIT_NOTICE') }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </dl>
              </div>
            </template>
            <template v-else>
              <div class="loading">
                <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import VueCountdown from '@chenfengyuan/vue-countdown';
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';
import Navigation from '@/components/pc/layouts/Navigation';
import CustomCheckbox from '@/components/pc/layouts/partial/CustomCheckbox';
import CustomInput from '@/components/share/layouts/partial/CustomInput';
import CustomRadio from '@/components/pc/layouts/partial/CustomRadio';
import Uploader from '@/components/share/layouts/partial/Uploader';
import PhoneInput from '@/components/share/layouts/partial/PhoneInput';
import application from '@/api/prod/application';
import constants from '@/lib/constants';
import utils from '@/lib/utils';
import { ACTION_TRIGGER_POPUP } from '@/store/action-types';
import {
  API_RETURN_CODE_APPLIED_TOO_MANY_TIMES,
  API_RETURN_CODE_PROFILE_INCOMPLETE,
  API_RETURN_CODE_APPLICATION_IN_PROGRESS,
  API_RETURN_CODES_ERROR_REQUIRE_GARENA_ACCOUNT,
} from '@/lib/return-codes';

export default {
  name: 'ResetProfile',
  components: {
    VueCountdown,
    Navigation,
    Breadcrumb,
    CustomCheckbox,
    CustomInput,
    CustomRadio,
    Uploader,
    PhoneInput,
  },
  data() {
    return {
      paths: [
        {
          id: 1,
          name: 'Verification',
          title: this.$t('HOME__VERIFICATION_TITLE'),
        },
        {
          id: 2,
          name: '',
          title: this.$t('VERIFICATION__RESET_PROFILE_TITLE'),
        },
      ],
      constants,
      isDataReady: false,
      verificationTypes: [],
      hasCancelPhoneChecked: false,
      hasCancelEmailChecked: false,
      hasResetPasswordChecked: false,
      notificationType: constants.APPLICATION__VERIFICATION_NOTIFICATION_TYPES.EMAIL,
      notificationInfo: null,
      uploadingFiles: {},
      uploadedFiles: [],
      isTosChecked: false,
      isSubmitting: false,
      submittingCountdown: 0,
    };
  },
  computed: {
    fieldsValue() {
      const ret = {
        types: this.verificationServiceTypes,
        files: this.uploadedFiles,
      };
      if (this.notificationType !== null) {
        ret.notificationType = this.notificationType;
      }
      if (this.notificationInfo !== null) {
        ret.notificationInfo = this.notificationInfo;
      }
      return ret;
    },
    verificationServiceTypes() {
      return [
        this.hasCancelPhoneChecked ? constants.APPLICATION__VERIFICATION_TYPES.CANCEL_PHONE : '',
        this.hasCancelEmailChecked ? constants.APPLICATION__VERIFICATION_TYPES.CANCEL_EMAIL : '',
        this.hasResetPasswordChecked ? constants.APPLICATION__VERIFICATION_TYPES.RESET_PASSWORD : '',
      ].filter((e) => e !== '');
    },
    isNotifyByEmail() {
      return this.notificationType === constants.APPLICATION__VERIFICATION_NOTIFICATION_TYPES.EMAIL;
    },
    isNotifyBySms() {
      return this.notificationType === constants.APPLICATION__VERIFICATION_NOTIFICATION_TYPES.SMS;
    },
    isNotificationInfoFilledIfResetPassword() {
      return (
        (this.hasResetPasswordChecked &&
          this.isNotifyByEmail &&
          utils.isValidEmail(this.notificationInfo) &&
          this.notificationInfo.length !== 0) ||
        (this.hasResetPasswordChecked && this.isNotifyBySms && this.notificationInfo && this.notificationInfo.length !== 0)
      );
    },
    isUploading() {
      return !utils.isEmptyObject(this.uploadingFiles) && Object.values(this.uploadingFiles).some((file) => !file.uploadCompleted);
    },
    isFormFilled() {
      return (
        this.verificationServiceTypes.length !== 0 &&
        this.uploadedFiles.length !== 0 &&
        (this.isNotificationInfoFilledIfResetPassword || !this.hasResetPasswordChecked)
      );
    },
    isButtonEnabled() {
      return this.isTosChecked && this.isFormFilled && !this.isUploading && !this.isSubmitting;
    },
  },
  methods: {
    updateUploadingFiles(files) {
      this.uploadingFiles = files;
    },
    addUploadedFileIds(name, fileId) {
      this.uploadedFiles.push(fileId);
    },
    removeUploadedFileIds(name, fileId) {
      const idx = this.uploadedFiles.indexOf(fileId);
      this.uploadedFiles.splice(idx, 1);
    },
    handleCountdownEnd() {
      this.isSubmitting = false;
    },
    submitApplication() {
      this.isSubmitting = true;
      const payload = this.fieldsValue;
      application.createApplication('verification', payload).then((resp) => {
        const { error } = resp;
        if (error) {
          if (error === API_RETURN_CODE_APPLIED_TOO_MANY_TIMES) {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_APPLICATION_APPLIED_TOO_MANY_TIMES'),
            });
          } else if (error === API_RETURN_CODE_PROFILE_INCOMPLETE) {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_APPLICATION_PROFILE_INCOMPLETE'),
            });
          } else if (error === API_RETURN_CODE_APPLICATION_IN_PROGRESS) {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_APPLICATION_APPLICATION_IN_PROGRESS'),
            });
          } else if (error === API_RETURN_CODES_ERROR_REQUIRE_GARENA_ACCOUNT) {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_APPLICATION_FB_ACCOUNT_CANT_VERIFICATION'),
            });
          } else {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_APPLICATION_CREATE_FAILED'),
            });
          }
        } else {
          this.$router.push({
            name: 'ResetProfileCompletion',
            params: { status: constants.APPLICATION__VERIFICATION_RESULTS.SUCCESS },
          });
        }
      });
    },
    ...mapActions({
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
  },
  mounted() {
    application.getVerificationAvailability().then((resp) => {
      const { data, error } = resp;
      if (error) {
        if (error === API_RETURN_CODE_APPLIED_TOO_MANY_TIMES) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_APPLICATION_APPLIED_TOO_MANY_TIMES'),
          });
        } else if (error === API_RETURN_CODE_PROFILE_INCOMPLETE) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_APPLICATION_PROFILE_INCOMPLETE'),
          });
        } else if (error === API_RETURN_CODE_APPLICATION_IN_PROGRESS) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_APPLICATION_APPLICATION_IN_PROGRESS'),
          });
        } else if (error === API_RETURN_CODES_ERROR_REQUIRE_GARENA_ACCOUNT) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_APPLICATION_FB_ACCOUNT_CANT_VERIFICATION'),
          });
        } else {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_EXCEPTION'),
          });
        }
        this.$router.push({
          name: 'Home',
        });
      } else {
        this.isDataReady = true;
        this.verificationTypes = data.verificationTypes;
      }
    });
  },
  watch: {
    notificationType() {
      // reset filled notification_info
      this.notificationInfo = null;
    },
  },
};
</script>
