<template>
  <section class="home-section">
    <div class="home-section__title">
      <div class="home-section__title-icon" :class="homeGameInfo.class"></div>
      <h3 class="home-section__title-txt">
        {{ $t(homeGameInfo.title) }}
      </h3>
    </div>
    <div class="home-games">
      <div class="home-games__inner">
        <router-link
          v-for="game in games"
          :key="game.id"
          class="home-games__item"
          :to="{ name: 'ArticleList', params: { gameCode: game.code, baseCategory: 'game' } }"
        >
          <img :src="game.displayImageUrl" alt="" class="home-games__img" />
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'GameList',
  props: ['gameType', 'games'],
  computed: {
    homeGameInfo() {
      const ret = { title: '', class: '' };
      switch (this.gameType) {
        case 'pc':
          ret.title = 'HOME__PC_GAME_QUESTION';
          ret.class = 'home-section__title-icon--pcgames';
          break;
        case 'mobile':
          ret.title = 'HOME__MOBILE_GAME_QUESTION';
          ret.class = 'home-section__title-icon--mgames';
          break;
        default:
          break; // left blank intentionally
      }
      return ret;
    },
  },
};
</script>
