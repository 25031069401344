<template>
  <div class="popup">
    <div class="popup__content">
      <p class="popup__content-title">{{ $t('AI_FAQ__POPUP__TITLE') }}</p>
      <a class="popup__content-back" @click="$emit('onPopupCancel')">&#x21A9;</a>
      <div>
        <Countdown class="popup__content-countdown" :endDate="endDate" @onEnd="$emit('onEnd')" />
        <div class="popup__content-support">
          <img :src="constants.AI_FAQ__CHAT_IMG[gameCodeOnUrl]" />
          <div v-if="rate === 'helpful'" class="response">{{ $t('AI_FAQ__POPUP__RATE_HELPFUL') }}</div>
          <div v-else-if="rate === 'unhelpful'" class="response">
            {{ $t('AI_FAQ__POPUP__RATE_UNHELPFUL_1') }}
            <a class="link" @click="$emit('onLinkClose')">{{ $t('AI_FAQ__POPUP__RATE_UNHELPFUL_2') }}</a>
            {{ $t('AI_FAQ__POPUP__RATE_UNHELPFUL_3') }}
          </div>
          <div v-else class="response" v-html="$t('AI_FAQ__POPUP__RATE_DEFAULT')"></div>
        </div>
        <div class="popup__content-rate">
          <div class="wrapper" @click="$emit('onRateHelpful')">
            <a class="helpful-icon helpful-icon--helpful" :class="{ isActive: rate === 'helpful' }" />
            <span>{{ $t('AI_FAQ__POPUP__RATE_HELPFUL_BTN') }}</span>
          </div>
          <div class="wrapper" @click="$emit('onRateUnhelpful')">
            <a class="helpful-icon helpful-icon--unhelpful" :class="{ isActive: rate === 'unhelpful' }" />
            <span>{{ $t('AI_FAQ__POPUP__RATE_UNHELPFUL_BTN') }}</span>
          </div>
        </div>
      </div>
      <div class="popup__content-btn">
        <p class="note" v-html="popupContent"></p>
        <a class="confirm" @click="$emit('onPopupConfirm')">{{ $t('AI_FAQ__POPUP__CONFIRM_BTN') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import constants from '@/lib/constants';
import Countdown from './Countdown';

export default {
  name: 'Popup',
  components: {
    Countdown,
  },
  props: {
    rate: String,
    popupContent: String,
    endDate: Date,
  },
  data() {
    return {
      constants,
      gameCodeOnUrl: this.$route.params.gameCode,
    };
  },
};
</script>

<style lang="scss" scoped>
.popup {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.2);
  @include flexCenter(row);

  &__content {
    background-color: $general-white;
    padding: 20px 30px;
    min-width: 72%;
    max-width: 90%;
    border-radius: 10px;
    position: relative;

    &-countdown {
      font-size: 13px;
      margin: 10px 0 10px 0;
      text-align: center;
    }

    &-back {
      position: absolute;
      top: 20px;
      font-size: 26px;
    }

    &-title {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }

    &-p {
      text-align: center;
      margin: 30px 0;
    }

    &-rate {
      @include flexCenter(row);
      margin-bottom: 20px;

      div {
        margin: 0 10px;
      }

      .wrapper {
        cursor: pointer;
        @include flexCenter(row);
        span {
          font-size: 16px;
          color: #5f5f5f;
          margin-left: 2px;
        }
      }
    }

    &-support {
      @include flexCenter(row);
      margin: 40px 0 10px 0;
      > img {
        $imgSize: 54;
        width: $imgSize + px;
        height: $imgSize + px;
        border-radius: 27px;
      }
      .response {
        font-size: 16px;
        margin: 0 5px;
        border: $general-red-hover 1px solid;
        padding: 4px 10px;
        width: 230px;
        border-radius: 10px;
      }
    }

    &-btn {
      margin: 40px 0 0 0;
      @include flexCenter(column);
      font-size: 14px;

      .note {
        font-size: 12px;
        color: rgb(123, 123, 123);
        margin-bottom: 2px;
      }

      .confirm {
        @include flexCenter(row);
        min-width: 100px;
        height: 30px;
        border-radius: 15px;
        border: 1px solid $general-red;
        color: $general-red;
        padding: 0 8px;

        &:hover {
          border: 1px solid $general-red-hover;
          color: $general-red-hover;
        }
      }
    }
  }
}
</style>
