<template>
  <div class="md-radio checkbox-wrap">
    <input class="checkbox__widget" type="checkbox"
           :id="name"
           :name="name"
           :disabled="isDisabled"
           :checked="val"
           @change="$emit('input', $event.target.checked)"/>
    <label :for="name" v-html="label"></label>
  </div>
</template>

<script>
export default {
  name: 'CustomCheckbox',
  props: {
    label: String,
    name: String,
    isDisabled: Boolean,
    errorMsg: String,
    value: Boolean,
  },
  computed: {
    val() {
      return this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .checkbox__disabled {
    cursor: not-allowed;
  }
</style>
