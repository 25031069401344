<template>
  <div class="chat__bottom">
    <textarea
      ref="chatInput"
      class="chat__bottom-input"
      v-model="inputVal"
      rows="3"
      cols="50"
      maxlength="200"
      autofocus
      :placeholder="$t('AI_FAQ__CHAT_INPUT__PLACEHOLDER')"
      :disabled="inputLocked"
      @focus="focus"
      @blur="blur"
      @keydown="onKeydown"
    />
    <a class="chat__bottom-send" :class="{ disabled: isMessageLoading || !inputVal }" @click="send"></a>
  </div>
</template>

<script>
export default {
  name: 'ChatInput',
  props: {
    value: String,
    isMessageLoading: Boolean,
    inputLocked: Boolean,
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    focus() {
      this.$emit('onFocus');
    },
    blur() {
      this.$emit('onBlur');
    },
    send() {
      this.$emit('onSend');
    },
    onKeydown(evt) {
      if (evt.keyCode === 13 && !evt.shiftKey) {
        evt.preventDefault();

        if (this.isMessageLoading) return;
        this.$emit('onSend');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$ai-general: $general-red;
.chat {
  &__bottom {
    @include flexCenter(row);
    width: 100%;
    background-color: white;
    border-top: solid 1px rgb(235, 187, 189);

    &-input {
      border: unset;
      flex: 1;
      padding: 10px;
      margin: 10px;
      resize: none;
    }

    &-send {
      margin-right: 10px;
      font-family: 'icon';
      font-size: 46px;
      transform: rotate(-90deg);

      &.disabled {
        color: rgb(165, 165, 165);
        pointer-events: none;
      }

      &::before {
        content: '\E907';
      }

      color: $ai-general;
    }
  }
}
</style>
