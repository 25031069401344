<template>
  <div class="main-wrap">
    <div class="main-bg main-bg-game" :style="mainBgImage" />
    <div class="main">
      <navigation
        :title="currentGameName"
        :has-search-bar="true"
        :search-type="'game'"
        :search-code="gameCodeOnUrl"
        :is-blank="true"
        :is-game-page="true"
      />
      <template v-if="article">
        <section class="game-section">
          <div class="cproblem__main-content">
            <div class="cproblem__main">
              <div class="faqdetail-title">
                <div class="faqdetail__question">{{ article.title }}</div>
              </div>
            </div>
            <div class="faqdetail__content">
              <div class="ip-solution__txt" v-html="article.content"></div>
              <!-- Article Tags -->
              <template v-if="!utils.isEmptyArray(article.tags)">
                <div class="faqdetail__tag">
                  <div class="faqdetail__statistics-item">
                    {{ $t('ARTICLE__TAGS') }}
                    <a v-for="(tag, idx) in article.tags" :key="idx" @click.prevent="" class="faqdetail__statistics-tag">
                      {{ tag }}
                    </a>
                  </div>
                </div>
              </template>
              <!-- Article Tags /-->
            </div>
            <div class="cproblem__main-grey"></div>
            <!-- Related Articles -->
            <template v-if="!utils.isEmptyArray(article.relatedArticles)">
              <div class="faqdetail-title">
                <div class="faqdetail__question">{{ $t('ARTICLE__RELATED_ARTICLE') }}</div>
              </div>
              <ul class="cproblem__main-list">
                <li v-for="relatedArticle in article.relatedArticles" :key="relatedArticle.id">
                  <router-link :to="{ name: 'MobileArticleGeneral', params: { id: relatedArticle.id } }" class="cproblem__main-item">
                    <div class="cproblem__main-item--icon"></div>
                    <p>{{ relatedArticle.title }}</p>
                  </router-link>
                </li>
              </ul>
            </template>
            <!-- Related Articles /-->
          </div>
          <div class="cproblem__main-entry">
            <a
              v-if="showAIHelp"
              :to="{ name: 'MobileTicket', params: { gameCode: gameCodeOnUrl }, query: { bc: baseCategoryNameOnUrl } }"
              class="cproblem__main-entry--item rectangle-button"
            >
              <div>
                <div class="cproblem__main-entry--desc">
                  {{ $t(`ARTICLE__TICKET_SUBTITLE__${gameCodeOnUrl.toUpperCase()}`) }}
                </div>
                <div class="cproblem__main-entry--name">
                  {{ $t(`ARTICLE__TICKET_TITLE__${gameCodeOnUrl.toUpperCase()}`) }}
                </div>
                <div class="cproblem__main-entry--desc">
                  {{ $t(`ARTICLE__TICKET_DESC__${gameCodeOnUrl.toUpperCase()}`) }}
                </div>
              </div>
              <div class="cproblem__main-entry--icon cproblem__main-entry--createreport"></div>
            </a>
            <router-link
              v-else
              :to="{ name: 'MobileTicket', params: { gameCode: gameCodeOnUrl }, query: { bc: baseCategoryNameOnUrl } }"
              class="cproblem__main-entry--item rectangle-button"
            >
              <div>
                <div class="cproblem__main-entry--desc">
                  {{ $t('ARTICLE__TICKET_SUBTITLE') }}
                </div>
                <div class="cproblem__main-entry--name">
                  {{ $t('ARTICLE__TICKET_TITLE') }}
                </div>
                <div class="cproblem__main-entry--desc">
                  {{ $t('ARTICLE__TICKET_DESC') }}
                </div>
              </div>
              <div class="cproblem__main-entry--icon cproblem__main-entry--createreport"></div>
            </router-link>
          </div>
        </section>
      </template>

      <template v-else>
        <div class="loading">
          <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { ACTION_TRIGGER_AI_CHATTING } from '@/store/action-types';
import Navigation from '@/components/mobile/layouts/Navigation';
import article from '@/api/prod/article';
import utils from '@/lib/utils';

export default {
  name: 'Article',
  beforeRouteLeave(to, from, next) {
    if (this.isAIChatting && to.name !== 'MobileArticleList') {
      const answer = window.confirm(this.$t('AI_FAQ__CONFIRM_LEAVE'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  components: {
    Navigation,
  },
  data() {
    return {
      utils,
      article: null,
      gameCodeOnUrl: this.$route.params.gameCode,
      baseCategoryNameOnUrl: this.$route.params.baseCategory,
    };
  },
  computed: {
    currentGameName() {
      return this.gamesCodeNameMapping[this.gameCodeOnUrl] || '';
    },
    currentGameId() {
      return this.gamesCodeIdMapping[this.gameCodeOnUrl];
    },
    currentBaseCategoryId() {
      return this.baseCategoriesCodeIdMapping[this.baseCategoryNameOnUrl];
    },
    mainBgImage() {
      return {
        'background-image': `url(${process.env.CDN}/games/${this.gameCodeOnUrl}/bg-main-${this.gameCodeOnUrl}.jpg)`,
      };
    },
    isDataReady() {
      return this.currentGameId && this.currentBaseCategoryId;
    },
    showAIHelp() {
      return (this.gameCodeOnUrl === 'aov'||this.gameCodeOnUrl === 'ff') && process.env.REGION === 'tw';
    },
    ...mapState({
      games: (state) => state.game.games,
      baseCategories: (state) => state.category.baseCategories,
      isAIChatting: (state) => state.common.isAIChatting,
    }),
    ...mapGetters(['gamesCodeNameMapping', 'gamesCodeIdMapping', 'baseCategoriesCodeNameMapping', 'baseCategoriesCodeIdMapping']),
  },
  methods: {
    ...mapActions({
      triggerAIChatting: ACTION_TRIGGER_AI_CHATTING,
    }),
  },
  watch: {
    isDataReady: {
      immediate: true,
      handler(newValue) {
        if (newValue && !this.article) {
          article.getArticleByAgcId(this.currentGameId, this.currentBaseCategoryId, this.$route.params.agcId).then((resp) => {
            const { data, error } = resp;
            if (error || !data) {
              if (error === 'error_no_matched_data') {
                this.$router.push(
                  { name: 'MobileNotFound' },
                  () => {},
                  () => {},
                );
              } else {
                this.triggerPopup({
                  title: this.$t('POPUP__ERROR'),
                  content: this.$t('POPUP__ERROR_EXCEPTION'),
                });
              }
            } else {
              this.article = data;
            }
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .main-wrap {
    height: calc(100vh - 60px);
  }
  .main {
    height: 100%;
  }
  .game-section {
    display: flex;
    flex-flow: column;
  }
  .ip-solution__txt {
    margin: 12px 12px 20px 12px;
  }
  .cproblem__main-grey {
    height: 10px;
    background-color: #f8f9fa;
  }
  .cproblem__main-grey-down {
    background-color: #f8f9fa;
    flex-grow: 1;
  }
  .cproblem__main-entry--name {
    margin-top: 8px;
  }
  .loading {
    margin-top: 100px;
    text-align: center;
    img {
      width: 15%;
    }
  }
}
</style>
