<template>
  <div class="reportlist__feedback">
    <template v-if="canProvideFeedback && !hasProvidedFeedback">
      <div class="reportlist__detail-main">
        <form class="report-form">
          <div class="report-form__content">
            <div class="report-form__label">{{ $t('REPORT__FEEDBACK_TITLE') }}</div>
            <div class="report-form__desc" v-html="$t('REPORT__FEEDBACK_DESC', { formEntry: csNumber })"></div>
            <template v-if="!isTwVersion">
              <div class="helpful">
                <label class="helpful-radio satisfied-radio">
                  <div class="helpful-txt">{{ $t('REPORT__FEEDBACK_GOOD') }}</div>
                  <input
                    class="helpful-input"
                    type="radio"
                    name="ticket_rate"
                    :value="constants.TICKET__TICKET_RATE.IS_SATISFIED"
                    v-model="isSatisfied"
                  />
                  <div class="helpful-icon helpful-icon--helpful"></div>
                </label>
                <label class="helpful-radio satisfied-radio">
                  <div class="helpful-txt">{{ $t('REPORT__FEEDBACK_BAD') }}</div>
                  <input
                    class="helpful-input"
                    type="radio"
                    name="ticket_rate"
                    :value="constants.TICKET__TICKET_RATE.IS_NOT_SATISFIED"
                    v-model="isSatisfied"
                  />
                  <div class="helpful-icon helpful-icon--unhelpful"></div>
                </label>
              </div>
              <custom-textarea name="content" :placeholder="$t('REPORT__FEEDBACK_PLACEHOLDER')" v-model="content" />
              <button class="reportlist__return" :disabled="!isButtonEnabled" @click.prevent="submitTicketRate">
                {{ $t('GENERAL__BUTTON_SUBMIT_REPORT__FEEDBACK') }}
                <vue-countdown v-if="isSubmitting" :time="10000" @end="handleCountdownEnd">
                  <template slot-scope="props">({{ props.totalSeconds }})</template>
                </vue-countdown>
              </button>
            </template>
          </div>
        </form>
      </div>
    </template>
    <template v-else-if="hasProvidedFeedback">
      <div class="reportlist__detail-main">
        <form class="report-form">
          <div class="report-form__content">
            <div class="report-form__label">{{ $t('REPORT__FEEDBACK_TITLE') }}</div>
            <template v-if="hasSentRate">
              <p class="feedbackret__result-p">
                {{ $t('REPORT__FEEDBACK_RECORD_DESC') }}
                <template v-if="isSatisfied">
                  {{ $t('REPORT__FEEDBACK_GOOD') }}
                </template>
                <template v-else>
                  {{ $t('REPORT__FEEDBACK_BAD') }}
                </template>
              </p>
              <p class="feedbackret__result-p" v-html="utils.nl2br(content)"></p>
            </template>
            <template v-else>
              <p class="feedbackret__result-p">
                {{ $t('REPORT__FEEDBACK_RECORD_DESC') }}
                <template v-if="feedback.isSatisfied">
                  {{ $t('REPORT__FEEDBACK_GOOD') }}
                </template>
                <template v-else>
                  {{ $t('REPORT__FEEDBACK_BAD') }}
                </template>
              </p>
              <p class="feedbackret__result-p" v-html="utils.nl2br(feedback.content)"></p>
            </template>
          </div>
        </form>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import VueCountdown from '@chenfengyuan/vue-countdown';
import CustomTextarea from '@/components/share/layouts/partial/CustomTextarea';
import ticket from '@/api/prod/ticket';
import constants from '@/lib/constants';
import utils from '@/lib/utils';
import { ACTION_TRIGGER_POPUP } from '@/store/action-types';

export default {
  name: 'Feedback',
  props: {
    csNumber: Number,
    feedback: Object,
    canProvideFeedback: Boolean,
  },
  components: {
    VueCountdown,
    CustomTextarea,
  },
  data() {
    return {
      constants,
      utils,
      isSatisfied: null,
      content: null,
      isSubmitting: false,
      hasSentRate: false,
    };
  },
  computed: {
    hasProvidedFeedback() {
      return (this.feedback && true) || this.hasSentRate;
    },
    isButtonEnabled() {
      return this.content && this.content.length > 0 && this.isSatisfied !== null && !this.isSubmitting;
    },
  },
  methods: {
    handleCountdownEnd() {
      this.isSubmitting = false;
    },
    submitTicketRate() {
      this.isSubmitting = true;
      const payload = {
        isSatisfied: Boolean(this.isSatisfied),
        content: this.content,
      };
      ticket.rateTicket(this.csNumber, payload).then((resp) => {
        const { data, error } = resp;
        if (error || !data) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_EXCEPTION'),
          });
        } else {
          this.triggerPopup({
            title: this.$t('POPUP__ALERT'),
            content: this.$t('POPUP__ALERT_TICKET_REPLY_RATE_SUCCEED_CONTENT'),
          });
          this.hasSentRate = true;
        }
      });
    },
    ...mapActions({
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .reportlist__feedback {
    font-size: 14px;
    line-height: 1.8;
    border: $general-border;
    background: #ffffff;
    border-radius: 6px;
    padding: 3px 10px;
    color: $general-dark-text-color;
  }
  .helpful {
    margin-bottom: 10px;
  }
  .satisfied-radio {
    padding: 12px 28px;
    line-height: 1rem;
  }
}
</style>
