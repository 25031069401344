<template>
  <div class="main-hd" :class="{ 'main-hd-blank': isBlank }">
    <h1 class="main-hd__title" :class="{ 'main-hd__title-common': !isGamePage }">
      {{ title }}
    </h1>
    <template v-if="hasSearchBar">
      <form class="main-hd__search" @submit.prevent>
        <div class="main-hd__search-input">
          <div class="main-hd__search-icon"></div>
          <input
            type="text"
            class="main-hd__search-widget"
            :placeholder="$t('SEARCH__BAR_PLACEHOLDER')"
            v-model.trim="searchString"
            @keyup.enter="submit()"
          />
        </div>
        <div @click="switchSearchMenu" class="main-hd__search-filter">
          <span id="selected-search-cate">{{ currentSearchCategory }}</span>
          <div class="main-hd__search-arrow"></div>
        </div>

        <!-- Search Bar Menu -->
        <template v-if="isSearchMenuVisible">
          <div class="box">
            <nav class="tabset--nav">
              <div
                v-show="games.pc"
                class="nav--item"
                data-tab-target="tab-1"
                :class="{ '-is--active': currentSearchListName === constants.SEARCH__SEARCH_MENU_LISTS.PC }"
                @click.prevent="changeSelectedSearchList(constants.SEARCH__SEARCH_MENU_LISTS.PC)"
              >
                <p>{{ $t('SEARCH__BAR_TITLE_PC') }}</p>
              </div>
              <div
                v-show="games.mobile"
                class="nav--item"
                data-tab-target="tab-2"
                :class="{ '-is--active': currentSearchListName === constants.SEARCH__SEARCH_MENU_LISTS.MOBILE }"
                @click.prevent="changeSelectedSearchList(constants.SEARCH__SEARCH_MENU_LISTS.MOBILE)"
              >
                <p>{{ $t('SEARCH__BAR_TITLE_MOBILE') }}</p>
              </div>
              <div
                class="nav--item"
                data-tab-target="tab-3"
                :class="{ '-is--active': currentSearchListName === constants.SEARCH__SEARCH_MENU_LISTS.OTHERS }"
                @click.prevent="changeSelectedSearchList(constants.SEARCH__SEARCH_MENU_LISTS.OTHERS)"
              >
                <p>{{ $t('SEARCH__BAR_TITLE_OTHERS') }}</p>
              </div>
              <div class="active--tab__marker"></div>
            </nav>
            <section class="tabset--content" data-active-tab="tab-1">
              <div v-if="currentSearchList" class="tabset--section">
                <div v-for="i in menuListCount(currentSearchList)" :key="i" class="main-hd__category-list">
                  <a
                    v-for="item in itemsInMenuList(i, currentSearchList)"
                    :key="item.id"
                    @click.prevent="changeSelectedCategory(constants.SEARCH__SEARCH_TYPES[currentSearchListName], item.code)"
                    :class="{ 'main-hd__category-item--active': item.code === currentSearchCode }"
                    class="main-hd__category-item"
                    href="#"
                  >
                    <div class="main-hd__category-name">
                      {{ item.name }}
                    </div>
                  </a>
                </div>
              </div>
            </section>
          </div>
        </template>
        <!-- Search Bar Menu /-->
      </form>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { ACTION_GET_GAMES, ACTION_GET_BASE_CATEGORIES } from '@/store/action-types';
import constants from '@/lib/constants';

export default {
  name: 'navigation',
  props: {
    title: String,
    hasSearchBar: Boolean,
    searchType: String,
    searchCode: String,
    isBlank: Boolean,
    isGamePage: Boolean,
  },
  data() {
    return {
      constants,
      isSearchMenuVisible: false,
      searchString: '',
      currentSearchType: '',
      currentSearchCode: '',
      itemsPerList: constants.SEARCH__SEARCH_MENU_ITEMS_COUNT_PER_LIST,
      userSelectedSearchListName: '',
    };
  },
  computed: {
    currentSearchList() {
      if (this.currentSearchListName === this.constants.SEARCH__SEARCH_MENU_LISTS.PC) {
        return this.games ? this.games.pc : [];
      }
      if (this.currentSearchListName === this.constants.SEARCH__SEARCH_MENU_LISTS.MOBILE) {
        return this.games ? this.games.mobile : [];
      }
      if (this.currentSearchListName === this.constants.SEARCH__SEARCH_MENU_LISTS.OTHERS) {
        const searchFilterAll = [
          {
            name: this.$t('SEARCH__BAR_ALL'),
            code: '',
          },
        ];
        return this.baseCategories ? searchFilterAll.concat(this.baseCategories) : [];
      }
      return [];
    },
    currentSearchListName() {
      let searchListName = this.constants.SEARCH__SEARCH_MENU_LISTS.PC;
      if (!this.games.pc) {
        searchListName = this.constants.SEARCH__SEARCH_MENU_LISTS.MOBILE;
      }
      if (this.userSelectedSearchListName) {
        searchListName = this.userSelectedSearchListName;
      } else if (this.currentSearchCode in this.gamesCodeTypeMapping) {
        searchListName = this.gamesCodeTypeMapping[this.currentSearchCode];
      } else if (this.currentSearchCode in this.baseCategoriesCodeNameMapping) {
        searchListName = constants.SEARCH__SEARCH_MENU_LISTS.OTHERS;
      }
      return searchListName;
    },
    currentSearchCategory() {
      const ret = this.$t('SEARCH__BAR_ALL');
      return this.gamesCodeNameMapping[this.currentSearchCode] || this.baseCategoriesCodeNameMapping[this.currentSearchCode] || ret;
    },
    ...mapState({
      games: (state) => state.game.games,
      baseCategories: (state) => state.category.baseCategories,
    }),
    ...mapGetters(['gamesCodeNameMapping', 'gamesCodeTypeMapping', 'baseCategoriesCodeNameMapping']),
  },
  methods: {
    menuListCount(items) {
      return Math.ceil(items.length / this.itemsPerList);
    },
    itemsInMenuList(index, items) {
      return items.slice((index - 1) * this.itemsPerList, index * this.itemsPerList);
    },
    switchSearchMenu() {
      this.isSearchMenuVisible = !this.isSearchMenuVisible;
    },
    changeSelectedSearchList(listName) {
      this.userSelectedSearchListName = listName;
    },
    changeSelectedCategory(type, code) {
      this.currentSearchCode = code;
      if (!code) {
        this.currentSearchType = null;
      } else {
        this.currentSearchType = type;
      }
      this.switchSearchMenu();
      if (this.searchString) {
        this.submit(true);
      }
    },
    initSearchCategory() {
      this.currentSearchType = this.searchType || '';
      this.currentSearchCode = this.searchCode || '';
    },
    submit(force = false) {
      if (this.searchString) {
        if (!force && this.$route.query.q === this.searchString) {
          return;
        }
        const queryString = { q: this.searchString };
        if (this.currentSearchType) {
          queryString.type = this.currentSearchType;
        }
        if (this.currentSearchCode) {
          queryString.code = this.currentSearchCode;
        }
        this.$router.push({
          name: 'MobileSearch',
          query: queryString,
        });
      }
    },
    ...mapActions({
      getGames: ACTION_GET_GAMES,
      getBaseCategories: ACTION_GET_BASE_CATEGORIES,
    }),
  },
  mounted() {
    this.initSearchCategory();
    this.searchString = this.$route.query.q;
  },
};
</script>
