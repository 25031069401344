import { render, staticRenderFns } from "./Search.vue?vue&type=template&id=0d5bd640&scoped=true&"
import script from "./Search.vue?vue&type=script&lang=js&"
export * from "./Search.vue?vue&type=script&lang=js&"
import style0 from "./Search.vue?vue&type=style&index=0&id=0d5bd640&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d5bd640",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/support.garena.all/frontend/main/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0d5bd640')) {
      api.createRecord('0d5bd640', component.options)
    } else {
      api.reload('0d5bd640', component.options)
    }
    module.hot.accept("./Search.vue?vue&type=template&id=0d5bd640&scoped=true&", function () {
      api.rerender('0d5bd640', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/mobile/pages/Search.vue"
export default component.exports