<template>
  <div class="main-wrap">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('HOME__CANT_LOGIN_TITLE')" :has-search-bar="false" />
      <div class="main-inner">
        <div class="cantlogin">
          <breadcrumb :paths="paths" />
          <div class="main-title">
            {{ $t('HOME__CANT_LOGIN_TITLE') }}
          </div>
          <div class="cantlogin-inner">
            <div class="cantlogin__list">
              <div v-for="item in items" :key="item.icon" class="cantlogin__card">
                <div class="cantlogin__card-icon" :class="['cantlogin__card-icon--' + item.icon]"></div>
                <div class="cantlogin__card-title">
                  {{ item.title }}
                </div>
                <div class="cantlogin__card-content" v-html="item.content"></div>
                <template v-if="item.isExternalLink">
                  <a :href="item.link" class="verification__card-verf" target="_blank">
                    {{ item.linkText }}
                  </a>
                </template>
                <template v-else>
                  <template v-if="item.routerName">
                    <router-link :to="{ name: item.routerName }" class="verification__card-verf">
                      {{ item.linkText }}
                    </router-link>
                  </template>
                  <template v-else-if="item.onClick">
                    <a href="#" @click.prevent="handleFunctionCall(item.onClick)" class="verification__card-verf">
                      {{ item.linkText }}
                    </a>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Navigation from '@/components/pc/layouts/Navigation';
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';
import { ACTION_TRIGGER_POPUP } from '@/store/action-types';

export default {
  name: 'CantLogin',
  components: {
    Breadcrumb,
    Navigation,
  },
  data() {
    return {
      paths: [
        {
          id: 1,
          name: 'CantLogin',
          params: {},
          title: this.$t('HOME__CANT_LOGIN_TITLE'),
        },
      ],
      items: [
        {
          icon: 'gameblock',
          title: this.$t('CANT_LOGIN__ITEM_GAMEBLOCK_TITLE'),
          content: this.$t('CANT_LOGIN__ITEM_GAMEBLOCK_CONTENT'),
          linkText: this.$t('CANT_LOGIN__ITEM_GAMEBLOCK_LINKTEXT'),
          isExternalLink: false,
          routerName: null,
          onClick: 'showGameBlockedHelp',
        },
        {
          icon: 'accblock',
          title: this.$t('CANT_LOGIN__ITEM_ACCBLOCK_TITLE'),
          content: this.$t('CANT_LOGIN__ITEM_ACCBLOCK_CONTENT'),
          linkText: this.$t('CANT_LOGIN__ITEM_ACCBLOCK_LINKTEXT'),
          isExternalLink: false,
          routerName: null,
          onClick: 'showAccountBlockedHelp',
        },
        {
          icon: 'resetpwd',
          title: this.$t('CANT_LOGIN__ITEM_RESETPWD_TITLE'),
          content: this.$t('CANT_LOGIN__ITEM_RESETPWD_CONTENT'),
          linkText: this.$t('CANT_LOGIN__ITEM_RESETPWD_LINKTEXT'),
          isExternalLink: false,
          routerName: 'AccountRecovery',
          onClick: null,
        },
      ],
    };
  },
  methods: {
    handleFunctionCall(fn) {
      this[fn]();
    },
    showGameBlockedHelp() {
      this.triggerPopup({
        title: this.$t('POPUP__ALERT'),
        content: this.$t('CANT_LOGIN__ITEM_GAMEBLOCK_CONTENT'),
      });
      this.$router.push({ name: 'Home' });
    },
    showAccountBlockedHelp() {
      this.triggerPopup({
        title: this.$t('POPUP__ALERT'),
        content: this.$t('CANT_LOGIN__ITEM_ACCBLOCK_CONTENT'),
      });
      this.$router.push({ name: 'Home' });
    },
    ...mapActions({
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
  },
};
</script>
