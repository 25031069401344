<template>
  <div v-if="!isEnded">
    {{ $t('AI_FAQ__COUNTDOWN_1') }}
    <span v-if="days > 0">{{ days }} 天</span>
    <span v-if="hours > 0">{{ hours }} 小時</span>
    <span v-if="minutes > 0">{{ minutes }} 分</span>
    <span v-if="seconds > 0">{{ seconds }} 秒</span>
    {{ $t('AI_FAQ__COUNTDOWN_2') }}
  </div>
</template>

<script>
export default {
  props: {
    endDate: {
      type: Date, // Date.parse(this.endDate)
    },
  },
  data() {
    return {
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
      isEnded: null,
    };
  },
  methods: {
    updateRemaining(distance) {
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    },

    tick() {
      const currentTime = new Date();
      const distance = Math.max(this.endDate - currentTime, 0);
      this.updateRemaining(distance);

      if (distance < 1000) {
        clearInterval(this.timer);
        this.isEnded = true;
        this.$emit('onEnd');
      }
    },
  },

  mounted() {
    this.tick();
    this.timer = setInterval(this.tick.bind(this), 1000);
  },

  destroy() {
    clearInterval(this.timer);
  },
};
</script>
