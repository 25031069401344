var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", { staticClass: "uploader-wrap" }, [
        _c("input", {
          ref: "input",
          staticClass: "uploader",
          attrs: {
            type: "file",
            accept: _vm.acceptFileTypes,
            name: _vm.name,
            disabled: _vm.isDisabled
          },
          on: { change: _vm.onFileChange }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "uploader__label",
            class: { input_file_disabled: _vm.isDisabled }
          },
          [_vm._v("\n      " + _vm._s(_vm.label) + "\n    ")]
        )
      ]),
      _vm._v(" "),
      _vm.isDisabled
        ? _c("div", { staticClass: "report-form__uploader-tip" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t("GENERAL__INPUT_FILE_UPLOAD_TIP", {
                    maxFileCnt: String(_vm.maxFileCnt)
                  })
                ) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showNotice
        ? [
            _c("div", { staticClass: "report-form__uploader-info" }, [
              _c("p", { staticClass: "report-form__uploader-infoitem" }, [
                _vm._v(_vm._s(_vm.$t("GENERAL__INPUT_FILE_UPLOAD_INFO")))
              ])
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      !_vm.isValid
        ? _c("div", { staticClass: "report-form__error" }, [
            _vm._v(_vm._s(_vm.errorMsg))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.files
        ? [
            _c(
              "div",
              { staticClass: "report-form__uploader-previews" },
              [
                _vm._l(_vm.files, function(file, idx) {
                  return [
                    _c(
                      "div",
                      {
                        key: idx,
                        staticClass: "report-form__uploader-preview"
                      },
                      [
                        file.file.type.indexOf("image/") === 0
                          ? _c("img", {
                              staticClass: "report-form__uploader-previewimg",
                              attrs: { src: file.src, alt: "" }
                            })
                          : _c("img", {
                              staticClass: "report-form__uploader-previewimg",
                              attrs: {
                                src:
                                  _vm.CDN +
                                  "/assets/pc/img/cproblem/icon-createreport@2x.png",
                                alt: ""
                              }
                            }),
                        _vm._v(" "),
                        !_vm.isUploading
                          ? _c(
                              "a",
                              {
                                staticClass: "report-form__uploader-del",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.removeImage(idx)
                                  }
                                }
                              },
                              [
                                _c("div", {
                                  staticClass: "report-form__uploader-delicon"
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !file.uploadCompleted
                          ? _c("div", {
                              staticClass: "report-form__uploader-previewmask"
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "report-form__uploader-previewbar" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-form__uploader-previewprogress",
                                style: { width: file.uploadPercentage + "%" }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(file.file.name) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                })
              ],
              2
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }