<template>
  <div class="main-wrap">
    <section class="game-section">
      <div class="cantlogin-list__main">
        <div class="reportlist__main-top">
          <div class="reportlist__main-top--title">{{ $t('M_HOME__CANT_LOGIN_TITLE') }}</div>
        </div>
        <div class="cantlogin-list__main-list">
          <ul class="cproblem__main-list">
            <li @click="switchSectionGameBlock">
              <div class="cproblem__main-item">
                <p>{{ $t('CANT_LOGIN__ITEM_GAMEBLOCK_TITLE') }}</p>
                <div class="cantlogin-list__main-item--icon"
                     :class="{ 'cproblem__main-item--icon--active': sectionGameBlockOpen }"></div>
              </div>
            </li>
            <transition name="slide-fade">
              <template v-if="sectionGameBlockOpen">
                <div class="verification-inner">
                  <div class="verification__inner">
                    <div class="verification__inner-icon verification__inner-icon--suspended"></div>
                    <div class="verification__inner-content" v-html="$t('M_CANT_LOGIN__ITEM_GAMEBLOCK_CONTENT')"></div>
                    <router-link :to="{ name: 'MobileGameList' }" class="verification__inner-verf">
                      {{ $t('CANT_LOGIN__ITEM_GAMEBLOCK_LINKTEXT') }}
                    </router-link>
                  </div>
                </div>
              </template>
            </transition>
            <hr>
            <li @click="switchSectionAccBlock">
              <div class="cproblem__main-item">
                <p>{{ $t('CANT_LOGIN__ITEM_ACCBLOCK_TITLE') }}</p>
                <div class="cantlogin-list__main-item--icon"
                     :class="{ 'cproblem__main-item--icon--active': sectionAccBlockOpen }"></div>
              </div>
            </li>
            <transition name="slide-fade">
              <template v-if="sectionAccBlockOpen">
                <div class="verification-inner">
                  <div class="verification__inner">
                    <div class="verification__inner-icon verification__inner-icon--lock"></div>
                    <div class="verification__inner-content" v-html="$t('M_CANT_LOGIN__ITEM_ACCBLOCK_CONTENT')"></div>
                    <router-link :to="{ name: 'MobileGameList' }" class="verification__inner-verf">
                      {{ $t('CANT_LOGIN__ITEM_ACCBLOCK_LINKTEXT') }}
                    </router-link>
                  </div>
                </div>
              </template>
            </transition>
            <hr>
            <li @click="switchSectionResetPwd">
              <div class="cproblem__main-item">
                <p>{{ $t('CANT_LOGIN__ITEM_RESETPWD_TITLE') }}</p>
                <div class="cantlogin-list__main-item--icon"
                     :class="{ 'cproblem__main-item--icon--active': sectionResetPwdOpen }"></div>
              </div>
            </li>
            <transition name="slide-fade">
              <template v-if="sectionResetPwdOpen">
                <div class="verification-inner">
                  <div class="verification__inner">
                    <div class="verification__inner-icon verification__inner-icon--reset"></div>
                    <div class="verification__inner-content" v-html="$t('M_CANT_LOGIN__ITEM_RESETPWD_CONTENT')"></div>
                    <router-link :to="{ name: 'MobileAccountRecovery' }" class="verification__inner-verf">
                      {{ $t('CANT_LOGIN__ITEM_RESETPWD_LINKTEXT') }}
                    </router-link>
                  </div>
                </div>
              </template>
            </transition>
            <hr>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'CantLogin',
  data() {
    return {
      sectionGameBlockOpen: false,
      sectionAccBlockOpen: false,
      sectionResetPwdOpen: false,
    };
  },
  methods: {
    switchSectionGameBlock() {
      this.sectionGameBlockOpen = !this.sectionGameBlockOpen;
    },
    switchSectionAccBlock() {
      this.sectionAccBlockOpen = !this.sectionAccBlockOpen;
    },
    switchSectionResetPwd() {
      this.sectionResetPwdOpen = !this.sectionResetPwdOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
  .game-section {
    margin-bottom: 20px;
  }
  .mobile .cproblem__main-item--icon--active {
    transform: rotate(-45deg);
  }
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .1s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(-10px);
    opacity: 0;
  }
</style>
