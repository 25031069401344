var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagination" },
    [
      _vm.currentPage > 1
        ? _c(
            "a",
            {
              staticClass: "pagination__item",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.changeCurrentPage(_vm.currentPage - 1)
                }
              }
            },
            [_c("div", { staticClass: "pagination__item-prev" })]
          )
        : _c(
            "a",
            { staticClass: "pagination__item pagination__item--disabled" },
            [_c("div", { staticClass: "pagination__item-prev" })]
          ),
      _vm._v(" "),
      _vm._l(_vm.totalPages, function(page) {
        return _c(
          "a",
          {
            key: page,
            staticClass: "pagination__item",
            class: { "pagination__item--active": _vm.currentPage === page },
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.changeCurrentPage(page)
              }
            }
          },
          [_vm._v("\n    " + _vm._s(page) + "\n  ")]
        )
      }),
      _vm._v(" "),
      _vm.currentPage < _vm.totalPages
        ? _c(
            "a",
            {
              staticClass: "pagination__item",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.changeCurrentPage(_vm.currentPage + 1)
                }
              }
            },
            [_c("div", { staticClass: "pagination__item-next" })]
          )
        : _c(
            "a",
            { staticClass: "pagination__item pagination__item--disabled" },
            [_c("div", { staticClass: "pagination__item-next" })]
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }