var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasNotification
    ? _c("div", { staticClass: "notification-wrap" }, [
        _c("div", { staticClass: "notification-dimmer" }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "notification",
            class: { "notification--sm": _vm.isSmallNotification }
          },
          [
            _c("div", { staticClass: "notification-inner" }, [
              _c("div", { staticClass: "notification__title" }, [
                _c("div", { staticClass: "notification__title-icon" }),
                _vm._v(" "),
                _c("div", { staticClass: "notification__title-txt" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("NOTIFICATION__TITLE")) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "notification__main" },
                [
                  !_vm.isSmallNotification
                    ? [
                        _c("div", { staticClass: "notification__img" }, [
                          _c("img", {
                            staticClass: "notification__img-inner",
                            attrs: { src: _vm.notification.img, alt: "" }
                          })
                        ])
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "notification__content faqdetail__content" },
                    [
                      _c("div", {
                        staticClass: "notification__content-txt",
                        domProps: {
                          innerHTML: _vm._s(_vm.notification.content)
                        }
                      })
                    ]
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "rountbutton",
                  on: { click: _vm.closeNotification }
                },
                [
                  _c("button", { staticClass: "rountbutton__widget" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("GENERAL__BUTTON_NOTIFICATION")) +
                        "\n        "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("a", {
                staticClass: "notification__close",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.closeNotification.apply(null, arguments)
                  }
                }
              })
            ])
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }