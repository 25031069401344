<template>
  <div class="main-wrap">
    <div class="main-bg"></div>
    <div class="main">
      <navigation :title="$t('GENERAL__TITLE')" :has-search-bar="true" :is-blank="true" />
      <section class="game-section">
        <div class="searchlist__main-title">
          {{ $t('SEARCH__RESULT_TITLE') }}
          <span class="search__keyword">{{ searchString }}</span>
        </div>
        <template v-if="searchResults && searchResults.length > 0">
          <div v-for="result in searchResults" :key="result.id" class="searchlist__main">
            <a href="#" class="searchlist__cards" @click.prevent="toArticle(result.id)">
              <div class="searchlist__main-top">Q：{{ result.title }}</div>
              <div class="searchlist__recmd" v-html="result.content"></div>
              <div class="searchlist__main-more">
                {{ $t('SEARCH__READ_MORE') }}&nbsp;
                <span class="searchlist__main-more--arrow">></span>
              </div>
            </a>
          </div>
        </template>
        <template v-else-if="searchResults">
          <div class="searchlist__main-title">
            {{ $t('SEARCH__NO_RESULT') }}
          </div>
        </template>
        <template v-else>
          <div class="loading">
            <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
          </div>
        </template>
      </section>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/mobile/layouts/Navigation';
import search from '@/api/prod/search';

export default {
  name: 'Search',
  components: {
    Navigation,
  },
  data() {
    return {
      searchResults: null,
    };
  },
  computed: {
    searchString() {
      return this.$route.query.q;
    },
    searchType() {
      return this.$route.query.type;
    },
    searchCode() {
      return this.$route.query.code;
    },
    queryString() {
      return [this.$route.query.q, this.$route.query.type, this.$route.query.code].join();
    },
  },
  methods: {
    toArticle(id) {
      this.$router.push({
        name: 'MobileArticleGeneral',
        params: { id },
      });
    },
  },
  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.searchResults = null;
        search.getSearchResult(this.searchString, this.searchType, this.searchCode).then((resp) => {
          const { data } = resp;
          this.searchResults = data;
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .main {
    text-align: unset;
  }
  .game-section {
    display: flex;
    flex-flow: column;
    padding-top: 40px;
  }
  dl {
    cursor: pointer;
  }
  .article-active {
    color: $general-red;
  }
  .searchlist__main-title {
    color: $general-dark-text-color;
    font-size: 17px;
    font-weight: bold;
    line-height: 28px;
    padding: 0.8rem 0.8rem 0.4rem;
  }
  .loading {
    text-align: center;
    img {
      width: 15%;
    }
  }
}
</style>
