<template>
  <div class="main-wrap">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('APPLICATION__TITLE')" />
      <div class="main-inner">
        <div class="reportlist">
          <breadcrumb :paths="paths" />
          <div class="onlineservice-list__tab">
            <div
              class="onlineservice-list__tablink"
              :class="{ 'onlineservice-list__tablink--active': currentApplicationType === constants.APPLICATION__TYPES.VERIFICATION }"
              @click="switchApplicationType(constants.APPLICATION__TYPES.VERIFICATION)"
            >
              {{ $t('APPLICATION__TAB_VERIFICATION') }}
            </div>
            <div
              class="onlineservice-list__tablink"
              :class="{ 'onlineservice-list__tablink--active': currentApplicationType === constants.APPLICATION__TYPES.CANT_LOGIN }"
              @click="switchApplicationType(constants.APPLICATION__TYPES.CANT_LOGIN)"
            >
              {{ $t('APPLICATION__TAB_CANT_LOGIN') }}
            </div>
            <div
              class="onlineservice-list__tablink"
              :class="{ 'onlineservice-list__tablink--active': currentApplicationType === constants.APPLICATION__TYPES.OTHERS }"
              @click="switchApplicationType(constants.APPLICATION__TYPES.OTHERS)"
            >
              {{ $t('APPLICATION__TAB_OTHERS') }}
            </div>
          </div>
          <keep-alive>
            <div class="onlineservicelist-main">
              <div class="onlineservicelist-table">
                <table class="onlineservicelist-table__inner">
                  <thead>
                    <tr>
                      <th class="onlineservicelist-table__th onlineservicelist-table__date">
                        {{ $t('APPLICATION__RECORD_TIME') }}
                      </th>
                      <th class="onlineservicelist-table__th onlineservicelist-table__item">
                        {{ $t('APPLICATION__RECORD_ITEM') }}
                      </th>
                      <th class="onlineservicelist-table__th onlineservicelist-table__status">
                        {{ $t('APPLICATION__RECORD_STATUS') }}
                      </th>
                      <th v-if="showApplicationNotes" class="onlineservicelist-table__th onlineservicelist-table__note">
                        {{ $t('APPLICATION__RECORD_NOTES') }}
                      </th>
                    </tr>
                  </thead>
                  <template v-if="applications && applications.length > 0">
                    <tbody>
                      <tr class="onlineservicelist-table__tr" v-for="app in applications" :key="app.appCsNumber">
                        <td class="onlineservicelist-table__td onlineservicelist-table__date">
                          {{ utils.formatTimestampToString(app.createdAt, $t('GENERAL__DATETIME_FORMAT')) }}
                        </td>
                        <td class="onlineservicelist-table__td onlineservicelist-table__item">
                          <span v-for="(type, idx) in app.types" :key="idx">
                            {{ getApplicationTypeByValue(type) }}
                            <span v-if="idx < app.types.length - 1">,</span>
                          </span>
                        </td>
                        <td class="onlineservicelist-table__td onlineservicelist-table__status">
                          <div :class="{ 'onlineservicelist-table__statusval': isApplicationRejected(app.verificationStatus) }">
                            {{ getApplicationStatusByValue(app.verificationStatus) }}
                          </div>
                        </td>
                        <td v-if="showApplicationNotes" class="onlineservicelist-table__td onlineservicelist-table__notes">
                          <span>{{ app.notes }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </table>
              </div>
              <template v-if="!applications || applications.length === 0">
                <div class="onlineservicelist-empty">
                  <div class="onlineservicelist-empty__icon onlineservicelist-empty__icon--failure"></div>
                  <div class="onlineservicelist-empty__title">
                    {{ $t('APPLICATION__NO_RECORD') }}
                  </div>
                </div>
              </template>
            </div>
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';
import Navigation from '@/components/pc/layouts/Navigation';
import application from '@/api/prod/application';
import constants from '@/lib/constants';
import utils from '@/lib/utils';
import { ACTION_TRIGGER_POPUP } from '@/store/action-types';

export default {
  name: 'ApplicationList',
  components: {
    Navigation,
    Breadcrumb,
  },
  data() {
    return {
      constants,
      utils,
      applications: null,
      currentApplicationType: constants.APPLICATION__TYPES.VERIFICATION,
      paths: [
        {
          id: 1,
          name: 'ApplicationList',
          title: this.$t('APPLICATION__TITLE'),
        },
      ],
    };
  },
  computed: {
    showApplicationNotes() {
      return this.currentApplicationType in [constants.APPLICATION__TYPES.VERIFICATION, constants.APPLICATION__TYPES.CANT_LOGIN];
    },
    ...mapGetters(['isFbUser']),
  },
  methods: {
    switchApplicationType(type) {
      this.applications = [];
      this.currentApplicationType = type;
    },
    getApplicationTypeByValue(type) {
      if (this.currentApplicationType === constants.APPLICATION__TYPES.VERIFICATION) {
        let translationKey = 'VERIFICATION__RESET_PROFILE_APPLICATION_';
        translationKey += Object.keys(constants.APPLICATION__VERIFICATION_TYPES).find(
          (key) => constants.APPLICATION__VERIFICATION_TYPES[key] === type,
        );
        return this.$t(translationKey);
      }
      if (this.currentApplicationType === constants.APPLICATION__TYPES.CANT_LOGIN) {
        return this.$t('APPLICATION__TAB_CANT_LOGIN');
      }
      if (this.currentApplicationType === constants.APPLICATION__TYPES.OTHERS) {
        return type;
      }
      return null;
    },
    getApplicationStatusByValue(status) {
      if (this.currentApplicationType === constants.APPLICATION__TYPES.VERIFICATION) {
        let translationKey = 'APPLICATION__VERIFICATION_STATUS_';
        translationKey += Object.keys(constants.APPLICATION__VERIFICATION_STATUS).find(
          (key) => constants.APPLICATION__VERIFICATION_STATUS[key] === status,
        );
        return this.$t(translationKey);
      }
      if (this.currentApplicationType === constants.APPLICATION__TYPES.CANT_LOGIN) {
        let translationKey = 'APPLICATION__ACCOUNT_RECOVERY_STATUS_';
        translationKey += Object.keys(constants.APPLICATION__ACCOUNT_RECOVERY_STATUS).find(
          (key) => constants.APPLICATION__ACCOUNT_RECOVERY_STATUS[key] === status,
        );
        return this.$t(translationKey);
      }
      if (this.currentApplicationType === constants.APPLICATION__TYPES.OTHERS) {
        return status;
      }
      return null;
    },
    isApplicationRejected(status) {
      if (this.currentApplicationType === constants.APPLICATION__TYPES.VERIFICATION) {
        return constants.APPLICATION__VERIFICATION_STATUS_MAPPING[status] === 'NG';
      }
      if (this.currentApplicationType === constants.APPLICATION__TYPES.CANT_LOGIN) {
        return constants.APPLICATION__ACCOUNT_RECOVERY_STATUS_MAPPING[status] === 'NG';
      }
      return false;
    },
    getApplications(type) {
      application.getApplications(type).then((resp) => {
        const { data, error } = resp;
        if (error || !data) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_APPLICATION_LOAD_FAILED'),
          });
        } else {
          this.applications = data;
        }
      });
    },
    ...mapActions({
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
  },
  mounted() {
    this.getApplications(this.currentApplicationType);
  },
  watch: {
    isFbUser: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_APPLICATION_FB_ACCOUNT_CANT_VERIFICATION'),
          });
          this.$router.push({ name: 'Home' });
        }
      },
    },
    currentApplicationType: {
      handler(newValue) {
        this.getApplications(newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.onlineservice-list__tab {
  display: flex;
  padding: 0 68px;
  font-size: 18px;
  font-stretch: normal;
}
.onlineservice-list__tablink {
  padding: 10px;
  width: 250px;
  border-top: $general-border;
  border-left: $general-border;
  border-right: $general-border;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: $general-light-text-color;
  cursor: pointer;
  text-align: center;
  background-color: $general-grey;
  &:hover {
    color: $general-dark-text-color;
  }
  &--active {
    color: $general-dark-text-color;
    margin-bottom: -1px;
    background-color: $general-white;
    cursor: default;
  }
}
.onlineservicelist-table__notes {
  white-space: pre-wrap;
}
</style>
