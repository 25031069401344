<template>
  <div class="main-wrap">
    <section class="game-section">
      <div class="cantlogin-list__main">
        <div class="reportlist__main-top">
          <div class="reportlist__main-top--title">{{ $t('M_HOME__VERIFICATION_TITLE') }}</div>
        </div>
        <div class="cantlogin-list__main-list">
          <ul class="cproblem__main-list">
            <li @click="switchSectionAccount">
              <div class="cproblem__main-item">
                <p>{{ $t('VERIFICATION__ITEM_ACCOUNT_TITLE') }}</p>
                <div class="cantlogin-list__main-item--icon" :class="{ 'cproblem__main-item--icon--active': sectionAccountOpen }"></div>
              </div>
            </li>
            <transition name="slide-fade">
              <template v-if="sectionAccountOpen">
                <div class="verification-inner">
                  <div class="verification__inner">
                    <div class="verification__inner-icon verification__inner-icon--email"></div>
                    <div class="verification__inner-content" v-html="$t('M_VERIFICATION__ITEM_ACCOUNT_CONTENT')"></div>
                    <a href="https://account.garena.com/security?locale_name=TW" target="_blank" class="verification__inner-verf">
                      {{ $t('VERIFICATION__ITEM_ACCOUNT_LINKTEXT') }}
                    </a>
                  </div>
                </div>
              </template>
            </transition>
            <hr />
            <li @click="switchSection2Steps">
              <div class="cproblem__main-item">
                <p>{{ $t('VERIFICATION__ITEM_2STEPS_TITLE') }}</p>
                <div class="cantlogin-list__main-item--icon" :class="{ 'cproblem__main-item--icon--active': section2StepsOpen }"></div>
              </div>
            </li>
            <transition name="slide-fade">
              <template v-if="section2StepsOpen">
                <div class="verification-inner">
                  <div class="verification__inner">
                    <div class="verification__inner-icon verification__inner-icon--email"></div>
                    <div class="verification__inner-content" v-html="$t('M_VERIFICATION__ITEM_2STEPS_CONTENT')"></div>
                    <a
                      href="https://account.garena.com/security/mobile/2-step-verification?locale_name=TW"
                      target="_blank"
                      class="verification__inner-verf"
                    >
                      {{ $t('VERIFICATION__ITEM_2STEPS_LINKTEXT') }}
                    </a>
                  </div>
                </div>
              </template>
            </transition>
            <hr />
            <li @click="switchSectionReset">
              <div class="cproblem__main-item">
                <p>{{ $t('VERIFICATION__ITEM_RESET_TITLE') }}</p>
                <div class="cantlogin-list__main-item--icon" :class="{ 'cproblem__main-item--icon--active': sectionResetOpen }"></div>
              </div>
            </li>
            <transition name="slide-fade">
              <template v-if="sectionResetOpen">
                <div class="verification-inner">
                  <div class="verification__inner">
                    <div class="verification__inner-icon verification__inner-icon--email"></div>
                    <div class="verification__inner-content" v-html="$t('M_VERIFICATION__ITEM_RESET_CONTENT')"></div>
                    <router-link :to="{ name: 'MobileResetProfile' }" class="verification__inner-verf">
                      {{ $t('VERIFICATION__RESET_PROFILE_TITLE') }}
                    </router-link>
                  </div>
                </div>
              </template>
            </transition>
            <hr />
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ACTION_TRIGGER_POPUP } from '@/store/action-types';

export default {
  name: 'MobileVerification',
  data() {
    return {
      sectionAccountOpen: false,
      section2StepsOpen: false,
      sectionResetOpen: false,
    };
  },
  computed: {
    ...mapGetters(['isGarenaUser']),
  },
  methods: {
    switchSectionAccount() {
      this.sectionAccountOpen = !this.sectionAccountOpen;
    },
    switchSection2Steps() {
      this.section2StepsOpen = !this.section2StepsOpen;
    },
    switchSectionReset() {
      this.sectionResetOpen = !this.sectionResetOpen;
    },
    ...mapActions({
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
  },
  watch: {
    isGarenaUser: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_APPLICATION_FB_ACCOUNT_CANT_VERIFICATION'),
          });
          this.$router.push({ name: 'Home' });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.game-section {
  margin-bottom: 20px;
}
.mobile .cproblem__main-item--icon--active {
  transform: rotate(-45deg);
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
