var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-wrap main-wrap--hassearch main-wrap--game" },
    [
      _c("div", { staticClass: "main-bg", style: _vm.mainBgImage }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("navigation", {
            attrs: {
              title: _vm.currentGameName,
              "has-search-bar": true,
              "search-type": "game",
              "search-code": _vm.gameCodeOnUrl,
              "is-game-page": true
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "main-inner" },
            [
              _vm.isDataReady
                ? [
                    _c(
                      "div",
                      { staticClass: "cproblem" },
                      [
                        _c("breadcrumb", {
                          attrs: {
                            paths: _vm.paths,
                            smpd: true,
                            "has-border": true
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "cproblem-inner" },
                          [
                            _c("left-menu", {
                              attrs: {
                                "game-code": _vm.gameCodeOnUrl,
                                "active-category": _vm.baseCategoryNameOnUrl
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "cproblem__main" },
                              [
                                _vm.articles.length > 0
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "cproblem__title" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("ARTICLE__TITLE", {
                                                  currentBaseCategoryName:
                                                    _vm.currentBaseCategoryName
                                                })
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "ul",
                                        { staticClass: "cproblem__list" },
                                        [
                                          _vm._l(_vm.articles, function(
                                            article,
                                            idx
                                          ) {
                                            return _c(
                                              "li",
                                              {
                                                key: article.agcId,
                                                staticClass: "cproblem__item",
                                                class: {
                                                  "cproblem__item-hide":
                                                    idx >=
                                                      _vm.constants
                                                        .GENERAL__FAQ_PER_PAGE &&
                                                    !_vm.isShowingAllArticles
                                                }
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass:
                                                      "cproblem__link",
                                                    attrs: {
                                                      to: {
                                                        name: "Article",
                                                        params: {
                                                          gameCode:
                                                            _vm.gameCodeOnUrl,
                                                          baseCategory:
                                                            _vm.baseCategoryNameOnUrl,
                                                          agcId: article.agcId
                                                        }
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "cproblem__link-title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              article.title
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          }),
                                          _vm._v(" "),
                                          _vm.articles.length >
                                            _vm.constants
                                              .GENERAL__FAQ_PER_PAGE &&
                                          !_vm.isShowingAllArticles
                                            ? [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "cproblem__more",
                                                    on: {
                                                      click: _vm.showAllArticles
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "cproblem__more-title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "ARTICLE__SHOW_MORE"
                                                              )
                                                            ) +
                                                            "\n                        "
                                                        ),
                                                        _c("div", {
                                                          staticClass:
                                                            "cproblem__top-arrow cproblem__more-arrow_down"
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            : _vm.articles.length >=
                                                _vm.constants
                                                  .GENERAL__FAQ_PER_PAGE &&
                                              _vm.isShowingAllArticles
                                            ? [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "cproblem__top",
                                                    on: { click: _vm.toTop }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "cproblem__top-title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "ARTICLE__TO_TOP"
                                                              )
                                                            ) +
                                                            "\n                        "
                                                        ),
                                                        _c("div", {
                                                          staticClass:
                                                            "cproblem__top-arrow cproblem__top-arrow_up"
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showAIHelp
                                  ? _c("a", { staticClass: "cproblem-entry" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "cproblem-entry__item rectangle-button",
                                          on: {
                                            click: function($event) {
                                              _vm.triggerAIChatting(true)
                                              _vm.gaTrack(
                                                "click",
                                                "pc",
                                                "main",
                                                _vm.userInfo.uid
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "cproblem-entry__item-icon cproblem-entry__item-icon--createreport"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "cproblem-entry__item-content"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cproblem-entry__item-name"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "ARTICLE__TICKET_TITLE__" +
                                                            _vm.gameCodeOnUrl.toUpperCase()
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cproblem-entry__item-desc"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "ARTICLE__TICKET_DESC__" +
                                                            _vm.gameCodeOnUrl.toUpperCase()
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  : _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "cproblem-entry__item rectangle-button",
                                        attrs: {
                                          to: {
                                            name: "Ticket",
                                            params: {
                                              gameCode: _vm.gameCodeOnUrl
                                            },
                                            query: {
                                              bc: _vm.baseCategoryNameOnUrl
                                            }
                                          }
                                        }
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "cproblem-entry__item-icon cproblem-entry__item-icon--createreport"
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "cproblem-entry__item-content"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "cproblem-entry__item-name"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "ARTICLE__TICKET_TITLE"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "cproblem-entry__item-desc"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "ARTICLE__TICKET_DESC"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : [
                    _c("div", { staticClass: "loading" }, [
                      _c("img", {
                        staticClass: "icon-loading",
                        attrs: {
                          src: _vm.CDN + "/assets/pc/img/common/loading.gif",
                          alt: ""
                        }
                      })
                    ])
                  ]
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.currentGameId
        ? [
            _c("notification", {
              attrs: { pos: "game", "category-id": _vm.currentGameId }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }