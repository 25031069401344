<template>
  <div class="main-wrap">
    <template v-if="news">
      <section class="game-section">
        <div class="cantlogin-list__main">
          <div class="faqdetail-title">
            <div class="faqdetail__question">{{ news.title }}</div>
          </div>
        </div>
        <div class="faqdetail__content">
          <div class="ip-solution__txt" v-html="news.content"></div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import news from '@/api/prod/news';

export default {
  name: 'News',
  data() {
    return {
      news: null,
    };
  },
  computed: {
    currentArticleId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    news.getNewsById(this.$route.params.id).then((resp) => {
      const { data, error } = resp;
      if (error || !data) {
        if (error === 'error_no_matched_data') {
          this.$router.push(
            { name: 'MobileNotFound' },
            () => {},
            () => {},
          );
        } else {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_EXCEPTION'),
          });
        }
      } else {
        this.news = data;
      }
    });
  },
};
</script>

<style scoped>
.game-section {
  margin-bottom: 30px;
}
.ip-solution__txt {
  margin: 12px 12px 20px 12px;
}
</style>
