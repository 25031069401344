<template>
  <div :class="['marquee', { 'align-left': alignLeft }]" ref="containerRef">
    <p class="text-marquee" ref="textRef" v-html="text"></p>
  </div>
</template>

<script>
export default {
  name: 'ItemMarquee',
  props: {
    text: {
      type: String,
      default: '',
    },
    duration: {
      type: Number,
      default: 160,
    },
    alignLeft: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.textRef?.scrollWidth) {
        this.$refs.textRef.classList.add('run');
      }
    }, 300);
  },
};
</script>

<style lang="scss">
$ai-marquee: rgb(123, 123, 123);
@keyframes marquee {
  0% {
    transform: translateX(150%);
    -webkit-transform: translateX(150%);
  }
  100% {
    transform: translateX(-150%);
    -webkit-transform: translateX(-150%);
  }
}

.marquee {
  @include flexCenter(row);
  position: relative;
  width: 100%;
  overflow: hidden;
  color: $ai-marquee;

  &.align-left {
    justify-content: flex-start;
  }

  > p {
    font-size: 13px;
    white-space: nowrap;
    color: $ai-marquee;
    position: absolute;
    display: block;
    transform: translateX(150%);
    -webkit-transform: translateX(150%);

    &.run {
      animation: marquee 20s linear infinite;
      -webkit-animation: marquee 20s linear infinite;
    }
  }
}
</style>
