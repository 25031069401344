/* eslint-disable no-shadow */

import {
  MUTATION_SET_BASE_CATEGORIES,
  MUTATION_SET_TICKET_TOPIC_CATEGORIES,
  MUTATION_SET_TICKET_TOPICS,
  MUTATION_SET_ERROR,
  MUTATION_SET_MOBILE_LEGACY
} from '@/store/mutation-types';
import { ACTION_GET_BASE_CATEGORIES, ACTION_GET_TICKET_TOPIC_CATEGORIES, ACTION_GET_TICKET_TOPICS, ACTION_SET_MOBILE_LEGACY } from '@/store/action-types';
import category from '@/api/prod/category';

// initial state
const state = {
  baseCategories: null,
  ticketTopicCategories: null,
  ticketTopics: null,
  globalErrorMsg: undefined,
  inMobileLegacy: false,
};

const getters = {
  baseCategoriesCodeNameMapping(state) {
    const ret = {};
    if (!state.baseCategories) {
      return ret;
    }
    state.baseCategories.forEach((e) => {
      ret[e.code] = e.name;
    });
    return ret;
  },
  baseCategoriesCodeIdMapping(state) {
    const ret = {};
    if (!state.baseCategories) {
      return ret;
    }
    state.baseCategories.forEach((e) => {
      ret[e.code] = e.id;
    });
    return ret;
  },
  baseCategoriesIdMapping(state) {
    const ret = {};
    if (!state.baseCategories) {
      return ret;
    }
    state.baseCategories.forEach((e) => {
      ret[e.id] = { id: e.id, name: e.name, code: e.code };
    });
    return ret;
  },
  baseCategoriesIdTicketTopicCategoriesMapping(state) {
    const ret = {};
    if (!state.ticketTopicCategories) {
      return ret;
    }
    state.ticketTopicCategories.forEach((e) => {
      ret[e.id] = e.ticketTopicCategories;
    });
    return ret;
  },
  ticketTopicCategoryIdMapping(state) {
    const ret = {};
    if (!state.ticketTopicCategories) {
      return ret;
    }
    state.ticketTopicCategories.forEach((ticketTopicCategory) => {
      ticketTopicCategory.ticketTopicCategories.forEach((e) => {
        ret[e.id] = e;
        ret[e.id].baseCategoryId = ticketTopicCategory.id;
      });
    });
    return ret;
  },
  ticketTopicIdMapping(state) {
    const ret = {};
    if (!state.ticketTopics) {
      return ret;
    }
    state.ticketTopics.ticketTopics.forEach((e) => {
      ret[e.id] = e;
    });
    return ret;
  },
};

const actions = {
  async [ACTION_GET_BASE_CATEGORIES]({ commit }) {
    try {
      const resp = await category.getBaseCategories();
      const { data } = resp;
      commit(MUTATION_SET_BASE_CATEGORIES, data);
    } catch (err) {
      commit(MUTATION_SET_ERROR, { msg: err });
    }
  },
  async [ACTION_GET_TICKET_TOPIC_CATEGORIES]({ commit }, gameId) {
    try {
      const resp = await category.getTicketTopicCategories(gameId);
      const { data } = resp;
      commit(MUTATION_SET_TICKET_TOPIC_CATEGORIES, data);
    } catch (err) {
      commit(MUTATION_SET_ERROR, { msg: err });
    }
  },
  async [ACTION_GET_TICKET_TOPICS]({ commit }, ticketTopicCategoryId) {
    try {
      const resp = await category.getTicketTopicsByTicketTopicCategoryId(ticketTopicCategoryId);
      const { data } = resp;
      commit(MUTATION_SET_TICKET_TOPICS, data);
    } catch (err) {
      commit(MUTATION_SET_ERROR, { msg: err });
    }
  },
  [ACTION_SET_MOBILE_LEGACY]({ commit }){
    commit(MUTATION_SET_MOBILE_LEGACY)
  }
};

const mutations = {
  [MUTATION_SET_BASE_CATEGORIES](state, data) {
    state.baseCategories = data;
  },
  [MUTATION_SET_TICKET_TOPIC_CATEGORIES](state, data) {
    state.ticketTopicCategories = data;
  },
  [MUTATION_SET_TICKET_TOPICS](state, data) {
    state.ticketTopics = data;
  },
  [MUTATION_SET_ERROR](state, { msg }) {
    state.globalErrorMsg = msg;
  },
  [MUTATION_SET_MOBILE_LEGACY](state) {
    state.inMobileLegacy = !state.inMobileLegacy;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
