<template>
  <div class="md-radio md-radio-inline">
    <input class="radio__widget" type="radio"
           :id="[name + '-' + radioValue]"
           :name="name"
           :value="radioValue"
           :checked="val"
           @change="$emit('input', parseInt($event.target.value))" />
    <label :for="[name + '-' + radioValue]">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'CustomRadio',
  props: {
    label: String,
    name: String,
    radioValue: Number,
    isDisabled: Boolean,
    value: Number,
  },
  computed: {
    val() {
      return this.value === this.radioValue;
    },
  },
};
</script>
