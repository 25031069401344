<template>
  <div class="main-wrap">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('GENERAL__TITLE')" :has-search-bar="true" :isBlank="true" />
      <template v-if="article">
        <section class="game-section">
          <div class="cproblem__main-content">
            <div class="cproblem__main">
              <div class="faqdetail-title">
                <div class="faqdetail__question">{{ article.title }}</div>
              </div>
            </div>
            <div class="faqdetail__content">
              <div class="ip-solution__txt" v-html="article.content"></div>
              <!-- Article Tags -->
              <template v-if="!utils.isEmptyArray(article.tags)">
                <div class="faqdetail__tag">
                  <div class="faqdetail__statistics-item">
                    {{ $t('ARTICLE__TAGS') }}
                    <a v-for="(tag, idx) in article.tags" :key="idx" @click.prevent="" class="faqdetail__statistics-tag">
                      {{ tag }}
                    </a>
                  </div>
                </div>
              </template>
              <!-- Article Tags /-->
            </div>
            <div class="cproblem__main-grey"></div>
            <!-- Related Articles -->
            <template v-if="!utils.isEmptyArray(article.relatedArticles)">
              <div class="faqdetail-title">
                <div class="faqdetail__question">{{ $t('ARTICLE__RELATED_ARTICLE') }}</div>
              </div>
              <ul class="cproblem__main-list">
                <li v-for="relatedArticle in article.relatedArticles" :key="relatedArticle.id">
                  <router-link :to="{ name: 'MobileArticleGeneral', params: { id: relatedArticle.id } }" class="cproblem__main-item">
                    <div class="cproblem__main-item--icon"></div>
                    <p>{{ related_article.title }}</p>
                  </router-link>
                </li>
              </ul>
            </template>
            <!-- Related Articles /-->
          </div>
          <div class="cproblem__main-entry">
            <a class="cproblem__main-entry--item rectangle-button" @click.prevent="openGameList">
              <div>
                <div class="cproblem__main-entry--desc">
                  {{ $t('ARTICLE__TICKET_SUBTITLE') }}
                </div>
                <div class="cproblem__main-entry--name">
                  {{ $t('ARTICLE__TICKET_TITLE') }}
                </div>
                <div class="cproblem__main-entry--desc">
                  {{ $t('ARTICLE__TICKET_DESC') }}
                </div>
              </div>
              <div class="cproblem__main-entry--icon cproblem__main-entry--createreport"></div>
            </a>
            <div class="cproblem__main-grey"></div>
          </div>
        </section>
      </template>

      <template v-else>
        <div class="loading">
          <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
        </div>
      </template>
    </div>

    <!--  Popup for choosing games to enter ticket  -->
    <div v-if="isShowingGames" class="notification-wrap">
      <div class="notification-dimmer" @click.prevent="closeGameList">
        <div class="notification notification--sm">
          <button class="rountbutton__widget" @click.prevent="closeGameList">{{ $t('M_HOME__SERVICE_RECORD_POPUP_BUTTON') }}</button>
          <div class="notification-inner">
            <div class="notification__title">
              <div class="notification__title-txt">{{ $t('ARTICLE__TICKET_CHOOSE_GAME') }}</div>
            </div>
            <div class="notification__main">
              <div class="notification__content">
                <div class="notification__content-txt">
                  <div class="notification__content-txt">
                    <div class="home-games">
                      <div class="home-games__inner">
                        <div class="game-section__title">
                          <div class="game-section__title-icon game-section__title-icon-pcgames"></div>
                          <h3 class="game-section__title-txt">
                            {{ $t('HOME__PC_GAME_QUESTION') }}
                          </h3>
                        </div>
                        <ul class="home-games article_general__game">
                          <li class="home-games-game" v-for="game in games.pc" :key="game.id">
                            <router-link :to="{ name: 'MobileTicket', params: { gameCode: game.code } }" class="cproblem__link">
                              {{ game.name }}
                            </router-link>
                          </li>
                        </ul>
                        <div class="game-section__title">
                          <div class="game-section__title-icon game-section__title-icon-mgames"></div>
                          <h3 class="game-section__title-txt">
                            {{ $t('HOME__MOBILE_GAME_QUESTION') }}
                          </h3>
                        </div>
                        <ul class="home-games article_general__game">
                          <li class="home-games-game" v-for="game in games.mobile" :key="game.id">
                            <router-link :to="{ name: 'MobileTicket', params: { gameCode: game.code } }" class="cproblem__link">
                              {{ game.name }}
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  Popup for choosing games to enter ticket  /-->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Navigation from '@/components/mobile/layouts/Navigation';
import article from '@/api/prod/article';
import utils from '@/lib/utils';

export default {
  name: 'ArticleGeneral',
  components: {
    Navigation,
  },
  data() {
    return {
      utils,
      article: null,
      isShowingGames: false,
    };
  },
  computed: {
    currentArticleId() {
      return this.$route.params.id;
    },
    ...mapState({
      games: (state) => state.game.games,
      isAIChatting: (state) => state.common.isAIChatting,
    }),
  },
  methods: {
    openGameList() {
      this.isShowingGames = true;
    },
    closeGameList() {
      this.isShowingGames = false;
    },
  },
  watch: {
    currentArticleId: {
      immediate: true,
      handler(newValue) {
        article.getArticle(newValue).then((resp) => {
          const { data, error } = resp;
          if (error || !data) {
            if (error === 'error_no_matched_data') {
              this.$router.push(
                { name: 'MobileNotFound' },
                () => {},
                () => {},
              );
            } else {
              this.triggerPopup({
                title: this.$t('POPUP__ERROR'),
                content: this.$t('POPUP__ERROR_EXCEPTION'),
              });
            }
          } else {
            this.article = data;
          }
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .main-wrap {
    height: calc(100vh - 60px);
  }
  .main {
    height: 100%;
  }
  .game-section {
    display: flex;
    flex-flow: column;
  }
  .ip-solution__txt {
    margin: 12px 12px 20px 12px;
  }
  .cproblem__main-grey {
    height: 10px;
    background-color: #f8f9fa;
  }
  .cproblem__main-grey-down {
    background-color: #f8f9fa;
    flex-grow: 1;
  }
  .cproblem__main-entry--name {
    margin-top: 8px;
  }
  .home-games {
    .game-section__title {
      padding: 20px 0;
    }
    .home-games__inner {
      width: 100%;
    }
    .home-games-game {
      padding: 5px 0;
    }
    .cproblem__link {
      color: $general-dark-text-color;
    }
  }
  .loading {
    text-align: center;
    img {
      width: 15%;
    }
  }
}
</style>
