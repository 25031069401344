<template>
  <div class="main-wrap" :class="{ 'bg-white': isApplicationListEmpty }">
    <section class="game-section">
      <div class="onlineservice-list__tab">
        <div
          class="onlineservice-list__tablink"
          :class="{ 'onlineservice-list__tablink--active': currentApplicationType === constants.APPLICATION__TYPES.VERIFICATION }"
          @click="switchApplicationType(constants.APPLICATION__TYPES.VERIFICATION)"
        >
          {{ $t('APPLICATION__TAB_VERIFICATION') }}
        </div>
        <div
          class="onlineservice-list__tablink"
          :class="{ 'onlineservice-list__tablink--active': currentApplicationType === constants.APPLICATION__TYPES.CANT_LOGIN }"
          @click="switchApplicationType(constants.APPLICATION__TYPES.CANT_LOGIN)"
        >
          {{ $t('APPLICATION__TAB_CANT_LOGIN') }}
        </div>
        <div
          class="onlineservice-list__tablink"
          :class="{ 'onlineservice-list__tablink--active': currentApplicationType === constants.APPLICATION__TYPES.OTHERS }"
          @click="switchApplicationType(constants.APPLICATION__TYPES.OTHERS)"
        >
          {{ $t('APPLICATION__TAB_OTHERS') }}
        </div>
      </div>
      <hr class="onlineservice-list__tab_underline" :class="`onlineservice-list__tab_underline--${currentApplicationType}`" />
      <template v-if="applications && !utils.isEmptyArray(applications)">
        <div v-for="app in applications" :key="app.appCsNumber" class="onlineservice-list__main">
          <div class="onlineservice-list__content">
            <div class="onlineservice-list__row">
              <div class="onlineservice-list__title">{{ $t('APPLICATION__RECORD_TIME') }}</div>
              <div class="onlineservice-list__item">{{ utils.formatTimestampToString(app.createdAt, $t('GENERAL__DATETIME_FORMAT')) }}</div>
            </div>
            <div class="onlineservice-list__row">
              <div class="onlineservice-list__title">{{ $t('APPLICATION__RECORD_ITEM') }}</div>
              <div class="onlineservice-list__item">
                <span v-for="(type, idx) in app.types" :key="idx">
                  {{ getApplicationTypeByValue(type) }}
                  <span v-if="idx < app.types.length - 1"><br /></span>
                </span>
              </div>
            </div>
            <div class="onlineservice-list__row">
              <div class="onlineservice-list__title">{{ $t('APPLICATION__RECORD_STATUS') }}</div>
              <div
                class="onlineservice-list__item"
                :class="{
                  'onlineservice-list__item--ng': constants.APPLICATION__VERIFICATION_STATUS_MAPPING[app.verificationStatus] === 'NG',
                }"
              >
                {{ getApplicationStatusByValue(app.verificationStatus) }}
              </div>
            </div>
            <div v-if="showApplicationNotes" class="onlineservice-list__row">
              <div class="onlineservice-list__title">{{ $t('APPLICATION__RECORD_NOTES') }}</div>
              <div class="onlineservice-list__item onlineservice-list__notes">
                <span>{{ app.notes }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="isApplicationListEmpty">
        <div class="reportlist-main">
          <div class="reportlist-inner">
            <div class="reportlist__inner-icon reportlist__inner-icon--2"></div>
            <div class="reportlist__inner-txt">{{ $t('APPLICATION__NO_RECORD') }}</div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="reportlist__nomore">
          <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import application from '@/api/prod/application';
import constants from '@/lib/constants';
import utils from '@/lib/utils';
import { ACTION_TRIGGER_POPUP } from '@/store/action-types';

export default {
  name: 'ApplicationList',
  data() {
    return {
      constants,
      utils,
      applications: null,
      currentApplicationType: constants.APPLICATION__TYPES.VERIFICATION,
      paths: [
        {
          id: 1,
          name: 'ApplicationList',
          title: this.$t('APPLICATION__TITLE'),
        },
      ],
    };
  },
  computed: {
    isApplicationListEmpty() {
      return this.applications && utils.isEmptyArray(this.applications);
    },
    showApplicationNotes() {
      return this.currentApplicationType in [constants.APPLICATION__TYPES.VERIFICATION, constants.APPLICATION__TYPES.CANT_LOGIN];
    },
    ...mapGetters(['isFbUser']),
  },
  methods: {
    switchApplicationType(type) {
      this.applications = [];
      this.currentApplicationType = type;
    },
    getApplicationTypeByValue(type) {
      if (this.currentApplicationType === constants.APPLICATION__TYPES.VERIFICATION) {
        let translationKey = 'VERIFICATION__RESET_PROFILE_APPLICATION_';
        translationKey += Object.keys(constants.APPLICATION__VERIFICATION_TYPES).find(
          (key) => constants.APPLICATION__VERIFICATION_TYPES[key] === type,
        );
        return this.$t(translationKey);
      }
      if (this.currentApplicationType === constants.APPLICATION__TYPES.CANT_LOGIN) {
        return this.$t('APPLICATION__TAB_CANT_LOGIN');
      }
      if (this.currentApplicationType === constants.APPLICATION__TYPES.OTHERS) {
        return type;
      }
      return null;
    },
    getApplicationStatusByValue(status) {
      if (this.currentApplicationType === constants.APPLICATION__TYPES.VERIFICATION) {
        let translationKey = 'APPLICATION__VERIFICATION_STATUS_';
        translationKey += Object.keys(constants.APPLICATION__VERIFICATION_STATUS).find(
          (key) => constants.APPLICATION__VERIFICATION_STATUS[key] === status,
        );
        return this.$t(translationKey);
      }
      if (this.currentApplicationType === constants.APPLICATION__TYPES.CANT_LOGIN) {
        let translationKey = 'APPLICATION__ACCOUNT_RECOVERY_STATUS_';
        translationKey += Object.keys(constants.APPLICATION__ACCOUNT_RECOVERY_STATUS).find(
          (key) => constants.APPLICATION__ACCOUNT_RECOVERY_STATUS[key] === status,
        );
        return this.$t(translationKey);
      }
      if (this.currentApplicationType === constants.APPLICATION__TYPES.OTHERS) {
        return status;
      }
      return null;
    },
    getApplications(type) {
      application.getApplications(type).then((resp) => {
        const { data, error } = resp;
        if (error || !data) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_APPLICATION_LOAD_FAILED'),
          });
        } else {
          this.applications = data;
        }
      });
    },
    ...mapActions({
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
  },
  mounted() {
    this.getApplications(this.currentApplicationType);
  },
  watch: {
    isFbUser: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_APPLICATION_FB_ACCOUNT_CANT_VERIFICATION'),
          });
          this.$router.push({ name: 'Home' });
        }
      },
    },
    currentApplicationType: {
      handler(newValue) {
        this.getApplications(newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .bg-white {
    background-color: white;
  }
  .game-section {
    padding: 0;
    margin-bottom: 20px;
  }
  .onlineservice-list__item--ng {
    color: $general-red;
  }
  .reportlist__nomore {
    text-align: center;
    color: $general-light-text-color;
    img {
      width: 100px;
      height: 100px;
    }
  }
  .onlineservice-list__tab {
    height: 4.6rem;
    border-bottom: $general-border;
    display: flex;
    text-align: center;
  }
  .onlineservice-list__tablink {
    display: inline-block;
    flex: 1;
    padding: 1rem 1.5rem;
    align-self: center;
    &--active {
      color: $general-red;
    }
  }
  .onlineservice-list__notes {
    white-space: pre-wrap;
  }
  .onlineservice-list__tab_underline {
    margin: unset;
    border-top: 2px solid $general-red;
    position: absolute;
    top: 4.5rem;
    width: 33%;
    transition-duration: 0.2s;
    &.onlineservice-list__tab_underline--0 {
      left: 0;
    }
    &.onlineservice-list__tab_underline--1 {
      left: 33%;
    }
    &.onlineservice-list__tab_underline--2 {
      left: 67%;
    }
  }
}
</style>
