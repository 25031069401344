/* eslint-disable */

import api from '@/api';

function login(accessToken) {
  const params = {
    accessToken: accessToken,
  };
  return api.call(api.GET, '/user/login', params);
}

function logout(accessToken) {
  const params = {
    accessToken: accessToken,
  };
  return api.call(api.GET, '/user/logout', params);
}

function getUserInfo() {
  return api.call(api.GET, '/user/me');
}

function getUnreadStats() {
  return api.call(api.GET, '/user/unread-stats');
}

// function login(accessToken) {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: JSON.parse(JSON.stringify({
//         data: {
//           name: 'Vicki Lin',
//           uid: 204347580,
//           platform: 1,
//           avatar: 'https://avatargarenanow-a.akamaihd.net/avatar/user/580/347/204347580.2164260864.jpg',
//         },
//         error: 'error_invalid_token',
//       })),
//     });
//   });
// }
//
// function getUserInfo() {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: {
//         name: 'Vicki Lin',
//         uid: 204347580,
//         platform: 1,
//         avatar: 'https://avatargarenanow-a.akamaihd.net/avatar/user/580/347/204347580.2164260864.jpg',
//       },
//     });
//   });
// }
//
// function getUnreadStats() {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: JSON.parse(JSON.stringify({
//         data: {
//           ticket: 5,
//           application: 0,
//         },
//         error: 'error_require_login',
//       })),
//     });
//   });
// }

export default {
  login,
  logout,
  getUserInfo,
  getUnreadStats,
};
