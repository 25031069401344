/* eslint-disable */

import api from '@/api';
// import common from '@/utils/common';

export function getChatConfig(eventKey) {
  return api.callAI(api.GET, { eventKey, endpoint: '/config' });
  // return new Promise((resolve) => {
  //   resolve({
  //     ...common.camelizeKeys({
  //       before_grading_period: 2,
  //       before_system_inform_period: 3,
  //       close_period: 5,
  //       greetings: '你好，我是智慧型小幫手，你可以在對話框內輸入任何想問的問題，我會盡可能回答你哦',
  //       marquee: '請雙方隊長務必透過約戰留言板進行溝通約戰',
  //       quick_reply: [{ title: '遊戲相關', content: '', children: [{ title: '我的帳號被停權了', content: '請填寫客服回報單' }] }],
  //       score_range: 1,
  //       title: 'Garena 傳說對決智能小幫手',
  //       token_limit: 1000,
  //     }),
  //     // error: 'ERROR__SESSION_EXPIRE',
  //     // code: 123,
  //   });
  // });
}

export function postCreateRoom(eventKey) {
  return api.callAI(api.POST, { eventKey, endpoint: '/create_room' });
  // return new Promise((resolve) => {
  //   resolve({
  //     ...common.camelizeKeys({
  //       chat_room_uuid: '4c29e740-8320-44d8-91b1-fa0d937c5a57',
  //       current_tokens: 0,
  //     }),
  //     // error: 'ERROR__SERVER_ERROR',
  //     // code: 412,
  //   });
  // });
}

export function postChat({ eventKey, chatRoomUuid, message }) {
  return api.callAI(api.POST, { eventKey, endpoint: '/chat' }, { chatRoomUuid, message });
  // return new Promise((resolve) => {
  //   resolve({
  //     ...common.camelizeKeys({
  //       current_tokens: 2,
  //       message: '請從官網登入',
  //     }),
  //     //   error: 'error_require_login',
  //     // error: 'ERROR__SERVER_ERROR',
  //     // code: 412,
  //   });
  // });
}

export function postGrading({ eventKey, chatRoomUuid, score }) {
  return api.callAI(api.POST, { eventKey, endpoint: '/grading' }, { chatRoomUuid, score });
  // return new Promise((resolve) => {
  //   resolve({
  //     //   error: 'error_require_login',
  //   });
  // });
}
