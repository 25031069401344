<template>
  <div class="main-wrap">
    <div class="main-bg main-bg-game" :style="mainBgImage" />
    <div class="main">
      <navigation
        :title="currentGameName"
        :has-search-bar="true"
        :search-type="'game'"
        :search-code="gameCodeOnUrl"
        :is-blank="true"
        :is-game-page="true"
      />
      <template v-if="isDataReady">
        <section class="game-section">
          <div class="cproblem__main">
            <div class="cproblem__main-top">
              <h2 class="cproblem__main-top--title">{{ currentGameName }} - {{ currentBaseCategoryName }}</h2>
            </div>
            <template v-if="articles.length > 0">
              <ul class="cproblem__main-list">
                <li v-for="(article, idx) in articles" :key="article.agcId" class="cproblem__item">
                  <router-link
                    :to="{
                      name: 'MobileArticle',
                      params: { gameCode: gameCodeOnUrl, baseCategory: baseCategoryNameOnUrl, agcId: article.agcId },
                    }"
                    class="cproblem__main-item"
                    :class="{ 'cproblem__item-hide': idx >= constants.GENERAL__FAQ_PER_PAGE && !isShowingAllArticles }"
                  >
                    <div class="cproblem__main-item--icon"></div>
                    <p>{{ article.title }}</p>
                  </router-link>
                </li>
                <template v-if="articles.length > constants.GENERAL__FAQ_PER_PAGE && !isShowingAllArticles">
                  <li class="cproblem__more" @click="showAllArticles">
                    <div class="cproblem__more-title">
                      {{ $t('ARTICLE__SHOW_MORE') }}
                      <div class="cproblem__more-arrow"></div>
                    </div>
                  </li>
                </template>
              </ul>
              <div class="cproblem__main-grey"></div>
            </template>
            <div class="cproblem__main-entry">
              <a
                v-if="showAIHelp"
                @click="
                  triggerAIChatting(true);
                  gaTrack('click', 'mb', 'main', userInfo.uid);
                "
                class="cproblem__main-entry--item rectangle-button"
              >
                <div>
                  <div class="cproblem__main-entry--name">
                    {{ $t(`ARTICLE__TICKET_TITLE__${gameCodeOnUrl.toUpperCase()}`) }}
                  </div>
                  <div class="cproblem__main-entry--desc">
                    {{ $t(`ARTICLE__TICKET_DESC__${gameCodeOnUrl.toUpperCase()}`) }}
                  </div>
                </div>
                <div class="cproblem__main-entry--icon cproblem__main-entry--createreport"></div>
              </a>

              <router-link
                v-else
                :to="{ name: 'MobileTicket', params: { gameCode: gameCodeOnUrl }, query: { bc: baseCategoryNameOnUrl } }"
                class="cproblem__main-entry--item rectangle-button"
              >
                <div>
                  <div class="cproblem__main-entry--name">
                    {{ $t('ARTICLE__TICKET_TITLE') }}
                  </div>
                  <div class="cproblem__main-entry--desc">
                    {{ $t('ARTICLE__TICKET_DESC') }}
                  </div>
                </div>
                <div class="cproblem__main-entry--icon cproblem__main-entry--createreport"></div>
              </router-link>
            </div>
          </div>
        </section>
      </template>

      <template v-else>
        <div class="loading">
          <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { ACTION_TRIGGER_AI_CHATTING } from '@/store/action-types';
import Navigation from '@/components/mobile/layouts/Navigation';
import article from '@/api/prod/article';
import constants from '@/lib/constants';

export default {
  name: 'ArticleList',
  beforeRouteLeave(to, from, next) {
    if (this.isAIChatting && to.name !== 'MobileArticle') {
      const answer = window.confirm(this.$t('AI_FAQ__CONFIRM_LEAVE'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  components: {
    Navigation,
  },
  data() {
    return {
      isShowingAllArticles: false,
      articles: [],
      gameCodeOnUrl: this.$route.params.gameCode,
      baseCategoryNameOnUrl: this.$route.params.baseCategory,
      constants,
    };
  },
  computed: {
    currentGameName() {
      return this.gamesCodeNameMapping[this.gameCodeOnUrl] || '';
    },
    currentBaseCategoryName() {
      return this.baseCategoriesCodeNameMapping[this.baseCategoryNameOnUrl] || '';
    },
    currentGameId() {
      return this.gamesCodeIdMapping[this.gameCodeOnUrl];
    },
    currentBaseCategoryId() {
      return this.baseCategoriesCodeIdMapping[this.baseCategoryNameOnUrl];
    },
    mainBgImage() {
      return {
        'background-image': `url(${process.env.CDN}/games/${this.gameCodeOnUrl}/bg-main-${this.gameCodeOnUrl}.jpg)`,
      };
    },
    isDataReady() {
      return this.currentGameId && this.currentBaseCategoryId;
    },
    showAIHelp() {
      return (this.gameCodeOnUrl === 'aov' || this.gameCodeOnUrl === 'ff') && process.env.REGION === 'tw';
    },
    ...mapState({
      games: (state) => state.game.games,
      baseCategories: (state) => state.category.baseCategories,
      isAIChatting: (state) => state.common.isAIChatting,
    }),
    ...mapGetters(['gamesCodeNameMapping', 'gamesCodeIdMapping', 'baseCategoriesCodeNameMapping', 'baseCategoriesCodeIdMapping']),
  },
  methods: {
    showAllArticles() {
      this.isShowingAllArticles = true;
    },
    ...mapActions({
      triggerAIChatting: ACTION_TRIGGER_AI_CHATTING,
    }),
  },
  watch: {
    isDataReady: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.isShowingAllArticles = false;
          article.getArticlesByGameAndCategory(this.currentGameId, this.currentBaseCategoryId).then((resp) => {
            const { data } = resp;
            this.articles = data;
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .loading {
    margin-top: 100px;
    text-align: center;
    img {
      width: 15%;
    }
  }
  .cproblem__main-item img {
    margin-right: 14px;
  }
  .cproblem__main-grey {
    height: 10px;
    background-color: #f8f9fa;
  }
  .cproblem__item-hide {
    display: none;
  }
  .cproblem__more {
    text-align: center;
    padding: 15px 0;
    &-title {
      display: inline-block;
      color: $general-light-text-color;
      cursor: pointer;
    }
    &-arrow {
      width: 0;
      height: 0;
      border-top: solid 4px;
      border-left: solid 4px;
      border-right: solid 4px;
      border-color: transparent;
      border-top-color: currentColor;
      display: inline-block;
      vertical-align: middle;
    }
    &-arrow:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  }
}
</style>
