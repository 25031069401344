var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: { title: _vm.$t("NEWS__TITLE"), "has-search-bar": false }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "main-inner" }, [
          _c(
            "div",
            { staticClass: "news" },
            [
              _c("breadcrumb", {
                attrs: { paths: _vm.paths, smpd: true, "has-border": false }
              }),
              _vm._v(" "),
              _vm.newsList
                ? [
                    _c(
                      "div",
                      { staticClass: "news-inner" },
                      [
                        _c("div", { staticClass: "news__title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("NEWS__TITLE")) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "news__list" },
                          _vm._l(_vm.newsList, function(news) {
                            return _c(
                              "li",
                              { key: news.id, staticClass: "news__item" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "news__link",
                                    attrs: {
                                      to: {
                                        name: "News",
                                        params: { id: news.id }
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "news__link-title" },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(news.title) +
                                            "\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "news__link-views" },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.utils.formatTimestampToString(
                                                news.publishAt,
                                                _vm.$t(
                                                  "GENERAL__DATETIME_FORMAT"
                                                )
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.newsList && _vm.newsList.length > 0
                          ? [
                              _c("pagination", {
                                attrs: {
                                  currentOffset: _vm.newsPagination.curOffset,
                                  totalCnt: _vm.newsPagination.totalCnt,
                                  perPage:
                                    _vm.constants.GENERAL__RECORDS_PER_PAGE
                                }
                              })
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }