export const MUTATION_SET_ACCOUNT_RECOVERY_PLAYER_INFO_FIELDS = 'MUTATION_SET_ACCOUNT_RECOVERY_PLAYER_INFO_FIELDS';
export const MUTATION_SET_GAMES = 'MUTATION_SET_GAMES';
export const MUTATION_SET_BASE_CATEGORIES = 'MUTATION_SET_BASE_CATEGORIES';
export const MUTATION_SET_TICKET_TOPIC_CATEGORIES = 'MUTATION_SET_TICKET_TOPIC_CATEGORIES';
export const MUTATION_SET_TICKET_TOPICS = 'MUTATION_SET_TICKET_TOPICS';
export const MUTATION_SET_USER_INFO = 'MUTATION_SET_USER_INFO';
export const MUTATION_SET_UNREAD_STATS = 'MUTATION_SET_UNREAD_STATS';
export const MUTATION_SET_ERROR = 'MUTATION_SET_ERROR';
export const MUTATION_SWITCH_LOGIN_POPUP = 'MUTATION_SWITCH_LOGIN_POPUP';
export const MUTATION_TRIGGER_POPUP = 'MUTATION_TRIGGER_POPUP';
export const MUTATION_CLOSE_POPUP = 'MUTATION_CLOSE_POPUP';
export const MUTATION_SET_NEWS = 'MUTATION_SET_NEWS';
export const MUTATION_SET_TOP_NEWS = 'MUTATION_SET_TOP_NEWS';
export const MUTATION_SET_PAGINATION = 'MUTATION_SET_PAGINATION';
export const MUTATION_SET_MOBILE_LEGACY = 'MUTATION_SET_MOBILE_LEGACY';
export const MUTATION_SWITCH_AI_CHATTING = 'MUTATION_SWITCH_AI_CHATTING';
export const MUTATION_TRIGGER_AI_CHATTING = 'MUTATION_TRIGGER_AI_CHATTING';
