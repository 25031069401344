<template>
  <div class="main-wrap main-wrap--hassearch main-wrap--game">
    <div class="main-bg" :style="mainBgImage" />
    <div class="main">
      <navigation :title="currentGameName" :has-search-bar="true" :search-type="'game'" :search-code="gameCodeOnUrl" :is-game-page="true" />
      <div class="main-inner">
        <template v-if="isDataReady">
          <div class="cproblem">
            <breadcrumb :paths="paths" :smpd="true" :has-border="true" />
            <div class="cproblem-inner">
              <left-menu :game-code="gameCodeOnUrl" :active-category="baseCategoryNameOnUrl" />
              <div class="cproblem__main">
                <template v-if="articles.length > 0">
                  <div class="cproblem__title">
                    {{ $t('ARTICLE__TITLE', { currentBaseCategoryName: currentBaseCategoryName }) }}
                  </div>
                  <ul class="cproblem__list">
                    <li
                      v-for="(article, idx) in articles"
                      :key="article.agcId"
                      class="cproblem__item"
                      :class="{ 'cproblem__item-hide': idx >= constants.GENERAL__FAQ_PER_PAGE && !isShowingAllArticles }"
                    >
                      <router-link
                        :to="{
                          name: 'Article',
                          params: { gameCode: gameCodeOnUrl, baseCategory: baseCategoryNameOnUrl, agcId: article.agcId },
                        }"
                        class="cproblem__link"
                      >
                        <div class="cproblem__link-title">
                          {{ article.title }}
                        </div>
                      </router-link>
                    </li>
                    <template v-if="articles.length > constants.GENERAL__FAQ_PER_PAGE && !isShowingAllArticles">
                      <li class="cproblem__more" @click="showAllArticles">
                        <div class="cproblem__more-title">
                          {{ $t('ARTICLE__SHOW_MORE') }}
                          <div class="cproblem__top-arrow cproblem__more-arrow_down"></div>
                        </div>
                      </li>
                    </template>
                    <!-- TODO: mobile 也加上？ -->
                    <template v-else-if="articles.length >= constants.GENERAL__FAQ_PER_PAGE && isShowingAllArticles">
                      <li class="cproblem__top" @click="toTop">
                        <div class="cproblem__top-title">
                          {{ $t('ARTICLE__TO_TOP') }}
                          <div class="cproblem__top-arrow cproblem__top-arrow_up"></div>
                        </div>
                      </li>
                    </template>
                  </ul>
                </template>
                <a v-if="showAIHelp" class="cproblem-entry">
                  <div
                    @click="
                      triggerAIChatting(true);
                      gaTrack('click', 'pc', 'main', userInfo.uid);
                    "
                    class="cproblem-entry__item rectangle-button"
                  >
                    <div class="cproblem-entry__item-icon cproblem-entry__item-icon--createreport"></div>
                    <div class="cproblem-entry__item-content">
                      <div class="cproblem-entry__item-name">
                        {{ $t(`ARTICLE__TICKET_TITLE__${gameCodeOnUrl.toUpperCase()}`) }}
                      </div>
                      <div class="cproblem-entry__item-desc">
                        {{ $t(`ARTICLE__TICKET_DESC__${gameCodeOnUrl.toUpperCase()}`) }}
                      </div>
                    </div>
                  </div>
                </a>
                <router-link
                  v-else
                  :to="{ name: 'Ticket', params: { gameCode: gameCodeOnUrl }, query: { bc: baseCategoryNameOnUrl } }"
                  class="cproblem-entry__item rectangle-button"
                >
                  <div class="cproblem-entry__item-icon cproblem-entry__item-icon--createreport"></div>
                  <div class="cproblem-entry__item-content">
                    <div class="cproblem-entry__item-name">
                      {{ $t('ARTICLE__TICKET_TITLE') }}
                    </div>
                    <div class="cproblem-entry__item-desc">
                      {{ $t('ARTICLE__TICKET_DESC') }}
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="loading">
            <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
          </div>
        </template>
      </div>
    </div>

    <template v-if="currentGameId">
      <notification :pos="'game'" :category-id="currentGameId" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { ACTION_TRIGGER_AI_CHATTING } from '@/store/action-types';
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';
import Navigation from '@/components/pc/layouts/Navigation';
import Notification from '@/components/pc/layouts/Notification';
import LeftMenu from '@/components/pc/layouts/article/LeftMenu';
import article from '@/api/prod/article';
import constants from '@/lib/constants';
import utils from '@/lib/utils';

export default {
  name: 'ArticleList',
  beforeRouteLeave(to, from, next) {
    if (this.isAIChatting && to.name !== 'Article') {
      const answer = window.confirm(this.$t('AI_FAQ__CONFIRM_LEAVE'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  components: {
    Breadcrumb,
    Navigation,
    Notification,
    LeftMenu,
  },
  data() {
    return {
      constants,
      utils,
      isShowingAllArticles: false,
      articles: [],
      gameCodeOnUrl: this.$route.params.gameCode,
    };
  },
  computed: {
    baseCategoryNameOnUrl() {
      return this.$route.params.baseCategory;
    },
    currentGameName() {
      return this.gamesCodeNameMapping[this.gameCodeOnUrl] || '';
    },
    currentBaseCategoryName() {
      return this.baseCategoriesCodeNameMapping[this.baseCategoryNameOnUrl] || '';
    },
    currentGameId() {
      return this.gamesCodeIdMapping[this.gameCodeOnUrl];
    },
    currentBaseCategoryId() {
      return this.baseCategoriesCodeIdMapping[this.baseCategoryNameOnUrl];
    },
    isDataReady() {
      return this.currentGameId && this.currentBaseCategoryId;
    },
    paths() {
      return [
        {
          id: 1,
          name: 'ArticleList',
          params: { gameCode: this.gameCodeOnUrl, baseCategory: 'game' },
          title: this.currentGameName,
        },
        {
          id: 2,
          name: '',
          params: {},
          title: this.currentBaseCategoryName,
        },
      ];
    },
    mainBgImage() {
      return {
        'background-image': `url(${process.env.CDN}/games/${this.gameCodeOnUrl}/bg-main-${this.gameCodeOnUrl}.jpg)`,
      };
    },
    showAIHelp() {
      return (this.gameCodeOnUrl === 'aov' || this.gameCodeOnUrl === 'ff') && process.env.REGION === 'tw';
    },
    ...mapState({
      games: (state) => state.game.games,
      baseCategories: (state) => state.category.baseCategories,
      isAIChatting: (state) => state.common.isAIChatting,
      userInfo: (state) => state.user.userInfo,
    }),
    ...mapGetters(['gamesCodeNameMapping', 'gamesCodeIdMapping', 'baseCategoriesCodeNameMapping', 'baseCategoriesCodeIdMapping']),
  },
  methods: {
    showAllArticles() {
      this.isShowingAllArticles = true;
    },
    toTop() {
      window.scrollTo(0, 0);
    },
    ...mapActions({
      triggerAIChatting: ACTION_TRIGGER_AI_CHATTING,
    }),
  },
  watch: {
    // Handle invalid gameCode.
    gamesCodeIdMapping: {
      immediate: true,
      handler(newValue) {
        if (!this.utils.isEmptyObject(newValue) && !(this.gameCodeOnUrl in newValue)) {
          this.$router.replace(
            { name: 'NotFound' },
            () => {},
            () => {},
          );
        }
      },
    },
    // Handle invalid baseCategory.
    baseCategoriesCodeIdMapping: {
      immediate: true,
      handler(newValue) {
        if (!this.utils.isEmptyObject(newValue) && !(this.baseCategoryNameOnUrl in newValue)) {
          this.$router.replace(
            { name: 'NotFound' },
            () => {},
            () => {},
          );
        }
      },
    },
    isDataReady: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.isShowingAllArticles = false;
          article.getArticlesByGameAndCategory(this.currentGameId, this.currentBaseCategoryId).then((resp) => {
            const { data } = resp;
            this.articles = data;
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.pc {
  .main-wrap {
    position: relative;
  }
  .loading {
    text-align: center;
    padding-bottom: 30px;
  }
  .cproblem__item-hide {
    display: none;
  }
  .cproblem__more,
  .cproblem__top {
    text-align: center;
    padding: 15px 0;
    &-title {
      display: inline-block;
      color: $general-light-text-color;
      cursor: pointer;
    }
    &-arrow {
      width: 0;
      height: 0;
      border-left: solid 4px;
      border-right: solid 4px;
      border-color: transparent;
      border-top-color: currentColor;
      display: inline-block;
      vertical-align: middle;
      &_down {
        border-top: solid 4px;
      }
      &_up {
        border-bottom: solid 4px;
      }
    }
    &-arrow:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  }
}
</style>
