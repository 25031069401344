<template>
  <div class="main-wrap">
    <section class="game-section">
      <div class="reportlist-main">
        <div class="faqdetail-title">
          <div class="faqdetail__question">{{ $t('VERIFICATION__RESET_PROFILE_TITLE') }}</div>
          <div class="reportlist-result-subhead"
               v-html="$t('M_VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_SUCCESS_TEXT')"></div>
        </div>
        <div class="reportlist-inner">
          <template v-if="status === constants.APPLICATION__VERIFICATION_RESULTS.SUCCESS">
            <div class="reportlist__inner-icon reportlist__inner-icon--1"></div>
            <div class="reportlist__inner-txt">
              {{ $t('VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_SUCCESS_TITLE') }}
            </div>
          </template>
          <template v-if="status === constants.APPLICATION__VERIFICATION_RESULTS.FAILURE">
            <div class="reportlist__inner-icon reportlist__inner-icon--2"></div>
            <div class="reportlist__inner-txt">
              {{ $t('VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_FAILURE_TITLE') }}
            </div>
          </template>
        </div>
      </div>
    </section>
    <div class="reportlist__return">
      <router-link :to="{ name: 'Home' }" class="reportlist__inner-verf">
        {{ $t('M_VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_BUTTON') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import constants from '@/lib/constants';

export default {
  name: 'ResetProfileCompletion',
  data() {
    return {
      constants,
      status: this.$route.params.status,
    };
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .reportlist__return {
    line-height: 46px;
  }
}
</style>
