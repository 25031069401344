var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-radio md-radio-inline" }, [
    _c("input", {
      staticClass: "radio__widget",
      attrs: {
        type: "radio",
        id: [_vm.name + "-" + _vm.radioValue],
        name: _vm.name
      },
      domProps: { value: _vm.radioValue, checked: _vm.val },
      on: {
        change: function($event) {
          _vm.$emit("input", parseInt($event.target.value))
        }
      }
    }),
    _vm._v(" "),
    _c("label", { attrs: { for: [_vm.name + "-" + _vm.radioValue] } }, [
      _vm._v(_vm._s(_vm.label))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }