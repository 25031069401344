<template>
  <div class="main-wrap main-wrap--hassearch">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('GENERAL__TITLE')" :has-search-bar="true" :search-type="searchType" :search-code="searchCode" />
      <div class="main-inner">
        <div class="search">
          <breadcrumb :paths="paths" />
          <div class="main-title">
            {{ $t('SEARCH__RESULT_TITLE') }}
            <span class="search__keyword">{{ searchString }}</span>
          </div>
          <div class="search-main">
            <div class="search-result">
              <template v-if="searchResults && searchResults.length > 0">
                <dl
                  v-for="result in searchResults"
                  :key="result.id"
                  class="search-result__item"
                  ref="searchResult"
                  @click="toArticle(result.id)"
                >
                  <dt class="search-result__q">
                    <div class="search-result__prefix">Q:</div>
                    <div class="search-result__main">
                      {{ result.title }}
                    </div>
                  </dt>
                  <dd class="search-result__a">
                    <div class="search-result__prefix">A:</div>
                    <div class="search-result__main search-result__main__a" v-html="result.content"></div>
                  </dd>
                </dl>
              </template>
              <template v-else-if="searchResults">
                <div class="search-result__a">
                  {{ $t('SEARCH__NO_RESULT') }}
                </div>
              </template>
              <!-- loading -->
              <template v-else>
                <div class="loading">
                  <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
                </div>
              </template>
              <!-- loading /-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/pc/layouts/Navigation';
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';
import search from '@/api/prod/search';

export default {
  name: 'Search',
  components: {
    Breadcrumb,
    Navigation,
  },
  data() {
    return {
      paths: [
        {
          id: 1,
          name: 'Search',
          params: {},
          title: this.$t('SEARCH__BREADCRUMB'),
        },
      ],
      searchResults: null,
    };
  },
  computed: {
    searchString() {
      return this.$route.query.q.trim();
    },
    searchType() {
      return this.$route.query.type;
    },
    searchCode() {
      return this.$route.query.code;
    },
    queryString() {
      return [this.$route.query.q, this.$route.query.type, this.$route.query.code].join();
    },
  },
  methods: {
    toArticle(id) {
      this.$router.push({
        name: 'ArticleGeneral',
        params: { id },
      });
    },
    changeArticleColor(idx) {
      this.$refs.searchResult[idx].classList.add('article-active');
    },
    resetArticleColor(idx) {
      this.$refs.searchResult[idx].classList.remove('article-active');
    },
  },
  watch: {
    queryString: {
      immediate: true,
      handler() {
        this.searchResults = null;
        search.getSearchResult(this.searchString, this.searchType, this.searchCode).then((resp) => {
          const { data } = resp;
          this.searchResults = data;
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.pc {
  .loading {
    text-align: center;
  }
}
</style>
