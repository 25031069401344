<template>
  <div class="main-wrap main-wrap--hassearch">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('GENERAL__TITLE')" :has-search-bar="true" />
      <div class="main-inner main-inner--nobg">
        <div class="home">
          <div class="home-main">
            <template v-if="isTwVersion">
              <section class="home-section">
                <div class="home-entry">
                  <router-link :to="{ name: 'CantLogin' }" class="home-entry__item rectangle-button">
                    <div class="home-entry__item-icon home-entry__item-icon--cantlogin"></div>
                    <div class="home-entry__item-content">
                      <div class="home-entry__item-name">
                        {{ $t('HOME__CANT_LOGIN_TITLE') }}
                      </div>
                      <div class="home-entry__item-desc">
                        {{ $t('HOME__CANT_LOGIN_DESC') }}
                      </div>
                    </div>
                  </router-link>
                  <router-link :to="{ name: 'Verification' }" class="home-entry__item rectangle-button">
                    <div class="home-entry__item-icon home-entry__item-icon--accountsc"></div>
                    <div class="home-entry__item-content">
                      <div class="home-entry__item-name">
                        {{ $t('HOME__VERIFICATION_TITLE') }}
                      </div>
                      <div class="home-entry__item-desc">
                        {{ $t('HOME__VERIFICATION_DESC') }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </section>
            </template>
            <template v-if="games">
              <game-list :games="games.pc ? games.pc : []" gameType="pc" v-show="games.pc" />
              <game-list :games="games.mobile ? games.mobile : []" gameType="mobile" v-show="games.mobile" />
            </template>
          </div>
          <div class="home-aside">
            <user />
            <template v-if="isTwVersion">
              <top-news />
            </template>
          </div>
        </div>
      </div>
    </div>
    <notification :pos="'home'" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Navigation from '@/components/pc/layouts/Navigation';
import Notification from '@/components/pc/layouts/Notification';
import GameList from '@/components/pc/layouts/home/GameList';
import TopNews from '@/components/pc/layouts/home/TopNews';
import User from '@/components/pc/layouts/home/User';
import { ACTION_GET_GAMES } from '@/store/action-types';

export default {
  name: 'Home',
  components: {
    Navigation,
    Notification,
    GameList,
    TopNews,
    User,
  },
  computed: {
    ...mapState({
      games: (state) => state.game.games,
    }),
  },
  methods: {
    ...mapActions({
      getGames: ACTION_GET_GAMES,
    }),
  },
};
</script>
