<template>
  <div class="custom_input-wrap">
    <div class="input-wrap">
      <date-picker
        :class="{ 'input--error': !isValid }"
        :value-type="valueType"
        :type="type"
        :format="format"
        :time-picker-options="timePickerOptions"
        :lang="lang"
        :placeholder="placeholder ? placeholder : $t('GENERAL__PLACEHOLDER_DEFAULT_SELECT_DATE')"
        :disabled-date="notAfterToday"
        v-model="val"
      >
        <div slot="calendar-icon" class="profile-form__inputdateicon"></div>
      </date-picker>
    </div>
    <div v-if="!isValid" class="report-form__error">{{ errorMsg }}</div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'CustomDatetimePicker',
  props: {
    type: { type: String, default: 'datetime' },
    placeholder: String,
    format: String,
    isRequired: Boolean,
    errorMsg: String,
    value: Number,
    valueType: { type: String, default: 'X' },
    notAfter: Date,
    isValidIfNull: { type: Boolean, default: true },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      val: this.value,
      lang: {
        days: [
          this.$t('GENERAL__CALENDAR_DAY_SUN'),
          this.$t('GENERAL__CALENDAR_DAY_MON'),
          this.$t('GENERAL__CALENDAR_DAY_TUE'),
          this.$t('GENERAL__CALENDAR_DAY_WED'),
          this.$t('GENERAL__CALENDAR_DAY_THU'),
          this.$t('GENERAL__CALENDAR_DAY_FRI'),
          this.$t('GENERAL__CALENDAR_DAY_SAT'),
        ],
        months: [
          this.$t('GENERAL__CALENDAR_MONTH_JAN'),
          this.$t('GENERAL__CALENDAR_MONTH_FEB'),
          this.$t('GENERAL__CALENDAR_MONTH_MAR'),
          this.$t('GENERAL__CALENDAR_MONTH_APR'),
          this.$t('GENERAL__CALENDAR_MONTH_MAY'),
          this.$t('GENERAL__CALENDAR_MONTH_JUN'),
          this.$t('GENERAL__CALENDAR_MONTH_JUL'),
          this.$t('GENERAL__CALENDAR_MONTH_AUG'),
          this.$t('GENERAL__CALENDAR_MONTH_SEP'),
          this.$t('GENERAL__CALENDAR_MONTH_OCT'),
          this.$t('GENERAL__CALENDAR_MONTH_NOV'),
          this.$t('GENERAL__CALENDAR_MONTH_DEC'),
        ],
      },
      timePickerOptions: {
        start: '00:00',
        step: '01:00',
        end: '23:00',
      },
    };
  },
  computed: {
    isValid() {
      return this.validate(this.val);
    },
  },
  methods: {
    validate(value) {
      if (this.isValidIfNull && value === null) {
        return true;
      }
      let hasValueIfRequired = true;
      if (this.isRequired) {
        hasValueIfRequired = value !== null;
      }
      return hasValueIfRequired;
    },
    notAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date.getTime() > today.getTime();
    },
  },
  watch: {
    value(newVal) {
      this.val = String(newVal);
    },
    val(newVal) {
      this.$emit('input', parseInt(newVal, 10));
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrap ::v-deep ::placeholder {
  color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.input-wrap ::v-deep .mx-datepicker {
  width: 100%;
}
.input-wrap ::v-deep .mx-input-wrapper {
  height: 42px;
}
.input-wrap ::v-deep .mx-input {
  height: 100%;
  border: 1px solid $general-input-border-color;
  border-radius: 3px;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.input-wrap ::v-deep .mx-calendar-content .cell.actived {
  background-color: $general-red;
  color: #fff;
}
.input-wrap ::v-deep .mx-panel-date td.today {
  color: $general-red;
}
.input-wrap .input--error ::v-deep .mx-input {
  border-color: $general-red;
}
</style>
