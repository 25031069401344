var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-wrap" },
    [
      _vm.isDataReady
        ? [
            _c("div", { staticClass: "onlineservicedet" }, [
              _c("div", { staticClass: "onlineservicedet-main" }, [
                _c("div", { staticClass: "onlineservicedet-recordwrap" }, [
                  _c("div", { staticClass: "report-linking" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("REPORT__LINK_CONTENT")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "report-user" }, [
                    _c("div", { staticClass: "report-user__info" }, [
                      _c("div", { staticClass: "report-user__avatar-wrap" }, [
                        _c("div", { staticClass: "report-user__avatar" }, [
                          _c("img", {
                            staticClass: "report-user__avatar-img",
                            attrs: { src: _vm.userInfo.avatar, alt: "" }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "report-user__channel" }, [
                          _c("img", {
                            staticClass: "report-user__channel-img",
                            attrs: {
                              src:
                                _vm.CDN +
                                "/assets/share/img/icon-circle-platform-" +
                                _vm.userInfo.platform +
                                ".png",
                              alt: ""
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "report-user__data" }, [
                        _c("div", { staticClass: "report-user__account" }, [
                          _c("div", { staticClass: "report-user__name" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.userInfo.name) +
                                "\n                  "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("div", { staticClass: "report-linking" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("REPORT__LINK_CONFIRM_MSG")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "report-form__next" }, [
                    _c(
                      "div",
                      { staticClass: "report-linking__btn" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "MobileHome" } } },
                          [
                            _c(
                              "button",
                              { staticClass: "reportlist__return" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("REPORT__LINK_CONFIRM_NO_BUTTON")
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "report-linking__btn" }, [
                      _c(
                        "button",
                        {
                          staticClass: "reportlist__return",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.linkTicketLink.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("REPORT__LINK_CONFIRM_YES_BUTTON"))
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ])
            ])
          ]
        : [
            _c("div", { staticClass: "loading" }, [
              _c("img", {
                staticClass: "icon-loading",
                attrs: {
                  src: _vm.CDN + "/assets/pc/img/common/loading.gif",
                  alt: ""
                }
              })
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }