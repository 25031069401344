var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", { attrs: { title: _vm.$t("REPORT__DETAIL_TITLE") } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "main-inner main-inner--nobg" },
          [
            _vm.isDataReady
              ? [
                  _c(
                    "div",
                    { staticClass: "onlineservicedet" },
                    [
                      _c("breadcrumb", { attrs: { paths: _vm.paths } }),
                      _vm._v(" "),
                      _c("div", { staticClass: "onlineservicedet-main" }, [
                        _c(
                          "div",
                          { staticClass: "onlineservicedet-linkwrap" },
                          [
                            _c("div", { staticClass: "report-linking" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("REPORT__LINK_CONTENT")) +
                                  "\n              "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "report-user" }, [
                              _c("div", { staticClass: "report-user__info" }, [
                                _c(
                                  "div",
                                  { staticClass: "report-user__avatar-wrap" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "report-user__avatar" },
                                      [
                                        _c("img", {
                                          staticClass:
                                            "report-user__avatar-img",
                                          attrs: {
                                            src: _vm.userInfo.avatar,
                                            alt: ""
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "report-user__channel" },
                                      [
                                        _c("img", {
                                          staticClass:
                                            "report-user__channel-img",
                                          attrs: {
                                            src:
                                              _vm.CDN +
                                              "/assets/share/img/icon-circle-platform-" +
                                              _vm.userInfo.platform +
                                              ".png",
                                            alt: ""
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "report-user__data" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "report-user__account" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "report-user__name" },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(_vm.userInfo.name) +
                                                "\n                      "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("div", { staticClass: "report-linking" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("REPORT__LINK_CONFIRM_MSG")) +
                                  "\n              "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "report-form__next" }, [
                              _c(
                                "div",
                                { staticClass: "rountbutton" },
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: { name: "Home" } } },
                                    [
                                      _c(
                                        "button",
                                        { staticClass: "rountbutton__widget" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "REPORT__LINK_CONFIRM_NO_BUTTON"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "rountbutton" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "rountbutton__widget",
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.linkTicketLink.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "REPORT__LINK_CONFIRM_YES_BUTTON"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ]
              : [
                  _c(
                    "div",
                    { staticClass: "onlineservicedet" },
                    [
                      _c("breadcrumb", { attrs: { paths: _vm.paths } }),
                      _vm._v(" "),
                      _c("div", { staticClass: "loading" }, [
                        _c("img", {
                          staticClass: "icon-loading",
                          attrs: {
                            src: _vm.CDN + "/assets/pc/img/common/loading.gif",
                            alt: ""
                          }
                        })
                      ])
                    ],
                    1
                  )
                ]
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }