<template>
  <div v-if="hasNotification" class="notification-wrap">
    <div class="notification-dimmer"></div>
    <div class="notification" :class="{ 'notification--sm': isSmallNotification }">
      <div class="notification-inner">
        <div class="notification__title">
          <div class="notification__title-icon"></div>
          <div class="notification__title-txt">
            {{ $t('NOTIFICATION__TITLE') }}
          </div>
        </div>
        <div class="notification__main">
          <template v-if="!isSmallNotification">
            <div class="notification__img">
              <img :src="notification.img" alt="" class="notification__img-inner" />
            </div>
          </template>

          <div class="notification__content faqdetail__content">
            <!--<div class="notification__content-greeting"></div>-->
            <div class="notification__content-txt" v-html="notification.content"></div>
          </div>
        </div>
        <div class="rountbutton" @click="closeNotification">
          <button class="rountbutton__widget">
            {{ $t('GENERAL__BUTTON_NOTIFICATION') }}
          </button>
        </div>

        <a @click.prevent="closeNotification" class="notification__close"></a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import notification from '@/api/prod/notification';

export default {
  name: 'Notification',
  props: ['pos', 'categoryId'],
  data() {
    return {
      hasNotification: false,
      notification: null,
    };
  },
  computed: {
    isSmallNotification() {
      return Boolean(this.notification && !this.notification.img);
    },
    ...mapState({
      hasPopup: (state) => state.common.hasPopup,
    }),
  },
  methods: {
    closeNotification() {
      this.hasNotification = false;
    },
  },
  mounted() {
    if (!this.hasPopup) {
      notification.getNotification(this.pos, this.categoryId).then((resp) => {
        const { data } = resp;
        if (data) {
          this.hasNotification = true;
          this.notification = data;
        }
      });
    }
  },
};
</script>
