import axios from 'axios';
import utils from '@/utils/common';
import lib from '@/lib/utils';

const API = `/api/v1/${process.env.REGION}`;
const GET = 'get';
const POST = 'post';

function bindKeys(fn, ...newargs) {
  return function _(...args) {
    fn.apply(this, args.concat(newargs));
  };
}

function api(method, endpoint, dataOrParams = {}, config = {}) {
  const url = `${API}${endpoint}`;
  const source = axios.CancelToken.source();
  let data = {};
  let params = {};
  switch (method) {
    case GET:
      params = utils.snakifyKeys(dataOrParams);
      break;
    case POST:
      if (dataOrParams instanceof FormData) {
        data = dataOrParams;
      } else {
        data = utils.snakifyKeys(dataOrParams);
      }
      break;
    default:
      break;
  }
  const reqConfig = {};
  if (config.onUploadProgress) {
    reqConfig.onUploadProgress = bindKeys(config.onUploadProgress, source);
  }
  const req = {
    method,
    url,
    params,
    data,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': lib.getCSRFToken(),
    },
    withCredentials: true,
    cancelToken: source.token,
    ...reqConfig,
  };
  return axios(req).then((resp) => utils.camelizeKeys(resp.data));
}

async function faqAPI(method, { eventKey, endpoint }, dataOrParams = {}, config = {}) {
  const prefix = window.location.origin.includes('test') ? 'test-' : '';
  const accessToken = localStorage.getItem('userToken');
  const url = `https://${prefix}ai-faq.support.garena.com/${eventKey}/api${endpoint}`;

  const source = axios.CancelToken.source();
  let data = {};
  let params = {};
  switch (method) {
    case GET:
      params = utils.snakifyKeys(dataOrParams);
      break;
    case POST:
      if (dataOrParams instanceof FormData) {
        data = dataOrParams;
      } else {
        data = utils.snakifyKeys(dataOrParams);
      }
      break;
    default:
      break;
  }
  const reqConfig = {};
  if (config.onUploadProgress) {
    reqConfig.onUploadProgress = bindKeys(config.onUploadProgress, source);
  }
  const req = {
    method,
    url,
    params,
    data,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': lib.getCSRFToken(),
      'Access-Token': accessToken,
    },
    withCredentials: true,
    cancelToken: source.token,
    ...reqConfig,
  };
  return axios(req).then((resp) => {
    // console.log('🚀 ~ returnaxios ~ resp:', utils.camelizeKeys({ ...resp.data }));

    return utils.camelizeKeys({ ...resp.data });
  });
}

export default {
  call: api,
  GET,
  POST,
  callAI: faqAPI,
};
