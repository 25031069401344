<template>
  <div>
    <div class="reportlist__detail-main">
      <form class="report-form">
        <div class="report-form__content">
          <div class="report-form__label">
            {{ $t('REPORT__CONTINUE_REPLY_TITLE') }}
          </div>
          <custom-textarea
            name="continue_reply_text"
            :placeholder="textareaPlaceholder"
            :is-limit-length="true"
            v-model="fieldsValue.content"
          />
          <uploader
            :key="uploaderComponentKey"
            @uploading="updateUploadingFiles"
            @uploaded="addUploadedFileIds"
            @removed="removeUploadedFileIds"
            :label="$t('REPORT__CONTINUE_REPLY_UPLOAD_FILE')"
            name="continue_reply_file"
          />
        </div>

        <div class="report-form__content">
          <custom-checkbox name="feedback_email_checkbox" :label="$t('REPORT__CONTINUE_REPLY_SEND_EMAIL')" v-model="isNewEmailRequired" />
          <template v-if="isNewEmailRequired">
            <div class="report-form__row">
              <div class="report-form__inline">
                <div class="report-form__label">
                  {{ $t('REPORT__CONTINUE_REPLY_CHANGE_EMAIL') }}
                </div>
                <div class="report-form__field">
                  <custom-input
                    name="notification_email_address"
                    :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH')"
                    :custom-type="constants.GENERAL__INPUT_TYPES.EMAIL"
                    :error-msg="$t('GENERAL__INPUT_ERROR_EMAIL_FORMAT')"
                    v-model="newUserEmail"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
      </form>
    </div>
    <button class="reportlist__return" :disabled="!isButtonEnabled" @click.prevent="submitReply">
      {{ $t('GENERAL__BUTTON_SUBMIT_REPLY') }}
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CustomCheckbox from '@/components/mobile/layouts/partial/CustomCheckbox';
import CustomInput from '@/components/share/layouts/partial/CustomInput';
import CustomTextarea from '@/components/share/layouts/partial/CustomTextarea';
import Uploader from '@/components/share/layouts/partial/Uploader';
import ticket from '@/api/prod/ticket';
import constants from '@/lib/constants';
import utils from '@/lib/utils';
import { ACTION_TRIGGER_POPUP } from '@/store/action-types';

export default {
  name: 'Continue',
  props: {
    csNumber: Number,
    isEmailRequired: Boolean,
    userEmail: String,
  },
  components: {
    CustomCheckbox,
    CustomInput,
    CustomTextarea,
    Uploader,
  },
  data() {
    return {
      constants,
      utils,
      uploadingFiles: {},
      fieldsValue: {
        content: null,
        files: [],
      },
      uploaderComponentKey: 0,
      isNewEmailRequired: this.isEmailRequired,
      newUserEmail: null,
      isSubmitting: false,
    };
  },
  computed: {
    textareaPlaceholder() {
      let text = this.$t('REPORT__CONTINUE_REPLY_PLACEHOLDER');
      if (this.isTwVersion) {
        text += `\n${this.$t('GENERAL__SERVICE_PHONE')}\n${this.$t('GENERAL__SERVICE_TIME')}`;
      }
      return text;
    },
    isEmailFilledIfRequired() {
      return !(!this.utils.isValidEmail(this.newUserEmail) && this.isNewEmailRequired);
    },
    isUploading() {
      return !utils.isEmptyObject(this.uploadingFiles) && Object.values(this.uploadingFiles).some((file) => !file.uploadCompleted);
    },
    isButtonEnabled() {
      return this.fieldsValue.content && this.isEmailFilledIfRequired && !this.isUploading;
    },
  },
  methods: {
    updateReplyText(name, value) {
      this.fieldsValue.content = value;
    },
    updateUploadingFiles(files) {
      this.uploadingFiles = files;
    },
    addUploadedFileIds(name, fileId) {
      this.fieldsValue.files.push(fileId);
    },
    removeUploadedFileIds(name, fileId) {
      const idx = this.fieldsValue.files.indexOf(fileId);
      this.fieldsValue.files.splice(idx, 1);
    },
    submitReply() {
      this.isSubmitting = true;
      const payload = this.fieldsValue;
      payload.need_notification_email = this.isNewEmailRequired;
      if (this.isNewEmailRequired) {
        payload.notification_email_address = this.newUserEmail;
      }
      ticket.replyTicket(this.csNumber, payload).then((resp) => {
        const { data, error } = resp;
        if (error || !data) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_TICKET_REPLY_CREATE_FAILED'),
          });
          this.isSubmitting = false;
        } else {
          this.triggerPopup({
            title: this.$t('POPUP__ALERT_TICKET_REPLY_CREATE_SUCCEED_TITLE', { csNumber: this.csNumber }),
            content: this.$t('POPUP__ALERT_TICKET_REPLY_CREATE_SUCCEED_CONTENT', { csNumber: this.csNumber }),
          });
          // emit event to reload ticket replies
          this.$emit('submit');
          // reset textarea content and uploaded files
          this.$set(this.fieldsValue, 'content', null);
          this.$set(this.fieldsValue, 'files', []);
          this.uploaderComponentKey += 1;
          if (!this.isNewEmailRequired) {
            this.newUserEmail = null;
          }
        }
      });
    },
    ...mapActions({
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
  },
  mounted() {
    this.newUserEmail = this.userEmail;
  },
};
</script>

<style scoped>
.mobile .report-form__content ::v-deep .textarea {
  margin-bottom: 10px;
}
</style>
