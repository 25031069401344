var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cproblem__sidebar" }, [
    _c(
      "ul",
      { staticClass: "cproblem__category" },
      _vm._l(_vm.baseCategories, function(category) {
        return _c(
          "li",
          { key: category.id, staticClass: "cproblem__category-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "cproblem__category-link",
                class: {
                  "cproblem__category-link--active":
                    category.code === _vm.activeCategory
                },
                attrs: {
                  to: {
                    name: "ArticleList",
                    params: {
                      gameCode: _vm.gameCode,
                      baseCategory: category.code
                    }
                  }
                }
              },
              [
                _c("div", { staticClass: "cproblem__category-line" }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "cproblem__category-icon",
                  class: ["cproblem__category-icon--" + category.code]
                }),
                _vm._v(" "),
                _c("div", { staticClass: "cproblem__category-text" }, [
                  _vm._v("\n          " + _vm._s(category.name) + "\n        ")
                ])
              ]
            )
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }