<template>
  <div class="main-wrap newslist__container" @scroll="onScroll">
    <section class="game-section">
      <template v-if="newsList && newsList.length > 0">
        <div class="cantlogin-list__main">
          <div class="reportlist__main-top">
            <div class="reportlist__main-top--title">{{ $t('NEWS__TITLE') }}</div>
          </div>
          <div class="">
            <ul class="cproblem__main-list">
              <template v-for="news in newsList">
                <li :key="news.id">
                  <router-link :to="{ name: 'MobileNews', params: { id: news.id } }" class="cproblem__main-item">
                    <div class="cproblem__main-item--icon"></div>
                    <p>{{ news.title }}</p>
                  </router-link>
                </li>
                <hr :key="news.id + 'hr'" />
              </template>
            </ul>
          </div>
        </div>
        <div v-if="isLoadingMore" class="news__nomore">
          <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
        </div>
        <div v-if="!canLoadMore" class="news__nomore">
          {{ $t('M_NEWS__NO_MORE_NEWS') }}
        </div>
      </template>
      <template v-else>
        <div class="news__nomore">
          <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ACTION_GET_NEWS } from '@/store/action-types';
import constants from '@/lib/constants';

export default {
  name: 'NewsList',
  data() {
    return {
      currentPage: 1,
      canLoadMore: true,
      isLoadingMore: false,
    };
  },
  computed: {
    queryOffset() {
      return (this.currentPage - 1) * constants.GENERAL__RECORDS_PER_PAGE;
    },
    ...mapState({
      newsList: (state) => state.news.allNews,
      newsPagination: (state) => state.news.pagination,
    }),
  },
  methods: {
    onScroll({ target }) {
      if (!target) return;
      const { scrollTop, clientHeight, scrollHeight } = target;
      if (scrollTop + clientHeight >= scrollHeight && this.canLoadMore && !this.isLoadingMore) {
        this.currentPage += 1;
      }
    },
    ...mapActions({
      getNewsList: ACTION_GET_NEWS,
    }),
  },
  watch: {
    queryOffset: {
      immediate: true,
      async handler(newValue) {
        this.isLoadingMore = true;
        await this.getNewsList(newValue);
        if (this.newsPagination.curOffset + constants.GENERAL__RECORDS_PER_PAGE >= this.newsPagination.totalCnt) {
          this.canLoadMore = false;
        }
        this.isLoadingMore = false;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .newslist__container {
    overflow: scroll;
    position: fixed;
    top: 60px;
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
  }
  .game-section {
    padding: 0;
    margin-bottom: 20px;
  }
  .cproblem__main-item img {
    margin-right: 14px;
  }
  .news__nomore {
    text-align: center;
    color: $general-light-text-color;
    img {
      width: 60px;
      height: 60px;
    }
  }
}
</style>
