export default {
  Vars: {
    OriginalTitle: document.title,
    Interval: null,
  },
  On(notification, intervalSpeed) {
    // eslint-disable-next-line no-underscore-dangle
    const _this = this;
    if (_this.Vars.Interval) {
      _this.Off();
    }

    _this.Vars.Interval = setInterval(function () {
      document.title = _this.Vars.OriginalTitle === document.title ? notification : _this.Vars.OriginalTitle;
    }, intervalSpeed || 1000);
  },
  Off() {
    clearInterval(this.Vars.Interval);
    this.Vars.Interval = null;
    document.title = this.Vars.OriginalTitle;
  },
};
