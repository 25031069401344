<template>
  <div class="main-wrap">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('REPORT__DETAIL_TITLE')" />
      <div class="main-inner main-inner--nobg">
        <!-- ticket not found -->
        <template v-if="errorMsg === 'error_no_matched_data'">
          <div class="onlineservicedet">
            <breadcrumb :paths="paths" />
            <div class="main-inner__not-found">
              <div class="main-inner__not-found-title">{{ $t('REPORT__DETAIL_NOT_FOUND') }}</div>
              <div class="main-inner__not-found-desc" v-html="$t('REPORT__DETAIL_NOT_FOUND_DESC')"></div>
            </div>
          </div>
        </template>
        <!-- ticket not found /-->

        <!-- ticket info -->
        <template v-else-if="isDataReady">
          <div class="onlineservicedet">
            <breadcrumb :paths="paths" />
            <div class="main-title">{{ $t('REPORT__DETAIL_CS_NUMBER') }} #{{ ticket.csNumber }}</div>
            <div v-if="ticket" class="onlineservicedet-main">
              <!-- Record Info -->
              <div class="onlineservicedet-info">
                <dl v-if="!isLegacy" class="onlineservicedet-info__item">
                  <dt class="onlineservicedet-info__item-title">
                    {{ $t('REPORT__DETAIL_GAME') }}
                  </dt>
                  <dd>{{ ticket.gameName }}</dd>
                </dl>
                <dl class="onlineservicedet-info__item">
                  <dt class="onlineservicedet-info__item-title">
                    {{ $t('REPORT__DETAIL_BASE_CATEGORY') }}
                  </dt>
                  <dd>
                    {{ ticket.baseCategoryName }}
                  </dd>
                </dl>
                <dl class="onlineservicedet-info__item">
                  <dt class="onlineservicedet-info__item-title">
                    {{ $t('REPORT__DETAIL_TOPIC_CATEGORY') }}
                  </dt>
                  <dd>
                    {{ ticket.ticketTopicCategoryName }}
                  </dd>
                </dl>
                <dl class="onlineservicedet-info__item">
                  <dt class="onlineservicedet-info__item-title">
                    {{ $t('REPORT__DETAIL_TOPIC') }}
                  </dt>
                  <dd>
                    {{ ticket.ticketTopicName }}
                  </dd>
                </dl>
              </div>
              <!-- Record Info /-->

              <div class="onlineservicedet-recordwrap">
                <div class="onlineservicedet-record">
                  <reply
                    v-for="reply in ticket.replies"
                    :key="reply.id"
                    :cs-number="ticket.csNumber"
                    :reply-id="reply.id"
                    :type="reply.type"
                    :creator-type="reply.creatorType"
                    :created-at="reply.createdAt"
                    :content="reply.content"
                    :attachments="reply.attachments"
                    :numOfAttachments="reply.numOfAttachments"
                    :staff-name="reply.staffName"
                    :is-helpful="reply.isHelpful"
                    :has-rated="reply.isHelpful !== null"
                    :hide-rated="isLegacy"
                  />
                  <template v-if="showFeedback">
                    <feedback :cs-number="ticket.csNumber" :feedback="ticket.feedback" :can-provide-feedback="ticket.canProvideFeedback" />
                  </template>
                </div>
              </div>
            </div>
          </div>

          <template v-if="canContinueReport">
            <continue
              :cs-number="ticket.csNumber"
              :is-email-required="ticket.needNotificationEmail"
              :user-email="ticket.notificationEmailAddress"
              @submit="getTicketByCsNumber(csNumber, isLegacy)"
            />
          </template>
        </template>

        <!-- loading -->
        <template v-else>
          <div class="onlineservicedet">
            <breadcrumb :paths="paths" />
            <div class="loading">
              <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
            </div>
          </div>
        </template>
        <!-- loading /-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';
import Navigation from '@/components/pc/layouts/Navigation';
import Reply from '@/components/pc/layouts/report/Reply';
import Feedback from '@/components/pc/layouts/report/Feedback';
import Continue from '@/components/pc/layouts/report/Continue';
import ticket from '@/api/prod/ticket';
import constants from '@/lib/constants';
import utils from '@/lib/utils';
import { ACTION_GET_TICKET_TOPIC_CATEGORIES, ACTION_GET_TICKET_TOPICS, ACTION_TRIGGER_POPUP } from '@/store/action-types';

export default {
  name: 'Report',
  components: {
    Reply,
    Continue,
    Feedback,
    Navigation,
    Breadcrumb,
  },
  data() {
    return {
      constants,
      utils,
      errorMsg: null,
      csNumber: this.$route.params.csNumber,
      isLegacy: Number(this.$route.query.source) === constants.TICKET__TICKET_STATUS_FOR_USER.LEGACY,
      ticket: null,
      paths: [
        {
          id: 1,
          name: 'ReportList',
          title: this.$t('REPORT__TITLE'),
        },
        {
          id: 2,
          name: 'Report',
          title: this.$t('REPORT__DETAIL_TITLE'),
        },
      ],
    };
  },
  computed: {
    isDataReady() {
      return this.userInfo && (this.errorMsg || this.ticket);
    },
    showFeedback() {
      return this.ticket.canProvideFeedback || (this.ticket.feedback && true);
    },
    canContinueReport() {
      return (
        constants.TICKET__TICKET_STATUS_MAPPING[this.ticket.ticketStatus].FILTER_STATUS !== constants.TICKET__TICKET_STATUS_FILTER.CLOSED &&
        !this.isLegacy
      );
    },
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      games: (state) => state.game.games,
      baseCategories: (state) => state.category.baseCategories,
    }),
  },
  methods: {
    ...mapActions({
      getTicketTopicCategories: ACTION_GET_TICKET_TOPIC_CATEGORIES,
      getTicketTopics: ACTION_GET_TICKET_TOPICS,
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
    getTicketByCsNumber(csNber, isLegacy) {
      ticket.getTicketByCsNumber(csNber, isLegacy).then((resp) => {
        const { data, error } = resp;
        if (error || !data) {
          if (error === 'error_no_matched_data') {
            this.errorMsg = error;
          } else {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_TICKET_LOAD_FAILED'),
            });
            this.$router.push({ name: 'ReportList' });
          }
        } else {
          this.ticket = data;
        }
      });
    },
  },
  mounted() {
    this.getTicketByCsNumber(this.csNumber, this.isLegacy);
  },
};
</script>

<style scoped lang="scss">
.loading {
  text-align: center;
}
.main-inner__not-found {
  padding: 22px 68px 16px;
  &-title {
    font-size: 20px;
    font-stretch: normal;
    font-weight: bold;
  }
  &-desc {
    ::v-deep a {
      color: $general-red;
    }
    padding: 40px 0 20px;
    ::v-deep ol {
      margin-top: 15px;
    }
    ::v-deep li {
      list-style: inside decimal;
      line-height: 30px;
    }
  }
}
</style>
