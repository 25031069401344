/* eslint-disable */

import api from '@/api';

function getSearchResult(searchString, searchType, searchCode) {
  const params = {
    q: searchString,
    type: searchType,
    code: searchCode,
  };
  return api.call(api.GET, `/articles`, params);
}

// function getSearchResult(searchString, searchType, searchCode) {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: JSON.parse(JSON.stringify({
//         data: [
//           {
//             id: 1,
//             title: '這是第一則' + searchString + '搜尋結果' + searchType + searchCode,
//             content: 'Garena官方賽事線上約戰系統採召喚師自行經由系統約戰，因此召喚師們在報名前務必確認全體隊員均有意願及時間能夠配合賽程（建議能備有後補隊員以免臨時有事不能出賽，一旦報名成功，將不能隨意更改任何資料），且務必地圖積極態度與對手連絡約戰，約戰前切記先加入對手好友名單討論後再送出約戰時間，如經舉報為態度消極的隊伍成立，則將列入觀察名單，切勿拿自身權益開玩笑。'
//           },
//           {
//             id: 2,
//             title: '這是第二則' + searchString + '搜尋結果',
//             content: '<s>臣亮言：</s>先帝創業未半，而中道崩殂。今天下三分，益州疲弊，此誠危急存亡之秋也。然侍衛之臣，不懈於內；忠志之士，忘身於外者，蓋追先帝之殊遇，欲報之於陛下也。誠宜開張聖聽，以光先帝遺德，恢弘志士之氣；不宜妄自菲薄，引喻失義，以塞忠諫之路也。宮中府中，俱為一體，陟罰臧否，不宜異同。若有作姦犯科，及為忠善者，宜付有司，論其刑賞，以昭陛下平明之治，不宜篇私，使內外異法也。'
//           },
//         ],
//         error: '',
//       })),
//     });
//   });
// }

export default {
  getSearchResult,
};
