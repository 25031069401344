import Vue from 'vue';
import VueGtag from 'vue-gtag';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import App from '@/App';
import constants from '@/lib/constants';
import store from '@/store';
import router from '@/router';
import { i18n } from '@/i18n';

Vue.config.productionTip = false;

Vue.use(VueGtag, {
    config: { id: 'G-RRM2SJW688' }
});

Vue.mixin({
  data() {
    let platformIds = [
      constants.GENERAL__GOP_PLATFORM.GARENA,
      constants.GENERAL__GOP_PLATFORM.FACEBOOK,
      constants.GENERAL__GOP_PLATFORM.GOOGLE,
      constants.GENERAL__GOP_PLATFORM.APPLE,
    ];
    const isTwVersion = process.env.REGION === 'tw';
    const isJpVersion = process.env.REGION === 'jp' || process.env.REGION === 'kr';
    const isSmpVersion = process.env.REGION === 'sg' || process.env.REGION === 'my' || process.env.REGION === 'ph';
    if (isJpVersion) {
      platformIds = [
        constants.GENERAL__GOP_PLATFORM.FACEBOOK,
        constants.GENERAL__GOP_PLATFORM.VK,
        constants.GENERAL__GOP_PLATFORM.GOOGLE,
        constants.GENERAL__GOP_PLATFORM.APPLE,
        constants.GENERAL__GOP_PLATFORM.TWITTER,
      ];
    }
    const { CDN } = process.env;
    return {
      CDN,
      isTwVersion,
      isJpVersion,
      isSmpVersion,
      platformIds,
    };
  },
  methods: {
    gaTrack (action, deviceType, value, uid) {
      this.$gtag.event(action, {
        'device_type': deviceType,
        'value': value,
        'uid': uid,
      })
    }
  }
});

window.vue = new Vue({
  el: '#app',
  store,
  router,
  i18n,
  render: (h) => h(App),
});

if (process.env.DEPLOY !== 'dev') {
  Sentry.init({
    dsn: 'https://4ade298f61a14c92961fe2d11852526f@pub.sentry.web.garenanow.com/235',
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  });
}
