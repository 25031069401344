<template v-if="totalPages > 1">
  <div class="pagination">
    <!-- previous page -->
    <a v-if="currentPage > 1" href="#" class="pagination__item"
       @click.prevent="changeCurrentPage(currentPage - 1)">
      <div class="pagination__item-prev"></div>
    </a>
    <a v-else class="pagination__item pagination__item--disabled">
      <div class="pagination__item-prev"></div>
    </a>
    <!-- previous page /-->

    <!-- @TODO: ellipsis pages -->
    <!-- pages -->
    <a v-for="page in totalPages" :key="page"
       href="#" class="pagination__item" :class="{ 'pagination__item--active': currentPage === page }"
       @click.prevent="changeCurrentPage(page)">
      {{ page }}
    </a>
    <!-- pages /-->

    <!-- next page -->
    <a v-if="currentPage < totalPages" href="#" class="pagination__item"
       @click.prevent="changeCurrentPage(currentPage + 1)">
      <div class="pagination__item-next"></div>
    </a>
    <a v-else class="pagination__item pagination__item--disabled">
      <div class="pagination__item-next"></div>
    </a>
    <!-- next page /-->

  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: ['currentOffset', 'totalCnt', 'perPage'],
  computed: {
    currentPage() {
      return Math.ceil(this.currentOffset / this.perPage) + 1;
    },
    totalPages() {
      return Math.ceil(this.totalCnt / this.perPage);
    },
  },
  methods: {
    changeCurrentPage(page) {
      const { query } = this.$route;
      this.$router.push({
        query: {
          ...query, // order matters!
          page,
        },
      });
    },
  },
};
</script>
