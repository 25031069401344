var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrap" }, [
    _c("section", { staticClass: "game-section" }, [
      _c("div", { staticClass: "cantlogin-list__main" }, [
        _c("div", { staticClass: "reportlist__main-top" }, [
          _c("div", { staticClass: "reportlist__main-top--title" }, [
            _vm._v(_vm._s(_vm.$t("M_HOME__CANT_LOGIN_TITLE")))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cantlogin-list__main-list" }, [
          _c(
            "ul",
            { staticClass: "cproblem__main-list" },
            [
              _c("li", { on: { click: _vm.switchSectionGameBlock } }, [
                _c("div", { staticClass: "cproblem__main-item" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("CANT_LOGIN__ITEM_GAMEBLOCK_TITLE")))
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "cantlogin-list__main-item--icon",
                    class: {
                      "cproblem__main-item--icon--active":
                        _vm.sectionGameBlockOpen
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "slide-fade" } },
                [
                  _vm.sectionGameBlockOpen
                    ? [
                        _c("div", { staticClass: "verification-inner" }, [
                          _c(
                            "div",
                            { staticClass: "verification__inner" },
                            [
                              _c("div", {
                                staticClass:
                                  "verification__inner-icon verification__inner-icon--suspended"
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "verification__inner-content",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "M_CANT_LOGIN__ITEM_GAMEBLOCK_CONTENT"
                                    )
                                  )
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "verification__inner-verf",
                                  attrs: { to: { name: "MobileGameList" } }
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "CANT_LOGIN__ITEM_GAMEBLOCK_LINKTEXT"
                                        )
                                      ) +
                                      "\n                  "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("li", { on: { click: _vm.switchSectionAccBlock } }, [
                _c("div", { staticClass: "cproblem__main-item" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("CANT_LOGIN__ITEM_ACCBLOCK_TITLE")))
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "cantlogin-list__main-item--icon",
                    class: {
                      "cproblem__main-item--icon--active":
                        _vm.sectionAccBlockOpen
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "slide-fade" } },
                [
                  _vm.sectionAccBlockOpen
                    ? [
                        _c("div", { staticClass: "verification-inner" }, [
                          _c(
                            "div",
                            { staticClass: "verification__inner" },
                            [
                              _c("div", {
                                staticClass:
                                  "verification__inner-icon verification__inner-icon--lock"
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "verification__inner-content",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "M_CANT_LOGIN__ITEM_ACCBLOCK_CONTENT"
                                    )
                                  )
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "verification__inner-verf",
                                  attrs: { to: { name: "MobileGameList" } }
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "CANT_LOGIN__ITEM_ACCBLOCK_LINKTEXT"
                                        )
                                      ) +
                                      "\n                  "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("li", { on: { click: _vm.switchSectionResetPwd } }, [
                _c("div", { staticClass: "cproblem__main-item" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("CANT_LOGIN__ITEM_RESETPWD_TITLE")))
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "cantlogin-list__main-item--icon",
                    class: {
                      "cproblem__main-item--icon--active":
                        _vm.sectionResetPwdOpen
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "slide-fade" } },
                [
                  _vm.sectionResetPwdOpen
                    ? [
                        _c("div", { staticClass: "verification-inner" }, [
                          _c(
                            "div",
                            { staticClass: "verification__inner" },
                            [
                              _c("div", {
                                staticClass:
                                  "verification__inner-icon verification__inner-icon--reset"
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "verification__inner-content",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "M_CANT_LOGIN__ITEM_RESETPWD_CONTENT"
                                    )
                                  )
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "verification__inner-verf",
                                  attrs: {
                                    to: { name: "MobileAccountRecovery" }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "CANT_LOGIN__ITEM_RESETPWD_LINKTEXT"
                                        )
                                      ) +
                                      "\n                  "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("hr")
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }