var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", { attrs: { title: _vm.$t("REPORT__TITLE") } }),
        _vm._v(" "),
        _c("div", { staticClass: "main-inner" }, [
          _c(
            "div",
            { staticClass: "reportlist" },
            [
              _c("breadcrumb", { attrs: { paths: _vm.paths } }),
              _vm._v(" "),
              _c("div", { staticClass: "main-title" }, [
                _c("div", { staticClass: "reportlist-title" }, [
                  _vm._v(_vm._s(_vm.$t("REPORT__TITLE")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "reportlist-filter" }, [
                  _c("div", { staticClass: "select-wrap" }, [
                    _c(
                      "div",
                      {
                        staticClass: "select",
                        on: { click: _vm.switchFilterMenu }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t(
                                "REPORT__STATUS_FILTER_".concat(
                                  _vm.ticketStatusNameMapping[
                                    _vm.currentTicketStatus
                                  ]["name"]
                                )
                              )
                            ) +
                            "\n              "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "select__arrow" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "select-options",
                        style: {
                          display: _vm.isFilterMenuVisible ? "block" : "none"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "select__option",
                            class: {
                              "select__option--active":
                                _vm.currentTicketStatus ===
                                _vm.constants.TICKET__TICKET_STATUS_FOR_USER.ALL
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.changeSelectedTicketStatus(
                                  _vm.constants.TICKET__TICKET_STATUS_FOR_USER
                                    .ALL
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("REPORT__STATUS_FILTER_ALL")) +
                                "\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "select__option",
                            class: {
                              "select__option--active":
                                _vm.currentTicketStatus ===
                                _vm.constants.TICKET__TICKET_STATUS_FOR_USER
                                  .PENDING
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.changeSelectedTicketStatus(
                                  _vm.constants.TICKET__TICKET_STATUS_FOR_USER
                                    .PENDING
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$t("REPORT__STATUS_FILTER_PENDING")
                                ) +
                                "\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "select__option",
                            class: {
                              "select__option--active":
                                _vm.currentTicketStatus ===
                                _vm.constants.TICKET__TICKET_STATUS_FOR_USER
                                  .PROCESSING
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.changeSelectedTicketStatus(
                                  _vm.constants.TICKET__TICKET_STATUS_FOR_USER
                                    .PROCESSING
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$t("REPORT__STATUS_FILTER_PROCESSING")
                                ) +
                                "\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "select__option",
                            class: {
                              "select__option--active":
                                _vm.currentTicketStatus ===
                                _vm.constants.TICKET__TICKET_STATUS_FOR_USER
                                  .CLOSED
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.changeSelectedTicketStatus(
                                  _vm.constants.TICKET__TICKET_STATUS_FOR_USER
                                    .CLOSED
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("REPORT__STATUS_FILTER_CLOSED")) +
                                "\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        this.isTwVersion || this.isSmpVersion
                          ? _c(
                              "div",
                              {
                                staticClass: "select__option",
                                class: {
                                  "select__option--active": _vm.isLegacy
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.changeSelectedTicketStatus(
                                      _vm.constants
                                        .TICKET__TICKET_STATUS_FOR_USER.LEGACY
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t("REPORT__STATUS_FILTER_LEGACY")
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "reportlist-main" },
                [
                  _c("div", { staticClass: "reportlist-table" }, [
                    _c(
                      "table",
                      { staticClass: "reportlist-table__inner" },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass:
                                  "reportlist-table__th reportlist-table__id"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("REPORT__COLUMN_CS_NUMBER"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "reportlist-table__th reportlist-table__date"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("REPORT__COLUMN_CREATED_DATE"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.isLegacy
                              ? _c(
                                  "th",
                                  {
                                    staticClass:
                                      "reportlist-table__th reportlist-table__game"
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.$t("REPORT__COLUMN_GAME")) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "reportlist-table__th reportlist-table__category"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("REPORT__COLUMN_BASE_CATEGORY"))
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "reportlist-table__th reportlist-table__subcategory"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("REPORT__COLUMN_TOPIC_CATEGORY")
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "reportlist-table__th reportlist-table__item"
                              },
                              [_vm._v(_vm._s(_vm.$t("REPORT__COLUMN_TOPIC")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "reportlist-table__th reportlist-table__status"
                              },
                              [_vm._v(_vm._s(_vm.$t("REPORT__COLUMN_STATUS")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "reportlist-table__th reportlist-table__link"
                              },
                              [_vm._v(_vm._s(_vm.$t("REPORT__COLUMN_VIEW")))]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.tickets && !_vm.utils.isEmptyArray(_vm.tickets)
                          ? [
                              _c(
                                "tbody",
                                _vm._l(_vm.tickets, function(ticket) {
                                  return _c("tr", { key: ticket.csNumber }, [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "reportlist-table__td reportlist-table__id"
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(ticket.csNumber) +
                                            "\n                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "reportlist-table__td reportlist-table__date"
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.utils.formatTimestampToString(
                                                ticket.createdAt,
                                                _vm.$t(
                                                  "GENERAL__DATETIME_FORMAT"
                                                )
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    !_vm.isLegacy
                                      ? _c(
                                          "th",
                                          {
                                            staticClass:
                                              "reportlist-table__td reportlist-table__game"
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(ticket.gameName) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "reportlist-table__td reportlist-table__category"
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(ticket.baseCategoryName) +
                                            "\n                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "reportlist-table__td reportlist-table__subcategory"
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              ticket.ticketTopicCategoryName
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "reportlist-table__td reportlist-table__item"
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(ticket.ticketTopicName) +
                                            "\n                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "reportlist-table__td reportlist-table__status"
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.$t(
                                                "REPORT__STATUS_".concat(
                                                  _vm.constants
                                                    .TICKET__TICKET_STATUS_MAPPING[
                                                    ticket.ticketStatus
                                                  ].TICKET_STATUS
                                                )
                                              )
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "reportlist-table__td reportlist-table__link"
                                      },
                                      [
                                        ticket.hasUnreadReply
                                          ? _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "reportlist-table__replylink reportlist-table__replylink--unread",
                                                attrs: {
                                                  to: {
                                                    name: "Report",
                                                    params: {
                                                      csNumber: ticket.csNumber,
                                                      isLegacy: _vm.isLegacy
                                                    },
                                                    query: _vm.isLegacy
                                                      ? {
                                                          source:
                                                            _vm.constants
                                                              .TICKET__TICKET_STATUS_FOR_USER
                                                              .LEGACY
                                                        }
                                                      : undefined
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "REPORT__VIEW_UNREAD"
                                                      )
                                                    ) +
                                                    "\n                      "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "reportlist-table__replylink",
                                                attrs: {
                                                  to: {
                                                    name: "Report",
                                                    params: {
                                                      csNumber: ticket.csNumber,
                                                      isLegacy: _vm.isLegacy
                                                    },
                                                    query: _vm.isLegacy
                                                      ? {
                                                          source:
                                                            _vm.constants
                                                              .TICKET__TICKET_STATUS_FOR_USER
                                                              .LEGACY
                                                        }
                                                      : undefined
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "REPORT__VIEW_READ"
                                                      )
                                                    ) +
                                                    "\n                      "
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ])
                                }),
                                0
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _vm.tickets && !_vm.utils.isEmptyArray(_vm.tickets)
                    ? [
                        _c("pagination", {
                          attrs: {
                            currentOffset: _vm.pagination.curOffset,
                            totalCnt: _vm.pagination.totalCnt,
                            perPage: _vm.constants.GENERAL__RECORDS_PER_PAGE
                          }
                        })
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.tickets
                    ? [
                        _c("div", { staticClass: "reportlist-empty" }, [
                          _c("img", {
                            staticClass: "icon-loading",
                            attrs: {
                              src:
                                _vm.CDN + "/assets/pc/img/common/loading.gif",
                              alt: ""
                            }
                          })
                        ])
                      ]
                    : _vm.utils.isEmptyArray(_vm.tickets)
                    ? [
                        _c("div", { staticClass: "reportlist-empty" }, [
                          _c("div", {
                            staticClass:
                              "reportlist-empty__icon reportlist-empty__icon--failure"
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "reportlist-empty__title" },
                            [_vm._v(_vm._s(_vm.$t("REPORT__NO_RECORD")))]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "reportlist-empty__text",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("REPORT__NO_RECORD_DESC")
                              )
                            }
                          })
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }