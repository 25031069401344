var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrap" }, [
    _c("div", { staticClass: "main-bg" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main" },
      [
        _c("navigation", {
          attrs: {
            title: _vm.$t("GENERAL__TITLE"),
            "has-search-bar": true,
            isBlank: true
          }
        }),
        _vm._v(" "),
        _vm.article
          ? [
              _c("section", { staticClass: "game-section" }, [
                _c(
                  "div",
                  { staticClass: "cproblem__main-content" },
                  [
                    _c("div", { staticClass: "cproblem__main" }, [
                      _c("div", { staticClass: "faqdetail-title" }, [
                        _c("div", { staticClass: "faqdetail__question" }, [
                          _vm._v(_vm._s(_vm.article.title))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "faqdetail__content" },
                      [
                        _c("div", {
                          staticClass: "ip-solution__txt",
                          domProps: { innerHTML: _vm._s(_vm.article.content) }
                        }),
                        _vm._v(" "),
                        !_vm.utils.isEmptyArray(_vm.article.tags)
                          ? [
                              _c("div", { staticClass: "faqdetail__tag" }, [
                                _c(
                                  "div",
                                  { staticClass: "faqdetail__statistics-item" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t("ARTICLE__TAGS")) +
                                        "\n                  "
                                    ),
                                    _vm._l(_vm.article.tags, function(
                                      tag,
                                      idx
                                    ) {
                                      return _c(
                                        "a",
                                        {
                                          key: idx,
                                          staticClass:
                                            "faqdetail__statistics-tag",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(tag) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ])
                            ]
                          : _vm._e()
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "cproblem__main-grey" }),
                    _vm._v(" "),
                    !_vm.utils.isEmptyArray(_vm.article.relatedArticles)
                      ? [
                          _c("div", { staticClass: "faqdetail-title" }, [
                            _c("div", { staticClass: "faqdetail__question" }, [
                              _vm._v(_vm._s(_vm.$t("ARTICLE__RELATED_ARTICLE")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "cproblem__main-list" },
                            _vm._l(_vm.article.relatedArticles, function(
                              relatedArticle
                            ) {
                              return _c(
                                "li",
                                { key: relatedArticle.id },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "cproblem__main-item",
                                      attrs: {
                                        to: {
                                          name: "MobileArticleGeneral",
                                          params: { id: relatedArticle.id }
                                        }
                                      }
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "cproblem__main-item--icon"
                                      }),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.related_article.title)
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "cproblem__main-entry" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "cproblem__main-entry--item rectangle-button",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openGameList.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "cproblem__main-entry--desc" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("ARTICLE__TICKET_SUBTITLE")) +
                                "\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "cproblem__main-entry--name" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("ARTICLE__TICKET_TITLE")) +
                                "\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "cproblem__main-entry--desc" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("ARTICLE__TICKET_DESC")) +
                                "\n              "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "cproblem__main-entry--icon cproblem__main-entry--createreport"
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "cproblem__main-grey" })
                ])
              ])
            ]
          : [
              _c("div", { staticClass: "loading" }, [
                _c("img", {
                  staticClass: "icon-loading",
                  attrs: {
                    src: _vm.CDN + "/assets/pc/img/common/loading.gif",
                    alt: ""
                  }
                })
              ])
            ]
      ],
      2
    ),
    _vm._v(" "),
    _vm.isShowingGames
      ? _c("div", { staticClass: "notification-wrap" }, [
          _c(
            "div",
            {
              staticClass: "notification-dimmer",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.closeGameList.apply(null, arguments)
                }
              }
            },
            [
              _c("div", { staticClass: "notification notification--sm" }, [
                _c(
                  "button",
                  {
                    staticClass: "rountbutton__widget",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.closeGameList.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("M_HOME__SERVICE_RECORD_POPUP_BUTTON"))
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "notification-inner" }, [
                  _c("div", { staticClass: "notification__title" }, [
                    _c("div", { staticClass: "notification__title-txt" }, [
                      _vm._v(_vm._s(_vm.$t("ARTICLE__TICKET_CHOOSE_GAME")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "notification__main" }, [
                    _c("div", { staticClass: "notification__content" }, [
                      _c("div", { staticClass: "notification__content-txt" }, [
                        _c(
                          "div",
                          { staticClass: "notification__content-txt" },
                          [
                            _c("div", { staticClass: "home-games" }, [
                              _c("div", { staticClass: "home-games__inner" }, [
                                _c(
                                  "div",
                                  { staticClass: "game-section__title" },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "game-section__title-icon game-section__title-icon-pcgames"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "h3",
                                      {
                                        staticClass: "game-section__title-txt"
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t("HOME__PC_GAME_QUESTION")
                                            ) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "home-games article_general__game"
                                  },
                                  _vm._l(_vm.games.pc, function(game) {
                                    return _c(
                                      "li",
                                      {
                                        key: game.id,
                                        staticClass: "home-games-game"
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "cproblem__link",
                                            attrs: {
                                              to: {
                                                name: "MobileTicket",
                                                params: { gameCode: game.code }
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(game.name) +
                                                "\n                          "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "game-section__title" },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "game-section__title-icon game-section__title-icon-mgames"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "h3",
                                      {
                                        staticClass: "game-section__title-txt"
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(
                                              _vm.$t(
                                                "HOME__MOBILE_GAME_QUESTION"
                                              )
                                            ) +
                                            "\n                        "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "home-games article_general__game"
                                  },
                                  _vm._l(_vm.games.mobile, function(game) {
                                    return _c(
                                      "li",
                                      {
                                        key: game.id,
                                        staticClass: "home-games-game"
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "cproblem__link",
                                            attrs: {
                                              to: {
                                                name: "MobileTicket",
                                                params: { gameCode: game.code }
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(game.name) +
                                                "\n                          "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ])
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }