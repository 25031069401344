<template>
  <div class="main-wrap">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('HOME__VERIFICATION_TITLE')" :has-search-bar="false" />
      <div class="main-inner">
        <div class="verification">
          <breadcrumb :paths="paths" />
          <div class="main-title">
            {{ $t('HOME__VERIFICATION_TITLE') }}
          </div>
          <div class="verification-inner">
            <div class="verification__list">
              <div v-for="item in items" :key="item.icon" class="verification__card">
                <div class="verification__card-icon" :class="['verification__card-icon--' + item.icon]"></div>
                <div class="verification__card-title">
                  {{ item.title }}
                </div>
                <div class="verification__card-content" v-html="item.content"></div>
                <template v-if="item.isExternalLink">
                  <a :href="item.link" class="verification__card-verf" target="_blank">
                    {{ item.linkText }}
                  </a>
                </template>
                <template v-else>
                  <router-link :to="{ name: item.routerName }" class="verification__card-verf">
                    {{ item.linkText }}
                  </router-link>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Navigation from '@/components/pc/layouts/Navigation';
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';
import { ACTION_TRIGGER_POPUP } from '@/store/action-types';

export default {
  name: 'Verification',
  components: {
    Breadcrumb,
    Navigation,
  },
  data() {
    return {
      paths: [
        {
          id: 1,
          name: 'Verification',
          params: {},
          title: this.$t('HOME__VERIFICATION_TITLE'),
        },
      ],
      items: [
        {
          icon: 'account',
          title: this.$t('VERIFICATION__ITEM_ACCOUNT_TITLE'),
          content: this.$t('VERIFICATION__ITEM_ACCOUNT_CONTENT'),
          linkText: this.$t('VERIFICATION__ITEM_ACCOUNT_LINKTEXT'),
          isExternalLink: true,
          link: 'https://account.garena.com/security?locale_name=TW',
        },
        {
          icon: '2steps',
          title: this.$t('VERIFICATION__ITEM_2STEPS_TITLE'),
          content: this.$t('VERIFICATION__ITEM_2STEPS_CONTENT'),
          linkText: this.$t('VERIFICATION__ITEM_2STEPS_LINKTEXT'),
          isExternalLink: true,
          link: 'https://account.garena.com/security/mobile/2-step-verification?locale_name=TW',
        },
        {
          icon: 'reset',
          title: this.$t('VERIFICATION__ITEM_RESET_TITLE'),
          content: this.$t('VERIFICATION__ITEM_RESET_CONTENT'),
          linkText: this.$t('VERIFICATION__RESET_PROFILE_TITLE'),
          isExternalLink: false,
          routerName: 'ResetProfile',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['isGarenaUser']),
  },
  methods: {
    ...mapActions({
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
  },
  watch: {
    isGarenaUser: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_APPLICATION_FB_ACCOUNT_CANT_VERIFICATION'),
          });
          this.$router.push({ name: 'Home' });
        }
      },
    },
  },
};
</script>
