export const ACTION_GET_ACCOUNT_RECOVERY_PLAYER_INFO_FIELDS = 'ACTION_GET_ACCOUNT_RECOVERY_PLAYER_INFO_FIELDS';
export const ACTION_GET_GAMES = 'ACTION_GET_GAMES';
export const ACTION_GET_BASE_CATEGORIES = 'ACTION_GET_BASE_CATEGORIES';
export const ACTION_GET_TICKET_TOPIC_CATEGORIES = 'ACTION_GET_TICKET_TOPIC_CATEGORIES';
export const ACTION_GET_TICKET_TOPICS = 'ACTION_GET_TICKET_TOPICS';
export const ACTION_LOGIN = 'ACTION_LOGIN';
export const ACTION_LOGOUT = 'ACTION_LOGOUT';
export const ACTION_GET_USER_INFO = 'ACTION_GET_USER_INFO';
export const ACTION_GET_UNREAD_STATS = 'ACTION_GET_UNREAD_STATS';
export const ACTION_SWITCH_LOGIN_POPUP = 'ACTION_SWITCH_LOGIN_POPUP';
export const ACTION_TRIGGER_POPUP = 'ACTION_TRIGGER_POPUP';
export const ACTION_CLOSE_POPUP = 'ACTION_CLOSE_POPUP';
export const ACTION_GET_NEWS = 'ACTION_GET_NEWS';
export const ACTION_SET_MOBILE_LEGACY = 'ACTION_SET_MOBILE_LEGACY';
export const ACTION_SWITCH_AI_CHATTING = 'ACTION_SWITCH_AI_CHATTING';
export const ACTION_TRIGGER_AI_CHATTING = 'ACTION_TRIGGER_AI_CHATTING';
