import Vue from 'vue';
import Vuex from 'vuex';

import application from './modules/application';
import category from './modules/category';
import game from './modules/game';
import news from './modules/news';
import user from './modules/user';
import common from './modules/common';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    application,
    category,
    game,
    news,
    user,
    common,
  },
});
