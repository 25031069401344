var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "topbar" } }, [
    _c(
      "header",
      { staticClass: "topbar-header" },
      [
        _c("router-link", {
          staticClass: "topbar-logo",
          attrs: { to: { name: "Home" } }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "topbar-panel" }, [
          _c("nav", { staticClass: "topbar-nav" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "topbar-login" },
            [
              _vm.hasLoggedIn
                ? [
                    _c("div", { staticClass: "topbar-login-info" }, [
                      _c("div", { staticClass: "topbar-login-info-account" }, [
                        _c("div", {
                          staticClass: "topbar-login-info-platform-icon",
                          style: { backgroundImage: _vm.platformIconImage }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "topbar-login-info-account-name" },
                          [_vm._v(_vm._s(_vm.userInfo.name))]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "topbar-logout-btn",
                          on: {
                            click: function($event) {
                              return _vm.toLogout()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("GENERAL__LOGOUT")))]
                      )
                    ])
                  ]
                : [
                    _c(
                      "a",
                      {
                        staticClass: "topbar-login-btn",
                        on: {
                          click: function($event) {
                            return _vm.switchLoginPopup(true)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("GENERAL__LOGIN")))]
                    )
                  ]
            ],
            2
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }