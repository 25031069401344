<template>
  <div class="main-wrap">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('NEWS__TITLE')" :has-search-bar="false" />
      <div class="main-inner">
        <div class="news">
          <breadcrumb :paths="paths" :smpd="true" :has-border="false" />
          <template v-if="newsList">
            <div class="news-inner">
              <div class="news__title">
                {{ $t('NEWS__TITLE') }}
              </div>
              <ul class="news__list">
                <li v-for="news in newsList" :key="news.id" class="news__item">
                  <router-link :to="{ name: 'News', params: { id: news.id } }" class="news__link">
                    <div class="news__link-title">
                      {{ news.title }}
                    </div>
                    <div class="news__link-views">
                      {{ utils.formatTimestampToString(news.publishAt, $t('GENERAL__DATETIME_FORMAT')) }}
                    </div>
                  </router-link>
                </li>
              </ul>

              <template v-if="newsList && newsList.length > 0">
                <pagination
                  :currentOffset="newsPagination.curOffset"
                  :totalCnt="newsPagination.totalCnt"
                  :perPage="constants.GENERAL__RECORDS_PER_PAGE"
                />
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ACTION_GET_NEWS } from '@/store/action-types';
import Navigation from '@/components/pc/layouts/Navigation';
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';
import Pagination from '@/components/pc/layouts/partial/Pagination';
import constants from '@/lib/constants';
import utils from '@/lib/utils';

export default {
  name: 'NewsList',
  components: {
    Navigation,
    Breadcrumb,
    Pagination,
  },
  data() {
    return {
      constants,
      utils,
      paths: [
        {
          id: 1,
          name: 'NewsList',
          params: {},
          title: this.$t('NEWS__TITLE'),
        },
      ],
    };
  },
  computed: {
    currentPage() {
      return this.$route.query.page || 1;
    },
    queryOffset() {
      return (this.currentPage - 1) * constants.GENERAL__RECORDS_PER_PAGE;
    },
    ...mapState({
      newsList: (state) => state.news.news,
      newsPagination: (state) => state.news.pagination,
    }),
  },
  methods: {
    ...mapActions({
      getNewsList: ACTION_GET_NEWS,
    }),
  },
  watch: {
    queryOffset: {
      immediate: true,
      handler(newValue) {
        window.scrollTo(0, 0);
        this.getNewsList(newValue);
      },
    },
  },
};
</script>
