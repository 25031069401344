<template>
  <div class="custom_input-wrap">
    <div class="input-wrap">
      <label>
        <input
          class="input"
          ref="input"
          :class="{ 'input--error': !isValid }"
          :name="name"
          :type="type"
          :placeholder="placeholder"
          :disabled="isDisabled"
          :required="isRequired"
          :value="val"
          :maxlength="maxLength"
          @input="onInputChange"
        />
      </label>
      <template v-if="children">
        <div v-html="children"></div>
      </template>
    </div>
    <div class="report-form__note">
      <div class="report-form__error">{{ noteMsg }}</div>
    </div>
    <div class="report-form__note">
      <div :class="{ invisible: isValid }" class="report-form__error">{{ errorMsg }}</div>
      <div v-show="isLimitLength" class="report-form__length">{{ curLength }} / {{ maxLength }}</div>
    </div>
  </div>
</template>

<script>
import constants from '@/lib/constants';
import utils from '@/lib/utils';

export default {
  name: 'CustomInput',
  props: {
    name: String,
    type: { type: String, default: 'text' },
    placeholder: String,
    isDisabled: Boolean,
    isRequired: Boolean,
    errorMsg: String,
    noteMsg: String,
    value: String,
    children: String,
    customType: String,
    isValidIfNull: { type: Boolean, default: true },
    isLimitLength: { type: Boolean, default: false },
    maxLength: { type: Number, default: 100 },
    needValidation: { type: Boolean, default: false },
  },
  computed: {
    val() {
      return this.value;
    },
    isValid() {
      return this.validate(this.val);
    },
    curLength() {
      if (this.val) {
        return this.val.length;
      }
      return 0;
    },
  },
  methods: {
    validate(value) {
      if (this.isValidIfNull && value === null) {
        return true;
      }
      let isValidType = true;
      let hasValueIfRequired = true;
      if (this.customType === constants.GENERAL__INPUT_TYPES.GARENA_ACCOUNT) {
        isValidType = utils.isValidGarenaAccount(value);
      }
      if (this.customType === constants.GENERAL__INPUT_TYPES.EMAIL) {
        isValidType = utils.isValidEmail(value);
      }
      if (this.isRequired) {
        hasValueIfRequired = value && value.length > 0;
      }
      return isValidType && hasValueIfRequired;
    },
    onInputChange($event) {
      this.$emit('input', $event.target.value);
      if (this.needValidation) {
        this.$emit('update:validation', this.validate($event.target.value));
      }
    },
  },
  watch: {
    val(newValue) {
      if (this.needValidation) {
        this.$emit('update:validation', this.validate(newValue));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input:disabled {
  cursor: not-allowed;
}
.report-form {
  &__note {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__length {
    color: $general-input-border-color;
    font-size: 0.8rem;
  }
}
</style>
