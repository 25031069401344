<template>
  <div class="phone_input-wrap">
    <div class="input-wrap">
      <vue-tel-input
        ref="input"
        :class="{ 'vue-tel-input--error': !isValid }"
        :placeholder="placeholder"
        :name="name"
        :enabledCountryCode="true"
        :validCharactersOnly="true"
        :preferredCountries="['TW', 'HK', 'MO']"
        :disabledFetchingCountry="true"
        :mode="'international'"
        v-model="phone"
        @input="onInputChange"
        @close="onDropdownClose"
      />
    </div>
    <div class="report-form__note">
      <div v-if="!isValid" class="report-form__error">{{ errorMsg }}</div>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';

export default {
  name: 'PhoneInput',
  props: {
    errorMsg: { type: String },
    placeholder: { type: String },
    name: { type: String },
    defaultNumber: { type: String, default: '' },
  },
  components: {
    VueTelInput,
  },
  data() {
    return {
      phone: '',
      isValid: true,
    };
  },
  methods: {
    onDropdownClose() {
      this.phone = '';
    },
    onInputChange(number, input) {
      const phoneNumber = input.number.significant;
      this.isValid = input.isValid;
      if (this.isValid) {
        this.$emit('input', {
          countryCode: input.country.dialCode,
          number: phoneNumber,
        });
      } else {
        this.$emit('input', null);
      }
    },
  },
  mounted() {
    this.phone = this.defaultNumber ? this.defaultNumber : '';
  },
};
</script>

<style lang="scss" scoped>
.input-wrap ::v-deep ::placeholder {
  color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.input-wrap ::v-deep .vue-tel-input {
  border: 1px solid $general-input-border-color;
  height: 42px;
  line-height: 40px;
}
.input-wrap ::v-deep .vue-tel-input:focus-within {
  border-color: #acb4b8;
  box-shadow: none;
}
.input-wrap ::v-deep .dropdown:focus {
  outline: none;
}
.input-wrap ::v-deep .vue-tel-input--error:focus-within,
.input-wrap ::v-deep .vue-tel-input--error {
  border-color: $general-red;
  box-shadow: none;
}
</style>
