var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup" }, [
    _c("div", { staticClass: "popup__content" }, [
      _c("p", { staticClass: "popup__content-title" }, [
        _vm._v(_vm._s(_vm.$t("AI_FAQ__POPUP__TITLE")))
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "popup__content-back",
          on: {
            click: function($event) {
              return _vm.$emit("onPopupCancel")
            }
          }
        },
        [_vm._v("↩")]
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("Countdown", {
            staticClass: "popup__content-countdown",
            attrs: { endDate: _vm.endDate },
            on: {
              onEnd: function($event) {
                return _vm.$emit("onEnd")
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "popup__content-support" }, [
            _c("img", {
              attrs: { src: _vm.constants.AI_FAQ__CHAT_IMG[_vm.gameCodeOnUrl] }
            }),
            _vm._v(" "),
            _vm.rate === "helpful"
              ? _c("div", { staticClass: "response" }, [
                  _vm._v(_vm._s(_vm.$t("AI_FAQ__POPUP__RATE_HELPFUL")))
                ])
              : _vm.rate === "unhelpful"
              ? _c("div", { staticClass: "response" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("AI_FAQ__POPUP__RATE_UNHELPFUL_1")) +
                      "\n          "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "link",
                      on: {
                        click: function($event) {
                          return _vm.$emit("onLinkClose")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("AI_FAQ__POPUP__RATE_UNHELPFUL_2")))]
                  ),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("AI_FAQ__POPUP__RATE_UNHELPFUL_3")) +
                      "\n        "
                  )
                ])
              : _c("div", {
                  staticClass: "response",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("AI_FAQ__POPUP__RATE_DEFAULT"))
                  }
                })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "popup__content-rate" }, [
            _c(
              "div",
              {
                staticClass: "wrapper",
                on: {
                  click: function($event) {
                    return _vm.$emit("onRateHelpful")
                  }
                }
              },
              [
                _c("a", {
                  staticClass: "helpful-icon helpful-icon--helpful",
                  class: { isActive: _vm.rate === "helpful" }
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("AI_FAQ__POPUP__RATE_HELPFUL_BTN")))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "wrapper",
                on: {
                  click: function($event) {
                    return _vm.$emit("onRateUnhelpful")
                  }
                }
              },
              [
                _c("a", {
                  staticClass: "helpful-icon helpful-icon--unhelpful",
                  class: { isActive: _vm.rate === "unhelpful" }
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("AI_FAQ__POPUP__RATE_UNHELPFUL_BTN")))
                ])
              ]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "popup__content-btn" }, [
        _c("p", {
          staticClass: "note",
          domProps: { innerHTML: _vm._s(_vm.popupContent) }
        }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "confirm",
            on: {
              click: function($event) {
                return _vm.$emit("onPopupConfirm")
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("AI_FAQ__POPUP__CONFIRM_BTN")))]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }