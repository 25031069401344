<template>
  <div class="main-wrap main-wrap--hassearch main-wrap--game">
    <div class="main-bg" :style="mainBgImage" />
    <div class="main">
      <navigation :title="currentGameName" :has-search-bar="true" :search-type="'game'" :search-code="gameCodeOnUrl" :is-game-page="true" />
      <div class="main-inner main-inner--nobg">
        <div class="faqdetail">
          <breadcrumb :paths="paths" :has-border="true" />
          <template v-if="article">
            <div class="faqdetail-main">
              <div class="faqdetail__title">
                {{ article.title }}
              </div>
              <div class="faqdetail__content">
                <div class="ip-solution__txt" v-html="article.content"></div>
              </div>
              <!-- Article Tags -->
              <template v-if="!utils.isEmptyArray(article.tags)">
                <div class="faqdetail__aside">
                  <div class="faqdetail__aside-item">
                    {{ $t('ARTICLE__TAGS') }}
                    <a v-for="(tag, idx) in article.tags" :key="idx" @click.prevent="" class="faqdetail__aside-tag">
                      {{ tag }}
                    </a>
                  </div>
                </div>
              </template>
              <!-- Article Tags /-->
              <!-- Related Articles -->
              <template v-if="!utils.isEmptyArray(article.relatedArticles)">
                <div class="faqdetail-related">
                  <div class="faqdetail-related__title">
                    {{ $t('ARTICLE__RELATED_ARTICLE') }}
                  </div>
                  <ul class="faqdetail-related__list">
                    <li v-for="relatedArticle in article.relatedArticles" :key="relatedArticle.id" class="faqdetail-related__item">
                      <router-link :to="{ name: 'ArticleGeneral', params: { id: relatedArticle.id } }" class="faqdetail-related__link">
                        <div class="faqdetail-related__link-title">
                          {{ relatedArticle.title }}
                        </div>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </template>
              <!-- Related Articles /-->
            </div>
          </template>
          <template v-else>
            <div class="loading">
              <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
            </div>
          </template>
        </div>
        <div class="cproblem-entry">
          <a v-if="showAIHelp" class="cproblem-entry__item rectangle-button" @click="triggerAIChatting(true)">
            <div class="cproblem-entry__item-icon cproblem-entry__item-icon--createreport"></div>
            <div class="cproblem-entry__item-content">
              <div class="cproblem-entry__item-desc">
                {{ $t(`ARTICLE__TICKET_SUBTITLE__${gameCodeOnUrl.toUpperCase()}`) }}
              </div>
              <div class="cproblem-entry__item-name">
                {{ $t(`ARTICLE__TICKET_TITLE__${gameCodeOnUrl.toUpperCase()}`) }}
              </div>
              <div class="cproblem-entry__item-desc">
                {{ $t(`ARTICLE__TICKET_DESC__${gameCodeOnUrl.toUpperCase()}`) }}
              </div>
            </div>
          </a>
          <router-link
            v-else
            :to="{ name: 'Ticket', params: { gameCode: gameCodeOnUrl }, query: { bc: baseCategoryNameOnUrl } }"
            class="cproblem-entry__item rectangle-button"
          >
            <div class="cproblem-entry__item-icon cproblem-entry__item-icon--createreport"></div>
            <div class="cproblem-entry__item-content">
              <div class="cproblem-entry__item-desc">
                {{ $t('ARTICLE__TICKET_SUBTITLE') }}
              </div>
              <div class="cproblem-entry__item-name">
                {{ $t('ARTICLE__TICKET_TITLE') }}
              </div>
              <div class="cproblem-entry__item-desc">
                {{ $t('ARTICLE__TICKET_DESC') }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { ACTION_TRIGGER_AI_CHATTING } from '@/store/action-types';
import Navigation from '@/components/pc/layouts/Navigation';
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';
import article from '@/api/prod/article';
import utils from '@/lib/utils';

export default {
  name: 'Article',
  beforeRouteLeave(to, from, next) {
    if (this.isAIChatting && to.name !== 'ArticleList') {
      const answer = window.confirm(this.$t('AI_FAQ__CONFIRM_LEAVE'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  components: {
    Navigation,
    Breadcrumb,
  },
  data() {
    return {
      utils,
      article: null,
      gameCodeOnUrl: this.$route.params.gameCode,
      baseCategoryNameOnUrl: this.$route.params.baseCategory,
    };
  },
  computed: {
    currentGameName() {
      return this.gamesCodeNameMapping[this.gameCodeOnUrl] || '';
    },
    currentBaseCategoryName() {
      return this.baseCategoriesCodeNameMapping[this.baseCategoryNameOnUrl] || '';
    },
    currentGameId() {
      return this.gamesCodeIdMapping[this.gameCodeOnUrl];
    },
    currentBaseCategoryId() {
      return this.baseCategoriesCodeIdMapping[this.baseCategoryNameOnUrl];
    },
    isDataReady() {
      return this.currentGameId && this.currentBaseCategoryId;
    },
    showAIHelp() {
      return (this.gameCodeOnUrl === 'aov' || this.gameCodeOnUrl === 'ff') && process.env.REGION === 'tw';
    },
    paths() {
      return [
        {
          id: 1,
          name: 'ArticleList',
          params: { gameCode: this.gameCodeOnUrl, baseCategory: 'game' },
          title: this.currentGameName,
        },
        {
          id: 2,
          name: 'ArticleList',
          params: { gameCode: this.gameCodeOnUrl, baseCategory: this.baseCategoryNameOnUrl },
          title: this.currentBaseCategoryName,
        },
        {
          id: 3,
          name: 'Article',
          params: {},
          title: this.article ? this.article.title : '',
        },
      ];
    },
    mainBgImage() {
      return {
        'background-image': `url(${process.env.CDN}/games/${this.gameCodeOnUrl}/bg-main-${this.gameCodeOnUrl}.jpg)`,
      };
    },
    ...mapState({
      games: (state) => state.game.games,
      baseCategories: (state) => state.category.baseCategories,
      isAIChatting: (state) => state.common.isAIChatting,
    }),
    ...mapGetters(['gamesCodeNameMapping', 'gamesCodeIdMapping', 'baseCategoriesCodeNameMapping', 'baseCategoriesCodeIdMapping']),
  },
  methods: {
    ...mapActions({
      triggerAIChatting: ACTION_TRIGGER_AI_CHATTING,
    }),
  },
  watch: {
    isDataReady: {
      immediate: true,
      handler(newValue) {
        if (newValue && !this.article) {
          article.getArticleByAgcId(this.currentGameId, this.currentBaseCategoryId, this.$route.params.agcId).then((resp) => {
            const { data, error } = resp;
            if (error || !data) {
              if (error === 'error_no_matched_data') {
                this.$router.push(
                  { name: 'NotFound' },
                  () => {},
                  () => {},
                );
              } else {
                this.triggerPopup({
                  title: this.$t('POPUP__ERROR'),
                  content: this.$t('POPUP__ERROR_EXCEPTION'),
                });
              }
            } else {
              this.article = data;
            }
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.pc {
  .loading {
    text-align: center;
  }
  .cproblem-entry__item-name {
    margin-top: 8px;
  }
}
</style>
