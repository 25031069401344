var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasNotification
    ? _c("div", { staticClass: "notification-wrap" }, [
        _c("div", { staticClass: "notification-dimmer" }, [
          _c("div", { staticClass: "notification notification--sm" }, [
            _c(
              "button",
              {
                staticClass: "rountbutton__widget",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.closeNotification.apply(null, arguments)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("GENERAL__BUTTON_NOTIFICATION")))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "notification-inner" }, [
              _c("div", { staticClass: "notification__title" }, [
                _c("div", { staticClass: "notification__title-icon" }),
                _vm._v(" "),
                _c("div", { staticClass: "notification__title-txt" }, [
                  _vm._v(_vm._s(_vm.$t("NOTIFICATION__TITLE")))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "notification__main" }, [
                _c(
                  "div",
                  { staticClass: "notification__content faqdetail__content" },
                  [
                    _c("div", { staticClass: "notification__content-txt" }, [
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(_vm.notification.content)
                        }
                      })
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }