<template>
  <div class="notification-wrap">
    <div class="notification-dimmer">
      <div class="notification notification--sm">
        <button class="rountbutton__widget-left"
                @click.prevent="closePopup">{{ $t('GENERAL__BUTTON_CANCEL') }}</button>
        <button class="rountbutton__widget-right"
                @click.prevent="confirmAction">{{ $t('GENERAL__BUTTON_CONFIRM') }}</button>
        <div class="notification-inner">
          <div class="notification__title">
            <div class="notification__title-icon"></div>
            <div class="notification__title-txt">{{ title }}</div>
          </div>
          <div class="notification__main">
            <div class="notification__content">
              <div class="notification__content-txt">
                <p v-html="content"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import constants from '@/lib/constants';

export default {
  name: 'Confirm',
  props: {
    title: String,
    content: String,
  },
  data() {
    return {
      constants,
    };
  },
  computed: {
  },
  methods: {
    closePopup() {
      this.$emit('close');
    },
    confirmAction() {
      this.$emit('confirm');
    },
  },
};
</script>

<style lang="scss" scoped>
.rountbutton__widget-left {
  background: #F8FAFD;
  color: $general-dark-text-color;
  font-size: 18px;
  font-weight: 500;
  border: 0;
  display: block;
  width: 50%;
  height: 52px;
  transition: .3s;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 5px 5px;
  left: 0;
}
.rountbutton__widget-right {
  background: #F8FAFD;
  color: $general-dark-text-color;
  font-size: 18px;
  font-weight: 500;
  border: 0;
  display: block;
  width: 50%;
  height: 52px;
  transition: .3s;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 5px 5px;
  right: 0;
}
</style>
