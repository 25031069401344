<template>
  <div class="ga_footer">
    <div class="fullc">
      <div id="destop_copyr" class="left-copyr">
        <p class="copyright text-center">
          Copyright &copy; Garena Online. Trademarks belong to their respective owners. All rights reserved.
        </p>
      </div>
      <div class="right-fmenu">
        <ul>
          <li class="terms-tos">
            <a :href="$t('TERMS__TOS_LINK')" target="_blank">{{ $t('TERMS__TOS') }}</a>
          </li>
          <li class="terms-pp">
            <a :href="$t('TERMS__PP_LINK')" target="_blank">{{ $t('TERMS__PP') }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss" scoped>
.ga_footer {
  border-top: 1px solid #e1e1e1;
  background: #fff;
  font-size: 14px;
  line-height: 1.4;
  color: rgba(32, 35, 37, 0.5);
  .fullc {
    padding: 15px 0;
    width: 1200px;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: space-between;
  }
  li {
    display: inline-flex;
    flex: 0 0 auto;
    margin: 0 0 0 30px;
    &.terms-pp {
      border-right: 0;
    }
  }
  .right-fmenu {
    color: rgba(32, 35, 37, 0.5);
    height: 25px;
  }
}
</style>
