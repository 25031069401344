var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkbox-wrap" }, [
    _c(
      "label",
      {
        staticClass: "checkbox",
        class: {
          "checkbox--error": _vm.errorMsg,
          checkbox__disabled: _vm.isDisabled
        }
      },
      [
        _c("input", {
          staticClass: "checkbox__widget",
          attrs: { type: "checkbox", name: _vm.name, disabled: _vm.isDisabled },
          domProps: { checked: _vm.val },
          on: {
            input: function($event) {
              return _vm.$emit("input", $event.target.checked)
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "checkbox__indicator" }),
        _vm._v(" "),
        _c("div", {
          staticClass: "checkbox__label",
          domProps: { innerHTML: _vm._s(_vm.label) }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }