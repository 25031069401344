<template>
  <div v-if="hasNotification" class="notification-wrap">
    <div class="notification-dimmer">
      <div class="notification notification--sm">
        <button class="rountbutton__widget" @click.prevent="closeNotification">{{ $t('GENERAL__BUTTON_NOTIFICATION') }}</button>
        <div class="notification-inner">
          <div class="notification__title">
            <div class="notification__title-icon"></div>
            <div class="notification__title-txt">{{ $t('NOTIFICATION__TITLE') }}</div>
          </div>
          <div class="notification__main">
            <div class="notification__content faqdetail__content">
              <div class="notification__content-txt">
                <p v-html="notification.content"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import notification from '@/api/prod/notification';

export default {
  name: 'Notification',
  props: ['pos', 'category-id'],
  data() {
    return {
      hasNotification: false,
      notification: null,
    };
  },
  computed: {
    isSmallNotification() {
      return Boolean(this.notification && !this.notification.img);
    },
    ...mapState({
      hasPopup: (state) => state.common.hasPopup,
    }),
  },
  methods: {
    closeNotification() {
      this.hasNotification = false;
    },
  },
  mounted() {
    if (!this.hasPopup) {
      notification.getNotification(this.pos, this.categoryId).then((resp) => {
        const { data } = resp;
        if (data) {
          this.hasNotification = true;
          this.notification = data;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.mobile .notification__content-txt {
  text-align: left;
}
</style>
