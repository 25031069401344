<template>
  <div class="main-wrap">
    <ticket-step :step="step" />
    <section class="game-section">
      <div class="reportlist-main">
        <div class="faqdetail-title">
          <div class="faqdetail__question">
            <div class="faqdetail__question-title">{{ $t('TICKET__TITLE') }} - {{ currentGameName }}</div>
            <div class="faqdetail__question-record">
              <router-link :to="{ name: 'MobileReportList' }" class="faqdetail__question-record-link">
                {{ $t('REPORT__TITLE') }}
              </router-link>
            </div>
          </div>
          <div class="reportlist-result-subhead">{{ $t('TICKET__REMINDER') }}</div>
        </div>
      </div>
    </section>

    <template v-if="isTicketTopicCategoryDataReady">
      <!-- FIRST STEP -->
      <template v-if="step === 1">
        <section class="form-section" :class="['form-section_' + step]">
          <form class="report-form">
            <ol class="report-form__ol">
              <li class="report-form__row">
                <div class="report-form__label">{{ $t('TICKET__QUESTION_1') }}</div>
                <custom-radio
                  v-for="category in baseCategoriesToDisplay"
                  :key="category.id"
                  :label="category.name"
                  :name="'base_category'"
                  :radio-value="category.id"
                  v-model="userCheckedBaseCategoryId"
                />
              </li>
              <li v-if="userCheckedBaseCategoryId" class="report-form__row">
                <div class="report-form__label">{{ $t('TICKET__QUESTION_2') }}</div>
                <custom-radio
                  v-for="ttc in currentTicketTopicCategories"
                  :key="ttc.id"
                  :label="ttc.name"
                  :name="'ticket_topic_category'"
                  :radio-value="ttc.id"
                  v-model="userCheckedTicketTopicCategoryId"
                />
              </li>
            </ol>
          </form>
        </section>
        <button class="reportlist__return" :disabled="!isFirstButtonEnabled" @click.prevent="lockTicketTopicCategoryId">
          {{ $t('GENERAL__BUTTON_NEXT_STEP') }}
          <vue-countdown v-if="isCoolingDown" :time="10000" @end="handleCooldownEnd">
            <template slot-scope="props">({{ props.totalSeconds }})</template>
          </vue-countdown>
        </button>
      </template>
      <!-- FIRST STEP /-->

      <!-- SECOND STEP -->
      <template v-if="step === 2">
        <section class="form-section">
          <form class="report-form">
            <div class="report-form__content">
              <div class="report-form__label">{{ $t('TICKET__FIELD_TICKET_TOPIC_CATEGORY') }}</div>
              <div class="report-form__field">
                <custom-input :value="currentTicketTopicCategoryName" :isDisabled="true" />
              </div>
            </div>
            <div class="report-form__content">
              <div class="report-form__label">{{ $t('TICKET__FIELD_TICKET_TOPIC') }}</div>
              <div class="report-form__field">
                <div class="select-wrap">
                  <custom-select
                    :placeholder="$t('GENERAL__PLACEHOLDER_DEFAULT_SELECT')"
                    :options="ticketTopicIdMapping"
                    v-model="userSelectedTicketTopicId"
                  />
                </div>
              </div>
            </div>
          </form>
        </section>
        <button class="reportlist__return" :disabled="!isSecondButtonEnabled" @click.prevent="lockTicketTopicId">
          {{ $t('GENERAL__BUTTON_NEXT_STEP') }}
        </button>
        <button class="reportlist__back" @click.prevent="unlockTicketTopicCategoryId">
          {{ $t('GENERAL__BUTTON_PREVIOUS_STEP') }}
        </button>
      </template>
      <!-- SECOND STEP /-->

      <!-- THIRD STEP -->
      <template v-if="step === 3">
        <section class="form-section">
          <form class="report-form">
            <template v-for="field in currentTicketTopicFields">
              <div class="report-form__content" :key="field.id">
                <div class="report-form__inline">
                  <div class="report-form__label">
                    {{ field.displayName }}
                    <span v-if="field.isRequired">*</span>
                  </div>
                  <div class="report-form__field">
                    <template v-if="field.type === 'input'">
                      <custom-input
                        :name="field.name"
                        :placeholder="field.attributes.placeholder"
                        :is-required="field.isRequired"
                        :error-msg="$t('GENERAL__INPUT_ERROR_REQUIRED')"
                        :is-limit-length="true"
                        v-model="fieldsValue[field.name]"
                      />
                    </template>
                    <template v-else-if="field.type === 'textarea'">
                      <custom-textarea
                        :name="field.name"
                        :placeholder="field.attributes.placeholder"
                        :is-required="field.isRequired"
                        :error-msg="$t('GENERAL__INPUT_ERROR_REQUIRED')"
                        :is-limit-length="true"
                        v-model="fieldsValue[field.name]"
                      />
                    </template>
                    <template v-if="errors && errors[field.name]">
                      <div class="report-form__error">{{ field.attributes.error }}。</div>
                    </template>
                    <template v-if="field.type === 'file'">
                      <uploader
                        :name="field.name"
                        @uploading="updateUploadingFiles"
                        @uploaded="addUploadedFileIds"
                        @removed="removeUploadedFileIds"
                        :label="$t('GENERAL__BUTTON_UPLOAD_FILE')"
                        :is-required="field.isRequired"
                      />
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </form>
        </section>
        <button class="reportlist__return" :disabled="!isThirdButtonEnabled" @click.prevent="lockForm">
          {{ $t('GENERAL__BUTTON_NEXT_STEP') }}
        </button>
        <button class="reportlist__back" @click.prevent="unlockTicketTopicId">
          {{ $t('GENERAL__BUTTON_PREVIOUS_STEP') }}
        </button>
      </template>
      <!-- THIRD STEP /-->

      <!-- FOURTH STEP -->
      <template v-if="step === 4">
        <section class="form-section">
          <form class="report-form">
            <div class="report-form__content">
              <custom-checkbox :name="'ticket_email_checkbox'" :label="$t('TICKET__EMAIL_FIELD_CHECKBOX')" v-model="isEmailRequired" />
            </div>
            <template v-if="isEmailRequired">
              <div class="report-form__content">
                <div class="report-form__label">
                  {{ $t('TICKET__EMAIL') }}
                </div>
                <div class="report-form__field">
                  <custom-input
                    name="notification_email_address"
                    :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH')"
                    :custom-type="constants.GENERAL__INPUT_TYPES.EMAIL"
                    :error-msg="$t('GENERAL__INPUT_ERROR_EMAIL_FORMAT')"
                    v-model="emailAddress"
                  />
                </div>
              </div>
            </template>
            <div class="report-form__content">
              <custom-checkbox :name="'ticket_tos_checkbox'" :label="$t('TICKET__TOS_CHECKBOX')" v-model="isTosChecked" />
            </div>
          </form>
        </section>
        <button class="reportlist__return" :disabled="!isFourthButtonEnabled" @click.prevent="submitTicket">
          {{ $t('GENERAL__BUTTON_SUBMIT_FORM') }}
          <vue-countdown v-if="isSubmitting" :time="10000" @end="handleCountdownEnd">
            <template slot-scope="props">({{ props.totalSeconds }})</template>
          </vue-countdown>
        </button>
        <button class="reportlist__back" @click.prevent="unlockForm">
          {{ $t('GENERAL__BUTTON_PREVIOUS_STEP') }}
        </button>
      </template>
      <!-- FOURTH STEP /-->
    </template>
    <template v-else>
      <div class="loading">
        <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
      </div>
    </template>

    <confirm
      v-if="isShowingConfirmLeaving"
      :title="$t('POPUP__ALERT')"
      :content="$t('POPUP__CONFIRM_LEAVE')"
      @close="closeConfirmLeaving"
      @confirm="changePage"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import VueCountdown from '@chenfengyuan/vue-countdown';
import TicketStep from '@/components/mobile/layouts/partial/TicketStep';
import CustomCheckbox from '@/components/mobile/layouts/partial/CustomCheckbox';
import CustomInput from '@/components/share/layouts/partial/CustomInput';
import CustomRadio from '@/components/mobile/layouts/partial/CustomRadio';
import CustomSelect from '@/components/share/layouts/partial/CustomSelect';
import CustomTextarea from '@/components/share/layouts/partial/CustomTextarea';
import Confirm from '@/components/share/layouts/partial/Confirm';
import Uploader from '@/components/share/layouts/partial/Uploader';
import ticket from '@/api/prod/ticket';
import constants from '@/lib/constants';
import utils from '@/lib/utils';
import { ACTION_GET_TICKET_TOPIC_CATEGORIES, ACTION_GET_TICKET_TOPICS, ACTION_TRIGGER_POPUP } from '@/store/action-types';

export default {
  name: 'MobileTicket',
  beforeRouteLeave(to, from, next) {
    if (this.isShowingConfirmLeaving || this.hasPopup || this.isSubmitting || to.name === 'MobileNotFound') {
      next();
    } else {
      this.openConfirmLeaving();
      if (!this.isGoingPreviousPage) {
        this.nextRoute = to;
      }
      next(false);
    }
  },
  components: {
    VueCountdown,
    TicketStep,
    CustomCheckbox,
    CustomInput,
    CustomRadio,
    CustomSelect,
    CustomTextarea,
    Confirm,
    Uploader,
  },
  data() {
    return {
      constants,
      utils,
      isShowingConfirmLeaving: false,
      isGoingPreviousPage: false,
      nextRoute: null,
      step: 1,
      gameCodeOnUrl: this.$route.params.gameCode,
      baseCategoryNameOnUrl: this.$route.query.bc,
      ticketTopicCategoryIdOnUrl: parseInt(this.$route.query.ttc, 10) || null,
      hasChosenTicketTopicCategory: false,
      hasChosenTicketTopic: false,
      hasFilledForm: false,
      userCheckedBaseCategoryId: null,
      userCheckedTicketTopicCategoryId: null,
      userSelectedTicketTopicId: null,
      isEmailRequired: false,
      isTosChecked: false,
      errors: null,
      uploadingFiles: {},
      fieldsValue: {},
      emailAddress: null,
      requiredFields: [],
      isCoolingDown: false,
      isSubmitting: false,
    };
  },
  computed: {
    isTicketTopicCategoryDataReady() {
      return (
        !utils.isEmptyObject(this.gamesCodeNameMapping) &&
        !utils.isEmptyObject(this.gamesCodeIdMapping) &&
        !utils.isEmptyObject(this.baseCategoriesCodeIdMapping) &&
        !utils.isEmptyObject(this.baseCategoriesIdMapping) &&
        !utils.isEmptyObject(this.baseCategoriesIdTicketTopicCategoriesMapping) &&
        !utils.isEmptyObject(this.ticketTopicCategoryIdMapping)
      );
    },
    baseCategoriesToDisplay() {
      return this.baseCategories.filter((bc) => this.baseCategoriesIdTicketTopicCategoriesMapping[bc.id]);
    },
    currentGameName() {
      return this.gamesCodeNameMapping[this.gameCodeOnUrl] || '';
    },
    currentGameId() {
      return this.gamesCodeIdMapping[this.gameCodeOnUrl] || 0;
    },
    currentBaseCategoryName() {
      return this.userCheckedBaseCategoryId ? this.baseCategoriesIdMapping[this.userCheckedBaseCategoryId].name || '' : '';
    },
    currentTicketTopicCategories() {
      return this.baseCategoriesIdTicketTopicCategoriesMapping[this.userCheckedBaseCategoryId] || [];
    },
    currentTicketTopicCategoryName() {
      return this.ticketTopicCategoryIdMapping[this.userCheckedTicketTopicCategoryId].name || '';
    },
    currentTicketTopicFields() {
      return this.userSelectedTicketTopicId ? this.ticketTopicIdMapping[this.userSelectedTicketTopicId].fields : [] || [];
    },
    isFirstButtonEnabled() {
      return this.userCheckedBaseCategoryId && this.userCheckedTicketTopicCategoryId && !this.isCoolingDown;
    },
    isSecondButtonEnabled() {
      return this.hasChosenTicketTopicCategory && this.userSelectedTicketTopicId;
    },
    isThirdButtonEnabled() {
      return this.userSelectedTicketTopicId && !this.isUploading && this.isFormFilled;
    },
    isFourthButtonEnabled() {
      return this.userSelectedTicketTopicId && this.isTosChecked && this.isFormFilled && this.isEmailFilledIfRequired && !this.isSubmitting;
    },
    isUploading() {
      return !utils.isEmptyObject(this.uploadingFiles) && Object.values(this.uploadingFiles).some((file) => !file.uploadCompleted);
    },
    isFormFilled() {
      // check all required fields are filled
      return this.requiredFields.every((field) => this.fieldsValue[field] && this.fieldsValue[field].length > 0);
    },
    isEmailFilledIfRequired() {
      return !(!this.utils.isValidEmail(this.emailAddress) && this.isEmailRequired);
    },
    ...mapState({
      games: (state) => state.game.games,
      baseCategories: (state) => state.category.baseCategories,
      ticketTopicCategories: (state) => state.category.ticketTopicCategories,
      ticketTopics: (state) => state.ticketTopics,
    }),
    ...mapGetters([
      'gamesCodeNameMapping',
      'gamesCodeIdMapping',
      'baseCategoriesCodeIdMapping',
      'baseCategoriesIdMapping',
      'baseCategoriesIdTicketTopicCategoriesMapping',
      'ticketTopicCategoryIdMapping',
      'ticketTopicIdMapping',
    ]),
  },
  methods: {
    handleCooldownEnd() {
      this.isCoolingDown = false;
    },
    handleCountdownEnd() {
      this.isSubmitting = false;
    },
    openConfirmLeaving() {
      this.isShowingConfirmLeaving = true;
    },
    closeConfirmLeaving() {
      this.isShowingConfirmLeaving = false;
    },
    toPrevious() {
      this.$router.go(-1);
    },
    toNext() {
      const { name, params } = this.nextRoute;
      this.$router.push({ name, params });
    },
    changePage() {
      if (this.isGoingPreviousPage) {
        this.isGoingPreviousPage = false;
        this.toPrevious();
      } else {
        this.toNext();
      }
    },
    checkTicketNotThrottling() {
      return ticket.getTicketThrottling(this.currentGameId, this.userCheckedBaseCategoryId).then((resp) => {
        const { error } = resp;
        return !error;
      });
    },
    lockTicketTopicCategoryId() {
      this.checkTicketNotThrottling().then((resp) => {
        if (resp) {
          this.hasChosenTicketTopicCategory = true;
          this.step = 2;
        } else {
          this.isCoolingDown = true;
          this.triggerPopup({
            title: this.$t('POPUP__ALERT'),
            content: this.$t('POPUP__ERROR_TICKET_THROTTLING'),
          });
        }
      });
    },
    unlockTicketTopicCategoryId() {
      this.hasChosenTicketTopicCategory = false;
      this.userSelectedTicketTopicId = null;
      this.step = 1;
    },
    lockTicketTopicId() {
      this.hasChosenTicketTopic = true;
      this.step = 3;
    },
    unlockTicketTopicId() {
      this.hasChosenTicketTopic = false;
      this.step = 2;
    },
    lockForm() {
      this.hasFilledForm = true;
      this.step = 4;
    },
    unlockForm() {
      this.hasFilledForm = false;
      this.step = 3;
    },
    updateUploadingFiles(files) {
      this.uploadingFiles = files;
    },
    addUploadedFileIds(name, fileId) {
      if (!this.fieldsValue[name]) {
        this.fieldsValue[name] = [];
      }
      this.fieldsValue[name].push(fileId);
    },
    removeUploadedFileIds(name, fileId) {
      const idx = this.fieldsValue[name].indexOf(fileId);
      this.fieldsValue[name].splice(idx, 1);
    },
    submitTicket() {
      this.isSubmitting = true;
      const payload = {
        game_id: this.currentGameId,
        category_id: this.userCheckedBaseCategoryId,
        ticket_topic_category_id: this.userCheckedTicketTopicCategoryId,
        ticket_topic_id: this.userSelectedTicketTopicId,
        need_notification_email: this.isEmailRequired,
        body: JSON.stringify(this.fieldsValue),
      };
      if (this.isEmailRequired) {
        payload.notification_email_address = this.emailAddress;
      }
      ticket
        .createTicket(payload)
        .then((resp) => {
          const { data, error } = resp;
          if (error || !data) {
            if (error === 'error_ticket_throttling') {
              this.triggerPopup({
                title: this.$t('POPUP__ERROR'),
                content: this.$t('POPUP__ERROR_TICKET_THROTTLING'),
              });
            } else {
              this.triggerPopup({
                title: this.$t('POPUP__ERROR'),
                content: this.$t('POPUP__ERROR_TICKET_CREATE_FAILED'),
              });
              this.isSubmitting = false;
            }
          } else {
            let popupContent = this.$t('POPUP__ALERT_TICKET_CREATE_SUCCEED_CONTENT', { csNumber: data.csNumber });
            if (this.isTwVersion) {
              popupContent += `<br>${this.$t('GENERAL__SERVICE_PHONE')}<br>${this.$t('GENERAL__SERVICE_TIME')}`;
            }
            this.triggerPopup({
              title: this.$t('POPUP__ALERT_TICKET_CREATE_SUCCEED_TITLE', { csNumber: data.csNumber }),
              content: popupContent,
            });
            this.$router.push({ name: 'MobileReport', params: { csNumber: data.csNumber } });
          }
        })
        .catch(() => {
          this.triggerPopup({
            title: this.$t('POPUP__ERROR'),
            content: this.$t('POPUP__ERROR_TICKET_CREATE_FAILED'),
          });
        });
    },
    ...mapActions({
      getTicketTopicCategories: ACTION_GET_TICKET_TOPIC_CATEGORIES,
      getTicketTopics: ACTION_GET_TICKET_TOPICS,
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
  },
  mounted() {
    window.onpopstate = () => {
      this.isGoingPreviousPage = true;
    };
  },
  watch: {
    currentGameId: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.getTicketTopicCategories(this.currentGameId);
        }
      },
    },
    gamesCodeIdMapping: {
      immediate: true,
      handler(newValue) {
        if (!utils.isEmptyObject(newValue) && this.currentGameId === 0) {
          this.$router.push({ name: 'MobileNotFound' });
        }
      },
    },
    isTicketTopicCategoryDataReady: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.userCheckedBaseCategoryId = this.baseCategoriesCodeIdMapping[this.baseCategoryNameOnUrl];
          if (!(this.userCheckedBaseCategoryId in this.baseCategoriesIdTicketTopicCategoriesMapping)) {
            this.userCheckedBaseCategoryId = null;
          }
          if (this.ticketTopicCategoryIdOnUrl && this.ticketTopicCategoryIdMapping[this.ticketTopicCategoryIdOnUrl]) {
            this.userCheckedTicketTopicCategoryId = this.ticketTopicCategoryIdOnUrl;
            if (this.ticketTopicCategoryIdMapping[this.ticketTopicCategoryIdOnUrl].baseCategoryId !== this.userCheckedBaseCategoryId) {
              this.userCheckedTicketTopicCategoryId = null;
            }
          }
          if (this.userCheckedBaseCategoryId && this.userCheckedTicketTopicCategoryId) {
            this.lockTicketTopicCategoryId();
          }
        }
      },
    },
    userCheckedBaseCategoryId(newValue) {
      if (newValue && this.userCheckedTicketTopicCategoryId) {
        if (this.ticketTopicCategoryIdMapping[this.userCheckedTicketTopicCategoryId].baseCategoryId !== newValue) {
          this.userCheckedTicketTopicCategoryId = null;
        }
      }
    },
    hasChosenTicketTopicCategory(newValue) {
      if (newValue) {
        this.getTicketTopics(this.userCheckedTicketTopicCategoryId);
      }
    },
    currentTicketTopicFields(newValue) {
      const newFieldsValue = {};
      this.requiredFields = [];
      newValue.forEach((field) => {
        newFieldsValue[field.name] = null;
        if (field.isRequired) {
          this.requiredFields.push(field.name);
        }
      });
      this.fieldsValue = Object.assign({}, newFieldsValue);
    },
    isEmailRequired: {
      handler(newValue) {
        // check email is filled if user need email notification
        if (!newValue) {
          this.emailAddress = null;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .form-section_1 {
    li.report-form__row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include queryRegionSMP {
        display: block;
      }
    }
    .report-form .report-form__label {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
  .report-form__label span {
    color: $general-red;
  }
}
</style>
