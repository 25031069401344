var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "home-section" }, [
    _c("div", { staticClass: "home-section__title" }, [
      _c("div", {
        staticClass: "home-section__title-icon",
        class: _vm.homeGameInfo.class
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "home-section__title-txt" }, [
        _vm._v("\n      " + _vm._s(_vm.$t(_vm.homeGameInfo.title)) + "\n    ")
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "home-games" }, [
      _c(
        "div",
        { staticClass: "home-games__inner" },
        _vm._l(_vm.games, function(game) {
          return _c(
            "router-link",
            {
              key: game.id,
              staticClass: "home-games__item",
              attrs: {
                to: {
                  name: "ArticleList",
                  params: { gameCode: game.code, baseCategory: "game" }
                }
              }
            },
            [
              _c("img", {
                staticClass: "home-games__img",
                attrs: { src: game.displayImageUrl, alt: "" }
              })
            ]
          )
        }),
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }