/* eslint-disable */

import utils from '@/lib/utils';
import common from '@/utils/common';
import api from '@/api';

const axios = require('axios');

function getTickets(status = null, offset = null) {
  return api.call(api.GET, '/tickets', {
    status: status,
    offset: offset,
  });
}

function getTicketByCsNumber(csNumber, isLegacy = false) {
  return api.call(api.GET, `/ticket/cs-number/${csNumber}`, {
    isLegacy,
  });
}

function getTicketThrottling(gameId, categoryId) {
  return api.call(api.GET, '/ticket/throttling', {
    gameId: gameId,
    categoryId: categoryId,
  });
}

function createTicket(payload) {
  return api.call(api.POST, '/ticket', payload);
}

function replyTicket(csNumber, payload) {
  // payload: { files: (array), content: (string) }
  return api.call(api.POST, `/ticket/cs-number/${csNumber}/reply`, payload);
}

function rateTicket(csNumber, payload) {
  // payload: { is_satisfied: (boolean), content: (string) }
  return api.call(api.POST, `/ticket/cs-number/${csNumber}/rate`, payload);
}

function rateTicketReply(csNumber, replyId, payload) {
  // payload: { is_satisfied: (boolean) }
  return api.call(api.POST, `/ticket/cs-number/${csNumber}/reply/${replyId}/rate`, payload);
}

function getTicketLinkInfo(token) {
  return api.call(api.GET, '/ticket-link', {
    token: token,
  });
}

function linkTicketLink(payload) {
  // payload: { token: (string) }
  return api.call(api.POST, '/ticket-link/link', payload);
}

// function getTickets(status, offset) {
//   if (status === 4) {
//     return new Promise((resolve) => {
//       resolve({
//         ok: true,
//         status: 200,

//         data: {
//           tickets: [
//             {
//               csNumber: 12425612312,
//               gameId: 2,
//               baseCategoryId: 5,
//               ticketTopicCategoryId: 7,
//               ticketTopicId: 1,
//               ticketStatus: 12,
//               hasUnreadReply: false,
//               createdAt: 1558333791,
//               gameName: '歷史遊戲',
//               baseCategoryName: '歷史申訴',
//               ticketTopicCategoryName: '歷史內容問題',
//               ticketTopicName: '歷史建議',
//             },
//           ],
//           pagination: {
//             cur_offset: 2,
//             total_cnt: 0,
//           },
//         },
//         error: '',
//       });
//     });
//   } else
//     return new Promise((resolve) => {
//       resolve({
//         ok: true,
//         status: 200,

//         data: {
//           tickets: [
//             {
//               csNumber: 124256,
//               gameId: 3,
//               baseCategoryId: 5,
//               ticketTopicCategoryId: 7,
//               ticketTopicId: 1,
//               ticketStatus: 11,
//               hasUnreadReply: true,
//               createdAt: 1558333791,
//               gameName: '無限法則',
//               baseCategoryName: '停權申訴',
//               ticketTopicCategoryName: '遊戲內容問題',
//               ticketTopicName: '遊戲建議',
//             },
//           ],
//           pagination: {
//             curOffset: 2,
//             totalCnt: 0,
//           },
//         },
//         error: '',
//       });
//     });
// }

// function getTickets(status, offset) {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: JSON.parse(JSON.stringify({
//         data: {
//           tickets: [
//             {
//               cs_number: 124256,
//               game_id: 3,
//               base_category_id: 5,
//               ticket_topic_category_id: 7,
//               ticket_topic_id: 10,
//               ticket_status: 1,
//               has_unread_reply: true,
//               created_at: 1558333791,
//               game_name: "無限法則",
//               base_category_name: "停權申訴",
//               ticket_topic_category_name: "遊戲內容問題",
//               ticket_topic_name: "遊戲建議"
//             },
//             {
//               cs_number: 124257,
//               game_id: 2,
//               base_category_id: 4,
//               ticket_topic_category_id: 6,
//               ticket_topic_id: 2,
//               ticket_status: 2,
//               has_unread_reply: false,
//               created_at: 1558333793,
//               game_name: "傳說對決",
//               base_category_name: "違規檢舉",
//               ticket_topic_category_name: "參加活動有問題",
//               ticket_topic_name: "遊戲建議"
//             },
//             {
//               cs_number: 124258,
//               game_id: 2,
//               base_category_id: 4,
//               ticket_topic_category_id: 6,
//               ticket_topic_id: 2,
//               ticket_status: 6,
//               has_unread_reply: false,
//               created_at: 1558333795,
//               game_name: "戰地之王",
//               base_category_name: "違規檢舉",
//               ticket_topic_category_name: "參加活動有問題",
//               ticket_topic_name: "遊戲建議"
//             },
//             {
//               cs_number: 124259,
//               game_id: 2,
//               base_category_id: 4,
//               ticket_topic_category_id: 6,
//               ticket_topic_id: 2,
//               ticket_status: 6,
//               has_unread_reply: false,
//               created_at: 1558333797,
//               game_name: "戰地之王",
//               base_category_name: "違規檢舉",
//               ticket_topic_category_name: "參加活動有問題",
//               ticket_topic_name: "遊戲建議"
//             },
//           ],
//           pagination: {
//             cur_offset: offset,
//             total_cnt: 4,
//           }

//         },
//         error: '',
//       })),
//     });
//   });
// }

// function getTicketByCsNumber(csNumber, isLegacy) {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: common.camelizeKeys({
//         id: 484,
//         cs_number: isLegacy ? 8787123123 : 12425612312,
//         ticket_status: 0,
//         game_id: 1,
//         base_category_id: 5,
//         ticket_topic_category_id: 1,
//         ticket_topic_id: 1,
//         need_notification_email: false,
//         notification_email_address: 'linvic@garena.com',
//         replies: [
//           {
//             id: 112,
//             type: 1,
//             created_at: 1558324391,
//             content:
//               '## [角色暱稱]: YUKI💗💗💗💗💗💗💗💗💗\n## [狀況敘述]: YUKI 是一隻可愛的兔子 \\#\\#\n### [螢幕截圖]: [sticker (1).png](https://static.garena.tw/download/support.garena.com/2019-05-28/2T0amKnxQ-iRLFL840fHFg.png?key=Z424k)',
//             attachments: [
//               {
//                 url: 'https://example.com',
//                 filename: '附件檔名0',
//               },
//             ],
//           },
//           {
//             id: 113,
//             type: 2,
//             created_at: 1558333991,
//             content: '客服留言內容',
//             attachments: [
//               {
//                 url: 'https://example.com',
//                 filename: '附件檔名1',
//               },
//               {
//                 url: 'https://example.com',
//                 filename: '附件檔名2',
//               },
//             ],
//             staff_name: 'L',
//             is_helpful: false,
//             creator_type: 2,
//           },
//           {
//             id: 114,
//             type: 1,
//             created_at: 1558334791,
//             content: '用戶留言內容',
//             attachments: [
//               {
//                 url: 'https://example.com',
//                 filename: '附件檔名3',
//               },
//             ],
//           },
//           {
//             id: 116,
//             type: 2,
//             created_at: 1558343991,
//             content: '客服留言內容很有用',
//             attachments: [],
//             staff_name: 'L',
//             is_helpful: true,
//             creator_type: 2,
//           },
//         ],
//         can_provide_feedback: false,
//         feedback: null,
//         // feedback: {
//         //   is_satisfied: true,
//         //   content: '服務評價建議',
//         // }
//       }),
//       error: '',
//     });
//   });
// }

export default {
  getTickets,
  getTicketByCsNumber,
  getTicketThrottling,
  createTicket,
  replyTicket,
  rateTicket,
  rateTicketReply,
  getTicketLinkInfo,
  linkTicketLink,
};
