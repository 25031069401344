/* eslint-disable */

import api from '@/api';

function getApplications(type) {
  const params = {
    type: type,
  };
  return api.call(api.GET, '/applications', params);
}

function createApplication(type, payload) {
  return api.call(api.POST, `/application/${type}`, payload);
}

function getPlayInfo() {
  return api.call(api.GET, '/application/account-recovery/player-info-fields');
}

function getVerificationAvailability() {
  return api.call(api.GET, '/application/verification/availability');
}

// function getApplications() {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: JSON.parse(JSON.stringify({
//         data: [],
//         error: '',
//       })),
//     });
//   });
// }

// function getPlayInfo() {
//   return new Promise((resolve) => {
//     resolve({
//       ok: true,
//       status: 200,
//       data: JSON.parse(JSON.stringify({
//         data: config.PLAY_INFO[gameId],
//         error: '',
//       })),
//     });
//   });
// }

export default {
  getApplications,
  createApplication,
  getPlayInfo,
  getVerificationAvailability,
};
