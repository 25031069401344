<template>
  <div class="main-wrap">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('VERIFICATION__RESET_PROFILE_TITLE')" :has-search-bar="false" />
      <div class="main-inner">
        <div class="profile">
          <breadcrumb :paths="paths" />
          <div class="main-title">
            {{ $t('VERIFICATION__RESET_PROFILE_TITLE') }}
          </div>
          <div class="profileresult-main">
            <div class="profileresult-ret">
              <template v-if="status === constants.APPLICATION__VERIFICATION_RESULTS.SUCCESS">
                <div class="profileresult-ret__icon profileresult-ret__icon--success"></div>
                <div class="profileresult-ret__title">{{ $t('VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_SUCCESS_TITLE') }}</div>
                <div class="profileresult-ret__text" v-html="$t('VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_SUCCESS_TEXT')"></div>
              </template>
              <template v-if="status === constants.APPLICATION__VERIFICATION_RESULTS.FAILURE">
                <div class="profileresult-ret__icon profileresult-ret__icon--failure"></div>
                <div class="profileresult-ret__title">{{ $t('VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_FAILURE_TITLE') }}</div>
                <div class="profileresult-ret__text" v-html="$t('VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_FAILURE_TEXT')"></div>
              </template>
            </div>
            <div class="profileresult-tip">
              <div class="main-title profileresult-tip__title">{{ $t('VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_NOTICE') }}</div>
              <ol class="profileresult-tip__list">
                <li class="profileresult-tip__item">{{ $t('VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_NOTICE_1') }}</li>
                <li class="profileresult-tip__item">{{ $t('VERIFICATION__RESET_PROFILE_APPLICATION_COMPLETION_NOTICE_2') }}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';
import Navigation from '@/components/pc/layouts/Navigation';
import constants from '@/lib/constants';

export default {
  name: 'ResetProfileCompletion',
  components: {
    Navigation,
    Breadcrumb,
  },
  data() {
    return {
      paths: [
        {
          id: 1,
          name: 'Verification',
          title: this.$t('HOME__VERIFICATION_TITLE'),
        },
        {
          id: 2,
          name: 'ResetProfile',
          title: this.$t('VERIFICATION__RESET_PROFILE_TITLE'),
        },
      ],
      constants,
      status: this.$route.params.status,
    };
  },
  mounted() {
    if (Object.values(constants.APPLICATION__VERIFICATION_RESULTS).indexOf(this.status) === -1) {
      this.$router.push({ name: 'PageNotFound' });
    }
  },
};
</script>

<style scoped></style>
