<template>
  <div class="main-wrap">
    <ticket-step :step="step" />

    <!-- FIRST STEP -->
    <template v-if="step === 1">
      <section class="game-section">
        <div class="reportlist-main">
          <div class="reportlist-title">
            <div class="faqdetail__question">{{ $t('CANT_LOGIN__ITEM_RESETPWD_LINKTEXT') }}</div>
            <div class="reportlist-result-subhead" v-html="$t('M_CANT_LOGIN__ACCOUNT_RECOVERY_REMINDER')"></div>
          </div>
        </div>
      </section>
      <section class="form-section">
        <form class="report-form">
          <div class="report-form__content">
            <div class="report-form__label">
              {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_ACCOUNT') }}
            </div>
            <div class="report-form__field">
              <custom-input
                name="accountName"
                :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH')"
                :custom-type="constants.GENERAL__INPUT_TYPES.GARENA_ACCOUNT"
                :error-msg="$t('GENERAL__INPUT_ERROR_ACCOUNT_FORMAT')"
                :need-validation="true"
                :validation.sync="fieldsValidation.accountName"
                v-model.trim="fieldsValue.accountName"
              />
            </div>
          </div>
          <div class="report-form__content">
            <div class="report-form__label">
              {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_REGISTER_EMAIL') }}
            </div>
            <div class="report-form__field">
              <custom-input
                name="registrationEmail"
                :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH')"
                :custom-type="constants.GENERAL__INPUT_TYPES.EMAIL"
                :error-msg="$t('GENERAL__INPUT_ERROR_EMAIL_FORMAT')"
                :extra-msg="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH')"
                :need-validation="true"
                :validation.sync="fieldsValidation.registrationEmail"
                v-model="fieldsValue.registrationEmail"
              />
            </div>
          </div>
          <div class="report-form__content">
            <div class="report-form__label">
              {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_PHONE') }}
            </div>
            <div class="onlineservice-form__field">
              <div class="onlineservice-form__field-phone">
                <phone-input
                  name="notification_info"
                  :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH_INT')"
                  :error-msg="$t('GENERAL__INPUT_ERROR_PHONE_FORMAT')"
                  v-model="contactNumberObj"
                />
              </div>
            </div>
          </div>
          <div class="report-form__content">
            <div class="report-form__label">
              {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_CONTACT_EMAIL') }}
            </div>
            <div class="report-form__field">
              <custom-checkbox
                name="contactEmailCheckbox"
                :label="$t('CANT_LOGIN__ACCOUNT_RECOVERY_SAME_EMAIL')"
                :is-disabled="!canSwitchContactEmailSame"
                v-model="isContactEmailSame"
              />
              <div v-if="!canSwitchContactEmailSame" class="report-form__error">
                {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_SAME_EMAIL_TIP') }}
              </div>
              <custom-input
                name="contactEmail"
                :placeholder="$t('GENERAL__INPUT_PLACEHOLDER_HALFWIDTH')"
                :custom-type="constants.GENERAL__INPUT_TYPES.EMAIL"
                :error-msg="$t('GENERAL__INPUT_ERROR_EMAIL_FORMAT')"
                :note-msg="$t('CANT_LOGIN__ACCOUNT_RECOVERY_CONTACT_EMAIL_TIP')"
                :is-disabled="isContactEmailSame"
                :need-validation="true"
                :validation.sync="fieldsValidation.contactEmail"
                v-model="fieldsValue.contactEmail"
              />
            </div>
          </div>
          <div class="report-form__content">
            <div class="report-form__label">
              {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_PROFILE_STATUS') }}
            </div>
            <custom-radio
              name="isUserInfoComplete"
              :label="$t('CANT_LOGIN__ACCOUNT_RECOVERY_PROFILE_WITH_FULL_DATA')"
              :radio-value="constants.APPLICATION__ACCOUNT_RECOVERY_USER_INFO_STATUS.COMPLETE"
              v-model="fieldsValue.isUserInfoComplete"
            />
            <custom-radio
              name="isUserInfoComplete"
              :label="$t('CANT_LOGIN__ACCOUNT_RECOVERY_PROFILE_WITHOUT_FULL_DATA')"
              :radio-value="constants.APPLICATION__ACCOUNT_RECOVERY_USER_INFO_STATUS.NOT_COMPLETE"
              v-model="fieldsValue.isUserInfoComplete"
            />
          </div>
        </form>
      </section>
      <button class="reportlist__return" :disabled="!isFirstButtonEnabled" @click.prevent="toNextStep">
        {{ $t('GENERAL__BUTTON_NEXT_STEP') }}
      </button>
    </template>
    <!-- FIRST STEP /-->

    <!-- SECOND STEP -->
    <template v-if="step === 2">
      <section class="form-section">
        <form class="report-form">
          <div class="report-form__content">
            <div class="report-form__label">
              {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP') }}
            </div>
            <ol class="report-form__ol">
              <li class="report-form__row">
                <custom-radio
                  name="topup_status"
                  :label="$t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_NO')"
                  :radio-value="constants.APPLICATION__ACCOUNT_RECOVERY_USER_TOPUP_STATUS.NO"
                  v-model="fieldsValue.hasTopUpHistory"
                />
              </li>
              <li class="report-form__row">
                <custom-radio
                  name="topup_status"
                  :label="$t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_YES')"
                  :radio-value="constants.APPLICATION__ACCOUNT_RECOVERY_USER_TOPUP_STATUS.YES"
                  v-model="fieldsValue.hasTopUpHistory"
                />
              </li>
            </ol>
            <!-- 有儲值紀錄 -->
            <template v-if="fieldsValue.hasTopUpHistory">
              <div class="report-form__label">
                {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_LAST_TOPUP_TIME') }}
              </div>
              <div class="report-form__field">
                <custom-datetime-picker
                  v-model="fieldsValue.lastTopUpTime"
                  :type="'datetime'"
                  :format="$t('GENERAL__DATETIME_FORMAT')"
                  :not-after="new Date()"
                >
                  <div slot="calendar-icon" class="profile-form__inputdateicon"></div>
                </custom-datetime-picker>
              </div>
              <div class="report-form__label">
                {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_AMOUNT') }}
              </div>
              <div class="report-form__field">
                <custom-input
                  name="lastTopUpAmount"
                  type="number"
                  :placeholder="$t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_AMOUNT_PLACEHOLDER')"
                  v-model="fieldsValue.lastTopUpAmount"
                  :children="
                    '<div class=\'onlineservice-form__field-priceunit\'>' +
                    $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_AMOUNT_UNIT') +
                    '</div>'
                  "
                />
              </div>
              <div class="report-form__label">
                {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_CHANNEL') }}
              </div>
              <div class="report-form__field">
                <custom-input name="lastTopUpChannel" v-model="fieldsValue.lastTopUpChannel" />
              </div>
            </template>
            <div class="report-form__label">
              {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_ACCOUNT_CREATED_TIME') }}
            </div>
            <div class="report-form__field">
              <custom-input
                name="accountCreateYear"
                :placeholder="$t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_LAST_TOPUP_TIME_PLACEHOLDER')"
                v-model="fieldsValue.accountCreateYear"
              />
            </div>
            <div class="report-form__label">
              {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_LAST_LOGIN_TIME') }}
            </div>
            <div class="report-form__field">
              <custom-datetime-picker
                v-model="fieldsValue.lastOnlineTime"
                :type="'datetime'"
                :format="$t('GENERAL__DATETIME_FORMAT')"
                :not-after="new Date()"
              >
                <div slot="calendar-icon" class="profile-form__inputdateicon"></div>
              </custom-datetime-picker>
            </div>
            <div class="report-form__label">
              {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_MAIN_GAME') }}
            </div>
            <div class="report-form__field">
              <custom-select
                :hasPlaceholder="true"
                :placeholder="$t('GENERAL__PLACEHOLDER_DEFAULT_SELECT_GAME')"
                :options="gamesIdMapping"
                v-model="mainGameId"
              />
            </div>
          </div>
        </form>
      </section>
      <button class="reportlist__return" :disabled="!isSecondButtonEnabled" @click.prevent="toNextStep">
        {{ $t('GENERAL__BUTTON_NEXT_STEP') }}
      </button>
    </template>
    <!-- SECOND STEP /-->

    <!-- THIRD STEP -->
    <template v-if="step === 3">
      <!-- 主要進行的遊戲更多資訊 -->
      <section class="form-section">
        <form class="report-form">
          <div class="report-form__content" v-for="playerInfoField in playerInfo" :key="playerInfoField.order">
            <div class="report-form__label">
              {{ playerInfoField.displayName }}
            </div>
            <div class="report-form__field">
              <custom-input
                :name="'playinfo_' + playerInfoField.order"
                :placeholder="playerInfoField.placeholder"
                v-model="fieldsValue.playerInfo[playerInfoField.key]"
                @change="$forceUpdate()"
              />
            </div>
          </div>
        </form>
      </section>
      <button class="reportlist__return" :disabled="!isThirdButtonEnabled" @click.prevent="toNextStep">
        {{ $t('GENERAL__BUTTON_NEXT_STEP') }}
      </button>
      <!-- 主要進行的遊戲更多資訊 /-->
    </template>
    <!-- THIRD STEP /-->

    <!-- FOURTH STEP -->
    <template v-if="step === 4">
      <section class="form-section">
        <form class="report-form" style="margin-bottom: 40px">
          <div class="report-form__content">
            <div class="report-form__label">
              {{ $t('CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD') }}
            </div>
            <div class="report-form__field">
              <uploader
                name="files"
                @uploaded="addUploadedFileIds"
                @removed="removeUploadedFileIds"
                :label="$t('GENERAL__BUTTON_UPLOAD_ID')"
                :is-anonymous="true"
                :show-notice="false"
                :is-required="true"
                :is-img-only="true"
                :max-file-cnt="constants.GENERAL__MAX_FILE_CNT.APPLICATION"
                :error-msg="$t('GENERAL__INPUT_ERROR_FILE_NOT_SELECTED')"
              />
              <ol
                v-if="fieldsValue.isUserInfoComplete"
                class="report-form__uploader-info"
                v-html="$t('CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD_NOTICE_1')"
              ></ol>
              <ol v-else class="report-form__uploader-info" v-html="$t('CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD_NOTICE_2')"></ol>
            </div>
          </div>
        </form>
        <div class="bg-grey"></div>
        <form class="report-form" style="margin-top: 16px">
          <ol class="report-form__ol">
            <li class="report-form__row">
              <div class="backSelect">
                <custom-checkbox
                  name="confirm_checkbox"
                  :label="$t('CANT_LOGIN__ACCOUNT_RECOVERY_CONFIRM')"
                  v-model="isConfirmChecked"
                ></custom-checkbox>
                <custom-checkbox
                  name="tos_checkbox"
                  :label="$t('CANT_LOGIN__ACCOUNT_RECOVERY_CHECKBOX')"
                  v-model="isTosChecked"
                ></custom-checkbox>
              </div>
            </li>
          </ol>
        </form>
      </section>
      <button class="reportlist__return" :disabled="!isFourthButtonEnabled" @click.prevent="submitAccountRecoveryApplication">
        {{ $t('GENERAL__BUTTON_SUBMIT_FORM') }}
        <vue-countdown v-if="isSubmitting" :time="10000" @end="handleCountdownEnd">
          <template slot-scope="props">({{ props.totalSeconds }})</template>
        </vue-countdown>
      </button>
    </template>
    <!-- FOURTH STEP /-->

    <confirm
      v-if="isShowingConfirmLeaving"
      :title="$t('POPUP__ALERT')"
      :content="$t('POPUP__CONFIRM_LEAVE')"
      @close="closeConfirmLeaving"
      @confirm="toNext"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import VueCountdown from '@chenfengyuan/vue-countdown';
import TicketStep from '@/components/mobile/layouts/partial/TicketStep';
import CustomCheckbox from '@/components/mobile/layouts/partial/CustomCheckbox';
import CustomDatetimePicker from '@/components/share/layouts/partial/CustomDatetimePicker';
import CustomInput from '@/components/share/layouts/partial/CustomInput';
import CustomRadio from '@/components/mobile/layouts/partial/CustomRadio';
import CustomSelect from '@/components/share/layouts/partial/CustomSelect';
import Confirm from '@/components/share/layouts/partial/Confirm';
import Uploader from '@/components/share/layouts/partial/Uploader';
import PhoneInput from '@/components/share/layouts/partial/PhoneInput';
import application from '@/api/prod/application';
import constants from '@/lib/constants';
import utils from '@/lib/utils';
import { ACTION_GET_ACCOUNT_RECOVERY_PLAYER_INFO_FIELDS, ACTION_TRIGGER_POPUP } from '@/store/action-types';
import { API_RETURN_CODE_APPLIED_TOO_MANY_TIMES } from '@/lib/return-codes';

export default {
  name: 'AccountRecovery',
  beforeRouteLeave(to, from, next) {
    if (this.isShowingConfirmLeaving || this.hasPopup) {
      next();
    } else {
      this.openConfirmLeaving();
      this.nextRoute = to;
      next(false);
    }
  },
  components: {
    VueCountdown,
    TicketStep,
    CustomCheckbox,
    CustomDatetimePicker,
    CustomInput,
    CustomRadio,
    CustomSelect,
    Confirm,
    Uploader,
    PhoneInput,
  },
  data() {
    return {
      constants,
      isShowingConfirmLeaving: false,
      nextRoute: null,
      step: 1,
      contactNumberObj: null,
      fieldsValue: {
        accountName: null,
        registrationEmail: null,
        contactNumberCountryCode: null,
        contactNumber: null,
        contactEmail: null,
        isUserInfoComplete: null,
        files: [],
        hasTopUpHistory: null,
        lastTopUpTime: null,
        lastTopUpAmount: null,
        lastTopUpChannel: null,
        accountCreateYear: null,
        lastOnlineTime: null,
        mainGameId: null,
        playerInfo: {},
      },
      fieldsValidation: {
        accountName: false,
        registrationEmail: false,
        contactEmail: false,
      },
      mainGameId: null,
      firstStepRequiredFields: [
        'accountName',
        'registrationEmail',
        'contactNumberCountryCode',
        'contactNumber',
        'contactEmail',
        'isUserInfoComplete',
      ],
      secondStepRequiredFields: ['hasTopUpHistory', 'accountCreateYear', 'lastOnlineTime', 'mainGameId'],
      thirdStepRequiredFields: ['hasTopUpHistory', 'accountCreateYear', 'lastOnlineTime', 'mainGameId'],
      playerInfo: [],
      isContactEmailSame: false,
      isConfirmChecked: false,
      isTosChecked: false,
      isSubmitting: false,
    };
  },
  computed: {
    canSwitchContactEmailSame() {
      return this.fieldsValue.registrationEmail && utils.isValidEmail(this.fieldsValue.registrationEmail);
    },
    isFirstButtonEnabled() {
      return (
        this.firstStepRequiredFields.every(
          (field) =>
            (this.fieldsValue[field] && this.fieldsValue[field].length > 0) ||
            typeof this.fieldsValue[field] === 'number' ||
            typeof this.fieldsValue[field] === 'boolean',
        ) && Object.values(this.fieldsValidation).every((field) => field)
      );
    },
    isSecondButtonEnabled() {
      return this.secondStepRequiredFields.every(
        (field) =>
          (this.fieldsValue[field] && this.fieldsValue[field].length > 0) ||
          typeof this.fieldsValue[field] === 'number' ||
          typeof this.fieldsValue[field] === 'boolean',
      );
    },
    isThirdButtonEnabled() {
      return this.isPlayerInfoFilled;
    },
    isFourthButtonEnabled() {
      return this.isConfirmChecked && this.isTosChecked && this.fieldsValue.files.length > 0 && !this.isSubmitting;
    },
    isPlayerInfoFilled() {
      return Object.values(this.fieldsValue.playerInfo).every((value) => value && value.length > 0);
    },
    ...mapState({
      games: (state) => state.game.games,
      hasPopup: (state) => state.common.hasPopup,
      allPlayerInfoFields: (state) => state.application.accountRecoveryPlayerInfoFields,
    }),
    ...mapGetters(['gamesIdMapping']),
  },
  methods: {
    toNextStep() {
      if (this.step === 1 && this.fieldsValue.isUserInfoComplete) {
        this.step = 4;
      } else {
        this.step += 1;
      }
      window.scrollTo(0, 0);
    },
    openConfirmLeaving() {
      this.isShowingConfirmLeaving = true;
    },
    closeConfirmLeaving() {
      this.isShowingConfirmLeaving = false;
    },
    toNext() {
      const { name, params } = this.nextRoute;
      this.$router.push({ name, params });
    },
    addUploadedFileIds(name, fileId) {
      if (!this.fieldsValue[name]) {
        this.fieldsValue[name] = [];
      }
      this.fieldsValue[name].push(fileId);
    },
    removeUploadedFileIds(name, fileId) {
      const idx = this.fieldsValue[name].indexOf(fileId);
      this.fieldsValue[name].splice(idx, 1);
    },
    handleCountdownEnd() {
      this.isSubmitting = false;
    },
    submitAccountRecoveryApplication() {
      this.isSubmitting = true;
      const payload = utils.objBoolean2Int(this.fieldsValue);
      try {
        application.createApplication('account-recovery', payload).then((data) => {
          if (data.error) {
            if (data.error === API_RETURN_CODE_APPLIED_TOO_MANY_TIMES) {
              this.triggerPopup({
                title: this.$t('POPUP__ERROR'),
                content: this.$t('POPUP__ERROR_APPLICATION_APPLIED_TOO_MANY_TIMES'),
              });
            } else {
              this.triggerPopup({
                title: this.$t('POPUP__ERROR'),
                content: this.$t('POPUP__ERROR_APPLICATION_CREATE_FAILED'),
              });
            }
          } else {
            this.triggerPopup({
              title: this.$t('POPUP__ALERT'),
              content: this.$t('POPUP__ALERT_APPLICATION_CREATE_SUCCEED_CONTENT'),
            });
            this.$router.push({ name: 'Home' });
            window.scrollTo(0, 0);
          }
        });
      } catch {
        this.triggerPopup({
          title: this.$t('POPUP__ERROR'),
          content: this.$t('POPUP__ERROR_EXCEPTION'),
        });
      }
    },
    ...mapActions({
      triggerPopup: ACTION_TRIGGER_POPUP,
      getAccountRecoveryPlayerInfoFields: ACTION_GET_ACCOUNT_RECOVERY_PLAYER_INFO_FIELDS,
    }),
  },
  watch: {
    contactNumberObj(newValue) {
      if (newValue) {
        this.fieldsValue.contactNumberCountryCode = newValue.countryCode;
        this.fieldsValue.contactNumber = newValue.number;
      } else {
        this.fieldsValue.contactNumberCountryCode = null;
        this.fieldsValue.contactNumber = null;
      }
    },
    isContactEmailSame(newValue) {
      this.fieldsValue.contactEmail = newValue ? this.fieldsValue.registrationEmail : null;
    },
    'fieldsValue.registrationEmail': {
      handler(newValue) {
        if (this.isContactEmailSame) {
          this.fieldsValue.contactEmail = newValue;
        }
      },
    },
    'fieldsValue.isUserInfoComplete': {
      handler() {
        this.fieldsValue.hasTopUpHistory = 0;
      },
    },
    'fieldsValue.hasTopUpHistory': {
      handler(newValue) {
        const addiRequiredFields = ['lastTopUpTime', 'lastTopUpAmount', 'lastTopUpChannel'];
        this.fieldsValue.hasTopUpHistory = newValue;
        if (newValue) {
          this.secondStepRequiredFields = this.secondStepRequiredFields.concat(addiRequiredFields);
        } else {
          addiRequiredFields.forEach((value) => {
            const idx = this.secondStepRequiredFields.indexOf(value);
            if (idx !== -1) {
              this.secondStepRequiredFields.splice(idx, 1);
            }
          });
        }
      },
    },
    mainGameId(newValue) {
      this.playerInfo = this.allPlayerInfoFields && this.allPlayerInfoFields[newValue];
      this.fieldsValue.mainGameId = newValue;
      this.fieldsValue.playerInfo = this.playerInfo.reduce((acc, val) => {
        acc[val.key] = null;
        return acc;
      }, {});
    },
  },
  async mounted() {
    await this.getAccountRecoveryPlayerInfoFields();
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .custom_input-wrap {
    margin-top: 5px;
  }
  .phone_input-wrap {
    margin-top: 5px;
    width: 100%;
    ::v-deep .input-wrap .vue-tel-input {
      border-radius: 5px;
    }
  }
}
</style>
