<template>
  <div>
    <label class="uploader-wrap">
      <input
        type="file"
        class="uploader"
        ref="input"
        :accept="acceptFileTypes"
        :name="name"
        @change="onFileChange"
        :disabled="isDisabled"
      />
      <div class="uploader__label" :class="{ input_file_disabled: isDisabled }">
        {{ label }}
      </div>
    </label>
    <div v-if="isDisabled" class="report-form__uploader-tip">
      {{ $t('GENERAL__INPUT_FILE_UPLOAD_TIP', { maxFileCnt: String(maxFileCnt) }) }}
    </div>
    <template v-if="showNotice">
      <div class="report-form__uploader-info">
        <p class="report-form__uploader-infoitem">{{ $t('GENERAL__INPUT_FILE_UPLOAD_INFO') }}</p>
      </div>
    </template>
    <div v-if="!isValid" class="report-form__error">{{ errorMsg }}</div>
    <template v-if="files">
      <div class="report-form__uploader-previews">
        <template v-for="(file, idx) in files">
          <div class="report-form__uploader-preview" :key="idx">
            <img v-if="file.file.type.indexOf('image/') === 0" :src="file.src" alt="" class="report-form__uploader-previewimg" />
            <img v-else :src="`${CDN}/assets/pc/img/cproblem/icon-createreport@2x.png`" alt="" class="report-form__uploader-previewimg" />
            <a v-if="!isUploading" @click.prevent="removeImage(idx)" href="#" class="report-form__uploader-del">
              <div class="report-form__uploader-delicon"></div>
            </a>
            <div v-if="!file.uploadCompleted" class="report-form__uploader-previewmask"></div>
            <div class="report-form__uploader-previewbar">
              <div class="report-form__uploader-previewprogress" :style="{ width: file.uploadPercentage + '%' }">
                {{ file.file.name }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import constants from '@/lib/constants';
import common from '@/api/prod/common';
import utils from '@/lib/utils';
import { ACTION_TRIGGER_POPUP } from '@/store/action-types';

export default {
  name: 'Uploader',
  props: {
    label: String,
    name: String,
    isRequired: Boolean,
    errorMsg: String,
    isAnonymous: { type: Boolean, default: false },
    isValidIfNull: { type: Boolean, default: false },
    isImgOnly: { type: Boolean, default: false },
    maxFileCnt: { type: Number, default: constants.GENERAL__MAX_FILE_CNT.DEFAULT },
    showNotice: { type: Boolean, default: true },
  },
  data() {
    return {
      files: {},
      totalFilesIdx: -1,
      isUploading: false,
    };
  },
  computed: {
    acceptFileTypes() {
      if (this.isImgOnly) {
        return 'image/*';
      }
      return '';
    },
    isValid() {
      return this.validate(this.files);
    },
    isDisabled() {
      return Object.keys(this.files).length >= this.maxFileCnt;
    },
  },
  methods: {
    validate(value) {
      if (this.isValidIfNull) {
        return true;
      }
      let hasValueIfRequired = true;
      if (this.isRequired) {
        hasValueIfRequired = !utils.isEmptyObject(value);
      }
      return hasValueIfRequired;
    },
    onFileChange() {
      const { files } = this.$refs.input;
      if (!files.length) {
        return;
      }
      const file = files[0];
      this.renderFile(file);
      this.$refs.input.value = null;
    },
    renderFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.totalFilesIdx += 1;
        const currFileIdx = this.totalFilesIdx;
        const newFile = {};
        newFile[currFileIdx] = {
          file,
          src: e.target.result,
          uploadCompleted: false,
          uploadPercentage: 0,
          fileId: null,
        };
        this.files = Object.assign({}, this.files, newFile);
        this.$emit('uploading', this.files);
        common
          .uploadFile(
            file,
            this.isAnonymous,
            (progressEvent, source) => {
              if (currFileIdx in this.files) {
                this.files[currFileIdx].uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              } else {
                source.cancel(`User canceled ${currFileIdx} uploading image.`);
              }
            },
            this.isImgOnly ? 'image' : null,
          )
          .then((resp) => {
            const { data, error } = resp;
            if (error || !data) {
              this.$delete(this.files, currFileIdx);
              let errorContent = this.$t('POPUP__ERROR_EXCEPTION');
              switch (error) {
                case 'error_file_size_exceeded':
                  errorContent = this.$t('POPUP__ERROR_FILE_SIZE_EXCEEDED');
                  break;
                case 'error_invalid_file_type':
                  errorContent = this.$t('POPUP__ERROR_INVALID_FILE_TYPE');
                  break;
                default:
                  break;
              }
              this.triggerPopup({
                title: this.$t('POPUP__ERROR'),
                content: errorContent,
              });
            } else {
              const { fileId } = data;
              this.files[currFileIdx].uploadCompleted = true;
              this.files[currFileIdx].fileId = fileId;
              this.$emit('uploaded', this.name, fileId);
            }
          })
          .catch((err) => {
            if (!(err instanceof axios.Cancel)) {
              this.$delete(this.files, currFileIdx);
              this.triggerPopup({
                title: this.$t('POPUP__ERROR'),
                content: this.$t('POPUP__ERROR_EXCEPTION'),
              });
            }
          });
        this.$emit('uploading', this.files);
      };
      reader.readAsDataURL(file);
    },
    removeImage(filesIdx) {
      this.$emit('removed', this.name, this.files[filesIdx].fileId);
      this.$delete(this.files, filesIdx);
    },
    ...mapActions({
      triggerPopup: ACTION_TRIGGER_POPUP,
    }),
  },
};
</script>

<style lang="scss" scoped>
.uploader__label {
  cursor: pointer;
  &.input_file_disabled {
    background-color: #eee;
    cursor: not-allowed;
  }
}
.report-form__uploader-tip {
  display: inline;
  color: red;
}
</style>
