<template>
  <div class="cproblem__sidebar">
    <ul class="cproblem__category">
      <li v-for="category in baseCategories" :key="category.id" class="cproblem__category-item">
        <router-link
          :to="{ name: 'ArticleList', params: { gameCode: gameCode, baseCategory: category.code } }"
          class="cproblem__category-link"
          :class="{ 'cproblem__category-link--active': category.code === activeCategory }"
        >
          <div class="cproblem__category-line"></div>
          <div class="cproblem__category-icon" :class="['cproblem__category-icon--' + category.code]"></div>
          <div class="cproblem__category-text">
            {{ category.name }}
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LeftMenu',
  props: ['gameCode', 'activeCategory'],
  computed: {
    ...mapState({
      baseCategories: (state) => state.category.baseCategories,
    }),
  },
};
</script>

<style scoped></style>
