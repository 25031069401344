<template>
  <div class="main-wrap">
    <div class="main-bg" />
    <div class="main">
      <navigation :title="$t('REPORT__TITLE')" />
      <div class="main-inner">
        <div class="reportlist">
          <breadcrumb :paths="paths" />
          <div class="main-title">
            <div class="reportlist-title">{{ $t('REPORT__TITLE') }}</div>
            <div class="reportlist-filter">
              <div class="select-wrap">
                <div class="select" @click="switchFilterMenu">
                  {{ $t('REPORT__STATUS_FILTER_'.concat(ticketStatusNameMapping[currentTicketStatus]['name'])) }}
                </div>
                <div class="select__arrow"></div>

                <!-- Dropdown Ticket Status -->
                <div class="select-options" :style="{ display: isFilterMenuVisible ? 'block' : 'none' }">
                  <div
                    class="select__option"
                    @click.prevent="changeSelectedTicketStatus(constants.TICKET__TICKET_STATUS_FOR_USER.ALL)"
                    :class="{ 'select__option--active': currentTicketStatus === constants.TICKET__TICKET_STATUS_FOR_USER.ALL }"
                  >
                    {{ $t('REPORT__STATUS_FILTER_ALL') }}
                  </div>
                  <div
                    class="select__option"
                    @click.prevent="changeSelectedTicketStatus(constants.TICKET__TICKET_STATUS_FOR_USER.PENDING)"
                    :class="{ 'select__option--active': currentTicketStatus === constants.TICKET__TICKET_STATUS_FOR_USER.PENDING }"
                  >
                    {{ $t('REPORT__STATUS_FILTER_PENDING') }}
                  </div>
                  <div
                    class="select__option"
                    @click.prevent="changeSelectedTicketStatus(constants.TICKET__TICKET_STATUS_FOR_USER.PROCESSING)"
                    :class="{ 'select__option--active': currentTicketStatus === constants.TICKET__TICKET_STATUS_FOR_USER.PROCESSING }"
                  >
                    {{ $t('REPORT__STATUS_FILTER_PROCESSING') }}
                  </div>
                  <div
                    class="select__option"
                    @click.prevent="changeSelectedTicketStatus(constants.TICKET__TICKET_STATUS_FOR_USER.CLOSED)"
                    :class="{ 'select__option--active': currentTicketStatus === constants.TICKET__TICKET_STATUS_FOR_USER.CLOSED }"
                  >
                    {{ $t('REPORT__STATUS_FILTER_CLOSED') }}
                  </div>
                  <div
                    v-if="this.isTwVersion || this.isSmpVersion"
                    class="select__option"
                    @click.prevent="changeSelectedTicketStatus(constants.TICKET__TICKET_STATUS_FOR_USER.LEGACY)"
                    :class="{ 'select__option--active': isLegacy }"
                  >
                    {{ $t('REPORT__STATUS_FILTER_LEGACY') }}
                  </div>
                </div>
                <!-- Dropdown Ticket Status /-->
              </div>
            </div>
          </div>
          <div class="reportlist-main">
            <div class="reportlist-table">
              <table class="reportlist-table__inner">
                <thead>
                  <tr>
                    <th class="reportlist-table__th reportlist-table__id">{{ $t('REPORT__COLUMN_CS_NUMBER') }}</th>
                    <th class="reportlist-table__th reportlist-table__date">{{ $t('REPORT__COLUMN_CREATED_DATE') }}</th>
                    <th v-if="!isLegacy" class="reportlist-table__th reportlist-table__game">
                      {{ $t('REPORT__COLUMN_GAME') }}
                    </th>
                    <th class="reportlist-table__th reportlist-table__category">{{ $t('REPORT__COLUMN_BASE_CATEGORY') }}</th>
                    <th class="reportlist-table__th reportlist-table__subcategory">{{ $t('REPORT__COLUMN_TOPIC_CATEGORY') }}</th>
                    <th class="reportlist-table__th reportlist-table__item">{{ $t('REPORT__COLUMN_TOPIC') }}</th>
                    <th class="reportlist-table__th reportlist-table__status">{{ $t('REPORT__COLUMN_STATUS') }}</th>
                    <th class="reportlist-table__th reportlist-table__link">{{ $t('REPORT__COLUMN_VIEW') }}</th>
                  </tr>
                </thead>

                <template v-if="tickets && !utils.isEmptyArray(tickets)">
                  <tbody>
                    <tr v-for="ticket in tickets" :key="ticket.csNumber">
                      <td class="reportlist-table__td reportlist-table__id">
                        {{ ticket.csNumber }}
                      </td>
                      <td class="reportlist-table__td reportlist-table__date">
                        {{ utils.formatTimestampToString(ticket.createdAt, $t('GENERAL__DATETIME_FORMAT')) }}
                      </td>
                      <th v-if="!isLegacy" class="reportlist-table__td reportlist-table__game">
                        {{ ticket.gameName }}
                      </th>
                      <td class="reportlist-table__td reportlist-table__category">
                        {{ ticket.baseCategoryName }}
                      </td>
                      <td class="reportlist-table__td reportlist-table__subcategory">
                        {{ ticket.ticketTopicCategoryName }}
                      </td>
                      <td class="reportlist-table__td reportlist-table__item">
                        {{ ticket.ticketTopicName }}
                      </td>
                      <td class="reportlist-table__td reportlist-table__status">
                        {{ $t('REPORT__STATUS_'.concat(constants.TICKET__TICKET_STATUS_MAPPING[ticket.ticketStatus].TICKET_STATUS)) }}
                      </td>
                      <td class="reportlist-table__td reportlist-table__link">
                        <router-link
                          v-if="ticket.hasUnreadReply"
                          :to="{
                            name: 'Report',
                            params: {
                              csNumber: ticket.csNumber,
                              isLegacy,
                            },
                            query: isLegacy ? { source: constants.TICKET__TICKET_STATUS_FOR_USER.LEGACY } : undefined,
                          }"
                          class="reportlist-table__replylink reportlist-table__replylink--unread"
                        >
                          {{ $t('REPORT__VIEW_UNREAD') }}
                        </router-link>
                        <router-link
                          v-else
                          :to="{
                            name: 'Report',
                            params: {
                              csNumber: ticket.csNumber,
                              isLegacy,
                            },
                            query: isLegacy ? { source: constants.TICKET__TICKET_STATUS_FOR_USER.LEGACY } : undefined,
                          }"
                          class="reportlist-table__replylink"
                        >
                          {{ $t('REPORT__VIEW_READ') }}
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </table>
            </div>

            <template v-if="tickets && !utils.isEmptyArray(tickets)">
              <pagination
                :currentOffset="pagination.curOffset"
                :totalCnt="pagination.totalCnt"
                :perPage="constants.GENERAL__RECORDS_PER_PAGE"
              />
            </template>

            <template v-if="!tickets">
              <div class="reportlist-empty">
                <img class="icon-loading" :src="`${CDN}/assets/pc/img/common/loading.gif`" alt="" />
              </div>
            </template>
            <template v-else-if="utils.isEmptyArray(tickets)">
              <div class="reportlist-empty">
                <div class="reportlist-empty__icon reportlist-empty__icon--failure"></div>
                <div class="reportlist-empty__title">{{ $t('REPORT__NO_RECORD') }}</div>
                <div class="reportlist-empty__text" v-html="$t('REPORT__NO_RECORD_DESC')"></div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/pc/layouts/Breadcrumb';
import Navigation from '@/components/pc/layouts/Navigation';
import Pagination from '@/components/pc/layouts/partial/Pagination';
import ticket from '@/api/prod/ticket';
import constants from '@/lib/constants';
import utils from '@/lib/utils';

export default {
  name: 'ReportList',
  components: {
    Navigation,
    Breadcrumb,
    Pagination,
  },
  data() {
    return {
      constants,
      utils,
      isFilterMenuVisible: false,
      selectedTicketStatus: 0,
      tickets: null,
      pagination: {},
      paths: [
        {
          id: 1,
          name: 'ReportList',
          title: this.$t('REPORT__TITLE'),
        },
      ],
    };
  },
  computed: {
    currentTicketStatus() {
      const queryStatus = parseInt(this.$route.query.status, 10);
      if (!this.ticketStatusNameMapping[queryStatus]) {
        return 0;
      }
      return queryStatus || this.selectedTicketStatus;
    },
    currentPage() {
      return this.$route.query.page || 1;
    },
    queryTicketStatus() {
      if (this.currentTicketStatus > 0) {
        return this.currentTicketStatus;
      }
      return null;
    },
    queryOffset() {
      return (this.currentPage - 1) * constants.GENERAL__RECORDS_PER_PAGE;
    },
    queryParams() {
      return { status: this.queryTicketStatus, offset: this.queryOffset };
    },
    ticketStatusNameMapping() {
      const ret = {};
      Object.keys(constants.TICKET__TICKET_STATUS_FOR_USER).forEach((ticketStatusName) => {
        ret[constants.TICKET__TICKET_STATUS_FOR_USER[ticketStatusName]] = {
          status: constants.TICKET__TICKET_STATUS_FOR_USER[ticketStatusName],
          name: ticketStatusName,
        };
      });
      return ret;
    },
    isLegacy() {
      return this.currentTicketStatus === constants.TICKET__TICKET_STATUS_FOR_USER.LEGACY;
    },
  },
  methods: {
    switchFilterMenu() {
      this.isFilterMenuVisible = !this.isFilterMenuVisible;
    },
    changeSelectedTicketStatus(status) {
      this.selectedTicketStatus = status;
      this.switchFilterMenu();
      if (!status) {
        this.$router.push({ query: {} });
      } else {
        this.$router.push({ query: { status: this.selectedTicketStatus.toString() } });
      }
    },
  },
  watch: {
    queryParams: {
      immediate: true,
      handler(newValue) {
        window.scrollTo(0, 0);
        ticket.getTickets(newValue.status, newValue.offset).then((resp) => {
          const { data, error } = resp;
          if (error || !data) {
            this.triggerPopup({
              title: this.$t('POPUP__ERROR'),
              content: this.$t('POPUP__ERROR_EXCEPTION'),
            });
          } else {
            this.tickets = data.tickets.slice(0, constants.GENERAL__RECORDS_PER_PAGE);
            this.pagination = {
              curOffset: data.pagination.curOffset,
              totalCnt: data.pagination.totalCnt,
            };
          }
        });
      },
    },
  },
};
</script>
