/* eslint-disable no-shadow */

import { MUTATION_SET_GAMES, MUTATION_SET_ERROR } from '@/store/mutation-types';
import { ACTION_GET_GAMES } from '@/store/action-types';
import game from '@/api/prod/game';
import constants from '@/lib/constants';
import common from '@/utils/common';

// initial state
const state = {
  games: null,
  globalErrorMsg: undefined,
};

const getters = {
  gamesCodeNameMapping(state) {
    const ret = {};
    if (!state.games || common.isEmpty(state.games)) {
      return ret;
    }
    if (state.games.pc) {
      state.games.pc.forEach((e) => {
        ret[e.code] = e.name;
      });
    }
    if (state.games.mobile) {
      state.games.mobile.forEach((e) => {
        ret[e.code] = e.name;
      });
    }
    return ret;
  },
  gamesCodeIdMapping(state) {
    const ret = {};
    if (!state.games || common.isEmpty(state.games)) {
      return ret;
    }
    if (state.games.pc) {
      state.games.pc.forEach((e) => {
        ret[e.code] = e.id;
      });
    }
    if (state.games.mobile) {
      state.games.mobile.forEach((e) => {
        ret[e.code] = e.id;
      });
    }
    return ret;
  },
  gamesCodeTypeMapping(state) {
    const ret = {};
    if (!state.games || common.isEmpty(state.games)) {
      return ret;
    }
    if (state.games.pc) {
      state.games.pc.forEach((e) => {
        ret[e.code] = constants.SEARCH__SEARCH_MENU_LISTS.PC;
      });
    }
    if (state.games.mobile) {
      state.games.mobile.forEach((e) => {
        ret[e.code] = constants.SEARCH__SEARCH_MENU_LISTS.MOBILE;
      });
    }
    return ret;
  },
  gamesIdMapping(state) {
    const ret = {};
    if (!state.games || common.isEmpty(state.games)) {
      return ret;
    }
    if (state.games.pc) {
      state.games.pc.forEach((e) => {
        ret[e.id] = { id: e.id, name: e.name, code: e.code };
      });
    }
    if (state.games.mobile) {
      state.games.mobile.forEach((e) => {
        ret[e.id] = { id: e.id, name: e.name, code: e.code };
      });
    }
    return ret;
  },
};

const actions = {
  async [ACTION_GET_GAMES]({ commit }) {
    try {
      const resp = await game.getGames();
      const { data } = resp;
      commit(MUTATION_SET_GAMES, data);
    } catch (err) {
      commit(MUTATION_SET_ERROR, { msg: err });
    }
  },
};

const mutations = {
  [MUTATION_SET_GAMES](state, data) {
    state.games = data;
  },
  [MUTATION_SET_ERROR](state, { msg }) {
    state.globalErrorMsg = msg;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
