var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pc", attrs: { id: "app-pc" } },
    [
      _c("topbar"),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("garena-footer"),
      _vm._v(" "),
      _vm.showAIFaqBtn ? _c("AIFaq") : _vm._e(),
      _vm._v(" "),
      _c("login-popup"),
      _vm._v(" "),
      _c("popup")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }