/* eslint-disable no-shadow */

import { MUTATION_SET_NEWS, MUTATION_SET_TOP_NEWS, MUTATION_SET_PAGINATION, MUTATION_SET_ERROR } from '@/store/mutation-types';
import { ACTION_GET_NEWS } from '@/store/action-types';
import news from '@/api/prod/news';

// initial state
const state = {
  topNews: null,
  news: null,
  allNews: [],
  pagination: null,
  globalErrorMsg: undefined,
};

const actions = {
  async [ACTION_GET_NEWS]({ commit }, offset = null) {
    try {
      const resp = await news.getNews(offset);
      const { data } = resp;
      if (!offset || offset === 0) {
        commit(MUTATION_SET_NEWS, null);
        commit(MUTATION_SET_TOP_NEWS, data.news.slice(0, 5));
      }
      commit(MUTATION_SET_NEWS, data.news);
      commit(MUTATION_SET_PAGINATION, data.pagination);
    } catch (err) {
      commit(MUTATION_SET_ERROR, { msg: err });
    }
  },
};

const mutations = {
  [MUTATION_SET_NEWS](state, data) {
    state.news = data;
    if (data === null) {
      state.allNews = [];
    } else {
      state.allNews = state.allNews.concat(data);
    }
  },
  [MUTATION_SET_TOP_NEWS](state, data) {
    state.topNews = data;
  },
  [MUTATION_SET_PAGINATION](state, data) {
    state.pagination = data;
  },
  [MUTATION_SET_ERROR](state, { msg }) {
    state.globalErrorMsg = msg;
  },
};

export default {
  state,
  actions,
  mutations,
};
