<template>
  <div class="home-news">
    <div class="home-section__title">
      <div class="home-section__title-icon home-section__title-icon--news"></div>
      <h3 class="home-section__title-txt">
        {{ $t('HOME__NEWS_TITLE') }}
      </h3>
    </div>
    <ul v-if="topNews" class="home-news__entry">
      <li v-for="news in topNews" :key="news.id" class="home-news__entry-item">
        <router-link :to="{ name: 'News', params: { id: news.id } }" class="home-news__entry-link">
          <div class="home-news__entry-icon"></div>
          <div class="home-news__entry-txt">
            {{ news.title }}
          </div>
        </router-link>
      </li>
    </ul>
    <router-link :to="{ name: 'NewsList' }" class="home-news__more">
      <div class="home-news__more-txt">
        {{ $t('HOME__NEWS_LEARN_MORE') }}
      </div>
      <div class="home-news__more-icon"></div>
    </router-link>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ACTION_GET_NEWS } from '@/store/action-types';

export default {
  name: 'TopNews',
  computed: {
    ...mapState({
      topNews: (state) => state.news.topNews,
    }),
  },
  methods: {
    ...mapActions({
      getNewsList: ACTION_GET_NEWS,
    }),
  },
  mounted() {
    this.getNewsList();
  },
};
</script>
