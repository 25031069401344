<template>
  <div v-if="hasPopup" class="notification-wrap">
    <div class="notification-dimmer">
      <div class="notification notification--sm">
        <button class="rountbutton__widget" @click.prevent="onClickButton">{{ $t('GENERAL__BUTTON_NOTIFICATION') }}</button>
        <div class="notification-inner">
          <div class="notification__title">
            <div class="notification__title-icon"></div>
            <div class="notification__title-txt">{{ popupContent.title }}</div>
          </div>
          <div class="notification__main">
            <div class="notification__content">
              <div class="notification__content-txt">
                <p v-html="popupContent.content"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import constants from '@/lib/constants';
import { ACTION_CLOSE_POPUP } from '@/store/action-types';

export default {
  name: 'Popup',
  data() {
    return {
      constants,
    };
  },
  computed: {
    ...mapState({
      hasPopup: (state) => state.common.hasPopup,
      popupContent: (state) => state.common.popupContent,
    }),
  },
  methods: {
    onClickButton() {
      if (this.popupContent.action) {
        this.popupContent.action();
      } else {
        this.closePopup();
      }
    },
    ...mapActions({
      closePopup: ACTION_CLOSE_POPUP,
    }),
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .notification-wrap {
    z-index: 10;
  }
}
</style>
