<template>
  <div class="checkbox-wrap">
    <label class="checkbox"
           :class="{'checkbox--error' : errorMsg, 'checkbox__disabled' : isDisabled }">
      <input class="checkbox__widget"
             type="checkbox"
             :name="name"
             :disabled="isDisabled"
             :checked="val"
             @input="$emit('input', $event.target.checked)"/>
      <div class="checkbox__indicator"></div>
      <div class="checkbox__label" v-html="label"></div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'CustomCheckbox',
  props: {
    label: String,
    name: String,
    isDisabled: Boolean,
    errorMsg: String,
    value: Boolean,
  },
  computed: {
    val() {
      return this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .checkbox__disabled {
    cursor: not-allowed;
  }
</style>
