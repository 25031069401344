var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "ga_footer" }, [
    _c("div", { staticClass: "fullc" }, [
      _c("div", { staticClass: "top-footer" }, [
        _c("div", { staticClass: "footer-rules" }, [
          _c(
            "a",
            { attrs: { href: _vm.$t("TERMS__TOS_LINK"), target: "_blank" } },
            [_vm._v(_vm._s(_vm.$t("TERMS__TOS")))]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "pipe" }, [_vm._v("|")]),
        _vm._v(" "),
        _c("div", { staticClass: "footer-rules" }, [
          _c(
            "a",
            { attrs: { href: _vm.$t("TERMS__PP_LINK"), target: "_blank" } },
            [_vm._v(_vm._s(_vm.$t("TERMS__PP")))]
          )
        ])
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Copyright © Garena Online. Trademarks belong to their respective owners. All rights reserved."
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }