var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-wrap" },
    [
      _c("ticket-step", { attrs: { step: _vm.step } }),
      _vm._v(" "),
      _vm.step === 1
        ? [
            _c("section", { staticClass: "game-section" }, [
              _c("div", { staticClass: "reportlist-main" }, [
                _c("div", { staticClass: "reportlist-title" }, [
                  _c("div", { staticClass: "faqdetail__question" }, [
                    _vm._v(_vm._s(_vm.$t("CANT_LOGIN__ITEM_RESETPWD_LINKTEXT")))
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "reportlist-result-subhead",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("M_CANT_LOGIN__ACCOUNT_RECOVERY_REMINDER")
                      )
                    }
                  })
                ])
              ])
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "form-section" }, [
              _c("form", { staticClass: "report-form" }, [
                _c("div", { staticClass: "report-form__content" }, [
                  _c("div", { staticClass: "report-form__label" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("CANT_LOGIN__ACCOUNT_RECOVERY_ACCOUNT")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "report-form__field" },
                    [
                      _c("custom-input", {
                        attrs: {
                          name: "accountName",
                          placeholder: _vm.$t(
                            "GENERAL__INPUT_PLACEHOLDER_HALFWIDTH"
                          ),
                          "custom-type":
                            _vm.constants.GENERAL__INPUT_TYPES.GARENA_ACCOUNT,
                          "error-msg": _vm.$t(
                            "GENERAL__INPUT_ERROR_ACCOUNT_FORMAT"
                          ),
                          "need-validation": true,
                          validation: _vm.fieldsValidation.accountName
                        },
                        on: {
                          "update:validation": function($event) {
                            return _vm.$set(
                              _vm.fieldsValidation,
                              "accountName",
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.fieldsValue.accountName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.fieldsValue,
                              "accountName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "fieldsValue.accountName"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "report-form__content" }, [
                  _c("div", { staticClass: "report-form__label" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("CANT_LOGIN__ACCOUNT_RECOVERY_REGISTER_EMAIL")
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "report-form__field" },
                    [
                      _c("custom-input", {
                        attrs: {
                          name: "registrationEmail",
                          placeholder: _vm.$t(
                            "GENERAL__INPUT_PLACEHOLDER_HALFWIDTH"
                          ),
                          "custom-type":
                            _vm.constants.GENERAL__INPUT_TYPES.EMAIL,
                          "error-msg": _vm.$t(
                            "GENERAL__INPUT_ERROR_EMAIL_FORMAT"
                          ),
                          "extra-msg": _vm.$t(
                            "GENERAL__INPUT_PLACEHOLDER_HALFWIDTH"
                          ),
                          "need-validation": true,
                          validation: _vm.fieldsValidation.registrationEmail
                        },
                        on: {
                          "update:validation": function($event) {
                            return _vm.$set(
                              _vm.fieldsValidation,
                              "registrationEmail",
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.fieldsValue.registrationEmail,
                          callback: function($$v) {
                            _vm.$set(_vm.fieldsValue, "registrationEmail", $$v)
                          },
                          expression: "fieldsValue.registrationEmail"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "report-form__content" }, [
                  _c("div", { staticClass: "report-form__label" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("CANT_LOGIN__ACCOUNT_RECOVERY_PHONE")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "onlineservice-form__field" }, [
                    _c(
                      "div",
                      { staticClass: "onlineservice-form__field-phone" },
                      [
                        _c("phone-input", {
                          attrs: {
                            name: "notification_info",
                            placeholder: _vm.$t(
                              "GENERAL__INPUT_PLACEHOLDER_HALFWIDTH_INT"
                            ),
                            "error-msg": _vm.$t(
                              "GENERAL__INPUT_ERROR_PHONE_FORMAT"
                            )
                          },
                          model: {
                            value: _vm.contactNumberObj,
                            callback: function($$v) {
                              _vm.contactNumberObj = $$v
                            },
                            expression: "contactNumberObj"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "report-form__content" }, [
                  _c("div", { staticClass: "report-form__label" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("CANT_LOGIN__ACCOUNT_RECOVERY_CONTACT_EMAIL")
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "report-form__field" },
                    [
                      _c("custom-checkbox", {
                        attrs: {
                          name: "contactEmailCheckbox",
                          label: _vm.$t(
                            "CANT_LOGIN__ACCOUNT_RECOVERY_SAME_EMAIL"
                          ),
                          "is-disabled": !_vm.canSwitchContactEmailSame
                        },
                        model: {
                          value: _vm.isContactEmailSame,
                          callback: function($$v) {
                            _vm.isContactEmailSame = $$v
                          },
                          expression: "isContactEmailSame"
                        }
                      }),
                      _vm._v(" "),
                      !_vm.canSwitchContactEmailSame
                        ? _c("div", { staticClass: "report-form__error" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "CANT_LOGIN__ACCOUNT_RECOVERY_SAME_EMAIL_TIP"
                                  )
                                ) +
                                "\n            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("custom-input", {
                        attrs: {
                          name: "contactEmail",
                          placeholder: _vm.$t(
                            "GENERAL__INPUT_PLACEHOLDER_HALFWIDTH"
                          ),
                          "custom-type":
                            _vm.constants.GENERAL__INPUT_TYPES.EMAIL,
                          "error-msg": _vm.$t(
                            "GENERAL__INPUT_ERROR_EMAIL_FORMAT"
                          ),
                          "note-msg": _vm.$t(
                            "CANT_LOGIN__ACCOUNT_RECOVERY_CONTACT_EMAIL_TIP"
                          ),
                          "is-disabled": _vm.isContactEmailSame,
                          "need-validation": true,
                          validation: _vm.fieldsValidation.contactEmail
                        },
                        on: {
                          "update:validation": function($event) {
                            return _vm.$set(
                              _vm.fieldsValidation,
                              "contactEmail",
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.fieldsValue.contactEmail,
                          callback: function($$v) {
                            _vm.$set(_vm.fieldsValue, "contactEmail", $$v)
                          },
                          expression: "fieldsValue.contactEmail"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "report-form__content" },
                  [
                    _c("div", { staticClass: "report-form__label" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "CANT_LOGIN__ACCOUNT_RECOVERY_PROFILE_STATUS"
                            )
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("custom-radio", {
                      attrs: {
                        name: "isUserInfoComplete",
                        label: _vm.$t(
                          "CANT_LOGIN__ACCOUNT_RECOVERY_PROFILE_WITH_FULL_DATA"
                        ),
                        "radio-value":
                          _vm.constants
                            .APPLICATION__ACCOUNT_RECOVERY_USER_INFO_STATUS
                            .COMPLETE
                      },
                      model: {
                        value: _vm.fieldsValue.isUserInfoComplete,
                        callback: function($$v) {
                          _vm.$set(_vm.fieldsValue, "isUserInfoComplete", $$v)
                        },
                        expression: "fieldsValue.isUserInfoComplete"
                      }
                    }),
                    _vm._v(" "),
                    _c("custom-radio", {
                      attrs: {
                        name: "isUserInfoComplete",
                        label: _vm.$t(
                          "CANT_LOGIN__ACCOUNT_RECOVERY_PROFILE_WITHOUT_FULL_DATA"
                        ),
                        "radio-value":
                          _vm.constants
                            .APPLICATION__ACCOUNT_RECOVERY_USER_INFO_STATUS
                            .NOT_COMPLETE
                      },
                      model: {
                        value: _vm.fieldsValue.isUserInfoComplete,
                        callback: function($$v) {
                          _vm.$set(_vm.fieldsValue, "isUserInfoComplete", $$v)
                        },
                        expression: "fieldsValue.isUserInfoComplete"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "reportlist__return",
                attrs: { disabled: !_vm.isFirstButtonEnabled },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toNextStep.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("GENERAL__BUTTON_NEXT_STEP")) +
                    "\n    "
                )
              ]
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.step === 2
        ? [
            _c("section", { staticClass: "form-section" }, [
              _c("form", { staticClass: "report-form" }, [
                _c(
                  "div",
                  { staticClass: "report-form__content" },
                  [
                    _c("div", { staticClass: "report-form__label" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP"
                            )
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("ol", { staticClass: "report-form__ol" }, [
                      _c(
                        "li",
                        { staticClass: "report-form__row" },
                        [
                          _c("custom-radio", {
                            attrs: {
                              name: "topup_status",
                              label: _vm.$t(
                                "CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_NO"
                              ),
                              "radio-value":
                                _vm.constants
                                  .APPLICATION__ACCOUNT_RECOVERY_USER_TOPUP_STATUS
                                  .NO
                            },
                            model: {
                              value: _vm.fieldsValue.hasTopUpHistory,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.fieldsValue,
                                  "hasTopUpHistory",
                                  $$v
                                )
                              },
                              expression: "fieldsValue.hasTopUpHistory"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "report-form__row" },
                        [
                          _c("custom-radio", {
                            attrs: {
                              name: "topup_status",
                              label: _vm.$t(
                                "CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_YES"
                              ),
                              "radio-value":
                                _vm.constants
                                  .APPLICATION__ACCOUNT_RECOVERY_USER_TOPUP_STATUS
                                  .YES
                            },
                            model: {
                              value: _vm.fieldsValue.hasTopUpHistory,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.fieldsValue,
                                  "hasTopUpHistory",
                                  $$v
                                )
                              },
                              expression: "fieldsValue.hasTopUpHistory"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm.fieldsValue.hasTopUpHistory
                      ? [
                          _c("div", { staticClass: "report-form__label" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_LAST_TOPUP_TIME"
                                  )
                                ) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "report-form__field" },
                            [
                              _c(
                                "custom-datetime-picker",
                                {
                                  attrs: {
                                    type: "datetime",
                                    format: _vm.$t("GENERAL__DATETIME_FORMAT"),
                                    "not-after": new Date()
                                  },
                                  model: {
                                    value: _vm.fieldsValue.lastTopUpTime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.fieldsValue,
                                        "lastTopUpTime",
                                        $$v
                                      )
                                    },
                                    expression: "fieldsValue.lastTopUpTime"
                                  }
                                },
                                [
                                  _c("div", {
                                    staticClass: "profile-form__inputdateicon",
                                    attrs: { slot: "calendar-icon" },
                                    slot: "calendar-icon"
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "report-form__label" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_AMOUNT"
                                  )
                                ) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "report-form__field" },
                            [
                              _c("custom-input", {
                                attrs: {
                                  name: "lastTopUpAmount",
                                  type: "number",
                                  placeholder: _vm.$t(
                                    "CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_AMOUNT_PLACEHOLDER"
                                  ),
                                  children:
                                    "<div class='onlineservice-form__field-priceunit'>" +
                                    _vm.$t(
                                      "CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_AMOUNT_UNIT"
                                    ) +
                                    "</div>"
                                },
                                model: {
                                  value: _vm.fieldsValue.lastTopUpAmount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.fieldsValue,
                                      "lastTopUpAmount",
                                      $$v
                                    )
                                  },
                                  expression: "fieldsValue.lastTopUpAmount"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "report-form__label" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_CHANNEL"
                                  )
                                ) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "report-form__field" },
                            [
                              _c("custom-input", {
                                attrs: { name: "lastTopUpChannel" },
                                model: {
                                  value: _vm.fieldsValue.lastTopUpChannel,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.fieldsValue,
                                      "lastTopUpChannel",
                                      $$v
                                    )
                                  },
                                  expression: "fieldsValue.lastTopUpChannel"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "report-form__label" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_ACCOUNT_CREATED_TIME"
                            )
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "report-form__field" },
                      [
                        _c("custom-input", {
                          attrs: {
                            name: "accountCreateYear",
                            placeholder: _vm.$t(
                              "CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_TOPUP_LAST_TOPUP_TIME_PLACEHOLDER"
                            )
                          },
                          model: {
                            value: _vm.fieldsValue.accountCreateYear,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.fieldsValue,
                                "accountCreateYear",
                                $$v
                              )
                            },
                            expression: "fieldsValue.accountCreateYear"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "report-form__label" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_LAST_LOGIN_TIME"
                            )
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "report-form__field" },
                      [
                        _c(
                          "custom-datetime-picker",
                          {
                            attrs: {
                              type: "datetime",
                              format: _vm.$t("GENERAL__DATETIME_FORMAT"),
                              "not-after": new Date()
                            },
                            model: {
                              value: _vm.fieldsValue.lastOnlineTime,
                              callback: function($$v) {
                                _vm.$set(_vm.fieldsValue, "lastOnlineTime", $$v)
                              },
                              expression: "fieldsValue.lastOnlineTime"
                            }
                          },
                          [
                            _c("div", {
                              staticClass: "profile-form__inputdateicon",
                              attrs: { slot: "calendar-icon" },
                              slot: "calendar-icon"
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "report-form__label" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "CANT_LOGIN__ACCOUNT_RECOVERY_EXTRA_INFO_MAIN_GAME"
                            )
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "report-form__field" },
                      [
                        _c("custom-select", {
                          attrs: {
                            hasPlaceholder: true,
                            placeholder: _vm.$t(
                              "GENERAL__PLACEHOLDER_DEFAULT_SELECT_GAME"
                            ),
                            options: _vm.gamesIdMapping
                          },
                          model: {
                            value: _vm.mainGameId,
                            callback: function($$v) {
                              _vm.mainGameId = $$v
                            },
                            expression: "mainGameId"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "reportlist__return",
                attrs: { disabled: !_vm.isSecondButtonEnabled },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toNextStep.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("GENERAL__BUTTON_NEXT_STEP")) +
                    "\n    "
                )
              ]
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.step === 3
        ? [
            _c("section", { staticClass: "form-section" }, [
              _c(
                "form",
                { staticClass: "report-form" },
                _vm._l(_vm.playerInfo, function(playerInfoField) {
                  return _c(
                    "div",
                    {
                      key: playerInfoField.order,
                      staticClass: "report-form__content"
                    },
                    [
                      _c("div", { staticClass: "report-form__label" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(playerInfoField.displayName) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "report-form__field" },
                        [
                          _c("custom-input", {
                            attrs: {
                              name: "playinfo_" + playerInfoField.order,
                              placeholder: playerInfoField.placeholder
                            },
                            on: {
                              change: function($event) {
                                return _vm.$forceUpdate()
                              }
                            },
                            model: {
                              value:
                                _vm.fieldsValue.playerInfo[playerInfoField.key],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.fieldsValue.playerInfo,
                                  playerInfoField.key,
                                  $$v
                                )
                              },
                              expression:
                                "fieldsValue.playerInfo[playerInfoField.key]"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "reportlist__return",
                attrs: { disabled: !_vm.isThirdButtonEnabled },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.toNextStep.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("GENERAL__BUTTON_NEXT_STEP")) +
                    "\n    "
                )
              ]
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.step === 4
        ? [
            _c("section", { staticClass: "form-section" }, [
              _c(
                "form",
                {
                  staticClass: "report-form",
                  staticStyle: { "margin-bottom": "40px" }
                },
                [
                  _c("div", { staticClass: "report-form__content" }, [
                    _c("div", { staticClass: "report-form__label" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD")
                          ) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "report-form__field" },
                      [
                        _c("uploader", {
                          attrs: {
                            name: "files",
                            label: _vm.$t("GENERAL__BUTTON_UPLOAD_ID"),
                            "is-anonymous": true,
                            "show-notice": false,
                            "is-required": true,
                            "is-img-only": true,
                            "max-file-cnt":
                              _vm.constants.GENERAL__MAX_FILE_CNT.APPLICATION,
                            "error-msg": _vm.$t(
                              "GENERAL__INPUT_ERROR_FILE_NOT_SELECTED"
                            )
                          },
                          on: {
                            uploaded: _vm.addUploadedFileIds,
                            removed: _vm.removeUploadedFileIds
                          }
                        }),
                        _vm._v(" "),
                        _vm.fieldsValue.isUserInfoComplete
                          ? _c("ol", {
                              staticClass: "report-form__uploader-info",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD_NOTICE_1"
                                  )
                                )
                              }
                            })
                          : _c("ol", {
                              staticClass: "report-form__uploader-info",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "CANT_LOGIN__ACCOUNT_RECOVERY_UPLOAD_NOTICE_2"
                                  )
                                )
                              }
                            })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "bg-grey" }),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "report-form",
                  staticStyle: { "margin-top": "16px" }
                },
                [
                  _c("ol", { staticClass: "report-form__ol" }, [
                    _c("li", { staticClass: "report-form__row" }, [
                      _c(
                        "div",
                        { staticClass: "backSelect" },
                        [
                          _c("custom-checkbox", {
                            attrs: {
                              name: "confirm_checkbox",
                              label: _vm.$t(
                                "CANT_LOGIN__ACCOUNT_RECOVERY_CONFIRM"
                              )
                            },
                            model: {
                              value: _vm.isConfirmChecked,
                              callback: function($$v) {
                                _vm.isConfirmChecked = $$v
                              },
                              expression: "isConfirmChecked"
                            }
                          }),
                          _vm._v(" "),
                          _c("custom-checkbox", {
                            attrs: {
                              name: "tos_checkbox",
                              label: _vm.$t(
                                "CANT_LOGIN__ACCOUNT_RECOVERY_CHECKBOX"
                              )
                            },
                            model: {
                              value: _vm.isTosChecked,
                              callback: function($$v) {
                                _vm.isTosChecked = $$v
                              },
                              expression: "isTosChecked"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "reportlist__return",
                attrs: { disabled: !_vm.isFourthButtonEnabled },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.submitAccountRecoveryApplication.apply(
                      null,
                      arguments
                    )
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("GENERAL__BUTTON_SUBMIT_FORM")) +
                    "\n      "
                ),
                _vm.isSubmitting
                  ? _c("vue-countdown", {
                      attrs: { time: 10000 },
                      on: { end: _vm.handleCountdownEnd },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _vm._v("(" + _vm._s(props.totalSeconds) + ")")
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1521751193
                      )
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowingConfirmLeaving
        ? _c("confirm", {
            attrs: {
              title: _vm.$t("POPUP__ALERT"),
              content: _vm.$t("POPUP__CONFIRM_LEAVE")
            },
            on: { close: _vm.closeConfirmLeaving, confirm: _vm.toNext }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }