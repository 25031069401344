var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrap" }, [
    _c("section", { staticClass: "game-section" }, [
      _c("div", { staticClass: "cantlogin-list__main" }, [
        _c("div", { staticClass: "reportlist__main-top" }, [
          _c("div", { staticClass: "reportlist__main-top--title" }, [
            _vm._v(_vm._s(_vm.$t("M_HOME__VERIFICATION_TITLE")))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cantlogin-list__main-list" }, [
          _c(
            "ul",
            { staticClass: "cproblem__main-list" },
            [
              _c("li", { on: { click: _vm.switchSectionAccount } }, [
                _c("div", { staticClass: "cproblem__main-item" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("VERIFICATION__ITEM_ACCOUNT_TITLE")))
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "cantlogin-list__main-item--icon",
                    class: {
                      "cproblem__main-item--icon--active":
                        _vm.sectionAccountOpen
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "slide-fade" } },
                [
                  _vm.sectionAccountOpen
                    ? [
                        _c("div", { staticClass: "verification-inner" }, [
                          _c("div", { staticClass: "verification__inner" }, [
                            _c("div", {
                              staticClass:
                                "verification__inner-icon verification__inner-icon--email"
                            }),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "verification__inner-content",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("M_VERIFICATION__ITEM_ACCOUNT_CONTENT")
                                )
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "verification__inner-verf",
                                attrs: {
                                  href:
                                    "https://account.garena.com/security?locale_name=TW",
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "VERIFICATION__ITEM_ACCOUNT_LINKTEXT"
                                      )
                                    ) +
                                    "\n                  "
                                )
                              ]
                            )
                          ])
                        ])
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("li", { on: { click: _vm.switchSection2Steps } }, [
                _c("div", { staticClass: "cproblem__main-item" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("VERIFICATION__ITEM_2STEPS_TITLE")))
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "cantlogin-list__main-item--icon",
                    class: {
                      "cproblem__main-item--icon--active": _vm.section2StepsOpen
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "slide-fade" } },
                [
                  _vm.section2StepsOpen
                    ? [
                        _c("div", { staticClass: "verification-inner" }, [
                          _c("div", { staticClass: "verification__inner" }, [
                            _c("div", {
                              staticClass:
                                "verification__inner-icon verification__inner-icon--email"
                            }),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "verification__inner-content",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("M_VERIFICATION__ITEM_2STEPS_CONTENT")
                                )
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "verification__inner-verf",
                                attrs: {
                                  href:
                                    "https://account.garena.com/security/mobile/2-step-verification?locale_name=TW",
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "VERIFICATION__ITEM_2STEPS_LINKTEXT"
                                      )
                                    ) +
                                    "\n                  "
                                )
                              ]
                            )
                          ])
                        ])
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("li", { on: { click: _vm.switchSectionReset } }, [
                _c("div", { staticClass: "cproblem__main-item" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("VERIFICATION__ITEM_RESET_TITLE")))
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "cantlogin-list__main-item--icon",
                    class: {
                      "cproblem__main-item--icon--active": _vm.sectionResetOpen
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "slide-fade" } },
                [
                  _vm.sectionResetOpen
                    ? [
                        _c("div", { staticClass: "verification-inner" }, [
                          _c(
                            "div",
                            { staticClass: "verification__inner" },
                            [
                              _c("div", {
                                staticClass:
                                  "verification__inner-icon verification__inner-icon--email"
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "verification__inner-content",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("M_VERIFICATION__ITEM_RESET_CONTENT")
                                  )
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "verification__inner-verf",
                                  attrs: { to: { name: "MobileResetProfile" } }
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "VERIFICATION__RESET_PROFILE_TITLE"
                                        )
                                      ) +
                                      "\n                  "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("hr")
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }