<template>
  <div class="main-wrap">
    <div class="main-bg main-bg-game" :style="mainBgImage" />
    <div class="main">
      <navigation
        :title="currentGameName"
        :has-search-bar="true"
        :search-type="'game'"
        :search-code="gameCodeOnUrl"
        :is-blank="true"
        :is-game-page="true"
      />
      <section class="game-section">
        <ul class="cproblem__category">
          <div v-for="baseCategory in baseCategories" :key="baseCategory.name">
            <li>
              <router-link
                :to="{ name: 'MobileArticleList', params: { gameCode: gameCodeOnUrl, baseCategory: baseCategory.code } }"
                class="cproblem__category-link"
              >
                <div class="cproblem__category-icon" :class="['cproblem__category-icon--' + baseCategory.code]"></div>
                <div class="cproblem__category-txt">
                  <h2>{{ baseCategory.name }}</h2>
                </div>
                <div class="cproblem__category-blank"></div>
                <div class="cproblem__category-arrow">
                  <div class="cproblem__category-arrow--icon"></div>
                </div>
              </router-link>
            </li>
            <hr />
          </div>
        </ul>
      </section>

      <template v-if="currentGameId">
        <notification :pos="'game'" :category-id="currentGameId" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Navigation from '@/components/mobile/layouts/Navigation';
import Notification from '@/components/mobile/layouts/Notification';

export default {
  name: 'BaseCategoryList',
  components: {
    Navigation,
    Notification,
  },
  data() {
    return {
      gameCodeOnUrl: this.$route.params.gameCode,
      baseCategoryNameOnUrl: this.$route.params.baseCategory,
    };
  },
  computed: {
    currentGameName() {
      return this.gamesCodeNameMapping[this.gameCodeOnUrl] || '';
    },
    currentGameId() {
      return this.gamesCodeIdMapping[this.gameCodeOnUrl];
    },
    mainBgImage() {
      return {
        'background-image': `url(${process.env.CDN}/games/${this.gameCodeOnUrl}/bg-main-${this.gameCodeOnUrl}.jpg)`,
      };
    },
    ...mapState({
      games: (state) => state.game.games,
      baseCategories: (state) => state.category.baseCategories,
    }),
    ...mapGetters(['gamesCodeNameMapping', 'gamesCodeIdMapping']),
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  .cproblem__category-icon img {
    width: 18px;
  }
}
</style>
