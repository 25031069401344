<template>
  <section class="step">
    <div :class="['step-' + step + '-img']"></div>
  </section>
</template>

<script>
export default {
  props: ['step'],
  name: 'TicketStep',
};
</script>
