var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-wrap" }, [
    _c(
      "section",
      { staticClass: "game-section" },
      [
        _vm.games && _vm.games.pc
          ? [
              _c("div", { staticClass: "game-section__title" }, [
                _c("div", {
                  staticClass:
                    "game-section__title-icon game-section__title-icon-pcgames"
                }),
                _vm._v(" "),
                _c("h3", { staticClass: "game-section__title-txt" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("HOME__PC_GAME_QUESTION")) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "game-cards" },
                _vm._l(_vm.games.pc, function(game) {
                  return _c(
                    "router-link",
                    {
                      key: game.id,
                      staticClass: "game-cards__item",
                      attrs: {
                        to: {
                          name: "MobileBaseCategoryList",
                          params: { gameCode: game.code }
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "game-cards__img",
                        attrs: { src: game.displayImageUrl, alt: "" }
                      })
                    ]
                  )
                }),
                1
              )
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.games && _vm.games.mobile
          ? [
              _c("div", { staticClass: "game-section__title" }, [
                _c("div", {
                  staticClass:
                    "game-section__title-icon game-section__title-icon-mgames"
                }),
                _vm._v(" "),
                _c("h3", { staticClass: "game-section__title-txt" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("HOME__MOBILE_GAME_QUESTION")) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "game-cards" },
                _vm._l(_vm.games.mobile, function(game) {
                  return _c(
                    "router-link",
                    {
                      key: game.id,
                      staticClass: "game-cards__item",
                      attrs: {
                        to: {
                          name: "MobileBaseCategoryList",
                          params: { gameCode: game.code }
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "game-cards__img",
                        attrs: { src: game.displayImageUrl, alt: "" }
                      })
                    ]
                  )
                }),
                1
              )
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }